import classNames from 'classnames'
import { string, bool } from 'prop-types'
import React from 'react'
import styles from './SubNavItem.styl'

const SubNavItem = ({ url, label, fullHeight }) => {
  return (
    <div
      className={classNames(
        styles.SubNavItem,
        fullHeight && styles.SubNavItemFullHeight
      )}
    >
      <a href={url}>{label}</a>
    </div>
  )
}

export default SubNavItem
SubNavItem.propTypes = {
  url: string,
  label: string,
  fullHeight: bool
}
