import React, { useState } from 'react'
import { number } from 'prop-types'
import createDebug from 'debug'

import styles from './NavLinks.styl'

import AlertDot from '../../../../AlertDot'
import Icon from '../../../../primitive/Icon'
import AlertsPopover from '../../../../AlertsPopover'

const debug = createDebug('nav-links')

const NavLinks = ({ newFollowingCount }) => {
  const [articles, setArticles] = useState([])
  const [count, setCount] = useState(newFollowingCount || 0)
  const [loading, setLoading] = useState(false)
  const [hasLoadedAlerts, setHasLoadedAlerts] = useState(false)
  const [alertsPopoverOpen, setAlertsPopoverOpen] = useState(false)

  const handleClearAlerts = async () => {
    debug('clearing alerts')
    setLoading(true)
    try {
      const res = await fetch(`/api/feed/clear`, {
        method: 'POST',
        credentials: 'include',
        redirect: 'error'
      })
      setLoading(false)
      if (res.status !== 200) {
        debug('clear alerts status', res.status)
        return
      }

      setArticles([])
      setCount(0)
    } catch (error) {
      debug('clear alerts error', error)
      window.Sentry.captureException(error)
      setLoading(false)
    }
  }

  const handleLoadAlerts = async () => {
    debug('loading alerts')
    setLoading(true)
    try {
      const res = await fetch(`/api/feed/alerts`, {
        credentials: 'include',
        redirect: 'error'
      })
      setLoading(false)
      if (res.status !== 200) {
        debug('load alerts status', res.status)
        return
      }

      const data = await res.json()
      setArticles(data)
      setCount(data.length)
      setHasLoadedAlerts(true)
    } catch (error) {
      debug('load alerts error', error)
      window.Sentry.captureException(error)
      setLoading(false)
    }
  }

  const handleToggleAlertsPopover = e => {
    e.preventDefault()
    setAlertsPopoverOpen(prevState => !prevState)
    if (!hasLoadedAlerts) handleLoadAlerts()
  }

  const links = [
    {
      url: '/iq/saved',
      title: 'Saved Items',
      icon: 'Bookmark'
    },
    {
      url: '/iq/feed',
      title: 'Alerts',
      icon: 'NotificationsFilled',
      onClick: handleToggleAlertsPopover,
      count
    },
    {
      url: '/iq/profile',
      title: 'Account Settings',
      icon: 'Cog'
    }
  ]
  return (
    <ul className={styles.NavLinks}>
      <AlertsPopover
        loading={loading}
        open={alertsPopoverOpen}
        items={articles}
        onClear={handleClearAlerts}
        onClose={() => setAlertsPopoverOpen(false)}
      />
      {links.map(link => (
        <li key={`NavLink:${link.url}`} className={styles.NavLinksItem}>
          <a
            href={link.url}
            className={styles.NavLinksLink}
            title={link.title}
            onClick={link.onClick}
          >
            {link.icon && <Icon type={link.icon} width={24} />}
            {!!link.count && (
              <div className={styles.NavLinksIndicator}>
                <AlertDot count={link.count > 9999 ? '999+' : link.count} />
              </div>
            )}
          </a>
        </li>
      ))}
    </ul>
  )
}

NavLinks.propTypes = {
  newFollowingCount: number
}

export default NavLinks
