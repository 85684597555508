import renderComponent from '../lib/component-renderer'
import NewsletterSignupForm from '../../../../components/site/layout/component/NewsletterSignupForm/component'
import FooterNewsletterSignupForm from '../../../../components/site/layout/component/FooterNewsletterSignupForm/component'
import ResourcesForm from '../../../../components/site/widgets/resources-form/component/ResourcesForm'

const debug = require('debug')('forms')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init NewsletterSignupForm')
    renderComponent('.js-newsletter-signup-form', NewsletterSignupForm)
    debug('init FooterNewsletterSignupForm')
    renderComponent(
      '.js-footer-newsletter-signup-form',
      FooterNewsletterSignupForm
    )
    debug('init ResourcesForm')
    renderComponent('.js-resources-form', ResourcesForm)
  })
}
