import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import PropTypes from 'prop-types'
import Button from '../primitive/Button'
import classNames from 'classnames'
import Icon from '../primitive/Icon'
import ArticleCard from '../ArticleCard'

import styles from './ReadNext.styl'

const ArrowButton = ({ direction, onClick, disabled, className }) => (
  <Button
    icon
    className={classNames(
      styles.NavButton,
      disabled && styles.disabled,
      className
    )}
    onClick={onClick}
    ariaLabel="previous"
  >
    <Icon type={direction === 'left' ? 'ChevronLeft' : 'ChevronRight'} />
  </Button>
)

ArrowButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  onClick: PropTypes.func
}

const ReadNext = props => {
  const { title, articles, savedLists, hideSaveDropdown, className } = props

  const [canScrollNext, setCanScrollNext] = useState(false)
  const [canScrollPrev, setCanScrollPrev] = useState(false)

  // Duplicate articles to create less glitchy looping effect
  const renderedArticles =
    (articles && articles.length >= 4
      ? articles.concat(articles).map((article, i) => {
          return { ...article, key: article._id + i }
        })
      : articles) || []

  const [emblaRef, slider] = useEmblaCarousel({
    slidesToScroll: 1,
    startIndex: Math.round(renderedArticles.length / 2),
    containScroll: 'keepSnaps',
    skipSnaps: true,
    align: 'start',
    duration: 25,
    loop: true
  })

  const onSelect = useCallback(slider => {
    setCanScrollNext(slider.canScrollNext())
    setCanScrollPrev(slider.canScrollPrev())
  }, [])

  const handleScrollNext = useCallback(() => {
    slider && slider.scrollNext()
  }, [slider])

  const handleScrollPrev = useCallback(() => {
    slider && slider.scrollPrev()
  }, [slider])

  useEffect(() => {
    if (!slider) return
    onSelect(slider)
    slider.on('select', onSelect)
    slider.on('reInit', onSelect)

    return () => {
      slider.off('select', onSelect)
      slider.off('reInit', onSelect)
    }
  }, [slider])

  if (renderedArticles.length <= 0) return null

  return (
    <div className={classNames(styles.ReadNext, className)}>
      <div className={styles.OverflowContainer}>
        <div className={styles.Container}>
          <div className={styles.Intro}>
            <div className={styles.HeadlineContainer}>
              {title && <h2 className={styles.Headline}>{title}</h2>}
              <div className={styles.MobileNav}>
                <ArrowButton
                  direction="left"
                  onClick={handleScrollPrev}
                  disabled={!canScrollPrev}
                />
                <ArrowButton
                  direction="right"
                  onClick={handleScrollNext}
                  disabled={!canScrollNext}
                />
              </div>
            </div>
          </div>
          <div className={styles.CarouselWrapper} ref={emblaRef}>
            <div className={styles.Carousel}>
              {renderedArticles.map(article => (
                <div className={styles.Article} key={article.key}>
                  <ArticleCard
                    savedLists={savedLists}
                    hideSaveDropdown={hideSaveDropdown}
                    {...article}
                  />
                </div>
              ))}
            </div>
            <div className={styles.DesktopNav}>
              <ArrowButton
                direction="left"
                onClick={handleScrollPrev}
                disabled={!canScrollPrev}
              />
              <ArrowButton
                direction="right"
                onClick={handleScrollNext}
                disabled={!canScrollNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ReadNext.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  articles: PropTypes.array,
  subtitle: PropTypes.string,
  savedLists: PropTypes.array,
  hideSaveDropdown: PropTypes.bool
}

export default ReadNext
