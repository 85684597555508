import React, { useEffect, useRef, useState } from 'react'
import { array, bool, func } from 'prop-types'
import classNames from 'classnames'
import useEscapeKey from '../../lib/hook/useEscapeKey'
import useOutsideClick from '../../lib/hook/useOutsideClick'

import styles from './DropdownSave.styl'

import Icon from '../primitive/Icon'
import InlineGroup from '../InlineGroup'
import ShrinkWrap, { ShrinkWrapItem } from '../primitive/ShrinkWrap'

const DropdownSave = ({
  activeWithin,
  alignRight,
  block,
  lists,
  onChange,
  onNewList,
  inverse
}) => {
  const [expanded, setExpanded] = useState(false)
  const [out, setOut] = useState({})
  const wrapper = useRef()
  const list = useRef()

  const selectedLists = lists.filter(list => list.checked)
  const filteredLists = lists.filter(({ isDefault }) => !isDefault)

  const handleCheckEdges = () => {
    const bounding = list.current.getBoundingClientRect()
    const out = {}
    out.top = bounding.top < 0
    out.left = bounding.left < 0
    out.bottom =
      bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight)
    out.right =
      bounding.right >
      (window.innerWidth || document.documentElement.clientWidth)
    out.any = out.top || out.left || out.bottom || out.right
    out.all = out.top && out.left && out.bottom && out.right
    setOut(out)
  }

  const handleOpen = e => {
    e.preventDefault()
    setExpanded(true)
  }

  const handleClose = e => {
    e && e.preventDefault()
    setExpanded(false)
    setOut({})
  }

  const handleListSelection = (e, list) => {
    e.preventDefault()
    onChange && onChange(list)
    handleClose()
  }

  const handleNewList = e => {
    e.preventDefault()
    onNewList && onNewList()
  }

  useEscapeKey(expanded && handleClose)
  useOutsideClick(wrapper, expanded && handleClose)

  useEffect(() => {
    if (!expanded) return
    handleCheckEdges()
  }, [expanded])

  return (
    <div
      className={classNames(
        styles.DropdownSave,
        alignRight && styles.alignRight,
        activeWithin && styles.activeWithin,
        block && styles.block,
        inverse && styles.inverse,
        expanded && styles.expanded
      )}
      ref={wrapper}
    >
      <div
        className={classNames(
          styles.DropdownSaveList,
          styles.DropdownSaveListPreview
        )}
      >
        <InlineGroup block>
          <button
            aria-label={expanded ? 'Hide options' : 'Show options'}
            type="button"
            className={styles.DropdownSaveListButton}
            onClick={expanded ? handleClose : handleOpen}
            aria-haspopup="true"
            aria-expanded={expanded}
          >
            <Icon
              type={selectedLists.length ? 'Bookmark' : 'BookmarkBorder'}
              a11yText={expanded ? 'Hide options' : 'Show options'}
              width={21}
            />
          </button>
        </InlineGroup>
      </div>

      {expanded && (
        <div
          className={classNames(
            styles.DropdownSaveList,
            styles.DropdownSaveListOverlay,
            out.bottom && !out.top && styles.forceDirectionUp,
            out.right && !out.left && styles.forceDirectionLeft
          )}
          ref={list}
        >
          {filteredLists.length > 0 && (
            <ul>
              {filteredLists.map((list, i) => {
                return (
                  <li key={`dropdownSave-list-${i}`}>
                    <button
                      aria-label={list.name}
                      type="button"
                      className={classNames(
                        styles.DropdownSaveListItem,
                        list.checked && styles.selected,
                        !list.checked && styles.iconHidden
                      )}
                      onClick={e => handleListSelection(e, list)}
                      {...(list.disabled && { disabled: true })}
                    >
                      <ShrinkWrap spacing="narrow">
                        <ShrinkWrapItem shrink vAlign="middle">
                          <Icon
                            type="Check"
                            a11yText={list.checked ? 'Selected: ' : ''}
                          />
                        </ShrinkWrapItem>
                        <ShrinkWrapItem vAlign="middle">
                          {list.name}
                        </ShrinkWrapItem>
                      </ShrinkWrap>
                    </button>
                  </li>
                )
              })}
            </ul>
          )}
          <div className={styles.DropdownSaveAction}>
            <button
              aria-label="new list"
              type="button"
              className={styles.DropdownSaveListItem}
              onClick={handleNewList}
            >
              <ShrinkWrap spacing="narrow" vAlign="middle">
                <ShrinkWrapItem shrink vAlign="middle">
                  <Icon type="Add" a11yText="" />
                </ShrinkWrapItem>
                <ShrinkWrapItem vAlign="middle">New List</ShrinkWrapItem>
              </ShrinkWrap>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

DropdownSave.defaultProps = {
  lists: []
}

DropdownSave.propTypes = {
  activeWithin: bool,
  alignRight: bool,
  block: bool,
  lists: array,
  onChange: func,
  parentRef: func,
  onNewList: func,
  inverse: bool
}

export default DropdownSave
