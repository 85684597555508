import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import svgDimensionsFormatter from '../../../lib/svg-dimensions-formatter'
import ratioScaler from '../../../lib/ratio-scaler'

import styles from './Icon.styl'

import iconSvgs from '../Svg'
import VisuallyHidden from '../VisuallyHidden'

class Icon extends PureComponent {
  render() {
    const {
      a11yText,
      className,
      type,
      height,
      width,
      adaptive,
      color
    } = this.props
    const SvgType = Object.prototype.hasOwnProperty.call(iconSvgs, type)
      ? iconSvgs[type]
      : null

    if (!SvgType) return null

    const targetDimensions = { width, height }
    const nativeDimensions = svgDimensionsFormatter(SvgType)
    const ratioDimensions = ratioScaler(
      targetDimensions,
      nativeDimensions,
      'ceil'
    )
    const inlineStyles = {
      width: `${ratioDimensions.width}px`,
      height: `${ratioDimensions.height}px`,
      lineHeight: `${ratioDimensions.height}px`
    }

    return (
      <span
        className={classNames(
          styles.Icon,
          adaptive && styles.adaptive,
          className
        )}
        aria-hidden={!a11yText ? 'true' : null}
        style={adaptive ? null : inlineStyles}
      >
        {!!a11yText && <VisuallyHidden>{a11yText}</VisuallyHidden>}
        <SvgType style={{ fill: color ? color : null }} />
      </span>
    )
  }
}

Icon.displayName = 'Icon'

Icon.propTypes = {
  a11yText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(iconSvgs)).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  adaptive: PropTypes.bool,
  color: PropTypes.string
}

export default Icon
