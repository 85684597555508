export const footerLinks = {
  about: {
    heading: 'About',
    links: [
      {
        label: 'About us',
        href: '/about-us',
        target: '_blank'
      },
      {
        label: 'Work for us',
        href: '/join-us',
        target: '_blank'
      },
      {
        label: 'Our events',
        href: '/events',
        target: '_blank'
      }
    ]
  },
  contact: {
    heading: 'Contact',
    links: [
      {
        label: 'Get in touch',
        href: '/contact-us',
        target: '_blank'
      },
      {
        label: 'Submit your work',
        href: '/submit-your-work',
        target: '_blank'
      },
      {
        label: 'Partner with us',
        href: '/event-sponsorship',
        target: '_blank'
      }
    ]
  },
  legal: {
    heading: 'Legal',
    links: [
      {
        label: 'Terms & conditions',
        href: '/terms-and-conditions',
        target: '_blank'
      },
      {
        label: 'Copyright policy',
        href: '/terms-and-conditions',
        target: '_blank'
      },
      {
        label: 'Privacy',
        href: 'https://www.ascential.com/site-services/privacy-policy',
        target: '_blank'
      },
      {
        label: 'Cookies',
        href: 'https://www.ascential.com/site-services/cookies-policy',
        target: '_blank'
      }
    ]
  }
}

export const footerLinksCom = [
  {
    title: 'About us',
    href: '/about-us'
  },
  {
    title: 'Briefings',
    href: '/briefings'
  },
  {
    title: 'Research',
    href: '/research'
  },
  {
    title: 'Creative Accelerator',
    href: '/creative-accelerator'
  },
  {
    title: 'Submit your work',
    href: '/submit-your-work'
  }
]

export const footerLinksIo = [
  {
    title: 'About us',
    href: '/who-we-are'
  },
  {
    title: 'Submit your work',
    href: '/submit-your-work'
  },
  {
    title: 'Privacy policy',
    href: '/privacy-policy'
  },
  {
    title: 'Terms & conditions',
    href: '/terms-and-conditions'
  }
]

// export default { footerLinksCom, footerLinksIo }
