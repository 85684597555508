import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'

import Field, {
  FieldQuestion,
  FieldAnswer,
  // FieldRequired,
  FieldAssistance,
  FieldFeedback
} from '../../Field'
import VisuallyHidden from '../../VisuallyHidden'

class TextFieldTemplate extends PureComponent {
  render() {
    const {
      assistance,
      children,
      controlName,
      feedback,
      hideLabel,
      inverse,
      label,
      // required,
      status
    } = this.props

    const TextFieldTemplateQuestion = () => (
      <FieldQuestion htmlFor={controlName} /* matches Control controlName */>
        {label}
        {/* {required && <FieldRequired />} */}
        {/* matches Control required attribute */}
      </FieldQuestion>
    )

    return (
      <Field id={`field--${controlName}`} status={status} template="text">
        {hideLabel ? (
          <VisuallyHidden>
            <TextFieldTemplateQuestion />
          </VisuallyHidden>
        ) : (
          <TextFieldTemplateQuestion />
        )}

        <FieldAnswer>{children}</FieldAnswer>
        {assistance && <FieldAssistance>{assistance}</FieldAssistance>}
        {feedback && (
          <FieldFeedback inverse={inverse}>{feedback}</FieldFeedback>
        )}
      </Field>
    )
  }
}

TextFieldTemplate.propTypes = {
  assistance: node,
  controlName: string.isRequired,
  children: node.isRequired,
  feedback: node,
  hideLabel: bool,
  inverse: bool,
  label: node.isRequired,
  // required: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default TextFieldTemplate
