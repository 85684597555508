import React, { Fragment, useState } from 'react'
import { object, string, func, bool, array, shape } from 'prop-types'
import { List, arrayMove } from 'react-movable'
import classnames from 'classnames'
import Icon from '../primitive/Icon'
import CollectionLink from '../CollectionLink'
import CollectionPreview from '../CollectionPreview'
import ArticleCard from '../ArticleCard'
import Comment from '../primitive/Comment'
import HeadingSlash from '../primitive/HeadingSlash'
import versionPath from '../../../../../site/lib/version-path'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'

import styles from './CollectionList.styl'

const CollectionItem = ({
  value,
  props,
  isDragged,
  collectionId,
  user,
  savedLists,
  disableReorder,
  disableDelete,
  disableEdit,
  setConfirmDelete,
  onEdit
}) => {
  return (
    <Fragment>
      <li
        {...props}
        className={classnames(
          styles.ListItem,
          ['collection', 'article', 'link'].includes(value.type) &&
            styles.withBorder
        )}
      >
        <span className={styles.ControlGroup}>
          {!disableReorder && (
            <span
              className={classnames(
                styles.Handle,
                isDragged && styles.isDragged
              )}
              data-movable-handle
              tabIndex={-1}
            >
              <Icon type="Drag" a11yText="Move" />
            </span>
          )}
          {!disableDelete && (
            <span
              className={classnames(styles.DeleteItem)}
              onClick={() => setConfirmDelete(value.id)}
            >
              <Icon type="Delete" a11yText="Delete" />
            </span>
          )}
          {['heading', 'comment', 'link'].includes(value.type) &&
            !disableEdit &&
            onEdit && (
              <span
                className={classnames(styles.EditItem)}
                onClick={() => onEdit(value.id)}
              >
                <Icon type="Edit" a11yText="Edit" />
              </span>
            )}
        </span>
        {value.type === 'article' && (
          <>
            <ArticleCard
              _id={value.articleId}
              shortHeadline={value.headline}
              imageUrl={value.imageUrl}
              label={value.label}
              labelConfig={value.labelConfig}
              brand={value.brand || (value.author && value.author.name)}
              __displayDate={value.__displayDate}
              __fullUrlPath={`${value.__fullUrlPath}?collectionId=${collectionId}`}
              color={value.color || (value.section && value.section.color)}
              sell={value.sell}
              savedLists={savedLists}
              smallPreview
            />
          </>
        )}
        {value.type === 'comment' && (
          <Comment title={value.title || ''} text={value.comment} />
        )}
        {value.type === 'heading' && (
          <h2 className={styles.CollectionHeading}>
            <HeadingSlash>{value.title}</HeadingSlash>
          </h2>
        )}
        {value.type === 'link' && (
          <CollectionLink
            link={value.link}
            title={value.title}
            image={
              value.imageUrl ||
              versionPath('/asset/img/content/collection/default.png')
            }
            note={value.note}
            author={value.author}
          />
        )}
        {value.type === 'collection' && (
          <CollectionPreview
            heading={value.title}
            items={value.__items}
            description={value.description}
            dateSubtitle={value.__date}
            imageSource={value.__images}
            collectionList
            color={value.color}
            collectionPreview
            collectionHero
            collectionId={collectionId}
            savedLists={savedLists}
            user={user}
          />
        )}
      </li>
    </Fragment>
  )
}

CollectionItem.propTypes = {
  value: object.isRequired,
  props: object,
  isDragged: bool,
  disableReorder: bool,
  disableDelete: bool,
  disableEdit: bool,
  setConfirmDelete: func,
  savedLists: array,
  onEdit: func,
  collectionId: string.isRequired,
  user: shape({
    _id: string,
    firstName: string,
    lastName: string,
    organisation: string
  }).isRequired
}

const CollectionList = props => {
  const { onChange, onDelete, items, disableReorder } = props
  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleChange = ({ oldIndex, newIndex }) => {
    if (onChange) {
      onChange(arrayMove(props.items, oldIndex, newIndex))
    }
  }
  return (
    <Fragment>
      {confirmDelete && (
        <Modal
          title="Are you sure you want to remove this item?"
          onClose={() => setConfirmDelete(false)}
          rounded
        >
          <div className={styles.ButtonGroup}>
            <ButtonSimple
              ariaLabel="cancel"
              outline
              onClick={() => setConfirmDelete(false)}
            >
              Cancel
            </ButtonSimple>
            <ButtonSimple
              ariaLabel="delete"
              onClick={() => {
                onDelete(confirmDelete)
                setConfirmDelete(false)
              }}
            >
              Delete
            </ButtonSimple>
          </div>
        </Modal>
      )}
      {disableReorder ? (
        <ul className={styles.List}>
          {items.map(value => (
            <CollectionItem
              key={`CollectionList-${value.id}`}
              value={value}
              {...props}
            />
          ))}
        </ul>
      ) : (
        <List
          values={props.items}
          onChange={e => handleChange(e)}
          renderList={({ children, props: listProps }) => (
            <ul {...listProps}>{children}</ul>
          )}
          renderItem={item => (
            <CollectionItem
              key={`CollectionList-${item.value.id}`}
              {...item}
              {...props}
              setConfirmDelete={setConfirmDelete}
            />
          )}
        />
      )}
    </Fragment>
  )
}

CollectionList.propTypes = {
  disableReorder: bool,
  disableDelete: bool,
  disableEdit: bool,
  onDelete: func,
  onEdit: func,
  items: array.isRequired,
  collectionId: string.isRequired,
  onChange: func
}

export default CollectionList
