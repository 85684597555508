import React, { useEffect, useState } from 'react'
import { parse, stringify } from 'querystring'
import filterToQuery from '../lib/filter-to-query'
import categoriesQueryToFilter from '../lib/category-to-filter'
import checkFilteredCategories from '../lib/category-filter-checker'
import checkFilteredLabels from '../lib/label-filter-checker'
import { withPlatform } from '../../../../site/layout/component/PlatformContext'
import filterHiddenLabels from '../../../../site/widgets/search/lib/hidden-label-filterer'
const debug = require('debug')('search')

const getCheckedLabelNames = labels =>
  labels.filter(({ checked }) => checked).map(({ name }) => name)
const SearchContainer = withPlatform(
  ({
    title,
    labelFilterOptions,
    platform,
    label,
    labels: overriddenLabels = [],
    showFilters = true,
    savedLists,
    component: Component
  }) => {
    const {
      query: term,
      categories: defaultCategories,
      labels: defaultLabels,
      sort: selectedSort,
      page: defaultPage
    } = parse(location.search.substr(1))
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(
      !isNaN(defaultPage) ? Number(defaultPage) : 1
    )
    const [sort, setSort] = useState(
      selectedSort || (term ? 'relevance' : 'recent')
    )
    const [error, setError] = useState(null)
    const [categories, setCategories] = useState(defaultCategories)
    const [labels, setLabels] = useState(
      checkFilteredLabels(labelFilterOptions, defaultLabels)
    )
    const [filter, setFilter] = useState(
      categoriesQueryToFilter(defaultCategories)
    )
    const [results, setResults] = useState({
      articles: [],
      count: 0,
      filterOptions: {},
      labelOptions: []
    })
    const pageSize = 24
    const pages = Math.ceil(results.count / pageSize)

    const fetchSearch = async (query, page = 1) => {
      debug(`Searching`, { term, query })
      const { gtag } = window
      const timeout = setTimeout(() => setLoading(true), 200)

      const response = await fetch(
        `/api/articles/search?${stringify({
          ...query,
          limit: pageSize,
          page
        })}`
      )
      if (response.status !== 200) {
        setLoading(false)
        return setError('There was a problem. Please try again later.')
      }
      const {
        articles,
        filterOptions,
        labelOptions,
        count
      } = await response.json()
      setError(null)
      setLoading(false)
      clearTimeout(timeout)
      gtag('event', 'view_search_results', { search_term: term })
      return {
        filterOptions,
        labelOptions,
        articles,
        count
      }
    }

    const search = async (query, page) => {
      const searchLabels = new Set([
        ...getCheckedLabelNames(labels),
        ...overriddenLabels
      ])
      const res = await fetchSearch(
        {
          term,
          platform,
          categories,
          sort,
          label,
          labels: [...searchLabels],
          ...query
        },
        page
      )

      setResults(res)
      setPage(page)
      updateUrl({
        term,
        categories,
        labels,
        sort,
        page,
        ...query
      })
    }

    const displaySearchResults = async () => await search(null, page)

    useEffect(() => {
      displaySearchResults()
    }, [term])

    const updateUrl = ({ term, categories, labels, sort, page }) => {
      const selectedLabels = getCheckedLabelNames(labels)

      const querystring = {}
      if (term) querystring.query = term
      if (categories && categories.length) querystring.categories = categories
      if (selectedLabels && selectedLabels.length)
        querystring.labels = selectedLabels
      if (sort) querystring.sort = sort
      if (page) querystring.page = page

      history.replaceState(null, '', `?${stringify(querystring)}`)
    }

    const onFilterChange = async ({ group, item, value }) => {
      if (!filter[group]) filter[group] = {}
      if (value) {
        filter[group][item] = value
      } else {
        delete filter[group][item]
        if (Object.keys(filter[group]).length === 0) delete filter[group]
      }
      setFilter(filter)
      const categories = filterToQuery(filter)
      setCategories(categories)
      await search({ categories }, 1)
    }
    const onRefresh = async () => {
      displaySearchResults()
    }

    const onPageChange = async newPage => {
      if (newPage === page) {
        return
      }
      window.scrollTo(0, 0)
      await search(null, newPage)
    }

    const onSort = async sort => {
      setSort(sort)
      await search({ sort }, 1)
    }
    const onLabelChange = async ({ item, value }) => {
      // Only update label if it's the one we're interacting with
      const updatedLabels = labels.map(label => ({
        ...label,
        checked: label.slug === item ? value : label.checked
      }))

      setLabels(updatedLabels)

      const selectedLabels = getCheckedLabelNames(updatedLabels)

      await search({ labels: selectedLabels }, 1)
    }

    const labelOptions =
      overriddenLabels && overriddenLabels.length
        ? []
        : checkFilteredLabels(
            filterHiddenLabels(results.labelOptions, labels),
            getCheckedLabelNames(labels)
          )

    return (
      <Component
        sort={sort}
        count={results.count}
        term={term}
        labels={labelOptions}
        title={title}
        showFilters={showFilters}
        filters={checkFilteredCategories(results.filterOptions, filter)}
        articles={results.articles}
        onFilterChange={onFilterChange}
        onLabelChange={onLabelChange}
        onRefresh={onRefresh}
        onSort={onSort}
        loading={loading}
        error={error}
        onPageChange={onPageChange}
        pages={pages}
        currentPage={page}
        savedLists={savedLists}
      />
    )
  }
)

export default SearchContainer
