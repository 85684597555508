import { array } from 'prop-types'
import React from 'react'
import { groupSubNavs } from '../lib/group-sub-navs'
import SubNavItem from '../SubNavItem'
import styles from './Objectives.styl'
const Objectives = ({ subNavs }) => {
  var navGroups = groupSubNavs(subNavs, 5)

  return (
    <div className={styles.Container}>
      {navGroups.map((group, index) => (
        <div key={`Group-${index}`} className={styles.SubNavList}>
          {group.map(item => (
            <SubNavItem
              key={`SecondaryLink-Objectives-${item.url}`}
              url={item.url}
              label={item.title || item.text}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Objectives
Objectives.propTypes = {
  subNavs: array
}
