const createMaxPercentageCalculator = () => {
  let maxPercentage = 0

  return (current, max) => {
    const currentPercentage = current / max

    maxPercentage =
      currentPercentage > maxPercentage ? currentPercentage : maxPercentage

    const rounded = Math.round(maxPercentage * 100)

    if (rounded <= 0) {
      return 0
    }

    if (rounded > 100) {
      return 100
    }

    return rounded
  }
}

export default createMaxPercentageCalculator
