import React, { useState } from 'react'
import { array, bool, string, func } from 'prop-types'
import NewListModal from '../../NewListModal'
import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import DropdownSave from '../../DropdownSave'
import savedDispatcher, {
  useChangeDispatcher,
  useChangeAllDispatcher,
  useNewListDispatcher
} from '../lib/saved-update-dispatcher'

const debug = require('debug')('save-article-container')

const SaveArticleContainer = ({
  savedLists,
  type,
  itemId,
  name,
  inverse,
  onRemoveItem
}) => {
  const typeId = `${type}Id`
  const [lists, setLists] = useState(
    savedLists.map(savedList => ({
      ...savedList,
      items: null,
      checked: !!savedList.items.find(item => item[typeId] === itemId)
    }))
  )
  const [error, setError] = useState(null)
  const [showNewModal, setShowNewModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onSavedChange = event => {
    if (event.itemId !== itemId) return
    debug('onSavedChange', event)

    if (event.list.isDefault) {
      updateDefaultList(event.list, event.saved)
    } else {
      updateLists(event.list, event.saved)
    }
  }

  const onSavedChangeAll = event => {
    if (event.itemId !== itemId) return
    debug('onSavedChangeAll', event)
    updateAllLists(event.saved)
  }

  const onNewList = event => {
    debug('onNewList', event)
    addNewList(event.list, event.itemId === itemId)
  }

  useChangeDispatcher(onSavedChange)
  useChangeAllDispatcher(onSavedChangeAll)
  useNewListDispatcher(onNewList)

  const updateDefaultList = (list, saved) => {
    setLists(prevState => {
      const existingDefaultList = prevState.find(({ isDefault }) => isDefault)
      if (!existingDefaultList) {
        // Add to lists
        return [...prevState, { ...list, checked: saved }]
      }
      // or update in-place
      return [
        ...prevState.map(existingList =>
          existingList.isDefault
            ? { ...existingList, ...list, checked: saved }
            : existingList
        )
      ]
    })
  }

  const updateLists = (list, saved) => {
    setLists(prevState => [
      ...prevState.map(existingList =>
        existingList._id === list._id
          ? { ...existingList, checked: saved }
          : existingList
      )
    ])
  }

  const updateAllLists = saved => {
    setLists(prevState => [
      ...prevState.map(existingList => ({ ...existingList, checked: saved }))
    ])
  }

  const addNewList = (list, saved) => {
    setLists(prevState => {
      const foundList = prevState.find(({ _id }) => list._id === _id)
      // Prevent duplicating a list we already have
      if (foundList) return prevState
      return [...prevState, { ...list, checked: saved }]
    })
  }

  const handleChangeList = async list => {
    const uri = `/api/saved-lists/toggle/${list._id}/${type}/${itemId}`
    debug('Changing item list status', list._id, type, itemId)
    const response = await fetch(uri, {
      method: 'POST',
      credentials: 'include',
      redirect: 'error'
    })
    if (response.status !== 200) {
      debug('Saving article error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    const { saved } = await response.json()
    debug('Changing item list status', list._id, type, itemId, saved)
    updateLists(list, saved)
    savedDispatcher.dispatchChange({ itemId, list, saved })
    window.ioEventTracker({
      category: 'Save',
      action: saved ? 'save' : 'remove',
      label: name
    })
  }

  const handleRemoveFromAllLists = async () => {
    handleConfirmModalClose()
    onRemoveItem && onRemoveItem(typeId, itemId)
    updateAllLists(false)
    savedDispatcher.dispatchChangeAll({ itemId, saved: false })

    window.ioEventTracker({
      category: 'Save',
      action: 'remove-all',
      label: name
    })
  }

  const handleRemoveOrToggleDefault = async () => {
    const savedCount = lists.filter(({ checked }) => checked).length
    const isSaved = savedCount > 0
    const isInMultipleLists = savedCount > 1
    const isDefaultSaved =
      lists.filter(({ isDefault, checked }) => isDefault && checked).length > 0
    // if saved to multiple lists, do popup
    if (isInMultipleLists) {
      return setShowConfirmModal(true)
    }
    // if not in default, but in one or more lists, do popup
    else if (!isDefaultSaved && isSaved) {
      return setShowConfirmModal(true)
    } // if only in default list dont popup but remove it entirely
    else if (isDefaultSaved) {
      return handleChangeDefaultList(true)
    }
    // it isnt saved, toggle
    else {
      handleChangeDefaultList()
    }
  }

  const handleChangeDefaultList = async (remove = false) => {
    const uri = `/api/saved-lists/toggle/${type}/${itemId}`
    debug('Changing item default list status', type, itemId)
    const response = await fetch(uri, {
      method: 'POST',
      credentials: 'include',
      redirect: 'error'
    })
    if (response.status !== 200) {
      debug('Saving article error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    const { list, saved } = await response.json()
    debug('Changed item default list status', type, itemId, saved)
    updateDefaultList(list, saved)
    savedDispatcher.dispatchChange({ itemId, list, saved })

    if (remove && onRemoveItem) {
      onRemoveItem(typeId, itemId)
    }
    window.ioEventTracker({
      category: 'Save',
      action: saved ? 'save-default' : 'remove-default',
      label: name
    })
  }

  const handleChange = async list => {
    if (list) {
      return handleChangeList(list)
    } else {
      return handleRemoveOrToggleDefault()
    }
  }

  const handleNewModalOpen = () => {
    setShowNewModal(true)
  }

  const handleNewModalClose = () => {
    setShowNewModal(false)
  }

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false)
  }

  const handleNewList = list => {
    savedDispatcher.dispatchNewList({ itemId, list })
    addNewList(list, true)
    window.ioEventTracker({
      category: 'Save',
      action: 'new-list',
      label: name
    })
  }

  return (
    <>
      {showNewModal && (
        <NewListModal
          type={type}
          itemId={itemId}
          onClose={handleNewModalClose}
          onSuccess={handleNewList}
        />
      )}
      {showConfirmModal && (
        <ConfirmDeleteModal
          itemId={itemId}
          onClose={handleConfirmModalClose}
          onSuccess={handleRemoveFromAllLists}
        />
      )}
      <DropdownSave
        lists={lists}
        error={error}
        onChange={handleChange}
        onNewList={handleNewModalOpen}
        inverse={inverse}
      />
    </>
  )
}

SaveArticleContainer.propTypes = {
  savedLists: array.isRequired,
  type: string.isRequired,
  itemId: string.isRequired,
  name: string,
  inverse: bool,
  onRemoveItem: func
}

export default SaveArticleContainer
