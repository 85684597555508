import React from 'react'
import PropTypes from 'prop-types'
import HeadingSlash from '../HeadingSlash'
import nl2br from '../../../../lib/nl2br'

import styles from './Comment.styl'
class Comment extends React.PureComponent {
  render() {
    const { title, text } = this.props
    return (
      <div className={styles.Comment}>
        {title && (
          <h3 className={styles.CommentHeading}>
            <HeadingSlash>{title}</HeadingSlash>
          </h3>
        )}
        {text && (
          <p
            className={styles.CommentText}
            dangerouslySetInnerHTML={{ __html: nl2br(text) }}
          />
        )}
      </div>
    )
  }
}

Comment.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string
}

export default Comment
