import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Notice.styl'

class Notice extends React.PureComponent {
  render() {
    const { children, type, minimal } = this.props
    const classes = classnames(
      styles.Notice,
      styles[type],
      minimal && styles.minimal
    )
    return <div className={classes}>{children}</div>
  }
}

Notice.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['notice', 'success', 'warn', 'error']),
  minimal: PropTypes.bool
}

export default Notice
