import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { widthMin, tablet } from '../primitive/Media'
import styles from './Clients.styl'
import HeadingSlash from '../primitive/HeadingSlash'

const styleMap = {
  3: styles.Client3Col,
  4: styles.Client4Col
}

class Clients extends PureComponent {
  constructor() {
    super()
    this.onBreakpointChange = this.onBreakpointChange.bind(this)
    this.determineDisplayCountClass = this.determineDisplayCountClass.bind(this)
  }

  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.breakpoint = window.matchMedia(widthMin(tablet))
    this.breakpoint.addListener(this.onBreakpointChange)

    this.carousel = new Flickity(this.carousel, {
      wrapAround: false,
      prevNextButtons: false,
      autoPlay: 3000,
      pauseAutoPlayOnHover: false,
      pageDots: false,
      imagesLoaded: true,
      groupCells: this.breakpoint.matches
        ? this.props.clients.length <= 5
          ? this.props.clients.length
          : 5
        : 2,
      cellAlign: 'left',
      selectedAttraction: 1,
      friction: 1
    })
  }

  componentWillUnmount() {
    this.breakpoint.removeListener(this.onBreakpointChange)
  }

  onBreakpointChange(e) {
    this.carousel.options.groupCells = e.matches
      ? this.props.clients.length <= 5
        ? this.props.clients.length
        : 5
      : 2
  }

  determineDisplayCountClass() {
    const { clients, displayCount } = this.props

    const columnClass = displayCount
      ? styleMap[displayCount] || styles.Client5Col
      : undefined
    if (columnClass) return columnClass

    return styleMap[clients.length] || styles.Client5Col
  }

  render() {
    const { title, clients } = this.props
    const classes = classNames(styles.Clients)
    const clientColumnClass = this.determineDisplayCountClass()

    return (
      <div className={classes}>
        {title && (
          <h3 className={styles.Heading}>
            <HeadingSlash>{title}</HeadingSlash>
          </h3>
        )}
        <div className={styles.Carousel}>
          <div
            ref={carousel => {
              this.carousel = carousel
            }}
          >
            {clients.map((client, i) => (
              <div
                key={`client-list-item-${i}`}
                className={classNames(styles.Client, clientColumnClass)}
              >
                <a
                  aria-label={client.name}
                  className={styles.Image}
                  href={client.link}
                >
                  <div className={styles.ImageBorder}>
                    <img
                      src={client.__imageUrl}
                      alt={client.name}
                      loading="lazy"
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Clients.propTypes = {
  title: PropTypes.string,
  clients: PropTypes.array.isRequired,
  displayCount: PropTypes.oneOf([3, 4, 5])
}

export default Clients
