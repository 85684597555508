import React from 'react'
import renderComponent from '../lib/component-renderer'

import FollowingContainer from './container/Following'
import FollowedArticles from '../../../site/layout/component/FollowedArticles'

const debug = require('debug')('following')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Following')
    renderComponent('.js-following', props => (
      <FollowingContainer {...props} component={FollowedArticles} />
    ))
  })
}
