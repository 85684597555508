import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'
import { withColor } from '../ColorContext'

import styles from './ConfirmDeleteModal.styl'

const ConfirmDeleteModal = ({ onClose, onDelete, error, loading }) => {
  return (
    <Modal
      onClose={onClose}
      className={styles.ConfirmDeleteModal}
      title="This will be removed from all of your saved lists, are you sure?"
      rounded
    >
      {error && <p>{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.ButtonGroup}>
          <ButtonSimple ariaLabel="cancel" outline onClick={onClose}>
            Cancel
          </ButtonSimple>
          <ButtonSimple ariaLabel="Delete from all" onClick={onDelete}>
            Delete from all
          </ButtonSimple>
        </div>
      )}
    </Modal>
  )
}

ConfirmDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
}

export default withColor(ConfirmDeleteModal)
