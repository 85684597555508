import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../primitive/Button'
import Icon from '../primitive/Icon'
import styles from './Carousel.styl'

class Carousel extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: false,
      pageDots: false,
      imagesLoaded: true,
      adaptiveHeight: true
    })

    // https://github.com/metafizzy/flickity/issues/318
    this.timeout = setTimeout(() => {
      this.carousel.resize()
    }, 50)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { children, className } = this.props
    const count = React.Children.count(this.props.children)
    return (
      <div className={classnames(styles.Carousel, styles[className])}>
        <div
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {children}
        </div>
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavPrev)}
            onClick={() => {
              this.carousel.previous()
            }}
            ariaLabel="previous"
          >
            <Icon type="Left" />
          </Button>
        )}
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavNext)}
            onClick={() => {
              this.carousel.next()
            }}
            ariaLabel="next"
          >
            <Icon type="Right" />
          </Button>
        )}
      </div>
    )
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Carousel
