import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import createDebug from 'debug'
import Button from '../primitive/Button'
import Control from '../primitive/ControlLegacy'
import { Field, FieldAnswer, FieldQuestion } from '../primitive/Field'
import { Grid, GridItem } from '../primitive/Grid'
import Prose from '../Prose'
import parseHubspotCookie from '../../../../browser/base/hubspot-custom/lib/hubspot-cookie-parser'
import formatProperties from '../../../../service/hubspot/lib/property-formatter'

import styles from './TrialForm.styl'
const debug = createDebug('trial-form')

const reasonsForTrial = [
  'I need help with a task I’m working on',
  'I think my team could benefit from having access',
  'I want to convince my boss that we need it',
  'I just want to have a look around',
  'Other'
].map(reason => ({ value: reason, text: reason }))

const submitToHubspot = async data => {
  const formTransform = {
    firstname: 'firstName',
    lastname: 'lastName',
    email: 'emailAddress',
    jobtitle: 'jobTitle',
    company: 'companyName',
    brand_or_agency_: 'companyType',
    why_are_you_taking_out_a_trial_of_contagious_iq_: 'reasonForTrial',
    how_did_you_find_out_about_us_: 'howDidYouFindOutAboutUs'
  }
  const properties = formatProperties(formTransform, data)
  debug('submitting hubspot form', data, properties)
  const hutk = parseHubspotCookie()
  const consent = {
    consentToProcess: true,
    text:
      'I agree to the privacy policy and understand Contagious will contact me throughout the trial'
  }
  const formData = {
    // Map contact API to form API structure
    fields: properties.map(({ property, value }) => ({
      name: property,
      value
    })),
    legalConsentOptions: { consent },
    context: {
      pageName: document.title,
      pageUri: window.location.href,
      hutk
    }
  }
  debug('form data', formData)

  const hubspotApiUrl =
    'https://api.hsforms.com/submissions/v3/integration/submit'
  const portalId = '1725887'
  const formId = '4dfac870-4d5d-4529-bd51-d3fc8bc65484'
  const url = `${hubspotApiUrl}/${portalId}/${formId}`
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    if (res.status !== 200) {
      const responseData = await res.json()
      const error = new Error('Hubspot API Error')
      window.Sentry.captureException(error, {
        level: 'fatal',
        extra: responseData
      })
      debug('submit error', responseData)
    }
    debug('submit successful')
  } catch (error) {
    debug('Could not submit form', error)
  }
}

const TrialForm = ({ returnUrl, reCaptchaKey }) => {
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({})

  const handleChange = ({ target: { name, type, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    setSuccess(false)
    setSubmitting(true)
    setErrors({})

    window.grecaptcha.ready(async () => {
      try {
        const gRecaptchaToken = await window.grecaptcha.execute(reCaptchaKey, {
          action: 'submit'
        })
        const res = await fetch('/trial', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ ...data, gRecaptchaToken })
        })

        setSubmitting(false)
        if (res.status !== 200) {
          const errors = await res.json()
          setErrors(errors)
          return
        }

        await submitToHubspot(data)
        setSuccess(true)
      } catch (error) {
        setSubmitting(false)
        setErrors({
          emailAddress:
            'There was an issue submitting this form. Please try again later.'
        })
      }
    })
  }

  return (
    <div
      className={classnames(styles.TrialForm, {
        [styles.FormSuccess]: success
      })}
    >
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="returnUrl" value={returnUrl} />
        {success ? (
          <Grid flex gap={8}>
            <GridItem className={styles.SuccessMessage}>
              <Prose className={styles.Title}>Check your inbox</Prose>
              <Prose className={styles.Body}>
                A verification email has been sent to you. Please click the link
                in that email to set up a password and complete your Contagious
                registration.
              </Prose>
              <Prose className={styles.Body}>
                If the email did not come through or you need any help, email{' '}
                <a
                  aria-label="mail to support@contagious.com"
                  href="mailto:support@contagious.com"
                >
                  support@contagious.com
                </a>
                .
              </Prose>
            </GridItem>
          </Grid>
        ) : (
          <Grid flex gap={8}>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion>First Name</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    name="firstName"
                    defaultValue={data.firstName}
                    error={errors.firstName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion>Last Name</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your last name"
                    name="lastName"
                    defaultValue={data.lastName}
                    error={errors.lastName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion>Email</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    name="emailAddress"
                    defaultValue={data.emailAddress}
                    error={errors.emailAddress}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion>Job Title</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your job title"
                    name="jobTitle"
                    defaultValue={data.jobTitle}
                    error={errors.jobTitle}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion>Company</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your company name"
                    name="companyName"
                    defaultValue={data.companyName}
                    error={errors.companyName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem sizes={['desktop-one-half']}>
              <Field className={styles.noMargin}>
                <FieldQuestion>Brand or agency?</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    className={styles.Select}
                    type="select"
                    name="companyType"
                    defaultValue={data.companyType}
                    error={errors.companyType}
                    options={[
                      { text: 'Please select an option', value: '' },
                      { text: 'Brand', value: 'Brand' },
                      { text: 'Agency', value: 'Agency' }
                    ]}
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2}>
              <Field>
                <FieldQuestion>
                  Why are you taking out a trial of Contagious IQ?
                </FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    className={styles.Select}
                    type="select"
                    name="reasonForTrial"
                    defaultValue={data.reasonForTrial}
                    error={errors.reasonForTrial}
                    options={[
                      { text: 'Please select a reason', value: '' },
                      ...reasonsForTrial
                    ]}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2}>
              <Field>
                <FieldQuestion>How did you find out about us?</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    name="howDidYouFindOutAboutUs"
                    defaultValue={data.howDidYouFindOutAboutUs}
                    error={errors.howDidYouFindOutAboutUs}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem>
              <div className={styles.Submit}>
                <Control
                  onChange={handleChange}
                  options={[
                    {
                      text: (
                        <span>
                          I agree to{' '}
                          <a
                            aria-label="privacy policy"
                            href="/privacy-policy"
                            target="_blank"
                          >
                            the privacy policy
                          </a>{' '}
                          and understand Contagious will contact me throughout
                          the trial
                        </span>
                      ),
                      name: 'termsAgreed',
                      value: true,
                      required: true
                    }
                  ]}
                  type="checkbox"
                  error={errors.termsAgreed}
                  value={data.termsAgreed}
                />
                <div className={styles.ButtonWrapper}>
                  <Button
                    ariaLabel="start your free trial"
                    disabled={submitting}
                    primary
                  >
                    Start your free trial
                  </Button>
                </div>
              </div>
            </GridItem>
          </Grid>
        )}
      </form>
      <script
        src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`}
      />
    </div>
  )
}

TrialForm.propTypes = {
  returnUrl: PropTypes.string,
  reCaptchaKey: PropTypes.string.isRequired
}

export default TrialForm
