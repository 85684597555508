const formatItem = ({
  _id,
  shortHeadline,
  __fullUrlPath,
  brand,
  color,
  label,
  labelConfig,
  name,
  link
}) => ({
  _id,
  title: shortHeadline || name,
  details: brand,
  url: __fullUrlPath || link,
  color,
  label,
  labelConfig
})

module.exports = formatItem
