import React from 'react'
import { string, object } from 'prop-types'
import HeadingSlash from '../primitive/HeadingSlash'
import { withColor } from '../ColorContext'
import Categories from './container/Categories'
import styles from './ArticleMetaControls.styl'

const ArticleMetaControls = ({ categories }) => {
  return (
    <div className={styles.ArticleMetaControls}>
      {Object.keys(categories).length > 0 && (
        <div className={styles.MetaSection}>
          <h3>
            <a aria-label="add to interests" href="/iq/profile#following">
              <HeadingSlash>Add to interests</HeadingSlash>
            </a>
          </h3>
          <Categories categories={categories} />
        </div>
      )}
    </div>
  )
}

ArticleMetaControls.defaultProps = {
  categories: {}
}

ArticleMetaControls.propTypes = {
  categories: object.isRequired,
  color: string,
  headline: string.isRequired
}

export default withColor(ArticleMetaControls)
