import renderComponent from '../lib/component-renderer'
import FilterableLabel from './container/FilterableLabel'
const debug = require('debug')('filterable-label')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-filterable-label', FilterableLabel)
  })
}
