import renderComponent from '../lib/component-renderer'

import Landing from '../../../../components/site/user/component/organisation/Landing'
import Register from '../../../../components/site/user/component/organisation/Register'
import Details from '../../../../components/site/user/component/organisation/Details'
import Categories from '../../../../components/site/user/component/organisation/Categories'
import Complete from '../../../../components/site/user/component/organisation/Complete'

const debug = require('debug')('organisation')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Landing')
    renderComponent('.js-organisation-landing', Landing)
    debug('init Register')
    renderComponent('.js-organisation-register', Register)
    debug('init Details')
    renderComponent('.js-organisation-details', Details)
    debug('init Categories')
    renderComponent('.js-organisation-categories', Categories)
    debug('init Complete')
    renderComponent('.js-organisation-complete', Complete)
  })
}
