import renderComponent from '../lib/component-renderer'
import ArticleMetaControlsHeader from '../../../site/layout/component/ArticleMetaControlsHeader'

const debug = require('debug')('article meta controls')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article-meta-controls-header ')
    renderComponent(
      '.js-article-meta-controls-header',
      ArticleMetaControlsHeader
    )
  })
}
