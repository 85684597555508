import React from 'react'
import PropTypes, { oneOf } from 'prop-types'
import classNames from 'classnames'

import SingleCheckControl from './SingleCheck'
import styles from './Check.styl'

const CheckControl = ({ options, className, theme, ...other }) => {
  if (options.length === 1) {
    return (
      <span className={classNames(theme && styles[theme], className)}>
        <SingleCheckControl {...other} option={options[0]} />
      </span>
    )
  }

  return (
    <ul
      className={classNames(
        theme && styles[theme],
        styles.CheckList,
        className
      )}
    >
      {options.map((option, i) => (
        <li key={i}>
          <SingleCheckControl index={i} {...other} option={option} />
        </li>
      ))}
    </ul>
  )
}

CheckControl.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  theme: oneOf(['LightBg', 'DarkBg', 'secondary', 'ClipCorner', 'delete'])
}

export default CheckControl
