import React from 'react'
import renderComponent from '../lib/component-renderer'

import SaveArticleContainer from '../../../../components/site/layout/component/SaveArticle/container'

const debug = require('debug')('save-article')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Save Article')
    renderComponent('.js-save-article', props => (
      <SaveArticleContainer {...props} />
    ))
  })
}
