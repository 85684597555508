import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styles from '../file/File.styl'

class FileControl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null
    }
    this.onDrop = this.onDrop.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

  async onDrop(files) {
    const [file] = files
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
    this.setState({ file })

    if (this.props.onChange) {
      const fileAsString = await this.fileToString(file)
      this.props.onChange(fileAsString)
    }
  }

  fileToString(file) {
    return new Promise((resolve, reject) => {
      if (!file) resolve('')
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        resolve(result)
      }
      reader.onerror = error => reject(error)
      reader.readAsDataURL(file)
    })
  }

  handleClear(e) {
    e.preventDefault()
    if (this.state.file) URL.revokeObjectURL(this.state.file.preview)
    this.setState({ file: null })

    if (this.props.onChange) {
      this.props.onChange(null)
    }
  }

  render() {
    const { className, placeholder } = this.props

    return (
      <Dropzone
        disabled={!!this.state.file}
        accept="image/*"
        multiple={false}
        maxSize={2000000 /* 2 MB */}
        onDrop={this.onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={classNames(styles.FileControl, className)}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div>
              {this.state.file ? (
                <>
                  <img
                    className={styles.Preview}
                    src={this.state.file.preview}
                  />
                  <div
                    onClick={this.handleClear}
                    className={styles.RemoveButton}
                  >
                    <div className={styles.FauxButton}>-</div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.FauxButton}>+</div>
                  <p>{placeholder}</p>
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}

FileControl.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

export default FileControl
