import React, { useState } from 'react'
import qs from 'querystring'
import { string, any } from 'prop-types'

const OnboardingLoginContainer = ({
  error: initialError,
  identity: initialIdentity,
  returnUrl,
  component: Component
}) => {
  const [error, setError] = useState(initialError)
  const [loading, setLoading] = useState(false)
  const [allowPasswordInput, setAllowPasswordInput] = useState(false)
  const [identity, setIdentity] = useState(initialIdentity)
  const [password, setPassword] = useState(null)

  const handleIdentityChange = ({ target: { value } }) => setIdentity(value)
  const handlePasswordChange = ({ target: { value } }) => setPassword(value)

  const handleEditIdentity = e => {
    e.preventDefault()
    setAllowPasswordInput(false)
    setPassword(null)
  }

  const handleEmailSubmit = async e => {
    e.preventDefault()
    if (!identity) {
      return setError('Please enter your email address')
    }
    setLoading(true)
    setError(null)
    let identifier
    try {
      const res = await fetch(`/iq/login/type?${qs.stringify({ identity })}`)
      const data = await res.json()
      identifier = data.identifier
    } catch (requestError) {
      window.Sentry.captureException(requestError)
      setLoading(false)
      return setError('Something unexpected occurred, please try again later')
    }

    if (identifier) {
      window.location = `/iq/login/${identifier}?${qs.stringify({ returnUrl })}`
      return
    }
    setLoading(false)
    setAllowPasswordInput(true)
  }

  return (
    <Component
      error={error}
      returnUrl={returnUrl}
      loading={loading}
      identity={identity}
      password={password}
      allowPasswordInput={allowPasswordInput}
      onIdentityChange={handleIdentityChange}
      onPasswordChange={handlePasswordChange}
      onEmailSubmit={handleEmailSubmit}
      onEditIdentity={handleEditIdentity}
    />
  )
}

OnboardingLoginContainer.propTypes = {
  identity: string,
  error: string,
  returnUrl: string,
  component: any.isRequired
}

export default OnboardingLoginContainer
