import { string } from 'prop-types'
import React from 'react'
import styles from './TickerBar.styl'

const TickerBar = ({ label, url }) => {
  if (!label || !url) return <></>
  return (
    <a className={styles.Container} href={url}>
      {label}
    </a>
  )
}

export default TickerBar

TickerBar.propTypes = {
  label: string,
  url: string
}
