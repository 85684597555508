import React from 'react'
import versionPath from '../../../../../../site/lib/version-path'

export const loginImages = [
  {
    src: versionPath('/asset/img/content/hero/login_4.jpg'),
    alt: 'Nike / Dream Crazier / Wieden+Kennedy Portland'
  },
  {
    src: versionPath('/asset/img/content/hero/login_5.jpg'),
    alt: 'State Street Global Advisors / The Fearless Girl / McCann New York'
  },
  {
    src: versionPath('/asset/img/content/hero/login_1.jpg'),
    alt: 'Dove / Courage is Beautiful / Ogilvy Canada and Ogilvy UK'
  },
  {
    src: versionPath('/asset/img/content/hero/login_3.jpg'),
    alt: 'Tesco / There Is No Naughty List / BBH London'
  },
  {
    src: versionPath('/asset/img/content/hero/onboarding_4.jpg'),
    alt:
      'Burger King / Moldy Whopper / Ingo, Stockholm, David Miami and Publicis Worldwide'
  },
  {
    src: versionPath('/asset/img/content/hero/login_2.jpg'),
    alt: 'Bodyform / Womb Stories / AMV BBDO, London'
  }
]

export const landingImages = [
  {
    src: versionPath('/asset/img/content/hero/supercharge_1.jpg'),
    alt: 'Always / #LikeAGirl / Leo Burnett, Chicago, London and Toronto'
  },
  {
    src: versionPath('/asset/img/content/hero/supercharge_2.jpg'),
    alt:
      'Samsung QLED + 6 Underground + Aviation Gin + Netflix / Adam&EveDDB, New York and Maximum Effort Productions'
  },
  {
    src: versionPath('/asset/img/content/hero/supercharge_3.jpg'),
    alt:
      'Royal Automobile Club of Victoria / Cover for their Overconfidence / CHE Proximity, Melbourne'
  },
  {
    src: versionPath('/asset/img/content/hero/supercharge_4.jpg'),
    alt: 'NRMA Insurance / First Saturday / CHE Proximity, Melbourne'
  },
  {
    src: versionPath('/asset/img/content/hero/supercharge_5.jpg'),
    alt: 'Skittles / Skittles The Musical / DDB New York'
  }
]

export const onboardingImages = [
  {
    src: versionPath('/asset/img/content/hero/onboarding_1.jpg'),
    alt: 'Red Bull / Red Bull Stratos / In-house'
  },
  {
    src: versionPath('/asset/img/content/hero/onboarding_4.jpg'),
    alt:
      'Burger King / Moldy Whopper / Ingo, Stockholm, David Miami and Publicis Worldwide'
  },
  {
    src: versionPath('/asset/img/content/hero/onboarding_3.jpg'),
    alt: 'Nike / Breaking2 / In-house'
  },
  {
    src: versionPath('/asset/img/content/hero/onboarding_2.jpg'),
    alt: 'Audi / Clowns / BBH London'
  },
  {
    src: versionPath('/asset/img/content/hero/onboarding_5.jpg'),
    alt: 'Lush and Deliveroo / 30-Second Soap / And Us'
  }
]

export const brands = [
  {
    src: versionPath('/asset/img/content/brand/3-monkeys-zeno.jpg'),
    alt: '3 Monkeys Zeno'
  },
  {
    src: versionPath('/asset/img/content/brand/ab-inbev.jpg'),
    alt: 'AB InBev'
  },
  { src: versionPath('/asset/img/content/brand/adidas.jpg'), alt: 'Adidas' },
  {
    src: versionPath('/asset/img/content/brand/analog-folk.jpg'),
    alt: 'Analog Folk'
  },
  { src: versionPath('/asset/img/content/brand/bacardi.jpg'), alt: 'Bacardi' },
  { src: versionPath('/asset/img/content/brand/bbc.jpg'), alt: 'BBC' },
  { src: versionPath('/asset/img/content/brand/edelman.jpg'), alt: 'Edelman' },
  {
    src: versionPath('/asset/img/content/brand/fleishman-hillard.jpg'),
    alt: 'Fleishman Hillard'
  },
  {
    src: versionPath('/asset/img/content/brand/general-mills.jpg'),
    alt: 'General Mills'
  },
  { src: versionPath('/asset/img/content/brand/google.jpg'), alt: 'Google' },
  { src: versionPath('/asset/img/content/brand/havas.jpg'), alt: 'Havas' },
  {
    src: versionPath('/asset/img/content/brand/heineken.jpg'),
    alt: 'Heineken'
  },
  {
    src: versionPath('/asset/img/content/brand/impact-bbdo.jpg'),
    alt: 'Impact BBDO'
  },
  {
    src: versionPath('/asset/img/content/brand/karmarama.jpg'),
    alt: 'Karmarama'
  },
  {
    src: versionPath('/asset/img/content/brand/kraft-heinz.jpg'),
    alt: 'Kraft Heinz'
  },
  {
    src: versionPath('/asset/img/content/brand/leo-burnett.jpg'),
    alt: 'Leo Burnett'
  },
  {
    src: versionPath('/asset/img/content/brand/mcdonalds.jpg'),
    alt: 'McDonalds'
  },
  {
    src: versionPath('/asset/img/content/brand/microsoft-xbox.jpg'),
    alt: 'Microsoft Xbox'
  },
  {
    src: versionPath('/asset/img/content/brand/mindshare.jpg'),
    alt: 'Mindshare'
  },
  {
    src: versionPath('/asset/img/content/brand/mondelez.jpg'),
    alt: 'Mondelez'
  },
  { src: versionPath('/asset/img/content/brand/mrm.jpg'), alt: 'MRM' },
  { src: versionPath('/asset/img/content/brand/nivea.jpg'), alt: 'Nivea' },
  {
    src: versionPath('/asset/img/content/brand/noble-people.jpg'),
    alt: 'Noble People'
  },
  { src: versionPath('/asset/img/content/brand/ogilvy.jpg'), alt: 'Ogilvy' },
  { src: versionPath('/asset/img/content/brand/pepsico.jpg'), alt: 'Pepsico' },
  {
    src: versionPath('/asset/img/content/brand/pernod-ricard.jpg'),
    alt: 'Pernod Ricard'
  },
  { src: versionPath('/asset/img/content/brand/phd.jpg'), alt: 'PHD' },
  {
    src: versionPath('/asset/img/content/brand/publicis-groupe.jpg'),
    alt: 'Publicis Groupe'
  },
  { src: versionPath('/asset/img/content/brand/rothco.jpg'), alt: 'Rothco' },
  {
    src: versionPath('/asset/img/content/brand/sony-music.jpg'),
    alt: 'Sony Music'
  },
  {
    src: versionPath('/asset/img/content/brand/sound-hc.jpg'),
    alt: 'Sound HC'
  },
  { src: versionPath('/asset/img/content/brand/tbwa.jpg'), alt: 'TBWA' },
  {
    src: versionPath('/asset/img/content/brand/the-new-york-times.jpg'),
    alt: 'The New York Times'
  },
  {
    src: versionPath('/asset/img/content/brand/um-london.jpg'),
    alt: 'UM London'
  },
  {
    src: versionPath('/asset/img/content/brand/unilever.jpg'),
    alt: 'Unilever'
  },
  { src: versionPath('/asset/img/content/brand/vmly&r.jpg'), alt: 'VMLY&R' },
  {
    src: versionPath('/asset/img/content/brand/wunderman-thompson.jpg'),
    alt: 'Wunderman Thompson'
  }
]

export const cards = [
  {
    title: 'Raise the creative bar',
    body: (
      <>
        <p>
          Learn from in-depth interviews with the marketers, strategists and
          creatives achieving contagious creativity.
        </p>
        <p>
          <strong>
            Create work that adds value to people’s lives, grows brands and
            spreads like wildfire.
          </strong>
        </p>
      </>
    )
  },
  {
    title: 'Research at speed',
    body: (
      <>
        <p>
          Identify the strategies and tactics that leading brands have used to
          answer a brief similar to yours.
        </p>
        <p>
          <strong>
            Save time and effort with fast, relevant, high-quality analysis.
          </strong>
        </p>
      </>
    )
  },
  {
    title: 'Validate your ideas',
    body: (
      <>
        <p>
          Benchmark your ideas against best-in-class examples backed up with
          business results.
        </p>
        <p>
          <strong>
            Prove the case for creativity to clients or colleagues.
          </strong>
        </p>
      </>
    )
  },
  {
    title: 'Be inspired',
    body: (
      <>
        <p>
          Stay plugged-in to breakthrough creative ideas and disruptive
          startups.
        </p>
        <p>
          <strong>
            Fuel your creative and strategic thinking with need-to-know
            insights, curated by our editors.
          </strong>
        </p>
      </>
    )
  }
]

export const quotes = [
  {
    quote: `Contagious inspires strategic conversations with our clients and it helps my team stay smart and relevant.`,
    author: `Nick Rhodin, Managing Partner, Strategy / Sound HC`
  },
  {
    quote: `A mandatory resource for any creative leader that aspires to set the bar higher.`,
    author: `Federico Russo, Executive Creative Director / Ogilvy `
  },
  {
    quote: `Contagious sets marketers’ pulses racing.
    The platform has been integral to building creative capability at Heineken by inspiring and educating our teams with case studies from categories and markets that we wouldn’t see otherwise.`,
    author: `Claudia Schneider, Marketing Capability Manager / Heineken`
  },
  {
    quote: `A firehose of strategic and creative inspiration.
    It saves a ton of time that would otherwise be spent digging around the internet for only a fraction of the intel. It’s like having an extra teammate on our team whose full-time job is just to curate.`,
    author: `C Michael Shedd, Senior Strategist / Vitro Agency`
  },
  {
    quote: `Contagious helps us to create healthcare work that can live in the modern world. It not only opens up our agency to what’s possible, it opens up our clients’ minds to new ways of thinking.
    It’s inspiring, thought-provoking and educational.`,
    author: `Toby Pickford, Executive Creative Director / Ogilvy Health `
  },
  {
    quote: `Using Contagious improves the quality of your ideas.
    It allows us to hold ourselves up against the very best work from the industry. We use it to identify playbooks for solving the challenges our clients are facing. It’s the first place we go as soon as a brief comes in.`,
    author: `Allister Hercus, Strategy Director / Noble People `
  },
  {
    quote: `Using Contagious helps us prove to clients how world-class creativity drives business results.
    It’s so fast. Being able to find creative case studies backed up with smart strategic insights so quickly saves us time and stress, which allows us to focus on ideation.`,
    author: `Martin Biela, Executive Creative Director / MRM `
  }
]
