import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ option }) => {
  const { text, value, disabled } = option

  return (
    <option
      value={typeof value !== 'undefined' ? value : text}
      disabled={disabled}
    >
      {text}
    </option>
  )
}

Option.propTypes = {
  option: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    selected: PropTypes.bool
  })
}

export default Option
