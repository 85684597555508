const formatPagination = (pages, currentPage, buffer = 3) => {
  let pagesToShow = Array(pages)
    .fill()
    .map((_, i) => i + 1)

  const isBelowMinimum = currentPage <= buffer
  const isAboveMaximum = currentPage > pages - buffer
  const isWithinEndZone = isBelowMinimum || isAboveMaximum
  if (pages > buffer * 4) {
    if (isWithinEndZone) {
      pagesToShow = [
        ...pagesToShow.slice(0, buffer),
        '…',
        ...pagesToShow.slice(pages - buffer, pages)
      ]
    } else {
      pagesToShow = [
        pagesToShow[0],
        '…',
        ...pagesToShow.slice(currentPage - buffer, currentPage - 1),
        currentPage,
        ...pagesToShow.slice(currentPage, currentPage + buffer - 1),
        '…',
        pagesToShow[pages - 1]
      ]
    }
  }

  return pagesToShow
}

export default formatPagination
