import clone from 'lodash.clonedeep'

/**
 * Sets the `.checked` properties of an options group that are present in a filter
 */
export default (options, filter) => {
  const newOptions = clone(options)
  Object.keys(filter).forEach(group => {
    Object.keys(filter[group]).forEach(item => {
      if (!newOptions[group]) return
      const foundItemIndex = newOptions[group].items.findIndex(
        ({ slug }) => item === slug
      )
      if (foundItemIndex !== null && foundItemIndex >= 0) {
        newOptions[group].items[foundItemIndex].checked = filter[group][item]
      }
    })
  })
  return newOptions
}
