import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

import styles from './ScrollArea.module.styl'

const ScrollArea = ({ hideScrollbar, horizontal, children, className }) => (
  <div
    id="ScrollArea"
    className={classNames(
      styles.ScrollArea,
      hideScrollbar && styles.hideScrollbar,
      horizontal ? styles.horizontal : styles.vertical,
      className
    )}
  >
    {children}
  </div>
)

ScrollArea.propTypes = {
  hideScrollbar: bool,
  horizontal: bool,
  children: node.isRequired,
  className: string
}

export default ScrollArea
