import React from 'react'
import { array, string, func, bool } from 'prop-types'

import Icon from '../primitive/Icon'
import CheckControlGroup from '../primitive/Control/CheckControlGroup'
import CheckControl from '../primitive/Control/CheckControl'
import HeadingSlash from '../primitive/HeadingSlash'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'
import List from './List'
import ScrollArea from '../primitive/ScrollArea'

import styles from './FilterPanel.styl'

const MobilePanel = props => {
  const {
    filters,
    onLabelChange,
    color,
    onChange,
    onSort,
    showCount,
    showSort,
    sort,
    sortOptions,
    onClose
  } = props

  return (
    <Modal
      className={styles.MobileFilterPanelModal}
      fullScreenMobile
      rounded
      onClose={onClose}
    >
      <ButtonSimple
        ariaLabel="close modal"
        className={styles.MobileFilterCloseButton}
        transparent
        onClick={onClose}
      >
        <Icon width={24} height={24} type="Close" a11yText="Close modal" />
      </ButtonSimple>
      <div className={styles.MobileFilterPanel}>
        <ScrollArea>
          {showSort && (
            <div className={styles.SortBy}>
              <h3 className={styles.Heading}>
                <HeadingSlash>Sort by</HeadingSlash>
              </h3>
              <CheckControlGroup className={styles.CheckGroup}>
                {sortOptions.map(option => (
                  <CheckControl
                    key={`sort-${option.value}`}
                    type="radio"
                    name="sort"
                    value="1"
                    onChange={() => onSort(option.value)}
                    checked={sort === option.value}
                    small
                  >
                    {option.text}
                  </CheckControl>
                ))}
              </CheckControlGroup>
            </div>
          )}
          <h3 className={styles.Heading}>
            <HeadingSlash>Filter</HeadingSlash>
          </h3>
          {filters &&
            filters.length > 0 &&
            filters.map(filter => (
              <List
                key={`${filter.groupKey}`}
                onChange={
                  filter.groupKey === 'content-type' ? onLabelChange : onChange
                }
                group={{ id: filter.groupKey, ...filter }}
                color={color}
                showCount={showCount}
              />
            ))}
        </ScrollArea>
      </div>
    </Modal>
  )
}

MobilePanel.propTypes = {
  color: string,
  filters: array.isRequired,
  onChange: func.isRequired,
  onSort: func.isRequired,
  showCount: bool,
  showSort: bool,
  sort: string,
  sortOptions: array,
  onLabelChange: func,
  onClose: func
}

export default MobilePanel
