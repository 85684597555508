import clone from 'lodash.clonedeep'

const unsetAll = categories => {
  Object.keys(categories).forEach(taxonomy => {
    Object.keys(categories[taxonomy].items).forEach(category => {
      categories[taxonomy].items[category].following = false
    })
  })
  return categories
}

export const mutateCategory = (categories, taxonomy, category, follow) => {
  if (categories[taxonomy] && categories[taxonomy].items[category]) {
    categories[taxonomy].items[category].following = follow
    return true
  }
  return false
}

export const setFollowing = (currentCategories, following = {}) => {
  const categories = unsetAll(clone(currentCategories))
  Object.keys(following).forEach(taxonomy => {
    Object.keys(following[taxonomy].items).forEach(category => {
      mutateCategory(categories, taxonomy, category, true)
    })
  })
  return categories
}
