import renderComponent from '../lib/component-renderer'
import Challenge from '../../../site/widgets/challenge/component'

const debug = require('debug')('challenge')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init challenge')
    renderComponent('.js-challenge', Challenge)
  })
}
