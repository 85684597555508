import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

const CollectionFormContainer = ({
  user,
  collection,
  isContentOrganisation,
  articleId,
  component: Component
}) => {
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const isNew = typeof collection === 'undefined'

  const [data, setCollection] = useState(
    isNew ? {} : omit(collection, ['__users', '__items'])
  )

  const handleChange = ({ target }) => {
    const { name, type, value, checked } = target
    setCollection(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleDelete = async e => {
    e.preventDefault()
    if (
      window.confirm(`
      Are you sure you want to delete this collection?
      This cannot be undone.
    `)
    ) {
      const response = await fetch(`/api/collections/${data._id}`, {
        method: 'DELETE',
        credentials: 'include',
        redirect: 'error'
      })

      if (response.status === 200) {
        // eslint-disable-next-line require-atomic-updates
        window.location = '/iq'
      } else {
        setError('There was an issue deleting. Please try again later.')
      }
    }
  }

  const handleSubmit = async e => {
    e && e.preventDefault()
    setError(null)
    setErrors(null)
    setSuccess(false)

    let url = '/api/collections'

    if (!isNew) url += `/${data._id}`
    if (articleId) url += `?articleId=${articleId}`

    try {
      const res = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        body: JSON.stringify(data)
      })

      setSubmitting(false)
      if (res.status === 200) {
        setSuccess(true)
        if (isNew) {
          const { _id } = await res.json()
          window.location = `/iq/collection/${_id}`
        } else {
          window.location = `/iq/collection/${data._id}`
        }
      } else if (res.status === 400) {
        const { errors } = await res.json()

        setErrors(errors)
      } else {
        setError('There was an issue saving. Please try again later.')
      }
    } catch (error) {
      setSubmitting(false)
      setError('There was an issue saving. Please try again later.')
    }
  }

  useEffect(() => {
    if (!submitting) return
    handleSubmit()
  }, [submitting])

  return (
    <Component
      error={error}
      errors={errors}
      success={success}
      submitting={submitting}
      data={data}
      user={user}
      collection={collection}
      isContentOrganisation={isContentOrganisation}
      onChange={handleChange}
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      ownerId={isNew ? null : collection.user}
      isNew={isNew}
    />
  )
}

CollectionFormContainer.propTypes = {
  user: PropTypes.object.isRequired,
  isContentOrganisation: PropTypes.bool.isRequired,
  collection: PropTypes.object,
  articleId: PropTypes.string,
  component: PropTypes.any.isRequired
}

export default CollectionFormContainer
