import renderComponent from '../lib/component-renderer'

import QuoteCarousel from '../../../site/layout/component/QuoteCarousel'

const debug = require('debug')('quote-carousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init quote-carousel')
    renderComponent('.js-quote-carousel', QuoteCarousel)
  })
}
