import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HeadingSlash from '../primitive/HeadingSlash'
import Button from '../primitive/Button'
import ReactTooltip from 'react-tooltip'

import styles from './MembershipTable.styl'
import { string } from 'prop-types'
import rowData from './row-data.js'

const ColumnHeader = ({ title, description, content, smallerWidth }) => (
  <th
    className={classNames(
      styles.FixedCol,
      styles[content],
      smallerWidth && styles.SmallCol
    )}
  >
    <h3 className={classNames(styles.ColumnTitle, styles[title.toLowerCase()])}>
      {title}
    </h3>
    <p className={classNames(styles.ColumnDescription, styles[content])}>
      {description}
    </p>
  </th>
)

ColumnHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  smallerWidth: PropTypes.bool
}

const Row = ({ title, values, tooltip, content }) => {
  const tierClasses = ['starter', 'standard', 'supercharged', 'bespoke']
  return (
    <tr className={styles.Row}>
      <td className={styles.RowTitle}>
        <span>{title}</span>
        {tooltip && (
          <span
            className={styles.Tooltip}
            data-background-color="#323232"
            data-tip={tooltip}
          >
            ?
          </span>
        )}
      </td>
      {values.map((value, i) => (
        <td
          className={classNames(
            styles.FixedCol,
            styles[content],
            styles.Cell,
            styles[tierClasses[i]]
          )}
          key={`cell${i}`}
        >
          {value}
        </td>
      ))}
    </tr>
  )
}

Row.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array,
  tooltip: PropTypes.string,
  content: PropTypes.string
}

class MembershipTable extends React.PureComponent {
  render() {
    const { content } = this.props
    const data = rowData[content]
    const ColumnHeaderTitles = [
      'Starter',
      'Standard',
      'Supercharged',
      'Bespoke'
    ]
    return (
      <div className={styles.MembershipTableWrapper}>
        <div className={styles.MembershipTable}>
          <table>
            <thead>
              <tr>
                <th className={styles.TitleContainer}>
                  <h2 className={styles.Title}>
                    <HeadingSlash>Become a Contagious Member</HeadingSlash>
                  </h2>
                </th>
                {data.descriptions.map((description, index) => {
                  const smallerWidth = content === 'agencies' && index === 0
                  return (
                    <ColumnHeader
                      key={`column-header-${index}`}
                      title={ColumnHeaderTitles[index]}
                      description={description}
                      content={content}
                      smallerWidth={smallerWidth}
                    />
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {data.rows.map((row, i) => (
                <Row
                  key={`row${i}`}
                  title={row.title}
                  values={row.values}
                  tooltip={row.tooltip}
                  content={content}
                />
              ))}
              <tr className={styles.noBorder}>
                {content !== 'agencies' ? (
                  <td></td>
                ) : (
                  <td className={styles.RowTitle}>Annual price</td>
                )}
                {data.prices.map((price, index) => {
                  return content !== 'agencies' ? (
                    <td
                      key={`brand-membership-${index}`}
                      className={classNames(styles.FixedCol, styles.Cell)}
                    >
                      from <span className={styles.Price}>{`£${price}pa`}</span>
                    </td>
                  ) : (
                    <td
                      key={`agencies-membership-${index}`}
                      className={classNames(
                        styles.FixedCol,
                        styles[content],
                        styles.Cell,
                        styles.PriceCell
                      )}
                    >
                      <span className={styles.Price}>
                        {price === 'POA' ? price : `£${price}`}
                      </span>
                    </td>
                  )
                })}
              </tr>
              <tr className={styles.noBorder}>
                <td></td>
                {content !== 'agencies' ? (
                  <td colSpan={3}>
                    <Button
                      fullWidth
                      className={styles.Button}
                      href="/trial"
                      primary
                      ariaLabel=" Start free membership trial"
                    >
                      Start free membership trial
                    </Button>
                  </td>
                ) : (
                  <td colSpan={4} className={styles.BasicTier}>
                    <div>
                      <p className={styles.ColumnTitle}>Basic</p>
                      <p
                        className={classNames(styles.BasicRow, styles.RowTitle)}
                      >
                        Just want access to Contagious IQ?
                      </p>
                      <p className={styles.BasicRow}>
                        <span className={styles.Price}>£7,500</span> for 5 users
                      </p>
                    </div>
                  </td>
                )}
              </tr>
              {content === 'agencies' && (
                <tr className={styles.noBorder}>
                  <td></td>
                  <td className={styles.BasicTier} colSpan={2}>
                    <Button
                      fullWidth
                      className={styles.Button}
                      href="/trial"
                      primary
                      ariaLabel=" Start free membership trial"
                    >
                      Start free membership trial
                    </Button>
                  </td>
                  <td className={styles.BasicTier} colSpan={2}>
                    <Button
                      fullWidth
                      className={styles.Button}
                      href="/contact-us"
                      outline
                      primary
                      ariaLabel="Speak to our sales team"
                    >
                      Speak to our sales team
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {content !== 'agencies' && (
          <p className={styles.Footer}>
            Not sure which plan is right for you? Or ready to dive right in and
            purchase?{' '}
            <a
              aria-label="mail to support@contagious.com"
              href="mailto:support@contagious.com"
            >
              Click here to contact us
            </a>
          </p>
        )}

        <ReactTooltip className={styles.TooltipBubble} />
      </div>
    )
  }
}

MembershipTable.propTypes = {
  content: string
}

export default MembershipTable
