import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import Button from '../primitive/Button'
import Icon from '../primitive/Icon'

import styles from './Lightbox.styl'

class Lightbox extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEscape.bind(this))
  }

  handleEscape(e) {
    if (e.key === 'Escape') {
      this.props.toggle()
    }
  }

  render() {
    const { children, toggle } = this.props

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { fullSize: true })
    )

    return createPortal(
      <div className={styles.LightboxContainer} onClick={toggle}>
        <Button icon ariaLabel="close" className={styles.ExitButton}>
          <Icon type="Close" />
        </Button>
        <div className={styles.Lightbox}>{childrenWithProps}</div>
      </div>,
      document.body
    )
  }
}

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.func
}

export default Lightbox
