import renderComponent from '../lib/component-renderer'

import TabbedPageIntro from '../../../site/widgets/tabbed-page-intro/component/TabbedPageIntro'

const debug = require('debug')('tabbed-page intro')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init tabbed-page-intro')
    renderComponent('.js-tabbed-page-intro', TabbedPageIntro)
  })
}
