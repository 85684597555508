import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Surround.styl'

const Surround = ({
  children,
  shadow,
  background,
  rounded,
  className,
  padded,
  pullOut,
  ...other
}) => {
  return (
    <div
      className={classNames(
        styles.Surround,
        rounded && styles.rounded,
        shadow && styles.shadow,
        background && styles[`bg-${background}`],
        padded && styles.padded,
        pullOut && styles.pullOut,
        className
      )}
      {...other}
    >
      {children}
    </div>
  )
}

Surround.propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  shadow: PropTypes.bool,
  background: PropTypes.oneOf(['white', 'brand']),
  padded: PropTypes.bool,
  className: PropTypes.string,
  pullOut: PropTypes.bool
}

export default Surround
