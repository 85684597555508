import React from 'react'
import { string } from 'prop-types'
import WidgetWrapper from '../../../widget/component/WidgetWrapper'

import Spacer from '../../../layout/component/primitive/Spacer'
import AskContagiousForm from '../../../layout/component/AskContagiousForm'

const AskContagiousFormComponent = props => {
  const { returnUrl, reCaptchaKey } = props
  const componentProps = { reCaptchaKey, returnUrl }
  return (
    <WidgetWrapper id="ask-contagious-form" {...props}>
      <div
        className="js-ask-contagious-form"
        data-props={JSON.stringify(componentProps)}
      >
        <Spacer>
          <div className="widget widget--ask-contagious-form">
            <AskContagiousForm
              reCaptchaKey={reCaptchaKey}
              returnUrl={returnUrl}
            />
          </div>
        </Spacer>
      </div>
    </WidgetWrapper>
  )
}

AskContagiousFormComponent.propTypes = {
  returnUrl: string,
  reCaptchaKey: string
}

export default AskContagiousFormComponent
