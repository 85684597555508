import React, { useState } from 'react'
import { any } from 'prop-types'
import { stringify } from 'querystring'

const ExploreNavigationContainer = ({ component: Component, ...other }) => {
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [categorySuggestions, setCategorySuggestions] = useState([])
  const [contentSuggestions, setContentSuggestions] = useState({
    articles: [],
    articleCount: 0
  })
  const fetchSuggestions = async (resource, term, fallbackData) => {
    let response
    let data = fallbackData
    try {
      response = await fetch(`/api/search/${resource}?${stringify({ term })}`)
      if (response.status !== 200) {
        window.Sentry.captureException(
          new Error(
            `Error searching for ${resource}, status ${response.status}`
          )
        )
        return data
      }
      data = await response.json()
    } catch (error) {
      window.Sentry.captureException(error)
    }
    return data
  }

  const handleSearch = async term => {
    setSuggestionsLoading(true)
    setCategorySuggestions(await fetchSuggestions('category', term, []))
    setContentSuggestions(
      await fetchSuggestions('content', term, {
        articles: [],
        articleCount: 0
      })
    )
    setSuggestionsLoading(false)
    window.gtag('event', 'search', { search_term: term })
  }

  return (
    <Component
      categorySuggestions={categorySuggestions}
      contentSuggestions={contentSuggestions}
      suggestionsLoading={suggestionsLoading}
      onSearch={handleSearch}
      {...other}
    />
  )
}

ExploreNavigationContainer.propTypes = {
  component: any.isRequired
}

export default ExploreNavigationContainer
