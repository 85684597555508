import React from 'react'
import { func, string, bool } from 'prop-types'
import classNames from 'classnames'

import styles from './Tab.styl'

const Tab = ({ label, isActive, onClick, id }) => {
  const handleClick = e => {
    e.preventDefault()
    onClick(label)
  }

  return (
    <li
      role="presentation"
      className={classNames(styles.Tab, isActive && styles.active)}
    >
      <a
        href="#"
        onClick={handleClick}
        aria-selected={isActive}
        role="tab"
        id={id}
      >
        {label}
      </a>
    </li>
  )
}

Tab.propTypes = {
  id: string.isRequired,
  isActive: bool.isRequired,
  label: string.isRequired,
  onClick: func.isRequired
}

export default Tab
