import React from 'react'
import { object, bool } from 'prop-types'
import { withColor } from '../ColorContext'
import Loading from '../primitive/Loading'
import NavPanelContent from '../NavPanelContent'
import NavPanelSuggestions from '../NavPanelSuggestions'

import styles from './NavSearchPanel.styl'

const NavSearchPanel = ({ suggestions: { categories, content }, loading }) => {
  return (
    <div className={styles.NavSearchPanel}>
      <div className={styles.NavSearchPanelContent}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <NavPanelSuggestions
              suggestions={categories}
              articleCount={content.articleCount}
            />
            <NavPanelContent content={content} />
          </>
        )}
      </div>
    </div>
  )
}

NavSearchPanel.defaultProps = {}

NavSearchPanel.propTypes = {
  suggestions: object,
  loading: bool
}

export default withColor(NavSearchPanel)
