import React from 'react'
import { array, node, string, oneOfType } from 'prop-types'

import styles from './TabPane.styl'

const TabItem = ({ children, label }) => {
  return (
    <div label={label} className={styles.TabPane}>
      {children}
    </div>
  )
}

TabItem.propTypes = {
  children: oneOfType([node, array]).isRequired,
  label: string.isRequired
}

export default TabItem
