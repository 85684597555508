import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './SubNavigation.styl'

const Item = ({ children, link }) => {
  if (typeof window !== 'undefined' && window.location.pathname === link) {
    return (
      <div className={classNames(styles.Item, styles.active)}>{children}</div>
    )
  }

  return (
    <a aria-label="sub navigation" href={link} className={styles.Item}>
      {children}
    </a>
  )
}

Item.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string
}

const SubNavigation = ({ items }) => {
  return (
    <div className={styles.SubNavigation}>
      {items.map((item, i) => (
        <Item key={`item${i}`} link={item.link}>
          {item.label}
        </Item>
      ))}
    </div>
  )
}

SubNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
}

export default SubNavigation
