import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { withColor } from '../ColorContext'
import ConfirmDeleteModal from './ConfirmDeleteModal'

const debug = require('debug')('confirm-delete')

const ConfirmDeleteModalContainer = ({ itemId, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleDelete = async () => {
    debug('confirm delete from all saved lists')
    let response
    try {
      response = await fetch(`/api/saved-lists/item/${itemId}`, {
        method: 'DELETE',
        credentials: 'include',
        redirect: 'error'
      })
    } catch (error) {
      setLoading(false)
      debug('error', error)
      return setError('There was a problem. Please try again later.')
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    onSuccess()
  }

  return (
    <ConfirmDeleteModal
      error={error}
      loading={loading}
      onClose={onClose}
      onDelete={handleDelete}
    />
  )
}

ConfirmDeleteModalContainer.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  itemId: string.isRequired
}

export default withColor(ConfirmDeleteModalContainer)
