import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, GridItem } from '../../primitive/Grid'
import Icon from '../../primitive/Icon'
import SocialLinks from './SocialLinks'
import FooterNewsletterSignupFormContainer from '../../FooterNewsletterSignupForm/container'

import { footerLinks } from '../data/links'

import styles from '../Footer.styl'

class Footer extends PureComponent {
  constructor() {
    super()
  }

  render() {
    const {
      className,
      socialLinks,
      platform,
      hideNewsletterForm,
      instance
    } = this.props

    const classes = classNames(
      styles.Footer,
      className,
      platform === 'io' && styles.IO
    )

    return (
      <footer
        className={classes}
        role="contentinfo"
        ref={footer => {
          this.footer = footer
        }}
      >
        <div className={styles.FooterContent}>
          <div className={styles.Grid}>
            <Grid gap={5}>
              <GridItem sizes={['desktop-one-half']}>
                <Grid gap={5}>
                  <GridItem spacingBottom={3} sizes={['one-half']}>
                    <Icon
                      type="FullLogoTall"
                      width={105}
                      className={styles.Logo}
                    />
                  </GridItem>
                  <GridItem spacingBottom={3} sizes={['one-half']}>
                    <FooterLinks {...footerLinks.about} />
                    {socialLinks && (
                      <SocialLinks
                        className={styles.SocialLinks}
                        socialLinks={socialLinks}
                      />
                    )}
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem sizes={['desktop-one-half']}>
                <Grid gap={5}>
                  <GridItem spacingBottom={3} sizes={['one-half']}>
                    <FooterLinks {...footerLinks.contact} />
                  </GridItem>
                  <GridItem spacingBottom={3} sizes={['one-half']}>
                    <FooterLinks {...footerLinks.legal} />
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </div>
          {!hideNewsletterForm && platform === 'com' && (
            <div className={styles.FormWrapper}>
              <FooterNewsletterSignupFormContainer />
            </div>
          )}
        </div>
        <div className={styles.Small}>
          <small className={styles.Wrapper}>
            <div className={styles.Legal}>
              &copy; {new Date().getFullYear()} Contagious Communications.{' '}
              <a
                href="https://www.clock.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Made by Clock
              </a>
              .
            </div>
          </small>
        </div>
        {instance && (
          <>
            {instance.zoominfoId && (
              <noscript>
                <img
                  src={`https://ws.zoominfo.com/pixel/${instance.zoominfoId}`}
                  width="1"
                  height="1"
                  style={{ display: 'none' }}
                />
              </noscript>
            )}
          </>
        )}
      </footer>
    )
  }
}

const FooterLinks = ({ heading, links }) => {
  return (
    <>
      <h3 className={styles.Heading}>{heading}</h3>
      <ul className={styles.FooterNav}>
        {links.map(({ href, target, label }, i) => (
          <li key={i}>
            <a
              href={href}
              {...(target && { target: target })}
              {...(target === '_blank' && {
                rel: 'noopener noreferrer'
              })}
            >
              {label}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

FooterLinks.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
      target: PropTypes.string
    })
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  socialLinks: PropTypes.object,
  platform: PropTypes.string,
  color: PropTypes.string,
  hideNewsletterForm: PropTypes.bool,
  instance: PropTypes.shape({
    zoominfoId: PropTypes.string
  })
}

export default Footer
