import TabsWrapper from './component/TabsWrapper'
import TabPane from './component/TabPane'

const Tabs = TabsWrapper
Tabs.displayName = 'Tabs'

Tabs.Item = TabPane
Tabs.Item.displayName = 'Tabs.Item'

export default Tabs
