import React from 'react'
import { bool, string } from 'prop-types'
import OnboardingHero from '../../../layout/component/OnboardingPage/OnboardingHero'
import OnboardingCategoryForm from '../../../layout/component/OnboardingCategoryForm'
import { onboardingImages } from '../../../layout/component/OnboardingPage/data/onboarding-page-data'

const Categories = props => {
  const { canSkip, backUrl } = props
  const handleSkip = () => {
    window.location.pathname += `/skip`
  }
  const handleBack = () => {
    window.location = backUrl
  }
  return (
    <OnboardingHero backgroundMobile images={onboardingImages}>
      <OnboardingCategoryForm
        {...props}
        onSkip={canSkip ? handleSkip : undefined}
        onBack={backUrl ? handleBack : undefined}
      />
    </OnboardingHero>
  )
}

Categories.propTypes = {
  canSkip: bool,
  backUrl: string
}

export default Categories
