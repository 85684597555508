import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'
import DeleteListModal from './DeleteListModal'

const debug = require('debug')('delete-list')

const DeleteListModalContainer = ({ list, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleDelete = async name => {
    debug('delete saved list', name)
    let response
    try {
      response = await fetch(`/api/saved-lists/${list._id}`, {
        method: 'DELETE',
        credentials: 'include',
        redirect: 'error'
      })
    } catch (error) {
      setLoading(false)
      debug('delete saved list error', error)
      return setError('There was a problem. Please try again later.')
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('delete saved list error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    onSuccess()
  }

  return (
    <DeleteListModal
      list={list}
      error={error}
      loading={loading}
      onClose={onClose}
      onDelete={handleDelete}
    />
  )
}

DeleteListModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  list: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
}

export default withColor(DeleteListModalContainer)
