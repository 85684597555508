import renderComponent from '../lib/component-renderer'
import ArticleMetaControls from '../../../site/layout/component/ArticleMetaControls'

const debug = require('debug')('article meta controls')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article-hero')
    renderComponent('.js-article-meta-controls', ArticleMetaControls)
  })
}
