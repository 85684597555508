import { string } from 'prop-types'
import React from 'react'
import styles from './ArticleItem.styl'

const ArticleItem = ({ slug, imageUrl, shortHeadline, fullUrlPath }) => {
  return (
    <div className={styles.Wrap}>
      <a
        href={slug ? `/iq/article/${slug}` : fullUrlPath}
        className={styles.Container}
      >
        <div className={styles.Title}>{shortHeadline}</div>
        <div className={styles.Overlay} />
        <img className={styles.Image} loading="lazy" src={imageUrl} alt="" />
      </a>
    </div>
  )
}

export default ArticleItem
ArticleItem.propTypes = {
  slug: string,
  imageUrl: string,
  shortHeadline: string,
  fullUrlPath: string
}
