import React from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../../ColorContext'

import styles from './Loading.styl'

class Loading extends React.PureComponent {
  render() {
    const { color } = this.props
    return (
      <div className={styles.LoadingWrapper}>
        <span className={styles.Loading} style={color && { color: color }} />
      </div>
    )
  }
}

Loading.propTypes = {
  color: PropTypes.string
}

export default withColor(Loading)
