import createDebug from 'debug'
import parseHubspotCookie from './lib/hubspot-cookie-parser'
const debug = createDebug('hubspot-custom')

const hubspotApiUrl =
  'https://api.hsforms.com/submissions/v3/integration/submit'

const initHubspot = () => {
  const hubspotForms = document.querySelectorAll('.js-hubspot-custom-form')

  if (hubspotForms.length < 1) return false

  hubspotForms.forEach(form => {
    form.addEventListener('submit', captureSubmission)
  })
}

const captureSubmission = async e => {
  debug('captureSubmission', e)
  e.preventDefault()
  const form = e.target
  const successCopy = form.querySelector('.js-hubspot-custom-form-success')
  const errorCopy = form.querySelector('.js-hubspot-custom-form-error')
  const fields = form.querySelector('.js-hubspot-custom-form-fields')

  const { portalId, formId, fileUrl, disabled } = form.dataset
  const url = `${hubspotApiUrl}/${portalId}/${formId}`

  if (disabled) return false

  const data = formatSubmission(form)

  debug('Submitting form', { url, data })
  let response

  fields.classList.add('is-loading')

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (res.status !== 200) {
      const data = await res.json()
      const error = new Error('Hubspot API Error')
      window.Sentry.captureException(error, { level: 'fatal', extra: data })
      throw error
    }

    response = await res.json()
  } catch (error) {
    debug('Could not submit form', error)
    successCopy.classList.add('is-hidden')
    errorCopy.classList.remove('is-hidden')
    fields.classList.remove('is-loading')
    return false
  }
  debug('Hubspot submission response', response)

  if (fileUrl) {
    debug('downloading file', fileUrl)
    try {
      await logSubmission(data.fields, fileUrl)
    } catch (error) {
      debug('could not log submission', error)
      successCopy.classList.add('is-hidden')
      errorCopy.classList.remove('is-hidden')
      fields.classList.remove('is-loading')
      return
    }
    document.location = fileUrl
  }

  if (response.redirectUri) {
    window.location = response.redirectUri
  }

  errorCopy.classList.add('is-hidden')
  successCopy.classList.remove('is-hidden')

  // disable and reset form
  form.reset()
  form.querySelectorAll('input, textarea').forEach(el => {
    el.disabled = true
  })
  form.dataset.disabled = true
}

const formatSubmission = form => {
  const usesCheckedProperty = ({ type }) => ['radio', 'checkbox'].includes(type)
  const inputElements = [...form.elements].filter(({ nodeName }) =>
    ['SELECT', 'INPUT', 'TEXTAREA'].includes(nodeName)
  )
  const inputFields = inputElements.filter(el => !usesCheckedProperty(el))
  const consentFields = inputElements.filter(el => usesCheckedProperty(el))

  const fields = inputFields.map(({ name, value }) => ({ name, value }))
  const consent = consentFields.reduce(
    (options, field) => {
      if (field.name === 'consentToProcess') {
        options.consentToProcess = field.checked
        options.text = field.dataset.text
      } else {
        options.communications.push({
          value: field.checked,
          text: field.dataset.text,
          subscriptionTypeId: parseInt(field.value, 10)
        })
      }
      return options
    },
    { communications: [] }
  )

  if (form.dataset.communications) {
    const communications = JSON.parse(form.dataset.communications)
    communications.forEach(communication =>
      consent.communications.push({
        value: true,
        text: communication.text,
        subscriptionTypeId: parseInt(communication.id, 10)
      })
    )
  }

  const hutk = parseHubspotCookie()
  return {
    fields,
    legalConsentOptions: { consent },
    context: {
      pageName: document.title,
      pageUri: window.location.href,
      hutk
    }
  }
}

const logSubmission = async (fields, fileUrl) => {
  const data = fields.reduce(
    (data, { name, value }) => ({ ...data, [name]: value }),
    {}
  )
  const res = await fetch('/api/download', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...data,
      fileUrl
    })
  })
  if (res.status !== 200) throw new Error(await res.text())
}

export default () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', () => {
    if (window.hbspt) {
      initHubspot()
    } else {
      document
        .querySelector('#hubspot-forms-js')
        .addEventListener('load', initHubspot)
    }
  })
}
