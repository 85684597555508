import React, { useEffect, useState } from 'react'
import { arrayOf, number, shape, string } from 'prop-types'
import { Grid, GridItem } from '../primitive/Grid'
import ImageFader from '../ImageFader'

import styles from './ImageFaderGrid.styl'

const incOrReset = (val, limit) => {
  if (val >= limit) return 0
  return val + 1
}

const getColumns = (images, groupCount) => {
  // we need an array of groupCount columns containing the same number of images
  // e.g. for input of [1,2,3,4] and colCount 6
  // we want output of [1,2,3,4,1,2]
  const groupedImages = [...Array(groupCount)].map(() => [])
  const totalRuns = Math.ceil(images.length / groupCount) * groupCount

  // So as we fill up the groups, we allow running back over to the start
  // of the images array
  for (let i = 0; i < totalRuns; i++) {
    groupedImages[i % groupCount].push(images[i % images.length])
  }
  return groupedImages
}

const ImageFaderGrid = ({
  images,
  width,
  height,
  colCount = 6,
  interval = 5000
}) => {
  const columns = getColumns(images, colCount)

  const [columnIndexes, setColumnIndexes] = useState(
    [...Array(colCount)].map(() => 0)
  )

  const handleSetColumnIndexes = prevIndexes => {
    const indexes = prevIndexes.map((colIndex, i) => {
      return incOrReset(colIndex, columns[i].length - 1)
    })
    return indexes
  }

  useEffect(() => {
    columnIndexes.current = columnIndexes
    const timer = setInterval(
      () =>
        setColumnIndexes(columnIndexes =>
          handleSetColumnIndexes(columnIndexes)
        ),
      interval
    )
    return () => {
      clearInterval(timer)
    }
  }, [])

  const gridClassMap = {
    6: ['one-half', 'mobile-large-one-third', 'tablet-one-sixth'],
    8: ['one-half', 'mobile-large-one-quarter', 'desktop-one-eighth'],
    12: ['one-half', 'mobile-large-one-quarter', 'desktop-one-sixth'],
    16: ['one-half', 'mobile-large-one-quarter', 'desktop-one-eighth']
  }

  return (
    <div className={styles.ImageFaderGrid}>
      <Grid flex gap={8}>
        {columns.map((col, i) => (
          <GridItem
            className={styles.Brand}
            sizes={gridClassMap[colCount]}
            key={`col:${i}`}
            spacingBottom={4}
          >
            <ImageFader
              images={col}
              width={width}
              height={height}
              current={columnIndexes[i]}
              delay={i}
              delayInterval={400}
            />
          </GridItem>
        ))}
      </Grid>
    </div>
  )
}

ImageFaderGrid.defaultProps = {
  images: []
}

ImageFaderGrid.propTypes = {
  images: arrayOf(
    shape({
      alt: string,
      src: string
    })
  ),
  height: number,
  width: number,
  colCount: number,
  interval: number
}

export default ImageFaderGrid
