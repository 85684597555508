import React from 'react'
import { node, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './Spacer.styl'

const Spacer = ({ children, size = 'default' }) => (
  <div className={classNames(styles.Spacer, styles[size])}>{children}</div>
)

Spacer.propTypes = {
  children: node,
  size: oneOf(['default', 'large', 'md'])
}

export default Spacer
