import renderComponent from '../lib/component-renderer'

import Carousel from '../../../../components/site/widgets/article-layout/images/component/ArticleImages'

const debug = require('debug')('carousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article-hero')
    renderComponent('.js-carousel-hero', Carousel)
  })
}
