import React from 'react'
import { bool, func, object, string } from 'prop-types'

import { withColor } from '../ColorContext'
import ButtonSimple from '../primitive/ButtonSimple'
import Notice from '../primitive/Notice'
import RadioBlocks, { RadioBlock } from '../primitive/RadioBlocks'
import ShrinkWrap, { ShrinkWrapItem } from '../primitive/ShrinkWrap'
import ToggleControl from '../ToggleControl'

import styles from './EmailForm.styl'

const EmailForm = ({
  user,
  error,
  formErrors,
  onManageInterests,
  onChange,
  onSubmit,
  submitting,
  success
}) => {
  return (
    <div className={styles.EmailForm}>
      <form className={styles.Form} onSubmit={onSubmit}>
        {!!success && <Notice type="success">{success}</Notice>}
        {!!error && (
          <Notice minimal type="error">
            {error}
          </Notice>
        )}
        {!!formErrors && Object.keys(formErrors).length !== 0 && (
          <Notice minimal type="error">
            <div>Whoops, something isn’t right!</div>
            <ul>
              {Object.keys(formErrors).map(key => (
                <li key={key}>{formErrors[key]}</li>
              ))}
            </ul>
          </Notice>
        )}
        <ShrinkWrap fullWidth>
          <ShrinkWrapItem>
            <label htmlFor="weeklyNewsletterOptIn">
              <h4>Contagious Weekly Digest</h4>
              <p>Our curated round-up of the must-read articles of the week</p>
            </label>
          </ShrinkWrapItem>
          <ShrinkWrapItem shrink>
            <ToggleControl
              id="weeklyNewsletterOptIn"
              name="weeklyNewsletterOptIn"
              value="true"
              label=""
              checked={user.weeklyNewsletterOptIn}
              onChange={onChange}
            />
          </ShrinkWrapItem>
        </ShrinkWrap>

        <ShrinkWrap fullWidth>
          <ShrinkWrapItem>
            <label htmlFor="creativeCatchupOptIn">
              <h4>Contagious Creative Catch-up</h4>
              <p>
                Short on time? Don’t miss our bi-weekly video, all you need to
                know in just a few minutes
              </p>
            </label>
          </ShrinkWrapItem>
          <ShrinkWrapItem shrink>
            <ToggleControl
              id="creativeCatchupOptIn"
              name="creativeCatchupOptIn"
              value="true"
              label=""
              checked={user.creativeCatchupOptIn}
              onChange={onChange}
            />
          </ShrinkWrapItem>
        </ShrinkWrap>

        <div className={styles.EmailFormField}>
          <h4>Alerts based on interests</h4>
          <p>
            We’ll email you whenever we publish new content relevant to your
            interests. Select your preferred frequency
          </p>
          <div className={styles.EmailFormFieldControl}>
            <RadioBlocks>
              <RadioBlock
                name="emailFrequency"
                value="Push"
                onChange={onChange}
                checked={user.emailFrequency === 'Push'}
              >
                Push
              </RadioBlock>
              <RadioBlock
                name="emailFrequency"
                value="Weekly"
                onChange={onChange}
                checked={user.emailFrequency === 'Weekly'}
              >
                Weekly
              </RadioBlock>
              <RadioBlock
                name="emailFrequency"
                value="Monthly"
                onChange={onChange}
                checked={user.emailFrequency === 'Monthly'}
              >
                Monthly
              </RadioBlock>
              <RadioBlock
                name="emailFrequency"
                value="Never"
                onChange={onChange}
                checked={user.emailFrequency === 'Never'}
              >
                Never
              </RadioBlock>
              <ButtonSimple
                className={styles.ManageInterestsBtn}
                type="button"
                onClick={onManageInterests}
                disabled={submitting}
                ariaLabel="Manage interests"
              >
                Manage interests
              </ButtonSimple>
            </RadioBlocks>
          </div>
        </div>
      </form>
    </div>
  )
}

EmailForm.propTypes = {
  user: object.isRequired,
  error: string,
  formErrors: object,
  onManageInterests: func.isRequired,
  onChange: func.isRequired,
  onSubmit: func.isRequired,
  passwordChanged: bool,
  showPassword: bool,
  submitting: bool,
  success: string
}

export default withColor(EmailForm)
