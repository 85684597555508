import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import TextControl from '../primitive/Control/TextControl'
import ButtonSimple from '../primitive/ButtonSimple'
import Loading from '../primitive/Loading'
import FieldTemplate from '../primitive/FieldTemplate'
import { withColor } from '../ColorContext'

import styles from './RenameListModal.styl'

const RenameListModal = ({ onClose, onSave, error, loading, list }) => {
  const [name, setName] = useState(list.name)
  return (
    <Modal
      onClose={onClose}
      className={styles.RenameListModal}
      title="Rename List"
      rounded
      largeHeader
    >
      <form>
        {error && <p>{error}</p>}
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.CollectionSelect}>
            <FieldTemplate label="Name" hideLabel controlName="name">
              <TextControl
                type="text"
                name="name"
                placeholder="Name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
                required
              />
            </FieldTemplate>
          </div>
        )}
        <div className={styles.ButtonGroup}>
          <ButtonSimple
            ariaLabel="save list"
            onClick={e => {
              e.preventDefault()
              onSave(name)
            }}
            disabled={!name}
          >
            Save List
          </ButtonSimple>
        </div>
      </form>
    </Modal>
  )
}

RenameListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  error: PropTypes.string
}

export default withColor(RenameListModal)
