import createDebug from 'debug'
const debug = createDebug('hubspot:forms')

const initHubspot = () => {
  const hubspotForms = document.querySelectorAll('.js-hubspot-form')
  const hubspot = window.hbspt
  if (!hubspot) {
    debug('Hubspot js not loaded')
    return false
  }
  if (hubspotForms.length < 1) return false

  hubspotForms.forEach(form => {
    const { id, portalId, formId, sfdcCampaignId, redirectUrl } = form.dataset
    const target = `#js-hubspot-form-${id}`
    if (!portalId || !formId) return false
    debug('Creating form', { formId, portalId, sfdcCampaignId, target })
    hubspot.forms.create({
      formId,
      portalId,
      sfdcCampaignId,
      redirectUrl,
      target,
      css: ''
    })
  })
}

export default () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', () => {
    if (window.hbspt) {
      initHubspot()
    } else {
      document
        .querySelector('#hubspot-forms-js')
        .addEventListener('load', initHubspot)
    }
  })
}
