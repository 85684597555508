import React from 'react'
import { node, string } from 'prop-types'

import styles from './StepProgressWrapper.styl'

const StepProgressWrapper = ({ children, label, title }) => {
  return (
    <nav aria-label={label} className={styles.StepProgressWrapper}>
      <div className={styles.Title}>{title}</div>
      <ol className={styles.StepProgressWrapperList}>{children}</ol>
    </nav>
  )
}

StepProgressWrapper.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
  title: string
}

export default StepProgressWrapper
