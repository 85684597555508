import React from 'react'
import HeadingSlash from '../../primitive/HeadingSlash'
import CheckControl from '../../primitive/Control/CheckControl'

import styles from './Categories.styl'
import { object, func, string } from 'prop-types'
import { withColor } from '../../ColorContext'

const Categories = ({ categories, onChange, color }) => {
  const handleChange = category => e => {
    onChange(e.target.checked, category)
  }

  if (!categories) return null

  const sortedCategories = Object.keys(categories).sort(
    (a, b) => categories[a].order - categories[b].order
  )

  return sortedCategories.map(taxonomyId => {
    const taxonomy = categories[taxonomyId]
    return (
      <div className={styles.Category} key={`taxonomy--${taxonomy.name}`}>
        <h4>
          <HeadingSlash>{taxonomy.name}</HeadingSlash>
        </h4>
        <ul className={styles.CategoryList}>
          {Object.keys(taxonomy.items).map(categoryId => {
            const category = taxonomy.items[categoryId]
            return (
              <li
                className={styles.Item}
                key={`item--${taxonomyId}--${categoryId}`}
              >
                <CheckControl
                  value={`item--${taxonomyId}--${categoryId}`}
                  type="checkbox"
                  onChange={handleChange({
                    taxonomy: taxonomyId,
                    category: categoryId
                  })}
                  checked={category.following}
                  small
                >
                  <span className={styles.ItemText}>
                    <a
                      style={color && { borderColor: color }}
                      href={`/iq/following?categories=${taxonomy.slug}/${category.slug}`}
                    >
                      {category.name}
                    </a>
                  </span>
                </CheckControl>
              </li>
            )
          })}
        </ul>
      </div>
    )
  })
}

Categories.propTypes = {
  categories: object,
  color: string,
  onChange: func.isRequired
}

export default withColor(Categories)
