import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import PropTypes from 'prop-types'
import styles from './BestInClass.styl'
import Button from '../primitive/Button'
import ResponsiveMedia from '../primitive/ResponsiveMedia'
import classNames from 'classnames'
import Icon from '../primitive/Icon'

const ArrowButton = ({ direction, disabled, onClick, className }) => (
  <Button
    icon
    className={classNames(
      styles.NavButton,
      disabled && styles.disabled,
      className
    )}
    onClick={onClick}
    ariaLabel="previous"
  >
    <Icon type={direction === 'left' ? 'ChevronLeft' : 'ChevronRight'} />
  </Button>
)

ArrowButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  onClick: PropTypes.func
}

const Article = ({ article }) => (
  <a className={styles.Article} href={article.__fullUrlPath}>
    <ResponsiveMedia ratio={1}>
      <img src={article.imageUrl} />
    </ResponsiveMedia>
    <div className={styles.ArticleContent}>
      <h3 className={styles.ArticleHeadline}>{article.shortHeadline}</h3>
    </div>
  </a>
)

Article.propTypes = {
  article: PropTypes.object
}

const BestInClass = props => {
  const { title, subtitle, articles, className } = props

  const [canScrollNext, setCanScrollNext] = useState(false)
  const [canScrollPrev, setCanScrollPrev] = useState(false)

  const [emblaRef, slider] = useEmblaCarousel({
    slidesToScroll: 2,
    containScroll: 'trimSnaps',
    skipSnaps: true,
    align: 'start',
    duration: 25,
    loop: true
  })

  const onSelect = useCallback(slider => {
    setCanScrollNext(slider.canScrollNext())
    setCanScrollPrev(slider.canScrollPrev())
  }, [])

  const handleScrollNext = useCallback(() => {
    slider && slider.scrollNext()
  }, [slider])

  const handleScrollPrev = useCallback(() => {
    slider && slider.scrollPrev()
  }, [slider])

  useEffect(() => {
    if (!slider) return
    onSelect(slider)
    slider.on('select', onSelect)
    slider.on('reInit', onSelect)

    return () => {
      slider.off('select', onSelect)
      slider.off('reInit', onSelect)
    }
  }, [slider])

  if (!articles || articles.length <= 0) return null

  return (
    <div className={classNames(styles.BestInClass, className)}>
      <div className={styles.OverflowContainer}>
        <div className={styles.Container}>
          <div className={styles.Intro}>
            <div className={styles.HeadlineContainer}>
              <div>
                {title && <h2 className={styles.Headline}>{title}</h2>}
                {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
              </div>
              <div>
                <a className={styles.CtaLink} href="/iq/collections">
                  View all
                </a>
                <div className={styles.MobileNav}>
                  <ArrowButton
                    direction="left"
                    onClick={handleScrollPrev}
                    disabled={!canScrollPrev}
                  />
                  <ArrowButton
                    direction="right"
                    onClick={handleScrollNext}
                    disabled={!canScrollNext}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.CarouselWrapper} ref={emblaRef}>
            <div className={styles.Carousel}>
              {articles.map((article, i) => (
                <Article
                  key={`BestInClass--Articles--${i}`}
                  article={article}
                />
              ))}
            </div>
            <div className={styles.DesktopNav}>
              <ArrowButton
                direction="left"
                onClick={handleScrollPrev}
                disabled={!canScrollPrev}
              />
              <ArrowButton
                direction="right"
                onClick={handleScrollNext}
                disabled={!canScrollNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BestInClass.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  articles: PropTypes.array,
  subtitle: PropTypes.string
}

export default BestInClass
