import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './HubspotForm.styl'

import Notification from '../primitive/Notification'
import Control from '../primitive/ControlLegacy'

const HubspotForm = ({
  formId,
  portalId,
  commsText,
  commsId,
  ctaLabel,
  children,
  additionalFieldsFirst = true,
  successDark = true,
  notificationStyle
}) => (
  <form
    className={classNames(
      styles.Form,
      !additionalFieldsFirst && styles.reversed,
      'js-hubspot-custom-form'
    )}
    data-form-id={formId}
    data-portal-id={portalId}
    data-communications={
      commsText && commsId
        ? JSON.stringify([{ text: commsText, id: commsId }])
        : undefined
    }
  >
    <div className="js-hubspot-custom-form-success is-hidden">
      <Notification
        className={notificationStyle}
        state={successDark ? 'successBlack' : 'success'}
      >
        Thanks for signing up!
      </Notification>
    </div>
    <div className="js-hubspot-custom-form-error is-hidden">
      <Notification className={notificationStyle} state="error">
        There was an error. Please try again.
      </Notification>
    </div>
    <div className="js-hubspot-custom-form-fields">
      {additionalFieldsFirst && (
        <div className={styles.AdditionalFields}>{children}</div>
      )}
      <div className={styles.CoreFields}>
        <Control
          type="email"
          name="email"
          placeholder="Email"
          theme="LightBg"
          className={styles.FormField}
          required
        />
        <button aria-label={ctaLabel} className={styles.FormButton}>
          {ctaLabel}
        </button>
      </div>
      {!additionalFieldsFirst && (
        <div className={styles.AdditionalFields}>{children}</div>
      )}
    </div>
  </form>
)

HubspotForm.propTypes = {
  formId: PropTypes.string,
  portalId: PropTypes.string,
  commsText: PropTypes.string,
  commsId: PropTypes.string,
  ctaLabel: PropTypes.string,
  children: PropTypes.node,
  additionalFieldsFirst: PropTypes.bool,
  successDark: PropTypes.bool,
  notificationStyle: PropTypes.object
}

export default HubspotForm
