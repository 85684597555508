import React from 'react'
import PropTypes from 'prop-types'

import FAQs from '../../../layout/component/FAQs'
import Spacer from '../../../layout/component/primitive/Spacer'

const FAQsWidget = props => {
  const { title, items } = props
  return (
    <div className="widget widget--faqs">
      <Spacer>
        <FAQs items={items} title={title} />
      </Spacer>
    </div>
  )
}

FAQsWidget.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    })
  ).isRequired
}

export default FAQsWidget
