import React from 'react'
import PropTypes from 'prop-types'
import HeadingSlash from '../HeadingSlash'
import { withColor } from '../../ColorContext'

import styles from './SectionHeading.styl'

class SectionHeading extends React.PureComponent {
  render() {
    const { title, description } = this.props
    return (
      <div className={styles.SectionHeading}>
        <h2>
          <HeadingSlash className={styles.Slash}>{title}</HeadingSlash>
        </h2>
        {description && <p className={styles.Description}>{description}</p>}
      </div>
    )
  }
}

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node
}

export default withColor(SectionHeading)
