import React from 'react'
import { arrayOf, array, string } from 'prop-types'

import Prose from '../Prose'
import HeadingSlash from '../primitive/HeadingSlash'
import IconButton from '../primitive/IconButton'
import SaveArticle from '../SaveArticle'

import styles from './CollectionHero.styl'

const CollectionHero = ({
  collectionId,
  text,
  // preTitle,
  title,
  href,
  savedLists
}) => {
  const absoluteUrl = typeof window !== 'undefined' && window.location.href
  const mailUrl = `mailto:?subject=Shared: ${title}&body=${absoluteUrl}`
  return (
    <a className={styles.CollectionHero} aria-label={title} href={href}>
      <div className={styles.CollectionHeroHeader}>
        <h2 className={styles.CollectionHeroTitle}>
          <HeadingSlash>{title}</HeadingSlash>
        </h2>
        <div className={styles.CollectionHeroActions}>
          <div className={styles.CollectionHeroSave}>
            <IconButton
              className={styles.IconButton}
              ariaLabel="share"
              icon="Social"
              href={mailUrl}
              iconWidth={16}
              iconHeight={17}
              data-track="Share"
              data-track-label={title}
            />
            <SaveArticle
              type="collection"
              itemId={collectionId}
              name={text}
              savedLists={savedLists}
            />
          </div>
        </div>
      </div>

      <div className={styles.CollectionHeroDescription}>
        {/* {preTitle && (
          <div className={styles.CollectionHeroPreTitle}>{preTitle}</div>
        )} */}
        {text && (
          <div className={styles.CollectionHeroText}>
            <Prose dangerousHtml={text} />
          </div>
        )}
      </div>
    </a>
  )
}

CollectionHero.defaultProps = {
  images: []
}

CollectionHero.propTypes = {
  collectionId: string.isRequired,
  href: string,
  images: arrayOf(string).isRequired,
  text: string,
  preTitle: string,
  title: string.isRequired,
  savedLists: array,
  __fullUrlPath: string
}

export default CollectionHero
