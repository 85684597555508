import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'

import createItemSchema from '../../../../service/collection/item-schema'
import AddToCollection from '../AddToCollection/AddToCollection'

const debug = require('debug')('add-to-collection')
const schema = createItemSchema()

const EmbedCollectionContainer = ({
  collectionId,
  onClose,
  onAddCollection,
  items
}) => {
  const [collections, setCollections] = useState([])
  const [selectedCollection, setCollection] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const fetchCollections = async () => {
      debug('Fetching collections')
      let response
      try {
        response = await fetch('/api/collections', {
          credentials: 'include',
          redirect: 'error'
        })
      } catch (error) {
        setLoading(false)
        debug('Fetching collection error', error)
        return setError('There was a problem. Please try again later.')
      }

      setLoading(false)
      if (response.status !== 200) {
        debug('Fetching collection error', response.status)
        return setError('There was a problem. Please try again later.')
      }

      const fetchedCollections = (await response.json()).map(collection => ({
        ...collection,
        disabled:
          collection._id === collectionId ||
          items.some(item => item.collectionId === collection._id)
      }))

      const enabledCollections = fetchedCollections.filter(
        ({ disabled }) => !disabled
      )
      const disabledCollections = fetchedCollections.filter(
        ({ disabled }) => disabled
      )

      debug('Fetched collections', fetchedCollections)

      setCollections([
        { text: 'Select a collection', value: '' },
        ...[...enabledCollections, ...disabledCollections].map(
          ({ _id, name, disabled }) => {
            return {
              text: name,
              value: _id,
              disabled,
              title: disabled && 'This collection already exists',
              _id,
              link: `/iq/collection/${_id}`
            }
          }
        )
      ])
    }
    fetchCollections()
  }, [])

  const handleAdd = async () => {
    debug('Set collections')
    let response
    try {
      response = await fetch(
        `/api/collection/${selectedCollection.collectionId}`,
        {
          credentials: 'include',
          redirect: 'error'
        }
      )
    } catch (error) {
      setLoading(false)
      debug('Set collection error', error)
      return setError('There was a problem. Please try again later.')
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('Set collection error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    const collection = await response.json()
    onAddCollection({ ...selectedCollection, ...collection })
    setSuccess(true)
  }

  const handleSelect = id => {
    const selected = collections.find(({ _id }) => _id === id)
    setCollection({
      ...schema.makeDefault({
        type: 'collection',
        collectionId: selected._id,
        title: selected.text,
        author: selected.name
      })
    })
  }

  return (
    <AddToCollection
      onClose={onClose}
      loading={loading}
      error={error}
      success={success}
      onSelect={handleSelect}
      collections={collections}
      selectedCollection={selectedCollection}
      onAdd={handleAdd}
      itemType="collection"
    />
  )
}

EmbedCollectionContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddCollection: PropTypes.func,
  collectionId: PropTypes.string,
  items: PropTypes.array
}

export default withColor(EmbedCollectionContainer)
