import React, { PureComponent } from 'react'
import { withColor } from '../../../../ColorContext'
import { bool, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './CustomCheckControl.styl'

import NativeCheckControl from '../NativeCheckControl'
export class CustomCheckControl extends PureComponent {
  render() {
    const { status, type, small } = this.props

    const componentClassName = classNames(
      styles.CustomCheckControl,
      type && styles[type],
      status && styles[status],
      small && styles.small
    )

    return (
      <span className={componentClassName}>
        <NativeCheckControl
          className={styles.CustomCheckControlControl}
          {...this.props}
        />
        <span className={styles.CustomCheckControlIndicator} />
      </span>
    )
  }
}

CustomCheckControl.displayName = 'CustomCheckControl'

CustomCheckControl.propTypes = {
  status: oneOf(['error', 'notice', 'success', 'warning']),
  type: oneOf(['checkbox', 'radio']).isRequired,
  color: string,
  small: bool
}

export default withColor(CustomCheckControl)
