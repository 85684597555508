import renderComponent from '../lib/component-renderer'

import LogoCarousel from '../../../../components/site/widgets/logo-carousel/component/LogoCarousel'

const debug = require('debug')('logo-carousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init logo-carousel')
    renderComponent('.js-logo-carousel', LogoCarousel)
  })
}
