import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../../primitive/Icon'

import styles from './MainNavigation.styl'

const MainNavigation = ({ links, isOpen }) => {
  const [openItems, setOpenItems] = useState([])

  return (
    <ul className={classNames(styles.MainNavigation, isOpen && styles.open)}>
      {links &&
        links.map(({ title, href, links }, i) => {
          const hasLinks = links && links.length
          if (!href && !hasLinks) return null

          return (
            <li key={`Links${title}${i}`} className={styles.Item}>
              {hasLinks ? (
                <div tabIndex={0} className={styles.SubItemsContainer}>
                  <span className={styles.ItemLabel}>{title}</span>
                  {links && links.length && (
                    <>
                      <button
                        aria-label="open menu"
                        onClick={() =>
                          openItems.includes(i)
                            ? setOpenItems(openItems.filter(n => n !== i))
                            : setOpenItems([...openItems, i])
                        }
                        className={classNames(
                          styles.MenuSubItemToggle,
                          openItems.includes(i) && styles.flipped
                        )}
                      >
                        <Icon type="ChevronDown" />
                      </button>
                      <Icon
                        width={10}
                        className={styles.NavArrowDown}
                        type="ChevronDown"
                      />
                      <ul
                        className={classNames(
                          styles.SubItems,
                          openItems.includes(i) && styles.open
                        )}
                      >
                        {links.map((link, i) => (
                          <li
                            className={styles.SubItem}
                            key={`${link.title}-sublink${i}`}
                          >
                            <a href={link.href}>
                              <span>-</span>
                              {link.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ) : (
                <a
                  aria-label="Menu"
                  href={href}
                  data-track="Menu"
                  data-track-label={title}
                >
                  <span className={styles.ItemLabel}>{title}</span>
                </a>
              )}
            </li>
          )
        })}
    </ul>
  )
}

MainNavigation.propTypes = {
  links: PropTypes.array,
  isOpen: PropTypes.bool
}

export default MainNavigation
