import React, { useState } from 'react'
import { func, shape, string } from 'prop-types'
import { withColor } from '../ColorContext'
import ShareListModal from './ShareListModal'

const debug = require('debug')('share-list-modal')

const ShareListModalContainer = ({ list, onClose, onListUpdate }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const apiRequest = url => async data => {
    debug('saved list api request', url, data)
    setLoading(true)
    setSuccess(false)
    setError(null)
    let response
    try {
      response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        redirect: 'error',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data
      })
    } catch (error) {
      setLoading(false)
      debug('saved list api error', error)
      setError('There was a problem. Please try again later.')
      return list
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('saved list api error', response.status)
      setError('There was a problem. Please try again later.')
      return list
    }
    let responseData
    try {
      responseData = await response.json()
      setSuccess(true)
    } catch (error) {
      debug('saved list api error', response.status)
      setError('There was a problem. Please try again later.')
      return list
    }
    return responseData
  }
  const handleChangeCollaborative = async collaborative => {
    const updatedList = await apiRequest(`/api/saved-lists/${list._id}`)(
      JSON.stringify({ collaborative })
    )
    onListUpdate(updatedList)
  }

  const handleRemoveUser = async userId => {
    await apiRequest(`/api/saved-list/${list._id}/leave/${userId}`)()
    onListUpdate({
      ...list,
      users: list.users.filter(_id => _id !== userId),
      __users: list.__users.filter(({ _id }) => _id !== userId)
    })
  }

  return (
    <ShareListModal
      list={list}
      error={error}
      success={success}
      loading={loading}
      onClose={onClose}
      onChangeCollaborative={handleChangeCollaborative}
      onRemoveUser={handleRemoveUser}
    />
  )
}

ShareListModalContainer.propTypes = {
  onClose: func.isRequired,
  onListUpdate: func.isRequired,
  list: shape({
    _id: string.isRequired
  }).isRequired
}

export default withColor(ShareListModalContainer)
