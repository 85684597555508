import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridItem } from '../primitive/Grid'
import HeadingSlash from '../primitive/HeadingSlash'
import { withColor } from '../ColorContext'
import Icon from '../primitive/Icon'
import nl2br from '../../../lib/nl2br'

import styles from './CollectionLink.styl'

class CollectionLink extends React.PureComponent {
  render() {
    const { link, title, note, image, color } = this.props
    return (
      <a
        className={styles.CollectionLink}
        href={link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Grid>
          <GridItem sizes={['tablet-one-third']}>
            <div
              className={styles.ImageWrapper}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </GridItem>
          <GridItem sizes={['tablet-two-thirds']}>
            <div className={styles.ContentWrapper}>
              <label
                className={styles.LinkLabel}
                style={color && { backgroundColor: color }}
              >
                <Icon type="Link" className={styles.LinkIcon} />
                Link
              </label>
              <h2 className={styles.Heading}>
                <HeadingSlash>{title}</HeadingSlash>
              </h2>
              {note && (
                <p
                  className={styles.Note}
                  dangerouslySetInnerHTML={{ __html: nl2br(note) }}
                />
              )}
            </div>
          </GridItem>
        </Grid>
      </a>
    )
  }
}

CollectionLink.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  note: PropTypes.string,
  color: PropTypes.string
}

export default withColor(CollectionLink)
