import React from 'react'
import OnboardingHero from '../../../layout/component/OnboardingPage/OnboardingHero'
import OnboardingAccountForm from '../../../layout/component/OnboardingForm/OnboardingAccountForm'
import { onboardingImages } from '../../../layout/component/OnboardingPage/data/onboarding-page-data'

const Register = props => (
  <OnboardingHero backgroundMobile images={onboardingImages}>
    <OnboardingAccountForm {...props} />
  </OnboardingHero>
)

export default Register
