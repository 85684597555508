import React, { useState, useEffect } from 'react'
import { object, array, string, func } from 'prop-types'
import qs from 'querystring'
import RenameListModal from '../../../../site/layout/component/RenameListModal'
import DeleteListModal from '../../../../site/layout/component/DeleteListModal'
import ShareListModal from '../../../../site/layout/component/ShareListModal'
import NewListModal from '../../../../site/layout/component/NewListModal'

const debug = require('debug')('saved-list-list')

const SavedListsContainer = ({
  saved,
  savedLists,
  list,
  userId,
  component: Component
}) => {
  const { page: initialPage } = qs.parse(location.search.substr(1))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [lists, setLists] = useState(savedLists)
  const [selectedList, setSelectedList] = useState(list)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [page, setPage] = useState(Number(initialPage) || 1)
  const pageSize = 24
  const pages = selectedList
    ? Math.ceil(selectedList.__itemCount / pageSize)
    : 0
  const isOwner = selectedList && selectedList.user === userId
  const isMember =
    selectedList && selectedList.users && selectedList.users.includes(userId)

  useEffect(() => {
    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  useEffect(() => {
    if (list && list._id) {
      window.history.replaceState(
        {},
        null,
        `/iq/saved/${list._id}?page=${page}`
      )
    } else {
      window.history.replaceState({}, null, `/iq/saved?page=${page}`)
    }
    loadListItems(list ? list._id : null, false)
  }, [])

  const handlePopState = () => {
    const pathParts = location.pathname.split('/')
    const listId = pathParts[pathParts.length - 1]
    let pageToLoad = 1
    if (location.search) {
      const query = qs.parse(location.search.slice(1))
      pageToLoad = query.page || 1
    }
    setPage(pageToLoad)
    if (listId !== 'saved') {
      loadListItems(listId, false, pageToLoad)
    } else {
      loadListItems(null, false, pageToLoad)
    }
  }

  const loadListItems = async (listId, addToHistory = true, overridePage) => {
    debug('fetching list', { listId, addToHistory, page, overridePage })
    setLoading(true)
    setError(false)
    const query = qs.stringify({ page: overridePage || page, pageSize })
    const url = `/api/saved-list/items${listId ? `/${listId}` : ''}?${query}`
    try {
      const res = await fetch(url, {
        credentials: 'include',
        redirect: 'error'
      })
      setLoading(false)
      if (res.status !== 200) {
        debug('fetching list invalid status code', res.status)
        return setError(true)
      }

      const listItems = await res.json()
      if (!listItems) {
        debug('fetching list not found')
        return setSelectedList(null)
      }

      setSelectedList(prevState => ({
        ...prevState,
        __items: listItems
      }))

      if (addToHistory) {
        window.history.pushState(
          {},
          null,
          `/iq/saved${listId ? `/${listId}` : ''}?page=${overridePage || page}`
        )
      }
    } catch (error) {
      debug('fetching list error', error)
      window.Sentry.captureException(error)
      setLoading(false)
      setError(true)
    }
  }

  const handleChangeList = listToSelect => {
    setPage(1)
    if (!selectedList && !listToSelect) {
      return
    } else if (selectedList && !listToSelect) {
      setSelectedList({ _id: null })
    } else if (!selectedList && listToSelect) {
      setSelectedList(listToSelect)
    } else if (selectedList._id !== listToSelect._id) {
      setSelectedList(listToSelect)
    }
    loadListItems(listToSelect ? listToSelect._id : null, true, 1)
  }

  const handleRenameList = updatedList => {
    handleListUpdate(updatedList)
    handleRenameListClose()
  }

  const handleRenameListOpen = () => {
    setShowRenameModal(true)
  }
  const handleRenameListClose = () => {
    setShowRenameModal(false)
  }

  const removeSelectedList = () => {
    const filteredLists = lists.filter(list => list._id !== selectedList._id)
    setSelectedList(filteredLists[0])
    setLists(filteredLists)
  }

  const handleDeleteList = () => {
    handleDeleteListClose()
    removeSelectedList()
  }

  const handleDeleteListOpen = () => {
    setShowDeleteModal(true)
  }
  const handleDeleteListClose = () => {
    setShowDeleteModal(false)
  }

  const handleShareListOpen = () => {
    setShowShareModal(true)
  }
  const handleShareListClose = () => {
    setShowShareModal(false)
  }

  const handleCreateListOpen = () => {
    setShowCreateModal(true)
  }

  const handleCreateListClose = () => {
    setShowCreateModal(false)
  }

  const handleCreateList = list => {
    setLists(prevState => [...prevState, list])
  }

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
    window.scrollTo(0, 0)
    loadListItems(selectedList._id, true, pageNumber)
  }

  const handleDeleteItem = async itemToDelete => {
    const typeId = `${itemToDelete.type}Id`
    const itemId = itemToDelete[typeId]
    const uri = `/api/saved-lists/${selectedList._id}/${itemToDelete.type}/${itemToDelete._id}`
    const response = await fetch(uri, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'error'
    })
    if (response.status !== 200) {
      debug('remove article error', response.status)
      return setError(true)
    }
    handleRemoveItem(typeId, itemId)
  }

  const handleLeaveList = async () => {
    const uri = `/api/saved-list/${selectedList._id}/leave`
    const response = await fetch(uri, {
      method: 'POST',
      credentials: 'include',
      redirect: 'error'
    })
    if (response.status !== 200) {
      debug('remove article error', response.status)
      return setError(true)
    }
    removeSelectedList()
  }

  const handleRemoveItem = (typeId, itemId) => {
    const updatedList = {
      ...selectedList,
      items: selectedList.items.filter(item => item[typeId] !== itemId),
      __items: selectedList.__items.filter(item => item[typeId] !== itemId)
    }
    handleListUpdate(updatedList)
  }

  const handleListUpdate = updatedList => {
    setSelectedList(prevState =>
      prevState._id === updatedList._id
        ? { ...prevState, ...updatedList }
        : prevState
    )
    setLists(prevState =>
      prevState.map(list =>
        list._id === updatedList._id ? { ...list, ...updatedList } : list
      )
    )
  }

  return (
    <>
      {showRenameModal && (
        <RenameListModal
          list={selectedList}
          onClose={handleRenameListClose}
          onSuccess={handleRenameList}
        />
      )}
      {showDeleteModal && (
        <DeleteListModal
          list={selectedList}
          onClose={handleDeleteListClose}
          onSuccess={handleDeleteList}
        />
      )}
      {showShareModal && (
        <ShareListModal
          list={selectedList}
          onClose={handleShareListClose}
          onListUpdate={handleListUpdate}
        />
      )}
      {showCreateModal && (
        <NewListModal
          onClose={handleCreateListClose}
          onSuccess={handleCreateList}
        />
      )}
      <Component
        saved={saved}
        savedLists={lists}
        list={selectedList}
        loading={loading}
        error={error}
        userId={userId}
        isOwner={isOwner}
        isMember={isMember}
        onChangeList={handleChangeList}
        onRenameList={handleRenameListOpen}
        onDeleteList={handleDeleteListOpen}
        onShareList={handleShareListOpen}
        onLeaveList={handleLeaveList}
        onCreateList={handleCreateListOpen}
        page={page}
        pages={pages}
        onPageChange={handlePageChange}
        onDeleteItem={handleDeleteItem}
        onRemoveItem={handleRemoveItem}
      />
    </>
  )
}

SavedListsContainer.propTypes = {
  saved: object.isRequired,
  savedLists: array.isRequired,
  list: object,
  userId: string,
  component: func.isRequired
}

export default SavedListsContainer
