import React from 'react'
import OnboardingHero from '../../layout/component/OnboardingPage/OnboardingHero'
import PasswordResetRequestForm from '../../layout/component/Identity/PasswordResetRequestForm'
import { loginImages } from '../../layout/component/OnboardingPage/data/onboarding-page-data'

const PasswordResetRequest = props => (
  <OnboardingHero backgroundMobile images={loginImages}>
    <PasswordResetRequestForm {...props} />
  </OnboardingHero>
)

export default PasswordResetRequest
