import React, { useState } from 'react'
import { object, any, string, bool, array } from 'prop-types'

import Modal from '../../../../site/layout/component/Modal'
import { withColor } from '../../../../site/layout/component/ColorContext'
import EmbedCollectionContainer from '../../../../site/layout/component/EmbedCollection'

const CollectionViewContainer = ({
  user,
  collection,
  component: Component,
  isInContentOrganisation,
  color,
  savedLists,
  related = []
}) => {
  const [error, setError] = useState(null)
  const [conflict, setConflict] = useState(null)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [rawItems, setRawItems] = useState(collection.items)
  const [items, setItems] = useState(collection.__items)
  const [lastModifiedDate, setLastModifiedDate] = useState(
    collection.lastModifiedDate
  )
  const [editingItem, setEditingItem] = useState(null)
  const [showCommentForm, setShowCommentForm] = useState(false)
  const [showHeadingForm, setShowHeadingForm] = useState(false)
  const [showLinkForm, setShowLinkForm] = useState(false)
  const [showEmbedModal, setShowEmbedModal] = useState(false)

  const reorderItems = async orderedItems => {
    setError(null)
    setSuccess(false)
    setSubmitting(true)

    try {
      const res = await fetch(`/api/collections/${collection._id}/reorderer`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        body: JSON.stringify({
          items: orderedItems.map(({ id }) => id),
          lastModifiedDate
        })
      })

      setSubmitting(false)
      if (res.status === 200) {
        setSuccess(true)
        const data = await res.json()
        setLastModifiedDate(data.modifiedDate)
      } else if (res.status === 409) {
        setConflict(true)
        setError(
          'Some new articles have been added to the collection whilst you’ve had it open. Please reload the page to ensure nothing is lost.'
        )
      } else {
        setError('There was an issue saving. Please try again later.')
      }
    } catch (error) {
      setSubmitting(false)
      setError('There was an issue saving. Please try again later.')
    }
  }

  const deleteItem = async itemId => {
    setError(null)
    setSuccess(false)
    setSubmitting(true)

    try {
      const res = await fetch(
        `/api/collections/${collection._id}/items/${itemId}`,
        {
          method: 'DELETE',
          credentials: 'include',
          redirect: 'error'
        }
      )

      setSubmitting(false)
      if (res.status === 200) {
        setSuccess(true)
        const data = await res.json()
        setLastModifiedDate(data.modifiedDate)
      } else {
        setError(
          'There was an issue deleting that item. Please try again later.'
        )
      }
    } catch (error) {
      setSubmitting(false)
      setError('There was an issue deleting that item. Please try again later.')
    }
  }

  const createItem = async item => {
    setError(null)
    setSuccess(false)
    setSubmitting(true)

    try {
      const res = await fetch(`/api/collections/${collection._id}/items`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        body: JSON.stringify({ item })
      })

      setSubmitting(false)
      if (res.status === 200) {
        setSuccess(true)
        const { item: updatedItem, modifiedDate } = await res.json()
        setLastModifiedDate(modifiedDate)
        setItems(prevState => replaceItem(prevState, updatedItem))
      } else {
        setError('There was an issue adding that item. Please try again later.')
      }
    } catch (error) {
      setSubmitting(false)
      setError('There was an issue adding that item. Please try again later.')
    }
  }

  const saveItem = async item => {
    setError(null)
    setSuccess(false)
    setSubmitting(true)

    try {
      const res = await fetch(
        `/api/collections/${collection._id}/items/${item.id}`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify({ item, lastModifiedDate })
        }
      )

      setSubmitting(false)
      setEditingItem(null)
      if (res.status === 200) {
        setSuccess(true)
        const data = await res.json()
        setLastModifiedDate(data.modifiedDate)
      } else if (res.status === 409) {
        setConflict(true)
        setError(
          'Some new articles have been added to the collection whilst you’ve had it open. Please reload the page to ensure nothing is lost.'
        )
      } else {
        setError(
          'There was an issue editing that item. Please try again later.'
        )
      }
    } catch (error) {
      setSubmitting(false)
      setError('There was an issue editing that item. Please try again later.')
    }
  }

  const replaceItem = (itemList, newItem) =>
    itemList.map(item => (item.id === newItem.id ? newItem : item))

  const handleReorder = async reorderedItems => {
    setItems(reorderedItems)
    setRawItems(
      rawItems.sort(
        (a, b) =>
          reorderedItems.findIndex(({ id }) => id === a.id) -
          reorderedItems.findIndex(({ id }) => id === b.id)
      )
    )
    await reorderItems(reorderedItems)
  }

  const handleDeleteItem = async itemId => {
    setItems(prevState => prevState.filter(({ id }) => id !== itemId))
    setRawItems(prevState => prevState.filter(({ id }) => id !== itemId))
    await deleteItem(itemId)
  }

  const addItem = async item => {
    setRawItems(prevState => [item, ...prevState])
    setItems(prevState => [item, ...prevState])
    await createItem(item)
  }

  const updateItem = async item => {
    setRawItems(prevState => replaceItem(prevState, item))
    setItems(prevState => replaceItem(prevState, item))
    await saveItem(item)
  }

  const addOrUpdateItem = item => {
    if (editingItem && editingItem.id === item.id) {
      updateItem(item)
    } else {
      addItem(item)
    }
  }

  const handleAddComment = item => {
    setShowCommentForm(false)
    addOrUpdateItem(item)
  }

  const handleShowCommentForm = () => setShowCommentForm(true)
  const handleHideCommentForm = () => {
    setShowCommentForm(false)
    setEditingItem(null)
  }

  const handleShowHeadingForm = () => setShowHeadingForm(true)
  const handleHideHeadingForm = () => {
    setShowHeadingForm(false)
    setEditingItem(null)
  }

  const handleAddHeading = item => {
    setShowHeadingForm(false)
    addOrUpdateItem(item)
  }

  const handleShowLinkForm = () => setShowLinkForm(true)
  const handleHideLinkForm = () => {
    setShowLinkForm(false)
    setEditingItem(null)
  }

  const handleAddLink = item => {
    setShowLinkForm(false)
    addOrUpdateItem(item)
  }

  const handleShowEmbedModal = () => {
    setShowEmbedModal(true)
  }

  const handleEmbedCollection = item => {
    setShowEmbedModal(false)
    addOrUpdateItem(item)
  }

  const handleEdit = itemId => {
    const item = items.find(({ id }) => id === itemId)
    setEditingItem(item)
    if (item.type === 'comment') {
      handleShowCommentForm()
    } else if (item.type === 'heading') {
      handleShowHeadingForm()
    } else if (item.type === 'link') {
      handleShowLinkForm()
    }
  }

  const handleConflictClose = () => setConflict(false)

  return (
    <>
      {conflict && (
        <Modal title="Hold on" onClose={handleConflictClose}>
          <p>
            It looks like a few new articles have been added to this collection
            while you’ve had it open. Please{' '}
            <a
              aria-label="reload the page"
              href={window.location}
              style={color && { color }}
            >
              reload the page
            </a>{' '}
            before moving anything around to make sure nothing is lost.
          </p>
        </Modal>
      )}
      {showEmbedModal && (
        <EmbedCollectionContainer
          collectionId={collection._id}
          onClose={() => setShowEmbedModal(false)}
          onAddCollection={handleEmbedCollection}
          items={items}
        />
      )}
      <Component
        collectionId={collection._id}
        name={collection.name}
        description={collection.description}
        date={collection.__date}
        images={collection.__images}
        user={user}
        error={error}
        success={success}
        submitting={submitting}
        items={items}
        editingItem={editingItem}
        canEdit={isInContentOrganisation}
        savedLists={savedLists}
        onReorder={handleReorder}
        onEdit={handleEdit}
        onDeleteItem={handleDeleteItem}
        onAddComment={handleAddComment}
        onShowCommentForm={handleShowCommentForm}
        onHideCommentForm={handleHideCommentForm}
        showCommentForm={showCommentForm}
        onAddHeading={handleAddHeading}
        showHeadingForm={showHeadingForm}
        onShowHeadingForm={handleShowHeadingForm}
        onHideHeadingForm={handleHideHeadingForm}
        onAddLink={handleAddLink}
        showLinkForm={showLinkForm}
        onShowLinkForm={handleShowLinkForm}
        onHideLinkForm={handleHideLinkForm}
        onShowEmbedModal={handleShowEmbedModal}
        related={related}
      />
    </>
  )
}

CollectionViewContainer.propTypes = {
  user: object.isRequired,
  collection: object,
  component: any.isRequired,
  color: string,
  isInContentOrganisation: bool,
  savedLists: array,
  related: array
}

export default withColor(CollectionViewContainer)
