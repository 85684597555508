import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './DemoForm.styl'
import classnames from 'classnames'
import { Grid, GridItem } from '../primitive/Grid'
import Prose from '../Prose'
import Field, { FieldAnswer, FieldQuestion } from '../primitive/Field'
import Control from '../primitive/ControlLegacy'
import Button from '../primitive/Button'
import parseHubspotCookie from '../../../../browser/base/hubspot-custom/lib/hubspot-cookie-parser'
import freeEmailDomains from 'free-email-domains'
import formatProperties from '../../../../service/hubspot/lib/property-formatter'
import createDebug from 'debug'
import { countryCodes } from './phone-code'
const debug = createDebug('demo-form')

const submitToHubspot = async data => {
  const formTransform = {
    firstname: 'firstName',
    lastname: 'lastName',
    email: 'emailAddress',
    jobtitle: 'jobTitle',
    company: 'companyName',
    brand_agency__c: 'companyType',
    phone: 'phone'
  }

  const properties = formatProperties(formTransform, {
    ...data,
    phone: data.countryCode + data.phone
  })

  debug('submitting hubspot form', data, properties)
  const hutk = parseHubspotCookie()
  const consent = {
    consentToProcess: true,
    text:
      'I agree to the privacy policy and understand Contagious will contact me throughout the trial'
  }
  const formData = {
    // Map contact API to form API structure
    fields: [
      ...properties.map(({ property, value }) => ({
        name: property,
        value
      })),
      {
        name:
          'i_would_like_to_receive_marketing_offers_for_contagious_services',
        value: true
      }
    ],
    legalConsentOptions: { consent },
    context: {
      pageName: document.title,
      pageUri: window.location.href,
      hutk
    }
  }

  const hubspotApiUrl =
    'https://api.hsforms.com/submissions/v3/integration/submit'
  const portalId = '1725887'
  const formId = '7092f1b6-6219-4d86-8f9e-130362e56aa1'
  const url = `${hubspotApiUrl}/${portalId}/${formId}`
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    if (res.status !== 200) {
      const responseData = await res.json()
      const error = new Error('Hubspot API Error')
      window.Sentry.captureException(error, {
        level: 'fatal',
        extra: responseData
      })
      debug('submit error', responseData)
    }
    debug('submit successful')
  } catch (error) {
    debug('Could not submit form', error)
  }
}

const DemoForm = ({ reCaptchaKey, returnUrl }) => {
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({
    countryCode: '+44'
  })

  const handleChange = ({ target: { name, type, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    setSuccess(false)
    setSubmitting(true)
    setErrors({})

    window.grecaptcha.ready(async () => {
      try {
        const checkValidEmail = email => {
          const emailDomain = email.split('@')[1].replace('@', '')
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          const isPersonalEmail = freeEmailDomains.includes(emailDomain)
          return emailRegex.test(email) && !isPersonalEmail
        }

        const isValidEmail = checkValidEmail(data.emailAddress)

        //check valid phone
        const phoneRegex = /^[0-9]{7,20}$/
        const isValidPhone = phoneRegex.test(data.phone)
        if (isValidEmail && isValidPhone) {
          await submitToHubspot(data)
          setSuccess(true)
        } else {
          if (!isValidEmail) {
            setErrors({
              emailAddress:
                'Please ensure you are using a valid workplace email address'
            })
          }
          if (!isValidPhone) {
            setErrors({
              phone: 'Phone number is not valid'
            })
          }
          setSubmitting(false)
        }
      } catch (error) {
        setSubmitting(false)
        setErrors({
          emailAddress:
            'There was an issue submitting this form. Please try again later.'
        })
      }
    })
  }
  return (
    <div
      className={classnames(styles.TrialForm, {
        [styles.FormSuccess]: success
      })}
    >
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="returnUrl" value={returnUrl} />
        {success ? (
          <Grid flex gap={8}>
            <GridItem className={styles.SuccessMessage}>
              <Prose className={styles.Title}>
                Thank you for your submission.
                <br />
                We&#39;ll be in touch.
              </Prose>
            </GridItem>
          </Grid>
        ) : (
          <Grid flex gap={8}>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion htmlFor="firstName">First Name</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    name="firstName"
                    defaultValue={data.firstName}
                    error={errors.firstName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion htmlFor="lastName">Last Name</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your last name"
                    name="lastName"
                    defaultValue={data.lastName}
                    error={errors.lastName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion htmlFor="email">
                  WORK EMAIL ADDRESS
                </FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    name="emailAddress"
                    defaultValue={data.emailAddress}
                    error={errors.emailAddress}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion htmlFor="jobTitle">Job Title</FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your job title"
                    name="jobTitle"
                    defaultValue={data.jobTitle}
                    error={errors.jobTitle}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem spacingBottom={2} sizes={['desktop-one-half']}>
              <Field>
                <FieldQuestion htmlFor="companyName">
                  Company name
                </FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    placeholder="Enter your company name"
                    name="companyName"
                    defaultValue={data.companyName}
                    error={errors.companyName}
                    required
                  />
                </FieldAnswer>
              </Field>
            </GridItem>
            <GridItem sizes={['desktop-one-half']}>
              <Field className={styles.noMargin}>
                <FieldQuestion htmlFor="companyType">
                  Type of business
                </FieldQuestion>
                <FieldAnswer>
                  <Control
                    onChange={handleChange}
                    className={styles.Select}
                    type="select"
                    name="companyType"
                    defaultValue={data.companyType}
                    error={errors.companyType}
                    options={[
                      { text: 'Please select an option', value: '' },
                      { text: 'Brand', value: 'Brand' },
                      { text: 'Agency', value: 'Agency' },
                      { text: 'Media Owner', value: 'Media Owner' },
                      { text: 'University', value: 'University' },
                      { text: 'Other', value: 'Other' }
                    ]}
                  />
                </FieldAnswer>
              </Field>
            </GridItem>

            <GridItem spacingBottom={2}>
              <FieldQuestion htmlFor="phone">PHONE NUMBER</FieldQuestion>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '20px'
                }}
              >
                <div style={{ maxWidth: '200px' }}>
                  <Field>
                    <FieldAnswer>
                      <Control
                        onChange={handleChange}
                        className={styles.Select}
                        type="select"
                        name="countryCode"
                        value={data.countryCode}
                        error={errors.countryCode}
                        options={countryCodes.map(item => ({
                          text: item.name,
                          value: item.dial_code
                        }))}
                      ></Control>
                    </FieldAnswer>
                  </Field>
                </div>
                <div className={styles.PhoneWrapper}>
                  {data.countryCode && (
                    <span
                      className={classnames(
                        styles.CountryCodeLabel,
                        errors.phone && styles.WithError
                      )}
                    >
                      {data.countryCode}
                    </span>
                  )}
                  <Field>
                    <FieldAnswer>
                      <Control
                        onChange={handleChange}
                        name="phone"
                        defaultValue={data.phone}
                        error={errors.phone}
                        className={styles.Phone}
                      />
                    </FieldAnswer>
                  </Field>
                </div>
              </div>
            </GridItem>

            <GridItem spacingBottom={2}>
              We may email you from time to time with updates and other
              information about our events, products and services that may be of
              interest. You can unsubscribe at any time. See our privacy policy
              <a
                className={styles.PrivacyPolicyLink}
                href="/privacy-policy"
                target="_blank"
              >
                {' '}
                here
              </a>
              .
            </GridItem>
            <GridItem>
              <div className={styles.Submit}>
                <div className={styles.ButtonWrapper}>
                  <Button disabled={submitting} primary>
                    Submit
                  </Button>
                </div>
              </div>
            </GridItem>
          </Grid>
        )}
      </form>

      <script
        src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`}
      />
    </div>
  )
}
DemoForm.propTypes = {
  returnUrl: PropTypes.string,
  reCaptchaKey: PropTypes.string
}
export default DemoForm
