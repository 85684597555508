import React from 'react'
import TabbedPageIntro from '../../../layout/component/TabbedPageIntro'
import Spacer from '../../../layout/component/primitive/Spacer'

const TabbedPageIntroWidget = props => {
  return (
    <div className="widget widget--tabbed-page-intro">
      <Spacer>
        <TabbedPageIntro {...props} />
      </Spacer>
    </div>
  )
}

export default TabbedPageIntroWidget
