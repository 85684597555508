import React from 'react'
import classnames from 'classnames'
import {
  arrayOf,
  object,
  bool,
  func,
  oneOfType,
  shape,
  string,
  array,
  number,
  any
} from 'prop-types'

import Select, { createFilter, components } from 'react-select'
import { FixedSizeList } from 'react-window'

import styles from './SelectInput.styl'

const customStyles = {
  valueContainer: provided => ({
    ...provided,
    padding: '12px'
  }),
  multiValue: provided => ({
    ...provided,
    border: '1px solid #FF0096',
    padding: '4px 4px 4px 4px',
    borderRadius: '4px',
    backgroundColor: '#F5F5F5',
    margin: '4px 6px'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#FF0096',
    fontSize: '14px',
    marginRight: '4px',
    padding: 0
  })
}

const height = 30

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  )
}

MenuList.propTypes = {
  options: array,
  getValue: func,
  maxHeight: number,
  children: any.isRequired
}

const Option = ({ innerProps, isFocused, children, ...other }) => {
  delete innerProps.onMouseMove
  delete innerProps.onMouseOver
  const newProps = { innerProps, ...other }
  return (
    <components.Option
      {...newProps}
      className={classnames(styles.Option, { [styles.OptionHover]: isFocused })}
    >
      {children}
    </components.Option>
  )
}

Option.propTypes = {
  children: any.isRequired,
  innerProps: object.isRequired,
  isFocused: bool
}

const SelectInput = ({ options, onChange, defaultValue, ...other }) => {
  return (
    <div className={styles.SelectInput}>
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        styles={customStyles}
        onChange={onChange}
        defaultValue={defaultValue}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          MenuList,
          Option
        }}
        options={options}
        isMulti
        isClearable={false}
        {...other}
      />
    </div>
  )
}

const optionShape = arrayOf(
  shape({
    label: string,
    value: oneOfType([string, bool])
  })
).isRequired

SelectInput.propTypes = {
  defaultValue: optionShape,
  options: optionShape,
  onChange: func
}

export default SelectInput
