import React from 'react'
import { arrayOf, object } from 'prop-types'
import StaticCarousel from '../../../layout/component/StaticCarousel'
import Spacer from '../../../layout/component/primitive/Spacer'

const StaticCarouselWidget = props => {
  const { items } = props
  return (
    <div className="widget widget--static-carousel">
      <Spacer>
        <StaticCarousel items={items} />
      </Spacer>
    </div>
  )
}

StaticCarouselWidget.propTypes = {
  items: arrayOf(object).isRequired
}

export default StaticCarouselWidget
