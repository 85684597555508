import React, { PureComponent } from 'react'
import { bool, func, number, oneOf, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import styles from './TextControl.styl'

export class TextControl extends PureComponent {
  render() {
    const {
      autoComplete,
      autoFocus,
      className,
      controlRef,
      defaultValue,
      disabled,
      id,
      maxLength,
      minLength,
      multiline,
      name,
      onBlur,
      onChange,
      onFocus,
      pattern,
      placeholder,
      readOnly,
      required,
      rows,
      size,
      status,
      tabIndex,
      type,
      value
    } = this.props

    const componentClassName = classNames(
      styles.TextControl,
      multiline && styles.multiline,
      status && styles[status],
      className
    )

    const TextControlEl = multiline ? 'textarea' : 'input'

    return (
      <TextControlEl
        aria-invalid={status === 'error' || undefined}
        aria-required={required}
        className={componentClassName}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        rows={multiline && rows}
        ref={controlRef}
        size={size}
        tabIndex={tabIndex}
        type={!multiline ? type : undefined}
        value={value}
      />
    )
  }
}

TextControl.displayName = 'TextControl'

TextControl.defaultProps = {
  type: 'text',
  rows: 3
}

TextControl.propTypes = {
  autoComplete: oneOfType([bool, string]),
  autoFocus: bool,
  className: string,
  controlRef: func,
  defaultValue: string,
  disabled: bool,
  id: string,
  maxLength: number,
  minLength: number,
  multiline: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  pattern: string,
  placeholder: string,
  readOnly: bool,
  required: bool,
  rows: number,
  size: number,
  status: oneOf(['error', 'notice', 'success', 'warning']),
  tabIndex: number,
  type: string,
  value: string
}

export default TextControl
