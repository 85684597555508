import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'
import HeadingForm from './HeadingForm'
import createItemSchema from '../../../../service/collection/item-schema'

const schema = createItemSchema()

const HeadingFormContainer = ({
  onClose,
  onSubmit,
  author,
  authorId,
  item
}) => {
  const blank = schema.makeDefault({ type: 'heading', author, authorId })
  const initialState = item || blank
  const [data, setData] = useState(initialState)
  const [errors, setErrors] = useState({})
  const handleChange = ({ target: { name, value } }) => {
    setData(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSubmit = e => {
    e && e.preventDefault()
    setErrors({})

    schema.validate(data, (err, errors) => {
      if (errors && Object.keys(errors).length) {
        return setErrors(errors)
      }
      return onSubmit(data)
    })
  }
  return (
    <HeadingForm
      onSubmit={handleSubmit}
      onClose={onClose}
      data={data}
      errors={errors}
      onChange={handleChange}
      isNew={!item}
    />
  )
}

HeadingFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  author: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  item: PropTypes.object
}

export default withColor(HeadingFormContainer)
