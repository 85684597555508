import React, { useState } from 'react'
import { string, number, object, array, bool, func } from 'prop-types'

import OnboardingFormLayout from '../OnboardingFormLayout'
import CategoryPickerForm from '../CategoryPickerForm'
import Notice from '../primitive/Notice'

import styles from './OnboardingCategoryForm.styl'

const OnboardingCategoryForm = ({
  title,
  subtitle,
  minimumSelected = 3,
  selectedCategoryMap,
  checkboxColumns = 3,
  categories,
  taxonomies,
  step,
  redirected,
  onSkip,
  onBack
}) => {
  const [selected, setSelected] = useState(selectedCategoryMap)
  const toggle = (taxonomyId, categoryId) => {
    setSelected(prevState => ({
      ...prevState,
      [taxonomyId]: {
        ...prevState[taxonomyId],
        [categoryId]: !prevState[taxonomyId][categoryId]
      }
    }))
  }

  const setOption = (taxonomyId, categoryId, value) => {
    setSelected(prevState => ({
      ...prevState,
      [taxonomyId]: {
        ...prevState[taxonomyId],
        [categoryId]: value
      }
    }))
  }

  const hasMinimumSelected =
    Object.keys(selected).reduce(
      (sum, key) =>
        sum +
        Object.keys(selected[key]).filter(category => selected[key][category])
          .length,
      0
    ) >= minimumSelected

  const hasSelected = (taxonomyId, categoryId) =>
    selected[taxonomyId][categoryId]

  return (
    <OnboardingFormLayout
      step={step}
      title={title}
      subtitle={subtitle}
      disabled={!hasMinimumSelected}
      onSkip={onSkip}
      onBack={onBack}
    >
      <input type="hidden" name="selected" value={JSON.stringify(selected)} />
      {redirected && (
        <Notice minimal type="error" className={styles.Error}>
          Please complete the steps to gain full access.
        </Notice>
      )}
      <CategoryPickerForm
        checkboxColumns={checkboxColumns}
        taxonomies={taxonomies}
        categories={categories}
        hasSelected={hasSelected}
        onToggle={toggle}
        onSetOption={setOption}
      />
    </OnboardingFormLayout>
  )
}

OnboardingCategoryForm.propTypes = {
  step: number.isRequired,
  title: string.isRequired,
  subtitle: string,
  minimumSelected: number,
  selectedCategoryMap: object.isRequired,
  categories: object.isRequired,
  taxonomies: array.isRequired,
  checkboxColumns: number,
  redirected: bool,
  onSkip: func,
  onBack: func
}

export default OnboardingCategoryForm
