import React from 'react'
import classNames from 'classnames'
import styles from './NavItem.styl'
import { array, bool, func, object, string } from 'prop-types'
import Icon from '../../../../../primitive/Icon'
import CaseStudies from '../SubNavs/CaseStudies'
import Objectives from '../SubNavs/Objectives'
import Learning from '../SubNavs/Learning'
import Sectors from '../SubNavs/Sectors'

const NavItem = ({
  value,
  subNavs,
  caseStudiesArticles,
  learningArticles,
  property,
  expanded,
  onClick
}) => {
  const toggleMenuHandle = property => {
    if (!expanded) {
      onClick(property)
    } else {
      onClick(null)
    }
  }
  if (!value.links && !value.items) {
    return (
      <div className={styles.ListLinkWrap}>
        <a
          href={value.url}
          className={classNames(
            styles.ListLink,
            value.colourOverride && styles.isPink
          )}
        >
          <span>{value.title}</span>
        </a>
      </div>
    )
  }
  return (
    <>
      <div className={styles.ListLinkWrap}>
        <button
          onClick={() => toggleMenuHandle(property)}
          className={classNames(
            styles.ListLink,
            expanded && styles.ListLinkActive
          )}
        >
          <span>{value.title}</span>
          <span className={styles.ListLinkIconWrap}>
            <Icon
              type="ChevronLeft"
              className={classNames(styles.ListLinkIcon)}
            />
          </span>
        </button>
        {expanded && (
          <nav
            className={classNames(
              styles.DropDownContentWrap,
              (property === 'objectives' || property === 'sectors') &&
                styles.PaddingRight,
              (property === 'caseStudies' || property === 'learning') &&
                styles.NoPaddingRight,
              'NavigationDropDownContent'
            )}
          >
            <div className={styles.DropDownContent}>
              {property === 'caseStudies' && subNavs && (
                <CaseStudies subNavs={subNavs} articles={caseStudiesArticles} />
              )}
              {property === 'objectives' && subNavs && (
                <Objectives subNavs={subNavs} />
              )}
              {property === 'sectors' && subNavs && (
                <Sectors subNavs={subNavs} />
              )}
              {property === 'learning' && subNavs && (
                <Learning subNavs={subNavs} articles={learningArticles} />
              )}
            </div>
          </nav>
        )}
      </div>
      {expanded && (
        <div className={styles.Overlay} onClick={() => onClick(null)} />
      )}
    </>
  )
}

export default NavItem
NavItem.propTypes = {
  value: object,
  subNavs: array,
  caseStudiesArticles: array,
  learningArticles: array,
  property: string,
  expanded: bool,
  onClick: func
}
