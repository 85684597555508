import React from 'react'
import { array, bool, string, func } from 'prop-types'
import { withColor } from '../ColorContext'
import Container from './container'

const SaveArticleContainer = ({
  type,
  itemId,
  name,
  savedLists = [],
  inverse,
  onRemoveItem
}) => {
  const componentProps = {
    type,
    itemId,
    name,
    savedLists,
    inverse
  }
  return (
    <div
      className="js-save-article"
      data-props={JSON.stringify(componentProps)}
    >
      <Container onRemoveItem={onRemoveItem} {...componentProps} />
    </div>
  )
}

SaveArticleContainer.propTypes = {
  savedLists: array,
  type: string.isRequired,
  itemId: string.isRequired,
  name: string,
  inverse: bool,
  onRemoveItem: func
}

export default withColor(SaveArticleContainer)
