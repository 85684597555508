import React from 'react'
import PropTypes from 'prop-types'
import Clients from '../../../layout/component/Clients'

const LogoCarousel = props => {
  const { clients } = props

  return (
    <div className="widget widget--logo-carousel">
      <Clients clients={clients} />
    </div>
  )
}

LogoCarousel.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string
    })
  )
}

export default LogoCarousel
