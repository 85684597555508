import React from 'react'
import { bool, func, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ToggleControl.styl'

import VisuallyHidden from '../primitive/VisuallyHidden'

const Toggle = ({ checked, id, label, name, value, onChange }) => {
  const handleChange = e => {
    onChange && onChange(e)
  }

  return (
    <label className={classNames(styles.ToggleControl, 'js-toggle-control')}>
      <input
        checked={checked}
        className={styles.ToggleControlInput}
        id={id}
        name={name}
        type="checkbox"
        value={value}
        onChange={handleChange}
      />
      <VisuallyHidden>{label}</VisuallyHidden>
      <span className={styles.ToggleControlFrame} />
      <span className={styles.ToggleControlIndicator} />
    </label>
  )
}

Toggle.propTypes = {
  checked: bool,
  id: string,
  label: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  onChange: func
}

export default Toggle
