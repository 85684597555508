const get = require('lodash.get')

module.exports = (transform, data) =>
  Object.keys(transform)
    .map(key => {
      const map = transform[key]
      let value
      if (typeof map === 'function') {
        value = map(data)
      } else {
        value = get(data, map.split('.'))
      }

      if (typeof value === 'undefined') {
        return null
      }
      return {
        property: key,
        value
      }
    })
    .filter(property => property)
