import React from 'react'
import { string } from 'prop-types'
import WidgetWrapper from '../../../widget/component/WidgetWrapper'

import Spacer from '../../../layout/component/primitive/Spacer'
import DemoForm from '../../../layout/component/DemoForm'

const DemoFormWidget = props => {
  const { returnUrl, reCaptchaKey } = props
  const componentProps = { reCaptchaKey, returnUrl }
  return (
    <WidgetWrapper id="demo-form" {...props}>
      <div className="js-demo-form" data-props={JSON.stringify(componentProps)}>
        <Spacer>
          <div className="widget widget--DemoForm">
            <DemoForm reCaptchaKey={reCaptchaKey} returnUrl={returnUrl} />
          </div>
        </Spacer>
      </div>
    </WidgetWrapper>
  )
}

DemoFormWidget.propTypes = {
  returnUrl: string,
  reCaptchaKey: string
}

export default DemoFormWidget
