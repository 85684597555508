import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Field.styl'

export class FieldQuestion extends PureComponent {
  render() {
    const { htmlFor, children } = this.props
    return (
      <div className={styles.FieldQuestion}>
        <label htmlFor={htmlFor}>{children}</label>
      </div>
    )
  }
}

FieldQuestion.displayName = 'FieldQuestion'

FieldQuestion.propTypes = {
  children: node.isRequired,
  htmlFor: string.isRequired
}

export class FieldAnswer extends PureComponent {
  render() {
    const { children } = this.props
    return <div className={styles.FieldAnswer}>{children}</div>
  }
}

FieldAnswer.displayName = 'FieldAnswer'

FieldAnswer.propTypes = {
  children: node.isRequired
}

export class FieldRequired extends PureComponent {
  render() {
    return (
      <abbr className={styles.FieldRequired} title="This field is required">
        *
      </abbr>
    )
  }
}

export class FieldAssistance extends PureComponent {
  render() {
    const { children } = this.props
    return <div className={styles.FieldAssistance}>{children}</div>
  }
}

FieldAssistance.displayName = 'FieldAssistance'

FieldAssistance.propTypes = {
  children: node.isRequired
}

export class FieldFeedback extends PureComponent {
  render() {
    const { children, inverse } = this.props
    return (
      <div
        className={classNames(styles.FieldFeedback, inverse && styles.inverse)}
      >
        {children}
      </div>
    )
  }
}

FieldFeedback.displayName = 'FieldFeedback'

FieldFeedback.propTypes = {
  children: node.isRequired,
  inverse: bool
}

export class Field extends PureComponent {
  render() {
    const { children, id, status, template } = this.props
    return (
      <div
        className={classNames(
          styles.Field,
          status && styles[status],
          template && styles[template]
        )}
        id={id}
      >
        {children}
      </div>
    )
  }
}

Field.displayName = 'Field'

Field.propTypes = {
  children: node.isRequired,
  id: string,
  status: oneOf(['error', 'notice', 'success', 'warning']),
  template: string
}

export default Field
