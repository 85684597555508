import React from 'react'
import PropTypes from 'prop-types'

import OnboardingFormLayout from '../../OnboardingFormLayout'
import FieldTemplate from '../../primitive/FieldTemplate'
import TextControl from '../../primitive/Control/TextControl'

const PasswordResetRequestForm = ({ sent }) => (
  <OnboardingFormLayout
    title="Password Reset Request"
    buttonText="Request reset"
    subtitle={
      sent ? (
        <p>
          If you have a registered account, an email with instructions to reset
          your password is on its way. If you do not receive an email, please
          contact{' '}
          <a
            aria-label="mail to support@contagious.com"
            href="mailto:support@contagious.com"
          >
            support@contagious.com
          </a>
          .
        </p>
      ) : (
        <p>
          Please enter the email address of the account you&#39;d like to reset.
        </p>
      )
    }
    hideButton={sent}
  >
    {!sent && (
      <FieldTemplate label="Email address" controlName="identity">
        <TextControl
          type="email"
          name="identity"
          required
          placeholder="Email address"
        />
      </FieldTemplate>
    )}
  </OnboardingFormLayout>
)

PasswordResetRequestForm.defaultProps = {
  sent: false
}

PasswordResetRequestForm.propTypes = {
  sent: PropTypes.bool
}

export default PasswordResetRequestForm
