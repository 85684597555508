import React from 'react'
import PropTypes from 'prop-types'

import styles from './FAQs.styl'
import HeadingSlash from '../primitive/HeadingSlash'
import Expander from '../Expander'

class FAQs extends React.PureComponent {
  render() {
    const { items, title } = this.props

    if (!items || items.length === 0) {
      return null
    }

    return (
      <div className={styles.FAQs}>
        <h2 className={styles.Title}>
          <HeadingSlash>{title}</HeadingSlash>
        </h2>
        {items.map((item, i) => (
          <Expander
            key={`faqs${i}`}
            question={`${item.title} /`}
            answer={item.body}
          />
        ))}
      </div>
    )
  }
}

FAQs.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
}

export default FAQs
