import React, { useState, useEffect } from 'react'
import PropTypes, { oneOf } from 'prop-types'
import classNames from 'classnames'

import BaseControl from '../BaseControl'
import styles from './Text.styl'

const TextControl = ({
  className,
  placeholder,
  error,
  theme,
  disabled,
  element = 'input',
  ...other
}) => {
  const [hasValue, setHasValue] = useState(true)
  const handleBlur = e => {
    setHasValue(e.target.value !== '')
  }
  useEffect(() => {
    setHasValue(!!other.value || !!other.defaultValue)
  }, [other.value, other.defaultValue])

  const controlClassNames = classNames(
    styles.TextControl,
    theme && styles[theme],
    hasValue && styles.HasValue
  )
  return (
    <div
      className={classNames(
        styles.TextControlGroup,
        disabled && styles.disabled,
        className
      )}
    >
      <label>
        <BaseControl
          {...other}
          className={controlClassNames}
          element={element}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
      </label>
      {error && <div className={styles.Error}>{error}</div>}
    </div>
  )
}

TextControl.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  theme: oneOf(['LightBg', 'DarkBg', 'PinkBg', 'TransparentBg']),
  element: oneOf(['input', 'textarea']),
  disabled: PropTypes.bool
}

export default TextControl
