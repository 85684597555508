import renderComponent from '../lib/component-renderer'

import VideoGrid from '../../../site/widgets/video-grid/component/VideoGrid'

const debug = require('debug')('video-grid')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init video-grid')
    renderComponent('.js-video-grid', VideoGrid)
  })
}
