import createDebug from 'debug'
import delegate from 'delegate'
import debounce from 'lodash.debounce'
import createMaxPercentageCalculator from './lib/max-percentage-calculator'

const debug = createDebug('tracking')

export default () => {
  debug('init')
  const { __userMeta: userMeta = {}, __entity } = window
  const { user = {}, organisation = {} } = userMeta

  if (!window.dataLayer) window.dataLayer = []
  if (!window.gtag)
    window.gtag = function gtag() {
      window.dataLayer.push(arguments)
    }

  const { gtag, hj } = window

  // GA4
  gtag('set', 'user_properties', {
    userId: user._id || 'logged-out',
    organisation: organisation.name || 'no-organisation'
  })
  gtag('set', {
    platform: __entity.platform || 'com',
    content: __entity.contentType,
    page_type: __entity.type
  })

  hj && user._id && hj('identify', user._id)

  // page_view event disabled in GA initialise, so trigger one now
  gtag('event', 'page_view', {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
    platform: __entity.platform || 'com',
    content: __entity.contentType,
    page_type: __entity.type
  })

  const ioEventTracker = (event, data, callback) => {
    const { category, action, label } = event
    debug(event)
    gtag('event', action, {
      event_category: category,
      event_label: label,
      event_callback: callback,
      platform: __entity.platform || 'com',
      content: __entity.contentType,
      page_type: __entity.type
    })
  }

  // Capture analytics event & use event callback to navigate _after_
  // analytics event is captured
  const ioLinkEventTracker = (e, event, data) => {
    const { href, target, rel } = e.delegateTarget
    const isWebLink = href.startsWith('http')
    const isExternal = !href.includes(document.location.host)
    // Only prevent navigation if it is a link
    // This prevents the browser blocking mailto links as it thinks
    // there is no user gesture
    if (isWebLink) {
      e.preventDefault()
    }
    const followLink = () => {
      if (isWebLink) {
        if (isExternal) {
          window.open(href, target, rel)
        } else {
          window.location = href
        }
      }
    }
    // If there's an issue tracking, just follow the event anyway
    setTimeout(followLink, 1000)
    ioEventTracker(event, data, followLink)
  }

  delegate(
    'a',
    'click',
    e => {
      const { href } = e.delegateTarget
      if (href && !href.includes(document.location.host)) {
        debug('External link found: ' + href)
        ioLinkEventTracker(e, {
          category: 'OutboundLink',
          action: 'click',
          label: href
        })
      }
    },
    false
  )

  delegate(
    '[data-track]',
    'click',
    e => {
      const { tagName, href, dataset } = e.delegateTarget
      const { track, trackLabel } = dataset

      if (['BUTTON'].includes(tagName)) {
        ioEventTracker({
          category: track,
          action: 'click',
          label: trackLabel
        })
      }

      if (['A'].includes(tagName) && href) {
        ioLinkEventTracker(e, {
          category: track,
          action: 'click',
          label: trackLabel
        })
      }
    },
    false
  )

  window.ioEventTracker = ioEventTracker

  const calculateMaxPercentage = createMaxPercentageCalculator()
  let lastPercent = 0
  document.addEventListener(
    'scroll',
    debounce(
      () => {
        // Measured from top of the page in the viewport
        const scrollPosition =
          document.documentElement.scrollTop || document.body.scrollTop
        // Scroll position is measured from the TOP of the viewport, so the max
        // scroll position can only be (documentHeight - viewportHeight)
        const maxScroll =
          (document.documentElement.scrollHeight ||
            document.body.scrollHeight) - document.documentElement.clientHeight
        const percent = calculateMaxPercentage(scrollPosition, maxScroll)

        if (percent === 0 || percent === lastPercent) return
        lastPercent = percent

        if (percent % 5 === 0) {
          ioEventTracker({
            category: __entity.type,
            action: 'Scroll',
            label: percent
          })
        }
      },
      30,
      { maxWait: 300 }
    )
  )
}
