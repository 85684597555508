import React, { useEffect, useRef, useState } from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import classNames from 'classnames'
import useEscapeKey from '../../lib/hook/useEscapeKey'
import useOutsideClick from '../../lib/hook/useOutsideClick'

import styles from './DropdownStandard.styl'

import Icon from '../primitive/Icon'
import InlineGroup from '../InlineGroup'
import ShrinkWrap, { ShrinkWrapItem } from '../primitive/ShrinkWrap'

const DropdownStandard = ({ activeWithin, alignRight, block, items, name }) => {
  const [expanded, setExpanded] = useState(false)
  const [out, setOut] = useState({})
  const wrapper = useRef()
  const list = useRef()

  const handleCheckEdges = () => {
    const bounding = list.current.getBoundingClientRect()
    const out = {}
    out.top = bounding.top < 0
    out.left = bounding.left < 0
    out.bottom =
      bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight)
    out.right =
      bounding.right >
      (window.innerWidth || document.documentElement.clientWidth)
    out.any = out.top || out.left || out.bottom || out.right
    out.all = out.top && out.left && out.bottom && out.right
    setOut(out)
  }

  const handleOpen = () => {
    setExpanded(true)
  }

  const handleClose = () => {
    setExpanded(false)
    setOut({})
  }

  useEscapeKey(expanded && handleClose)
  useOutsideClick(wrapper, expanded && handleClose)

  useEffect(() => {
    if (!expanded) return
    handleCheckEdges()
  }, [expanded])

  return (
    <div
      className={classNames(
        styles.DropdownStandard,
        alignRight && styles.alignRight,
        activeWithin && styles.activeWithin,
        block && styles.block,
        expanded && styles.expanded
      )}
      ref={wrapper}
    >
      <div
        className={classNames(styles.DropdownList, styles.DropdownListPreview)}
      >
        <InlineGroup block>
          <div
            className={classNames(styles.DropdownListItem, styles.DropdownName)}
          >
            {name}
          </div>
          <button
            aria-label={expanded ? 'Hide options' : 'Show options'}
            type="button"
            className={classNames(
              styles.DropdownListItem,
              styles.DropdownToggle
            )}
            onClick={expanded ? handleClose : handleOpen}
            aria-haspopup="true"
            aria-expanded={expanded}
          >
            <Icon
              type={expanded ? 'Up' : 'Down'}
              a11yText={expanded ? 'Hide options' : 'Show options'}
            />
          </button>
        </InlineGroup>
      </div>

      {expanded && (
        <div
          className={classNames(
            styles.DropdownList,
            styles.DropdownListOverlay,
            out.bottom && !out.top && styles.forceDirectionUp,
            out.right && !out.left && styles.forceDirectionLeft
          )}
          ref={list}
        >
          {items.length > 0 && (
            <ul>
              {items.map((item, i) => {
                return (
                  <li key={`DropdownStandard-item-${i}`}>
                    <button
                      aria-label={item.name}
                      type="button"
                      className={classNames(styles.DropdownListItem)}
                      onClick={() => item.onClick()}
                      {...(item.disabled && { disabled: true })}
                    >
                      <ShrinkWrap spacing="narrow">
                        <ShrinkWrapItem shrink vAlign="middle">
                          <Icon
                            width={20}
                            height={20}
                            type={item.icon}
                            a11yText={item.name}
                          />
                        </ShrinkWrapItem>
                        <ShrinkWrapItem vAlign="middle">
                          {item.name}
                        </ShrinkWrapItem>
                      </ShrinkWrap>
                    </button>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

DropdownStandard.defaultProps = {
  items: []
}

DropdownStandard.propTypes = {
  name: string.isRequired,
  activeWithin: bool,
  alignRight: bool,
  block: bool,
  items: arrayOf(
    shape({
      name: string,
      icon: string,
      onClick: func
    })
  )
}

export default DropdownStandard
