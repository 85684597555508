import React from 'react'
import renderComponent from '../lib/component-renderer'
import SearchContainer from './container/Search'
import SearchComponent from '../../../site/layout/component/Search'
const debug = require('debug')('search')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-search', props => (
      <SearchContainer component={SearchComponent} {...props} />
    ))
  })
}
