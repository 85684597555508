import React from 'react'
import ReactDOM from 'react-dom'
import {
  Provider as PlatformProvider,
  withPlatform
} from '../../../site/layout/component/PlatformContext'
import {
  Provider as ColorProvider,
  withColor
} from '../../../site/layout/component/ColorContext'

const debug = require('debug')('component-renderer')

/* Re-renders components client side
 * Assumes the following structure:
 *
 *  Container.js
 *
 *  <div
 *    className="selector"
 *    data-props={JSON.stringify(componentProps)}
 *  >
 *    <Component {...componentProps} />
 *  </div>
 *
 *  *Do not stringify the entire `props` object*
 *
 *  const { product } = props
 *  const componentProps = { product }
 *
 */

export default (selector, Component) => {
  const containers = document.querySelectorAll(selector)
  if (!containers) {
    debug(`No components with selector "${selector}" found, not rendering`)
    return
  }

  containers.forEach(container => {
    const props = JSON.parse(container.getAttribute('data-props') || '{}')
    const ContextifiedComponent = withPlatform(withColor(Component))

    if (window.__entity) {
      debug(`Rendering contextified "${selector}" with props`, props)
      ReactDOM.render(
        <PlatformProvider platform={window.__entity.platform}>
          <ColorProvider color={window.__entity.color}>
            <ContextifiedComponent {...props} />
          </ColorProvider>
        </PlatformProvider>,
        container
      )
    } else {
      debug(`Rendering "${selector}" with props`, props)
      ReactDOM.render(<Component {...props} />, container)
    }
  })
}
