import React, { PureComponent } from 'react'
import { string, node } from 'prop-types'

const ColorContext = React.createContext()

const Provider = ({ color, children }) => {
  return (
    <ColorContext.Provider value={{ color }}>{children}</ColorContext.Provider>
  )
}

Provider.propTypes = {
  color: string,
  children: node
}

const withColor = ChildComponent => {
  class WithColor extends PureComponent {
    render() {
      return (
        <ColorContext.Consumer>
          {context => (
            <ChildComponent {...this.props} color={context && context.color} />
          )}
        </ColorContext.Consumer>
      )
    }
  }

  WithColor.displayName = `${ChildComponent.displayName ||
    ChildComponent.name}ColorWrapper`

  return WithColor
}

export { withColor, Provider }
