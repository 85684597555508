import React from 'react'
import { bool, func, object, shape, string } from 'prop-types'

import countries from '../../lib/countries.json'
import { withColor } from '../ColorContext'
import ButtonSimple from '../primitive/ButtonSimple'
import HeadingSlash from '../primitive/HeadingSlash'
import Notice from '../primitive/Notice'
import PasswordControl from '../primitive/Control/PasswordControl'
import Rule from '../primitive/Rule'
import FieldTemplate from '../primitive/FieldTemplate'
import TextControl from '../primitive/Control/TextControl'
import SelectControl from '../primitive/Control/SelectControl'
import styles from './SettingsForm.styl'

const countryList = ['United Kingdom'].concat(Object.values(countries))

const SettingsForm = ({
  error,
  formErrors,
  onboardingConfig,
  onLogout,
  onChange,
  onSubmit,
  passwordChanged,
  showPassword,
  submitting,
  success,
  user
}) => {
  return (
    <div className={styles.SettingsForm}>
      <form className={styles.Form} onSubmit={onSubmit}>
        {!!success && <Notice type="success">{success}</Notice>}
        {!!error && (
          <Notice minimal type="error">
            {error}
          </Notice>
        )}
        {!!formErrors && Object.keys(formErrors).length !== 0 && (
          <Notice minimal type="error">
            <div>Whoops, something isn’t right!</div>
            <ul>
              {Object.keys(formErrors).map(key => (
                <li key={key}>{formErrors[key]}</li>
              ))}
            </ul>
          </Notice>
        )}
        <p>
          Keep your details up to date so we can ensure our content is always
          relevant
        </p>

        <FieldTemplate
          label="First name"
          controlName="firstName"
          status={formErrors.firstName && 'error'}
          feedback={formErrors.firstName}
        >
          <TextControl
            type="text"
            name="firstName"
            status={formErrors.firstName && 'error'}
            value={user.firstName || ''}
            onChange={onChange}
            required
            autoComplete="given-name"
          />
        </FieldTemplate>

        <FieldTemplate
          label="Last name"
          controlName="lastName"
          status={formErrors.lastName && 'error'}
          feedback={formErrors.lastName}
        >
          <TextControl
            type="text"
            name="lastName"
            status={formErrors.lastName && 'error'}
            value={user.lastName || ''}
            onChange={onChange}
            required
            autoComplete="family-name"
          />
        </FieldTemplate>

        <FieldTemplate label="Email address" controlName="emailAddress">
          <TextControl
            type="text"
            name="emailAddress"
            defaultValue={user.emailAddress}
            readOnly
            disabled
            required
            autoComplete="off"
          />
        </FieldTemplate>

        <FieldTemplate
          label="Job title"
          controlName="jobTitle"
          status={formErrors.jobTitle && 'error'}
          feedback={formErrors.jobTitle}
        >
          <TextControl
            type="text"
            name="jobTitle"
            status={formErrors.jobTitle && 'error'}
            value={user.jobTitle || ''}
            onChange={onChange}
          />
        </FieldTemplate>

        <FieldTemplate
          label="City"
          controlName="city"
          status={formErrors.city && 'error'}
          feedback={formErrors.city}
        >
          <TextControl
            type="text"
            name="city"
            status={formErrors.city && 'error'}
            value={user.city || ''}
            onChange={onChange}
          />
        </FieldTemplate>

        <FieldTemplate
          label="Country"
          controlName="country"
          status={formErrors.country && 'error'}
          feedback={formErrors.country}
        >
          <SelectControl
            type="text"
            name="country"
            status={formErrors.country && 'error'}
            value={user.country || ''}
            onChange={onChange}
            required
          >
            <option value="">Country</option>
            {countryList.map(c => (
              <option key={c}>{c}</option>
            ))}
          </SelectControl>
        </FieldTemplate>

        {onboardingConfig.showRegionField && (
          <FieldTemplate
            label="Region"
            controlName="region"
            status={formErrors.region && 'error'}
            feedback={formErrors.region}
          >
            <SelectControl
              type="text"
              name="region"
              status={formErrors.region && 'error'}
              value={user.region || ''}
              onChange={onChange}
              required
            >
              <option value="">Region</option>
              {onboardingConfig.regionList.map(r => (
                <option key={r}>{r}</option>
              ))}
            </SelectControl>
          </FieldTemplate>
        )}

        {onboardingConfig.showOfficeField && (
          <>
            <FieldTemplate
              label="Office"
              controlName="office"
              status={formErrors.office && 'error'}
              feedback={formErrors.office}
            >
              <SelectControl
                type="text"
                name="office"
                status={formErrors.office && 'error'}
                value={user.office || ''}
                onChange={onChange}
                required
              >
                <option value="">Office</option>
                {onboardingConfig.officeList.map(o => (
                  <option key={o}>{o}</option>
                ))}
              </SelectControl>
            </FieldTemplate>
            {user.office === 'Other' && (
              <FieldTemplate
                label="Office (Other)"
                controlName="officeOther"
                status={formErrors.officeOther && 'error'}
                feedback={formErrors.officeOther}
              >
                <TextControl
                  type="text"
                  name="officeOther"
                  status={formErrors.officeOther && 'error'}
                  value={user.officeOther || ''}
                  onChange={onChange}
                  required
                />
              </FieldTemplate>
            )}
          </>
        )}

        <div className={styles.ButtonGroup}>
          <ButtonSimple
            ariaLabel="save details"
            type="submit"
            disabled={submitting}
          >
            Save details
          </ButtonSimple>
        </div>
        <Rule />
      </form>
      <form className={styles.Form} onSubmit={onSubmit}>
        {showPassword && (
          <>
            <h3 className={styles.SubHeading}>
              Security
              <HeadingSlash />
            </h3>
            <p>Change your password</p>
            {formErrors.newPassword && (
              <Notice type="error">{formErrors.newPassword}</Notice>
            )}
            {passwordChanged && <Notice type="success">{success}</Notice>}
            <PasswordControl
              name="newPassword"
              autoComplete="new-password"
              placeholder="New password"
              value={user.newPassword || ''}
              onChange={onChange}
            />
            <PasswordControl
              name="confirmPassword"
              autoComplete="confirm-password"
              placeholder="Confirm password"
              value={user.confirmPassword || ''}
              onChange={onChange}
            />
            <div className={styles.ButtonGroup}>
              <ButtonSimple
                ariaLabel="save new password"
                type="submit"
                disabled={submitting}
              >
                Save new password
              </ButtonSimple>
            </div>
            <Rule />
          </>
        )}
        <div className={styles.ButtonGroup}>
          <ButtonSimple
            ariaLabel="sign out"
            outline
            type="button"
            onClick={onLogout}
          >
            Sign out
          </ButtonSimple>
        </div>
      </form>
    </div>
  )
}

SettingsForm.propTypes = {
  error: string,
  formErrors: object,
  onboardingConfig: object.isRequired,
  onChange: func.isRequired,
  onLogout: func.isRequired,
  onSubmit: func.isRequired,
  passwordChanged: bool,
  showPassword: bool,
  submitting: bool,
  success: string,
  user: shape({ emailAddress: string, agency: string })
}

export default withColor(SettingsForm)
