const mobile = '375px'
const tablet = '768px'
const desktop = '1000px'
const desktopSmall = '1100px'
const desktopMedium = '1200px'
const desktopLarge = '1366px'

const widthMin = width => `(min-width: ${width})`

export {
  mobile,
  tablet,
  desktop,
  desktopSmall,
  desktopMedium,
  desktopLarge,
  widthMin
}
