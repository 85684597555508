import React from 'react'

import Challenge from '../../../layout/component/Challenge'

const ChallengeWidget = props => {
  return (
    <div className="widget widget--challenge">
      <Challenge {...props} />
    </div>
  )
}

export default ChallengeWidget
