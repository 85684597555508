export const groupSubNavs = (subNavs, size) => {
  return subNavs.reduce(function(result, item, index) {
    if (index % size === 0) {
      result.push([item])
    } else {
      result[result.length - 1].push(item)
    }
    return result
  }, [])
}
