import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'

import Icon from '../primitive/Icon'

import styles from './NavPanelSuggestions.styl'

const NavPanelSuggestions = ({ suggestions, articleCount }) => {
  return (
    <section className={styles.NavPanelSuggestions}>
      {articleCount === 0 ? (
        <p>No articles found</p>
      ) : (
        <p>
          {articleCount} article{articleCount > 1 ? 's' : ''} found
        </p>
      )}
      {suggestions.length > 0 &&
        suggestions.map(group => (
          <div
            key={`${group.title}`}
            className={styles.NavPanelSuggestionsGroup}
          >
            <h3 className={styles.NavPanelSuggestionsHeading}>{group.title}</h3>
            <ul className={styles.NavPanelSuggestionsList}>
              {group.items.map(item => (
                <li key={`suggestion:${item.url}`}>
                  <a
                    href={item.url}
                    className={styles.NavPanelSuggestionsButton}
                  >
                    <Icon type="Search" a11yText="" width={16} height={16} />
                    <span>{item.text}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </section>
  )
}

NavPanelSuggestions.defaultProps = {
  suggestions: []
}

NavPanelSuggestions.propTypes = {
  suggestions: arrayOf(
    shape({
      title: string,
      items: arrayOf(
        shape({
          text: string,
          url: string
        })
      )
    })
  ),
  articleCount: number
}

export default NavPanelSuggestions
