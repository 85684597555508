import React from 'react'
import OnboardingHero from '../../layout/component/OnboardingPage/OnboardingHero'
import OnboardingLoginForm from '../../layout/component/OnboardingForm/OnboardingLoginForm'
import { loginImages } from '../../layout/component/OnboardingPage/data/onboarding-page-data'
import { Brands, Cards, Quotes } from '../../layout/component/OnboardingPage'

const Login = props => (
  <>
    <OnboardingHero
      backgroundMobile
      images={loginImages}
      withScrollIndicator
      randomInitialIndex
    >
      <OnboardingLoginForm {...props} />
    </OnboardingHero>
    <Brands />
    <Cards />
    <Quotes />
  </>
)

export default Login
