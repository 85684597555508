import React from 'react'
import PropTypes from 'prop-types'

import TeamCarousel from '../../../layout/component/TeamCarousel'
import TeamCarouselItem from '../../../layout/component/TeamCarouselItem'
import SectionDivider from '../../../layout/component/primitive/SectionDivider'

const PersonnelCarouselWidget = props => {
  const { title, headshots } = props
  return (
    <div className="widget widget--personnel-carousel">
      <SectionDivider noPaddingTop>
        <TeamCarousel heading={title}>
          {headshots.map((headshot, i) => (
            <TeamCarouselItem
              key={`headshot- ${i}`}
              image={headshot.imageUrl}
              name={headshot.name}
              position={headshot.role}
              summary={headshot.description}
              bio={headshot.html}
              socialLinks={{
                ...(headshot.linkedInId
                  ? {
                      linkedIn: `https://linkedin.com/in/${headshot.linkedInId}`
                    }
                  : {}),
                ...(headshot.emailAddress
                  ? { email: `mailto:${headshot.emailAddress}` }
                  : {})
              }}
            />
          ))}
        </TeamCarousel>
      </SectionDivider>
    </div>
  )
}

PersonnelCarouselWidget.propTypes = {
  title: PropTypes.string,
  headshots: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      description: PropTypes.string,
      linkedInId: PropTypes.string,
      emailAddress: PropTypes.string,
      html: PropTypes.string
    })
  ).isRequired
}

export default PersonnelCarouselWidget
