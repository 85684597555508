import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from '../../layout/component/primitive/SectionHeading'
import Button from '../../layout/component/primitive/Button'
import Notice from '../../layout/component/primitive/Notice'
import CollectionPreview from '../../layout/component/CollectionPreview'

const CollectionList = ({
  pageTitle,
  loading,
  showMore,
  error,
  collections,
  onMore
}) => (
  <Fragment>
    <SectionHeading title={`${pageTitle} Collections`} />
    {!!error && (
      <Notice minimal type="error">
        There was an issue performing that action. Please try again later.
      </Notice>
    )}
    {collections.map(({ _id, __date, __images, name, description, link }) => (
      <CollectionPreview
        key={`CollectionList-${_id}`}
        href={link}
        imageSource={__images}
        dateSubtitle={__date}
        heading={name}
        description={description}
        collectionList
      />
    ))}
    {showMore &&
      (!loading && (
        <Button
          onClick={onMore}
          className="js-load-more-btn"
          secondary
          fullWidth
          clipCorner
          ariaLabel="more"
        >
          More
        </Button>
      ))}
  </Fragment>
)

CollectionList.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  showMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      __date: PropTypes.string.isRequired,
      __items: PropTypes.array.isRequired
    })
  ).isRequired,
  onMore: PropTypes.func
}

export default CollectionList
