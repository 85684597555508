import React from 'react'
import renderComponent from '../lib/component-renderer'

import CollectionForm from '../../../../components/site/collection/component/CollectionForm'
import CollectionFormContainer from './container/CollectionForm'
import CollectionView from '../../../../components/site/collection/component/CollectionView'
import CollectionViewContainer from './container/CollectionView'
import CollectionList from '../../../../components/site/collection/component/CollectionList'
import CollectionListContainer from './container/CollectionList'

const debug = require('debug')('collection')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Collection')
    renderComponent('.js-collection-form', props => (
      <CollectionFormContainer {...props} component={CollectionForm} />
    ))
    renderComponent('.js-collection-view', props => (
      <CollectionViewContainer {...props} component={CollectionView} />
    ))
    renderComponent('.js-collection-list', props => (
      <CollectionListContainer {...props} component={CollectionList} />
    ))
  })
}
