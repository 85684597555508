import React from 'react'
import Navigation from '../../Navigation/component'

const PrimaryNavigation = props => {
  const componentProps = {
    ...props,
    links: [
      {
        title: 'What we do for agencies',
        href: '/agencies'
      },
      {
        title: 'What we do for brands',
        links: [
          { title: 'Brand Memberships', href: '/brands' },
          { title: 'Consulting', href: '/consulting' }
        ]
      },
      { title: 'Contagious IQ', href: '/contagious-iq' },
      { title: 'Events', href: '/events' },
      {
        title: 'News + Views',
        links: [
          { title: 'Latest', href: '/news-and-views' },
          { title: 'Free Reports + Webinars', href: '/reports-and-webinars' },
          { title: 'Newsletter', href: '/newsletter' }
        ]
      },
      {
        title: 'About',
        links: [
          { title: 'About Us', href: '/about-us' },
          { title: 'Careers', href: '/join-us' },
          { title: 'Contact Us', href: '/contact-us' }
        ]
      }
    ],
    registerUrl: '/demo',
    loginUrl: '/iq'
  }

  return <Navigation {...componentProps} />
}

export default PrimaryNavigation
