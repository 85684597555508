import React from 'react'
import { string, array } from 'prop-types'

import ArticleActions from '../ArticleActions'
import SaveArticle from '../SaveArticle'
import IconButton from '../primitive/IconButton'
import Spacer from '../primitive/Spacer'

import styles from './ArticleFooter.styl'

class ArticleFooter extends React.PureComponent {
  render() {
    const {
      absoluteUrl,
      headline,
      articleId,
      downloadUrl,
      savedLists,
      platform
    } = this.props
    return (
      <div className={styles.ArticleFooter}>
        {platform === 'io' && (
          <Spacer>
            <ArticleActions
              borderTop
              text={
                <>
                  <strong>Liked this article?</strong> Save it for later or
                  share it
                </>
              }
            >
              {typeof savedLists !== 'undefined' && (
                <SaveArticle
                  type="article"
                  itemId={articleId}
                  name={headline}
                  savedLists={savedLists}
                />
              )}
              <IconButton
                className={styles.IconButton}
                ariaLabel="share"
                icon="Social"
                href={`mailto:?subject=Shared: ${headline}&body=${absoluteUrl}`}
                iconWidth={16}
                iconHeight={17}
                data-track="Share"
                data-track-label={headline}
              />

              {downloadUrl && (
                <IconButton
                  className={styles.IconButton}
                  ariaLabel="download pdf"
                  iconWidth={16}
                  iconHeight={16}
                  icon="Download"
                  href={downloadUrl}
                  data-track="Download"
                  data-track-label={headline}
                />
              )}
            </ArticleActions>
          </Spacer>
        )}
      </div>
    )
  }
}

ArticleFooter.propTypes = {
  headline: string.isRequired,
  absoluteUrl: string.isRequired,
  articleId: string.isRequired,
  downloadUrl: string,
  savedLists: array,
  platform: string.isRequired
}

export default ArticleFooter
