import React, { Children, PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Grid.styl'

export class GridItem extends PureComponent {
  render() {
    const { children, sizes, spacingBottom, pushes, className } = this.props
    return (
      <div
        className={classNames(
          styles['grid__item'],
          sizes && sizes.map(size => styles[size]),
          spacingBottom &&
            styles[`grid__item--spacing-bottom-${spacingBottom}`],
          pushes && pushes.map(push => styles[push]),
          className
        )}
      >
        {children}
      </div>
    )
  }
}

GridItem.displayName = 'GridItem'

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string),
  spacingBottom: PropTypes.oneOf([2, 3, 4]),
  pushes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
}

export class Grid extends PureComponent {
  render() {
    const {
      className,
      children,
      center,
      gap,
      wideDesktopGap,
      flex,
      flexEnd,
      reverse,
      reverseDesktop,
      middled,
      gutterless
    } = this.props

    return (
      <div
        className={classNames(
          styles.grid,
          center && styles['grid--center'],
          gap && styles[`grid--gap${gap}`],
          wideDesktopGap && styles[`grid--desktop-wide-gutter`],
          gutterless && styles[`grid--gutterless`],
          flex && styles['grid--flex'],
          flexEnd && styles['grid--flex-align-end'],
          reverse && styles['grid--reverse'],
          reverseDesktop && styles['grid--desktop-reverse'],
          middled && styles['grid--middled'],
          className
        )}
      >
        {children}
      </div>
    )
  }
}

Grid.displayName = 'Grid'

Grid.propTypes = {
  className: PropTypes.string,
  center: PropTypes.bool,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    return (
      Children.toArray(prop).find(child => child.type !== GridItem) &&
      new Error(`${componentName} only accepts "<GridItem />" elements`)
    )
  },
  gap: PropTypes.oneOf([1, 2, 4, 5, 6, 7, 8]),
  wideDesktopGap: PropTypes.bool,
  flex: PropTypes.bool,
  flexEnd: PropTypes.bool,
  reverse: PropTypes.bool,
  reverseDesktop: PropTypes.bool,
  middled: PropTypes.bool,
  gutterless: PropTypes.bool
}
