import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './BlockTextTrio.styl'
import { Grid, GridItem } from '../primitive/Grid'

class BlockTextTrio extends PureComponent {
  render() {
    const { blocks, blockStyle } = this.props
    const classes = classNames(styles.Block, styles[blockStyle])

    return (
      <Grid gap={8} flex>
        {blocks.map((block, i) => {
          return (
            <GridItem className={classes} key={i} sizes={['tablet-one-third']}>
              <div className={styles.Inner}>
                {block.category && (
                  <div
                    className={classNames(
                      styles.Category,
                      block.title && styles.alt
                    )}
                  >
                    {block.category}
                  </div>
                )}
                {block.title && <h3 className={styles.Title}>{block.title}</h3>}
                <p
                  className={classNames(
                    styles.Content,
                    block.title && styles.alt
                  )}
                >
                  {block.content}
                </p>
              </div>
            </GridItem>
          )
        })}
      </Grid>
    )
  }
}

BlockTextTrio.propTypes = {
  blocks: PropTypes.array.isRequired,
  blockStyle: PropTypes.string
}

export default BlockTextTrio
