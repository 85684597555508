import React from 'react'
import OnboardingHero from '../../layout/component/OnboardingPage/OnboardingHero'
import PasswordResetForm from '../../layout/component/Identity/PasswordResetForm'
import { loginImages } from '../../layout/component/OnboardingPage/data/onboarding-page-data'

const PasswordReset = props => (
  <OnboardingHero backgroundMobile images={loginImages}>
    <PasswordResetForm {...props} />
  </OnboardingHero>
)

export default PasswordReset
