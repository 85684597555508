import React from 'react'
import {
  arrayOf,
  bool,
  func,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types'
import { Grid, GridItem } from '../primitive/Grid'
import { RadioBlock } from '../primitive/RadioBlocks'

import styles from './CheckboxGrid.styl'

const CheckboxGrid = ({ items, columnCount = 2, onChange }) => {
  const columnMap = {
    2: ['mobile-large-one-half'],
    3: ['mobile-large-one-half', 'tablet-one-third']
  }
  return (
    <div className={styles.CheckboxGrid}>
      <Grid gutterless flex>
        {items &&
          items.length > 0 &&
          items.map(item => {
            const { name, value, checked } = item
            return (
              <GridItem
                className={checked ? styles.checked : ''}
                key={`Check-${name}`}
                sizes={columnMap[columnCount]}
              >
                <RadioBlock
                  name={name}
                  type="checkbox"
                  value={value}
                  onChange={() => onChange(item)}
                  checked={checked}
                >
                  {name}
                </RadioBlock>
              </GridItem>
            )
          })}
      </Grid>
    </div>
  )
}

CheckboxGrid.propTypes = {
  columnCount: oneOf([2, 3]),
  onChange: func.isRequired,
  items: arrayOf(
    shape({
      name: string,
      value: oneOfType([string, bool]),
      checked: bool
    })
  ).isRequired
}

export default CheckboxGrid
