import React from 'react'
import PropTypes from 'prop-types'

import styles from '../Check.styl'

const CheckWithLinkControl = props => {
  const { color, link, text } = props

  return (
    <a
      className={styles.FilterLink}
      href={link}
      style={color && { borderColor: color }}
    >
      {text}
    </a>
  )
}

CheckWithLinkControl.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string
}

export default CheckWithLinkControl
