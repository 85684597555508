import React from 'react'
import { arrayOf, shape, object, string } from 'prop-types'

import ArticleCard from '../ArticleCard'

import styles from './NavPanelContent.styl'

const NavPanelContent = ({ content: { articles } }) => {
  if (articles && articles.length < 1) return null

  return (
    <div className={styles.NavSearchPanelSummaries}>
      <h4>Recommended Content</h4>
      <div className={styles.NavSearchPanelRow}>
        {articles.map(
          ({
            _id,
            imageUrl,
            shortHeadline,
            headline,
            __fullUrlPath,
            __displayDate,
            brand,
            color,
            label,
            labelConfig,
            score
          }) => (
            <div
              key={`ListItemArticle:${_id}`}
              className={styles.NavSearchPanelColumn}
            >
              <ArticleCard
                _id={_id}
                __displayDate={__displayDate}
                __fullUrlPath={__fullUrlPath}
                imageUrl={imageUrl}
                shortHeadline={shortHeadline}
                headline={headline}
                brand={brand}
                color={color}
                label={label}
                labelConfig={labelConfig}
                score={score}
                smallCard
                hideSaveDropdown
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}

NavPanelContent.defaultProps = {
  content: { collections: [], articles: [] }
}

NavPanelContent.propTypes = {
  content: shape({
    articles: arrayOf(
      shape({
        _id: string.isRequired,
        shortHeadline: string.isRequired,
        headline: string.isRequired,
        color: string,
        label: string.isRequired,
        labelConfig: object,
        brand: string,
        __fullUrlPath: string.isRequired,
        __displayDate: shape({ date: string }).isRequired,
        imageUrl: string.isRequired
      })
    ).isRequired
  }).isRequired
}

export default NavPanelContent
