import renderComponent from '../lib/component-renderer'

import ImageFaderGrid from '../../../site/layout/component/ImageFaderGrid'

const debug = require('debug')('logo grid')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init logo grid')
    renderComponent('.js-logo-grid', ImageFaderGrid)
  })
}
