import React, { useRef, useState, useEffect } from 'react'

import xss from 'xss'
import { object, bool } from 'prop-types'

import LinkList from '../primitive/LinkList'
import SettingsForm from '../SettingsForm'
import EmailForm from '../EmailForm'
import Following from './Container/Following'

const ProfileForm = ({ user: initialUser, onboardingConfig, showPassword }) => {
  const allowedModes = ['settings', 'email', 'following']
  const inputHash =
    typeof window !== 'undefined' &&
    window.location.hash.slice(1, window.location.hash.length)
  const [mode, setModeState] = useState(
    allowedModes.includes(inputHash) ? inputHash : 'settings'
  )
  const noticeTimeoutRef = useRef()
  const [user, setUser] = useState(initialUser)
  const [error, setError] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [success, setSuccess] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [passwordChanged, setPasswordChanged] = useState(null)

  useEffect(() => {
    const updateHash = () => {
      setMode(window.location.hash.slice(1))
    }
    window.addEventListener('hashchange', updateHash)

    return () => {
      window.removeEventListener('hashchange', updateHash)
    }
  }, [])

  const setMode = mode => {
    setModeState(mode)
    if (typeof window !== 'undefined') window.location.hash = '#' + mode
  }

  const clearSuccess = () => {
    if (noticeTimeoutRef.current) clearTimeout(noticeTimeoutRef.current)
    setSuccess(false)
  }

  const handleSubmit = async (e, overrides = {}) => {
    e && e.preventDefault()
    setError(null)
    setFormErrors({})
    clearSuccess()

    try {
      const res = await fetch('/iq/profile', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...user, ...overrides })
      })

      if (res.status === 200) {
        setSuccess(true)
        setSuccessMessage(
          user.newPassword
            ? 'Your password has been changed successfully.'
            : 'Your profile has been saved.'
        )
        setPasswordChanged(user.newPassword ? true : false)
        noticeTimeoutRef.current = setTimeout(clearSuccess, 5000)
      } else if (res.status === 400) {
        const { errors } = await res.json()
        setFormErrors(errors)
      } else {
        setError('There was an issue saving. Please try again later.')
      }
    } catch (error) {
      setError('There was an issue saving. Please try again later.')
    }

    setUser(prevState => ({
      ...prevState,
      newPassword: '',
      confirmPassword: ''
    }))
  }

  const handleChange = ({
    target: { name, type, value: rawValue, checked }
  }) => {
    clearSuccess()

    const shouldSanitize = !/password/i.test(name) && type !== 'checkbox' // do not strip password fields and checkboxes
    const value = shouldSanitize
      ? xss(rawValue, { stripIgnoreTag: true })
      : rawValue

    const updatedData = { [name]: type === 'checkbox' ? checked : value }

    setUser(prevState => ({
      ...prevState,
      ...updatedData
    }))

    if (['radio', 'checkbox'].includes(type)) handleSubmit(null, updatedData)
  }

  const handleLogout = e => {
    e.preventDefault()
    window.location = '/iq/logout'
  }
  const handleChangeMode = mode => e => {
    e.preventDefault()
    clearSuccess()
    setMode(mode)
  }

  const links = [
    {
      label: 'Settings',
      active: mode === 'settings',
      onClick: handleChangeMode('settings')
    },
    {
      label: 'Email',
      active: mode.includes('email'),
      onClick: handleChangeMode('email')
    },
    {
      label: 'Interests',
      active: mode.includes('following'),
      onClick: handleChangeMode('following')
    }
  ]

  const commonProps = {
    onChange: handleChange,
    onSubmit: handleSubmit,
    onLogout: handleLogout,
    user,
    error,
    formErrors,
    success: success ? successMessage : '',
    passwordChanged: success && passwordChanged
  }

  return (
    <>
      <LinkList links={links} />
      {mode === 'settings' && (
        <SettingsForm
          onboardingConfig={onboardingConfig}
          showPassword={showPassword}
          {...commonProps}
        />
      )}
      {mode === 'email' && (
        <EmailForm
          onManageInterests={handleChangeMode('following')}
          {...commonProps}
        />
      )}
      {mode === 'following' && <Following />}
    </>
  )
}

ProfileForm.propTypes = {
  showPassword: bool,
  onboardingConfig: object.isRequired,
  user: object.isRequired
}

export default ProfileForm
