import React from 'react'
import PropTypes from 'prop-types'
import Carousel from '../../../layout/component/Carousel'
import InlineImage from '../../../layout/component/inline-image/component/InlineImage'
import Spacer from '../../../layout/component/primitive/Spacer'

const InlineImageWidget = ({ images, landscape, headline, _id }) => {
  if (images.length > 1) {
    return (
      <Spacer size="md">
        <Carousel className="InlineCarousel">
          {images.map((image, i) => (
            <InlineImage
              key={`inline-image=${_id}-${i}`}
              {...image}
              headline={headline}
              lazy
            />
          ))}
        </Carousel>
      </Spacer>
    )
  }
  return (
    <Spacer size="md">
      <InlineImage
        className="InlineImage"
        {...images[0]}
        landscape={landscape}
        headline={headline}
      />
    </Spacer>
  )
}

InlineImageWidget.propTypes = {
  images: PropTypes.array.isRequired,
  landscape: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  _id: PropTypes.string
}

export default InlineImageWidget
