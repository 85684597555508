import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'
import AddToCollection from './AddToCollection'

const debug = require('debug')('add-to-collection')

const AddToCollectionContainer = ({ articleId, onClose }) => {
  const [collections, setCollections] = useState([])
  const [selectedCollection, setCollection] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const fetchCollections = async () => {
      debug('Fetching collections')
      let response
      try {
        response = await fetch(`/api/collections?articleId=${articleId}`, {
          credentials: 'include',
          redirect: 'error'
        })
      } catch (error) {
        setLoading(false)
        debug('Fetching collection error', error)
        return setError('There was a problem. Please try again later.')
      }

      setLoading(false)
      if (response.status !== 200) {
        debug('Fetching collection error', response.status)
        return setError('There was a problem. Please try again later.')
      }

      const collections = await response.json()
      collections.sort(function(a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
      debug('Fetched collections', collections)

      setCollections([
        { text: 'Select a collection', value: '' },
        ...[...collections].map(({ _id, name, disabled }) => ({
          text: name,
          value: _id,
          disabled,
          title: disabled && 'You already have this article in that collection',
          _id,
          link: `/iq/collection/${_id}`
        }))
      ])
    }
    fetchCollections()
  }, [])

  const handleAdd = async () => {
    const response = await fetch(
      `/api/collect/${selectedCollection._id}/${articleId}`,
      {
        method: 'POST',
        credentials: 'include',
        redirect: 'error'
      }
    )
    if (response.status !== 200) {
      debug('Collecting article error', response.status)
      return setError('There was a problem. Please try again later.')
    }
    setSuccess(true)
  }

  const handleSelect = id => {
    const selected = collections.find(({ _id }) => _id === id)
    setCollection(selected)
  }

  return (
    <AddToCollection
      onClose={onClose}
      loading={loading}
      error={error}
      success={success}
      articleId={articleId}
      onSelect={handleSelect}
      collections={collections}
      selectedCollection={selectedCollection}
      onAdd={handleAdd}
      itemType="article"
    />
  )
}

AddToCollectionContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  articleId: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired
}

export default withColor(AddToCollectionContainer)
