import React, { PureComponent } from 'react'
import { bool, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './CustomSelectControl.styl'

import NativeSelectControl from '../NativeSelectControl'

export class CustomSelectControl extends PureComponent {
  render() {
    const { borderless, status } = this.props
    const componentClassName = classNames(
      styles.CustomSelectControl,
      borderless && styles.borderless,
      status && styles[status]
    )

    return (
      <span className={componentClassName}>
        <NativeSelectControl {...this.props} />
      </span>
    )
  }
}

CustomSelectControl.displayName = 'CustomSelectControl'

CustomSelectControl.propTypes = {
  borderless: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default CustomSelectControl
