import React from 'react'
import { string, object, array, number, func, bool } from 'prop-types'
import { Grid, GridItem } from '../primitive/Grid'
import ArticleCardGrid from '../ArticleCardGrid'
import FilterPanel from '../FilterPanel'
import Divider from '../Divider'
import Loading from '../primitive/Loading'
import Spacer from '../primitive/Spacer'
import Pagination from '../Pagination'
import styles from './SearchResults.styl'

const SearchResults = ({
  labels,
  filters,
  articles = [],
  count,
  onFilterChange,
  onLabelChange,
  onSort,
  onPageChange,
  sort,
  loading,
  error,
  showSort = true,
  showFilters = true,
  pages,
  currentPage,
  savedLists
}) => {
  return (
    <Grid className={styles.SearchResults} gap={4}>
      {showFilters && (
        <GridItem sizes={['tablet-one-quarter']}>
          <FilterPanel
            labels={labels}
            data={filters}
            onLabelChange={onLabelChange}
            onChange={onFilterChange}
            onSort={onSort}
            sort={sort}
            showSort={showSort}
            showCount={count <= 2000}
          />
        </GridItem>
      )}
      <GridItem sizes={[showFilters ? 'tablet-three-quarters' : 'whole']}>
        {error ? (
          <p>{error}</p>
        ) : !loading && articles.length === 0 ? (
          <p>No matching articles</p>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <ArticleCardGrid
                  className={styles.ArticleCardGrid}
                  items={articles}
                  displayLimit={showFilters ? 3 : 4}
                  gridSize={
                    showFilters
                      ? ['tablet-one-half', 'desktop-one-third']
                      : undefined
                  }
                  savedLists={savedLists}
                />
                <Spacer>
                  <Divider className={styles.Divider} />
                </Spacer>
                {count > articles.length && (
                  <Pagination
                    total={pages}
                    currentPage={currentPage}
                    onChange={onPageChange}
                  />
                )}
              </>
            )}
          </>
        )}
      </GridItem>
    </Grid>
  )
}

SearchResults.propTypes = {
  labels: array,
  filters: object.isRequired,
  articles: array.isRequired,
  count: number,
  onFilterChange: func,
  onLabelChange: func,
  onSort: func,
  onPageChange: func,
  pages: number,
  currentPage: number,
  sort: string,
  loading: bool,
  error: string,
  showSort: bool,
  showFilters: bool,
  savedLists: array
}
export default SearchResults
