import React, { PureComponent } from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  string
} from 'prop-types'
import classnames from 'classnames'

import styles from './NativeSelectControl.styl'

export class NativeSelectControl extends PureComponent {
  render() {
    const {
      autofocus,
      children,
      className,
      defaultValue,
      disabled,
      id,
      multiple,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      size,
      status,
      tabIndex,
      value
    } = this.props

    const componentClassName = classnames(
      styles.NativeSelectControl,

      multiple && styles.multiline,
      status && styles[status],
      className
    )

    return (
      <select
        autoFocus={autofocus}
        defaultValue={defaultValue}
        className={componentClassName}
        disabled={disabled}
        id={id}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        size={size}
        tabIndex={tabIndex}
        value={value}
      >
        {children}
      </select>
    )
  }
}

NativeSelectControl.propTypes = {
  autofocus: bool,
  children: node.isRequired,
  className: string,
  defaultValue: string,
  disabled: bool,
  id: string,
  multiple: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  required: bool,
  size: number,
  status: oneOf(['error', 'notice', 'success', 'warning']),
  tabIndex: number,
  value: oneOfType([arrayOf(string), string])
}

export default NativeSelectControl
