import React, { useState } from 'react'
import { object, bool } from 'prop-types'
import OnboardingHero from '../../../layout/component/OnboardingPage/OnboardingHero'
import OnboardingDetailForm from '../../../layout/component/OnboardingForm/OnboardingDetailForm'
import { onboardingImages } from '../../../layout/component/OnboardingPage/data/onboarding-page-data'

const Details = props => {
  const { formErrors, formData, onboardingConfig, redirected } = props
  const [data, setData] = useState(formData)
  const handleChange = ({ target: { name, type, value, checked } }) =>
    setData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }))

  return (
    <OnboardingHero backgroundMobile images={onboardingImages}>
      <OnboardingDetailForm
        formErrors={formErrors}
        formData={data}
        onboardingConfig={onboardingConfig}
        onChange={handleChange}
        error={
          redirected ? 'Please complete the steps to gain full access.' : ''
        }
      />
    </OnboardingHero>
  )
}

Details.propTypes = {
  formErrors: object.isRequired,
  formData: object.isRequired,
  onboardingConfig: object.isRequired,
  redirected: bool
}

export default Details
