import React, { useState, useEffect } from 'react'
import { object, string } from 'prop-types'
import clone from 'lodash.clonedeep'
import Modal from '../../Modal'
import {
  mutateCategory,
  setFollowing
} from '../../../../../service/user/lib/following-to-categories-setter'
import updateFollowing from '../../ProfileForm/lib/following-updater'
import CategoriesComponent from '../component/Categories'
import { withColor } from '../../ColorContext'

const CategoriesContainer = ({ categories, following, color }) => {
  const [confirm, setConfirm] = useState(false)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [activeCategories, setActiveCategories] = useState(categories)
  const [lastFollow, setLastFollow] = useState('')

  const onChange = async (follow, { category, taxonomy }) => {
    const lastFollowing = `${categories[taxonomy].name} / ${categories[taxonomy].items[category].name}`
    try {
      const categories = clone(activeCategories)
      if (mutateCategory(categories, taxonomy, category, follow)) {
        setLastFollow(lastFollowing)
        setActiveCategories(categories)
      }

      const updatedFollowing = await updateFollowing({
        category,
        taxonomy,
        follow
      })
      setActiveCategories(setFollowing(activeCategories, updatedFollowing))
    } catch (e) {
      alert('There was a problem following. Please try again later.')
      throw e
    }

    if (follow && !hasConfirmed) {
      setConfirm(true)
      setHasConfirmed(true)
      setTimeout(() => setConfirm(false), 6000)
    }
    window.ioEventTracker({
      category: 'Following',
      action: follow ? 'add' : 'remove',
      label: lastFollowing
    })
  }

  useEffect(() => {
    if (!following) return
    setFollowing(activeCategories, following)
    setActiveCategories(categories)
  }, [following])

  const handleFollowingUpdate = e => {
    const { following } = e.detail
    setActiveCategories(setFollowing(activeCategories, following))
  }

  useEffect(() => {
    window.addEventListener('FOLLOWING_UPDATE', handleFollowingUpdate)
    return () => {
      window.removeEventListener('FOLLOWING_UPDATE', handleFollowingUpdate)
    }
  }, [])

  const handleConfirmClose = () => {
    setConfirm(false)
  }

  return (
    <>
      {confirm && (
        <Modal
          title="Added to interests"
          onClose={handleConfirmClose}
          timer={6000}
          rounded
        >
          <p>
            You will now get an email notification when there are new Articles
            in <strong style={color && { color: color }}>{lastFollow}</strong>.
            You can change this at any time via the{' '}
            <a
              aria-label="settings page"
              href="/iq/profile"
              style={color && { color: color }}
            >
              settings page
            </a>
            .
          </p>
        </Modal>
      )}
      <CategoriesComponent categories={activeCategories} onChange={onChange} />
    </>
  )
}

CategoriesContainer.propTypes = {
  categories: object,
  following: object,
  color: string
}

export default withColor(CategoriesContainer)
