import React from 'react'
import ReadNext from '../../../site/layout/component/ReadNext'
import renderComponent from '../lib/component-renderer'

const debug = require('debug')('read next')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init read next')
    renderComponent('.js-read-next', props => <ReadNext {...props} />)
  })
}
