import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './SectionDivider.styl'

class SectionDivider extends React.PureComponent {
  render() {
    const { children, noPaddingTop, borderTop } = this.props
    return (
      <div
        className={classnames(
          styles.SectionDivider,
          noPaddingTop && styles.noPaddingTop,
          borderTop && styles.borderTop
        )}
      >
        {children}
      </div>
    )
  }
}

SectionDivider.propTypes = {
  children: PropTypes.node.isRequired,
  noPaddingTop: PropTypes.bool,
  borderTop: PropTypes.bool
}

export default SectionDivider
