import React from 'react'
import { node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ResponsiveMedia.styl'

const formatRatio = ratio => {
  return parseFloat((ratio * 100).toFixed(4))
}

const ResponsiveMedia = ({ children, ratio, className }) => (
  <div
    className={classNames(styles.ResponsiveMedia, className)}
    style={{ paddingBottom: `${formatRatio(ratio)}%` }}
  >
    {children}
  </div>
)

ResponsiveMedia.propTypes = {
  children: node.isRequired,
  ratio: number.isRequired,
  className: string
}

export default ResponsiveMedia
