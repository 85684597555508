import React, { PureComponent } from 'react'
import { array, bool, node, string } from 'prop-types'
import classNames from 'classnames'

import Icon from '../../primitive/Icon'

import styles from './OnboardingHero.styl'

class OnboardingHero extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carouselInstance = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: 8000,
      pageDots: false,
      adaptiveHeight: false,
      draggable: false,
      pauseAutoPlayOnHover: false,
      lazyLoad: true,
      initialIndex: this.props.randomInitialIndex
        ? Math.floor(Math.random() * this.carousel.children.length)
        : 0
    })
  }

  componentWillUnmount() {
    this.carouselInstance.destroy()
  }

  render() {
    const {
      children,
      images,
      withScrollIndicator,
      backgroundMobile,
      className
    } = this.props
    return (
      <div
        className={classNames(
          styles.OnboardingHero,
          backgroundMobile && styles.backgroundMobile,
          className
        )}
      >
        <div
          className={styles.Carousel}
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {images &&
            images.map((img, i) => (
              <div
                key={`Carousel-image-${i}`}
                className={styles.BackgroundImage}
                style={{ backgroundImage: `url(${img.src})` }}
              >
                <div className={styles.Tag}>{img.alt}</div>
              </div>
            ))}
        </div>
        <div className={styles.Content}>{children}</div>
        {withScrollIndicator && (
          <div className={styles.LearnMore}>
            Learn more
            <Icon type="ArrowDown" width={18} height={18} />{' '}
          </div>
        )}
      </div>
    )
  }
}

OnboardingHero.defaultProps = {}

OnboardingHero.propTypes = {
  children: node,
  images: array,
  className: string,
  withScrollIndicator: bool,
  backgroundMobile: bool,
  randomInitialIndex: bool
}

export default OnboardingHero
