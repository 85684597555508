import React from 'react'
import renderComponent from '../lib/component-renderer'

import ReadingListContainer from './container/ReadingList'

const debug = require('debug')('reading-list')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Reading List')
    renderComponent('.js-reading-list', props => (
      <ReadingListContainer {...props} />
    ))
  })
}
