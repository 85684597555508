import React from 'react'
import PropTypes from 'prop-types'

import styles from './Vacancies.styl'
import HeadingSlash from '../primitive/HeadingSlash'
import Button from '../primitive/Button'
import Expander from '../Expander'

class Vacancies extends React.PureComponent {
  render() {
    const { items, title } = this.props
    return (
      <div className={styles.Vacancies}>
        <h2 className={styles.Title}>
          <HeadingSlash>{title}</HeadingSlash>
        </h2>
        {(!items || items.length === 0) && (
          <div className={styles.EmptyMessage}>
            <h3 className={styles.EmptyTitle}>No vacancies at the moment</h3>
            <p>
              But don’t let that stop you. If you love what we do, then we’d
              love to hear from you.
            </p>
            <Button
              className={styles.Button}
              outline
              href="mailto:jobs@contagious.com"
              ariaLabel="send us your cv"
            >
              Send us your CV
            </Button>
          </div>
        )}
        {items &&
          items.map((item, i) => (
            <Expander
              key={`vacncies${i}`}
              question={`${item.title} /`}
              answer={item.body}
            />
          ))}
      </div>
    )
  }
}

Vacancies.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
}

export default Vacancies
