import React from 'react'
import { arrayOf, shape, string } from 'prop-types'

import WidgetHeader from '../WidgetHeader'
import TextSummary from '../TextSummary'
import LinkWrapper from '../primitive/LinkWrapper'
import Icon from '../primitive/Icon'

import styles from './ReadingList.styl'

const ReadingList = ({ items }) => {
  const hasItems = items && items.length > 0
  return (
    <div className={styles.ReadingList}>
      <WidgetHeader
        link="/iq/saved"
        title="Saved Items"
        className={styles.Header}
        smallTitle
      />
      <div className={styles.ReadingListWrapper}>
        {hasItems ? (
          items.map(({ _id, title, url, details }) => (
            <TextSummary
              key={`SavedList-${_id}`}
              url={url}
              name={title}
              details={details}
            />
          ))
        ) : (
          <h3 className={styles.NoArticles}>
            Start saving items by clicking the{' '}
            <Icon type={'BookmarkBorder'} a11yText={'Save'} width={16} /> icon
            and you’ll find them all here.
          </h3>
        )}
      </div>
      <LinkWrapper className={styles.SeeAll} link="/iq/saved">
        See all
      </LinkWrapper>
    </div>
  )
}

ReadingList.propTypes = {
  items: arrayOf(
    shape({
      _id: string.isRequired,
      title: string.isRequired,
      url: string.isRequired,
      details: string,
      color: string,
      section: shape({
        color: string
      })
    })
  )
}

export default ReadingList
