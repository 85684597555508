import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withColor } from '../../../ColorContext'
import CustomCheckControl from './CustomCheck'
import NativeCheckControl from './NativeCheck'
import CheckWithLink from './CheckWithLink'

import styles from './Check.styl'

const SingleCheckControl = ({
  option,
  native,
  value,
  textClass,
  index = 0,
  disabled,
  link,
  ...other
}) => {
  const CheckControlType = native
    ? NativeCheckControl
    : withColor(CustomCheckControl)

  function isChecked(optionValue) {
    if (!value) return false
    if (value === optionValue) return true
    if (Array.isArray(value) && value[index] === optionValue) return true
    return false
  }

  return (
    <label className={disabled && styles.disabled}>
      <span>
        <CheckControlType
          {...other}
          value={option.value}
          name={option.name}
          {...(isChecked(option.value) && { checked: true })}
          required={option.required}
          data-text={option.dataText || option.text}
        />
      </span>
      {!!option.tag && (
        <span className={classnames(styles.TagText)}>{option.tag}</span>
      )}
      {option.text && (
        <span className={classnames(styles.OptionText, textClass)}>
          {link ? (
            <CheckWithLink link={link} text={option.text} />
          ) : (
            option.text
          )}
          {option.required && [
            ' ',
            <abbr key={`${option.name}Required`} title="This field is required">
              *
            </abbr>
          ]}
        </span>
      )}
    </label>
  )
}

SingleCheckControl.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    )
  ]),
  native: PropTypes.bool,
  option: PropTypes.shape({
    required: PropTypes.bool,
    name: PropTypes.string,
    tag: PropTypes.string,
    dataText: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ])
  }).isRequired,
  textClass: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  link: PropTypes.string
}

export default SingleCheckControl
