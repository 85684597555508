import React from 'react'
import { string, object } from 'prop-types'
import OnboardingPage from '../../../layout/component/OnboardingPage'
import { landingImages } from '../../../layout/component/OnboardingPage/data/onboarding-page-data'

const Landing = props => {
  const { name, onboardingConfig, inviteKey } = props

  return (
    <OnboardingPage
      name={name}
      images={landingImages}
      onboardingConfig={onboardingConfig}
      inviteLink={`/iq/onboarding/${inviteKey}/register`}
    />
  )
}

Landing.propTypes = {
  name: string.isRequired,
  onboardingConfig: object.isRequired,
  inviteKey: string.isRequired
}

export default Landing
