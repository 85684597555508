import React, { useState, useRef } from 'react'
import classNames from 'classnames'
// import useIntersectionObserver from '../../../lib/use-intersection-observer'
import PropTypes from 'prop-types'
// import Icon from '../primitive/Icon'
import SaveArticle from '../SaveArticle'
// import ButtonSimple from '../primitive/ButtonSimple'
import Overlay from '../Overlay'
import Categories from '../ArticleMetaControls/container/Categories'
import AddToCollection from '../AddToCollection'
import IconButton from '../primitive/IconButton'

import styles from './ArticleMetaControlsHeader.styl'

const ArticleMetaControlsHeader = ({
  absoluteUrl,
  headline,
  articleId,
  downloadUrl,
  showCollect,
  categories,
  savedLists
}) => {
  const [overlayExpanded, setOverlayExpanded] = useState(false)
  const [addToCollectionExpanded, setAddToCollectionExpanded] = useState(false)

  const actionsRef = useRef(null)
  // const entry = useIntersectionObserver(actionsRef, {})
  // const isActionsAside = entry && !entry.isIntersecting

  const handleOverlayClose = () => setOverlayExpanded(false)
  const handleOverlayOpen = () => setOverlayExpanded(true)
  const toggleAddToCollection = () =>
    setAddToCollectionExpanded(prevState => !prevState)

  const mailUrl = `mailto:?subject=Shared: ${headline}&body=${absoluteUrl}`
  return (
    <div
      className={classNames(
        styles.ArticleMetaControlsHeader
        // isActionsAside && styles.isActionsAside
      )}
      ref={actionsRef}
    >
      {/* Large desktop only */}
      {/* <aside className={styles.AsideActions}>
        <div className={styles.AsideActionsInner}>
          {typeof savedLists !== 'undefined' && (
            <SaveArticle
              type="article"
              itemId={articleId}
              name={headline}
              savedLists={savedLists}
            />
          )}

          <ButtonSimple
            outline
            href={mailUrl}
            data-track="Share"
            data-track-label={headline}
            ariaLabel="mail to"
          >
            <Icon className={styles.AsideActionIcon} width={12} type="Social" />
          </ButtonSimple>

          {downloadUrl && (
            <ButtonSimple ariaLabel="download" outline href={downloadUrl}>
              <Icon className={styles.AsideActionIcon} width={12} type="PDF2" />
            </ButtonSimple>
          )}

          {showCollect && (
            <ButtonSimple
              ariaLabel="show collect"
              outline
              onClick={toggleAddToCollection}
            >
              <Icon className={styles.AsideActionIcon} width={12} type="Add2" />
            </ButtonSimple>
          )}
        </div>
      </aside> */}
      {/* Large desktop only */}

      {addToCollectionExpanded && (
        <AddToCollection
          articleId={articleId}
          headline={headline}
          onClose={toggleAddToCollection}
          itemType="article"
        />
      )}
      <div className={styles.Actions}>
        {typeof savedLists !== 'undefined' && (
          <>
            <SaveArticle
              type="article"
              itemId={articleId}
              name={headline}
              savedLists={savedLists}
            />

            <div className={styles.AddToInterests}>
              <IconButton
                className={styles.IconButton}
                ariaLabel="add to interests"
                iconWidth={16}
                iconHeight={16}
                icon="Add2"
                onClick={handleOverlayOpen}
              />
              <Overlay
                open={overlayExpanded}
                title="Add to interests"
                smallScreenOnly
                onClose={handleOverlayClose}
              >
                <Categories categories={categories} />
              </Overlay>
            </div>
          </>
        )}
        <IconButton
          className={styles.IconButton}
          ariaLabel="share"
          icon="Social"
          href={mailUrl}
          iconWidth={16}
          iconHeight={17}
          data-track="Share"
          data-track-label={headline}
        />
        {downloadUrl && (
          <IconButton
            className={styles.IconButton}
            ariaLabel="download pdf"
            iconWidth={16}
            iconHeight={16}
            icon="Download"
            href={downloadUrl}
            data-track="Download"
            data-track-label={headline}
          />
        )}
        {showCollect && (
          <IconButton
            className={styles.IconButton}
            ariaLabel="show collect"
            iconWidth={16}
            iconHeight={16}
            onClick={toggleAddToCollection}
            icon="Add2"
          />
        )}
      </div>
    </div>
  )
}

ArticleMetaControlsHeader.defaultProps = {
  categories: {}
}

ArticleMetaControlsHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  absoluteUrl: PropTypes.string.isRequired,
  articleId: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string,
  showCollect: PropTypes.bool,
  categories: PropTypes.object.isRequired,
  savedLists: PropTypes.array.isRequired
}

export default ArticleMetaControlsHeader
