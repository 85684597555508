import React from 'react'
import { string, object, array, number, func, bool } from 'prop-types'
import { parse } from 'querystring'
import SectionHeading from '../../../../site/layout/component/primitive/SectionHeading'
import SearchResults from '../../../../site/layout/component/SearchResults'

const FilterableCategory = ({
  labels,
  filters,
  articles = [],
  count,
  onSort,
  onPageChange,
  showFilters,
  pages,
  currentPage,
  sort,
  loading,
  error,
  onFilterChange,
  onLabelChange,
  savedLists
}) => {
  const { categories = '' } = parse(location.search.substr(1))
  const [taxonomy, category] = (Array.isArray(categories)
    ? categories[0]
    : categories
  ).split('/')
  let heading = ''
  if (filters[taxonomy]) {
    const selectedCategoryIndex = filters[taxonomy].items.findIndex(
      ({ slug }) => slug === category
    )
    heading = filters[taxonomy].items[selectedCategoryIndex].name
    filters[taxonomy].items[selectedCategoryIndex].disabled = true
  }
  if (loading && articles.length === 0) return null
  return (
    <>
      <SectionHeading
        title={heading}
        description={<strong>{`${count} results`}</strong>}
      />
      <SearchResults
        labels={labels}
        filters={filters}
        articles={articles}
        count={count}
        onFilterChange={onFilterChange}
        onLabelChange={onLabelChange}
        onSort={onSort}
        onPageChange={onPageChange}
        showFilters={showFilters}
        pages={pages}
        currentPage={currentPage}
        sort={sort}
        loading={loading}
        error={error}
        showSort={false}
        savedLists={savedLists}
      />
    </>
  )
}

FilterableCategory.propTypes = {
  labels: array,
  filters: object.isRequired,
  articles: array.isRequired,
  count: number,
  onFilterChange: func,
  onLabelChange: func,
  onRefresh: func,
  onSort: func,
  onPageChange: func,
  showFilters: bool,
  pages: number,
  currentPage: number,
  sort: string,
  loading: bool,
  error: string,
  savedLists: array
}
export default FilterableCategory
