import renderComponent from '../lib/component-renderer'

import Carousel from '../../../../components/site/widgets/inline-image/component/InlineImage'
import EditButton from './component/EditButton'

const debug = require('debug')('article')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article')
    renderComponent('.js-carousel', Carousel)

    debug('init edit button')
    const hasCookie = document.cookie.includes('cmsLoggedIn=true')
    const hasStorage = localStorage.getItem('cmsLoggedIn')
    const canEdit = hasCookie || hasStorage

    if (canEdit) {
      localStorage.setItem('cmsLoggedIn', true)
      renderComponent('.js-edit-button', EditButton)
    }
  })
}
