import { bool, func, object, string } from 'prop-types'
import React from 'react'

import OnboardingFormLayout from '../OnboardingFormLayout'
import SelectControl from '../primitive/Control/SelectControl'
import TextControl from '../primitive/Control/TextControl'
import FieldTemplate from '../primitive/FieldTemplate'
import Notice from '../primitive/Notice'
import FormErrors from '../Onboarding/FormErrors'
import countries from '../../../layout/lib/countries.json'
import disciplineList from '../../../../service/user/lib/disciplines.json'

const countryList = ['United Kingdom'].concat(Object.values(countries))

import styles from './OnboardingForm.styl'

const OnboardingDetailForm = ({
  formErrors = {},
  formData = {},
  onboardingConfig,
  onChange,
  onSubmit,
  error,
  submitting
}) => {
  return (
    <OnboardingFormLayout
      step={2}
      title={`Welcome to Contagious, ${formData.firstName}`}
      subtitle="Your answers to the following questions will help us find the right content for you."
      onSubmit={onSubmit}
      disabled={submitting}
    >
      <div className={styles.Form}>
        {!!error && (
          <Notice minimal type="error">
            {error}
          </Notice>
        )}
        <FormErrors errors={formErrors} />

        {onboardingConfig.showAgencyField && (
          <FieldTemplate
            label="Agency"
            controlName="agency"
            status={formErrors.agency && 'error'}
            feedback={formErrors.agency}
          >
            <TextControl
              data-testid="agency"
              type="text"
              name="agency"
              placeholder="Agency"
              defaultValue={formData.agency}
              status={formErrors.agency && 'error'}
              onChange={onChange}
              required
            />
          </FieldTemplate>
        )}
        {onboardingConfig.showRegionField && (
          <FieldTemplate
            label="Region"
            controlName="region"
            status={formErrors.region && 'error'}
            feedback={formErrors.region}
          >
            <SelectControl
              data-testid="region"
              type="text"
              name="region"
              defaultValue={formData.region}
              status={formErrors.region && 'error'}
              onChange={onChange}
              required
            >
              <option value="">Region</option>
              {onboardingConfig.regionList.map(r => (
                <option key={r}>{r}</option>
              ))}
            </SelectControl>
          </FieldTemplate>
        )}
        {onboardingConfig.showOfficeField && (
          <>
            <FieldTemplate
              label="Office"
              controlName="office"
              status={formErrors.office && 'error'}
              feedback={formErrors.office}
            >
              <SelectControl
                data-testid="office"
                type="text"
                name="office"
                defaultValue={formData.office}
                status={formErrors.office && 'error'}
                onChange={onChange}
                required
              >
                <option value="">Office</option>
                {onboardingConfig.officeList.map(o => (
                  <option key={o}>{o}</option>
                ))}
              </SelectControl>
            </FieldTemplate>
            {formData.office === 'Other' && (
              <FieldTemplate
                label="Office (Other)"
                controlName="officeOther"
                status={formErrors.officeOther && 'error'}
                feedback={formErrors.officeOther}
              >
                <TextControl
                  data-testid="officeOther"
                  type="text"
                  name="officeOther"
                  defaultValue={formData.officeOther}
                  status={formErrors.officeOther && 'error'}
                  onChange={onChange}
                  required
                />
              </FieldTemplate>
            )}
          </>
        )}
        <div className={styles.TwoColumns}>
          <FieldTemplate
            label="Job title"
            controlName="jobTitle"
            status={formErrors.jobTitle && 'error'}
            feedback={formErrors.jobTitle}
          >
            <TextControl
              type="text"
              name="jobTitle"
              placeholder="Job title"
              defaultValue={formData.jobTitle}
              status={formErrors.jobTitle && 'error'}
              onChange={onChange}
              required
            />
          </FieldTemplate>

          <FieldTemplate
            label="Discipline"
            controlName="discipline"
            status={formErrors.discipline && 'error'}
            feedback={formErrors.discipline}
          >
            <SelectControl
              type="text"
              name="discipline"
              defaultValue={formData.discipline}
              status={formErrors.discipline && 'error'}
              onChange={onChange}
              required
            >
              <option value="">Discipline</option>
              {disciplineList.map(d => (
                <option key={d}>{d}</option>
              ))}
            </SelectControl>
          </FieldTemplate>
        </div>
        <div className={styles.TwoColumns}>
          <FieldTemplate
            label="City"
            controlName="city"
            status={formErrors.city && 'error'}
            feedback={formErrors.city}
          >
            <TextControl
              type="text"
              name="city"
              placeholder="City"
              defaultValue={formData.city}
              status={formErrors.city && 'error'}
              onChange={onChange}
            />
          </FieldTemplate>

          <FieldTemplate
            label="Country"
            controlName="country"
            status={formErrors.country && 'error'}
            feedback={formErrors.country}
          >
            <SelectControl
              type="text"
              name="country"
              defaultValue={formData.country}
              status={formErrors.country && 'error'}
              onChange={onChange}
              required
            >
              <option value="">Country</option>
              {countryList.map(c => (
                <option key={c}>{c}</option>
              ))}
            </SelectControl>
          </FieldTemplate>
        </div>
      </div>
    </OnboardingFormLayout>
  )
}

OnboardingDetailForm.propTypes = {
  formErrors: object,
  formData: object,
  onboardingConfig: object,
  error: string,
  submitting: bool,
  onChange: func.isRequired,
  onSubmit: func
}

export default OnboardingDetailForm
