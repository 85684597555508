import React from 'react'
import { node, string } from 'prop-types'
import style from './Prose.styl'
import classnames from 'classnames'

const Prose = ({ className, children, dangerousHtml }) => (
  <div
    className={classnames(className, style.Prose)}
    {...(dangerousHtml && {
      dangerouslySetInnerHTML: { __html: dangerousHtml }
    })}
  >
    {children}
  </div>
)
Prose.propTypes = {
  children: node,
  className: string,
  dangerousHtml: string
}
export default Prose
