import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { stringify } from 'qs'

const CollectionViewContainer = ({
  pageTitle,
  context,
  showMore,
  collections,
  component: Component
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(showMore)
  const [results, setResults] = useState(collections)

  const limit = 20

  const onMore = async () => {
    setLoading(true)
    setError(false)
    try {
      const res = await fetch(
        `/api/collections/${context}?${stringify({
          page,
          limit
        })}`,
        {
          credentials: 'include',
          redirect: 'error'
        }
      )
      setLoading(false)
      if (res.status === 200) {
        const { results, hasMore } = await res.json()
        setResults(prevResults => [...prevResults, ...results])
        setHasMore(hasMore)
        setPage(prevPage => prevPage + 1)
      } else {
        setError(true)
      }
    } catch (error) {
      setLoading(false)
      setError(true)
    }
  }

  return (
    <Component
      pageTitle={pageTitle}
      collections={results}
      loading={loading}
      error={error}
      showMore={hasMore}
      onMore={onMore}
    />
  )
}

CollectionViewContainer.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  showMore: PropTypes.bool.isRequired,
  collections: PropTypes.array.isRequired,
  component: PropTypes.func.isRequired
}

export default CollectionViewContainer
