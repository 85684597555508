import React from 'react'
import { bool } from 'prop-types'

import styles from './Rule.styl'

const Rule = ({ presentational }) => {
  const RuleEl = presentational ? 'div' : 'hr'
  return <RuleEl className={styles.Rule} />
}

Rule.propTypes = {
  presentational: bool
}

export default Rule
