import renderComponent from '../lib/component-renderer'

import FAQs from '../../../../components/site/widgets/faqs/component/FAQs'

const debug = require('debug')('faqs')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init faqs')
    renderComponent('.js-faqs', FAQs)
  })
}
