import React, { Fragment, useState } from 'react'
import { func, number, bool, shape, string, arrayOf } from 'prop-types'
import SectionHeading from '../../layout/component/primitive/SectionHeading'
import Notice from '../../layout/component/primitive/Notice'
import SavedList from './SavedList'
import { Grid, GridItem } from '../../layout/component/primitive/Grid'
import HeadingSlash from '../../layout/component/primitive/HeadingSlash'
import DropdownStandard from '../../layout/component/DropdownStandard'
import ButtonSimple from '../../layout/component/primitive/ButtonSimple'
import Icon from '../../layout/component/primitive/Icon'
import SavedListsModal from '../../layout/component/SavedListsModal/SavedListsModal'
import Pagination from '../../layout/component/Pagination'
import Loading from '../../layout/component/primitive/Loading'

import styles from './SavedLists.module.styl'

const SavedLists = ({
  loading,
  error,
  saved,
  savedLists,
  list,
  userId,
  isOwner,
  isMember,
  page,
  pages,
  onChangeList,
  onRenameList,
  onDeleteList,
  onShareList,
  onLeaveList,
  onCreateList,
  onPageChange,
  onDeleteItem,
  onRemoveItem
}) => {
  const [savedListsOpen, setSavedListsOpen] = useState(false)

  const dropdownItems = [
    { name: 'Rename', icon: 'Edit', onClick: onRenameList },
    { name: 'Delete', icon: 'Delete', onClick: onDeleteList }
  ]

  return (
    <Fragment>
      <div className={styles.Header}>
        <div className={styles.Heading}>
          <SectionHeading title="Saved Items" />
        </div>
        {list && (
          <div className={styles.Actions} data-testid="actions">
            {!list.isDefault && isOwner && (
              <ButtonSimple
                data-testid="share"
                className={styles.ShareButton}
                outline
                onClick={onShareList}
                ariaLabel="share"
              >
                <Icon width={12} height={12} type="Social" a11yText="Share" />{' '}
                <span>Share</span>
              </ButtonSimple>
            )}
            {isMember && (
              <ButtonSimple
                ariaLabel="leave"
                data-testid="leave"
                outline
                onClick={onLeaveList}
              >
                <Icon width={12} height={12} type="Social" a11yText="Leave" />{' '}
                <span>Leave</span>
              </ButtonSimple>
            )}
            {!list.isDefault && isOwner && (
              <DropdownStandard
                data-testid="edit-dropdown"
                name="Edit List"
                items={dropdownItems}
              />
            )}
          </div>
        )}
      </div>
      {!!error && (
        <Notice minimal type="error">
          There was an issue performing that action. Please try again later.
        </Notice>
      )}
      <Grid>
        <GridItem sizes={['tablet-one-quarter']} spacingBottom={2}>
          {/* Mobile Only */}
          <div className={styles.SavedListsWrapperMobile}>
            <ButtonSimple
              className={styles.OpenListButton}
              outline
              onClick={() => setSavedListsOpen(true)}
              ariaLabel="my lists"
            >
              <Icon
                width={24}
                height={24}
                type="List"
                a11yText="Open saved lists"
              />
              My Lists
            </ButtonSimple>
            {savedListsOpen && (
              <SavedListsModal
                savedLists={savedLists}
                activeList={list}
                onClose={() => setSavedListsOpen(false)}
                onChangeList={onChangeList}
                onCreateList={onCreateList}
              />
            )}
          </div>
          {/* Mobile Only */}
          {/* Desktop Only */}
          <div className={styles.SavedListsWrapperDesktop}>
            <h3 className={styles.SavedListHeading}>
              <HeadingSlash>My Lists</HeadingSlash>
            </h3>
            <ul className={styles.SavedLists} data-testid="all-lists">
              {savedLists.map(savedList => (
                <li
                  key={`SavedLists-${savedList._id}`}
                  className={
                    list && savedList._id === list._id
                      ? styles.active
                      : undefined
                  }
                >
                  <a
                    href={savedList.link}
                    onClick={e => {
                      e.preventDefault()
                      onChangeList(savedList)
                    }}
                  >
                    {savedList.name}
                    {savedList.users.includes(userId) && savedList.__user._id
                      ? ` (${savedList.__user.firstName} ${savedList.__user.lastName})`
                      : ''}
                  </a>
                </li>
              ))}
            </ul>
            <ButtonSimple
              outline
              onClick={onCreateList}
              ariaLabel="create new list"
            >
              <Icon
                width={12}
                height={12}
                type="Plus"
                a11yText="Create new list"
              />{' '}
              New List
            </ButtonSimple>
          </div>
          {/* Desktop Only */}
        </GridItem>
        <GridItem sizes={['tablet-three-quarters']} data-testid="list-content">
          {loading ? (
            <Loading />
          ) : (
            <>
              {!list && (
                <p>
                  You haven’t saved anything yet. Start saving items by clicking
                  the{' '}
                  <Icon type={'BookmarkBorder'} a11yText={'Save'} width={16} />{' '}
                  icon and you’ll find them all here.
                </p>
              )}
              {list && (
                <SavedList
                  savedLists={saved.savedLists}
                  list={list}
                  isOwner={isOwner}
                  userId={userId}
                  onDeleteItem={onDeleteItem}
                  onRemoveItem={onRemoveItem}
                />
              )}
            </>
          )}
          {pages > 1 && (
            <Pagination
              className={styles.Pagination}
              data-testid="pagination"
              total={pages}
              currentPage={page}
              onChange={onPageChange}
            />
          )}
        </GridItem>
      </Grid>
    </Fragment>
  )
}

const savedListShape = shape({
  _id: string,
  name: string.isRequired
})

SavedLists.propTypes = {
  onChangeList: func,
  onRenameList: func,
  onDeleteList: func,
  onShareList: func,
  onLeaveList: func,
  onCreateList: func,
  onPageChange: func,
  onDeleteItem: func,
  onRemoveItem: func,
  page: number,
  pages: number,
  loading: bool,
  error: bool,
  userId: string,
  isOwner: bool,
  isMember: bool,
  saved: shape({
    savedLists: arrayOf(savedListShape).isRequired
  }).isRequired,
  savedLists: arrayOf(savedListShape).isRequired,
  list: savedListShape
}

export default SavedLists
