import React from 'react'
import { bool, object, func } from 'prop-types'
import classNames from 'classnames'

import styles from './NavToggle.styl'

import Icon from '../../../../primitive/Icon'

const NavToggle = ({ setRef, expanded, onClick, smallScreen, largeScreen }) => (
  <button
    aria-label={expanded ? 'Close Navigation' : 'Reveal Navigation'}
    ref={setRef}
    className={classNames(
      styles.NavToggle,
      smallScreen && styles.smallScreen,
      largeScreen && styles.largeScreen
    )}
    onClick={onClick}
  >
    <Icon
      type={expanded ? 'Close' : 'MenuThree'}
      a11yText={expanded ? 'Close Navigation' : 'Reveal Navigation'}
    />
    <span className={styles.NavToggleText}>Menu</span>
  </button>
)

NavToggle.propTypes = {
  setRef: object,
  expanded: bool,
  onClick: func,
  smallScreen: bool,
  largeScreen: bool
}

export default NavToggle
