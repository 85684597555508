import React, { useEffect, useState } from 'react'

import styles from './DesktopNavItems.styl'

import { object, array, bool } from 'prop-types'
import NavItem from './NavItem/NavItem'

const DesktopNavItems = ({
  panelLinks,
  caseStudiesArticles,
  learningArticles,
  isSearchFocus
}) => {
  const [activeItem, setActiveItem] = useState(null)
  const expandedHandle = itemProperty => {
    setActiveItem(itemProperty)
  }
  useEffect(() => {
    if (isSearchFocus) {
      setActiveItem(null)
    }
  }, [isSearchFocus])
  return (
    <>
      <div className={styles.Container}>
        <div className={styles.MainContainer}>
          {Object.entries(panelLinks).map(([property, value]) => {
            const subNavs = value.links || value.items
            if (property === 'creativeAccelerator') return
            return (
              <NavItem
                key={property}
                property={property}
                value={value}
                subNavs={subNavs}
                caseStudiesArticles={caseStudiesArticles}
                learningArticles={learningArticles}
                onClick={expandedHandle}
                expanded={activeItem === property}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default DesktopNavItems

DesktopNavItems.propTypes = {
  panelLinks: object,
  caseStudiesArticles: array,
  learningArticles: array,
  isSearchFocus: bool
}
