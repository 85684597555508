// Takes an array of labels and labelsConfig from config.json and filters out any labels
// that should be hidden in the filters on the search page
const filterHiddenLabels = (labels = [], labelsConfig = []) => {
  if (!labels) {
    return []
  }

  if (!labelsConfig) {
    labelsConfig = []
  }

  return labels.filter(label => {
    const labelConfig = labelsConfig.find(config => config.name === label.name)
    return !labelConfig || (labelConfig && !labelConfig.hideInFilters)
  })
}

export default filterHiddenLabels
