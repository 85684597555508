import React from 'react'
import PropTypes from 'prop-types'

import Lightbox from '../Lightbox'
import Video from '../video'

import styles from './VideoGrid.styl'
import { Grid, GridItem } from '../primitive/Grid'
import HeadingSlash from '../primitive/HeadingSlash'

class VideoGrid extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showingLightbox: false,
      videoId: '',
      videoProvider: ''
    }

    this.toggleLightbox = this.toggleLightbox.bind(this)
  }

  toggleLightbox(id, provider) {
    document && document.body.classList.toggle('noScroll')
    this.setState({
      showingLightbox: !this.state.showingLightbox,
      videoId: id,
      videoProvider: provider
    })
  }

  render() {
    const { items, title } = this.props
    return (
      <div className={styles.VideoGrid}>
        <h2 className={styles.WidgetTitle}>
          <HeadingSlash>{title}</HeadingSlash>
        </h2>

        <Grid gap={8}>
          {items.map((item, i) => (
            <GridItem key={`item${i}`} sizes={['desktop-one-third']}>
              <button
                aria-label={item.title || 'video'}
                className={styles.Button}
                onClick={() => {
                  this.toggleLightbox(item.videoId, item.videoProvider)
                }}
              >
                <img className={styles.Image} src={item.imageUrl} />
                {item.title && (
                  <h2 className={styles.Title}>
                    <HeadingSlash>{item.title}</HeadingSlash>
                  </h2>
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: item.body }}
                  className={styles.Description}
                />
              </button>
            </GridItem>
          ))}
        </Grid>
        {this.state.showingLightbox && (
          <Lightbox toggle={() => this.toggleLightbox()}>
            <Video
              provider={this.state.videoProvider}
              videos={[{ videoId: this.state.videoId }]}
              autoplay
            />
          </Lightbox>
        )}
      </div>
    )
  }
}

VideoGrid.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array
}

export default VideoGrid
