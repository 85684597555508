import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '../../../layout/component/primitive/Button'

const LoadMore = props => {
  const { className, text, loadMoreMethod, moreIds } = props

  if (loadMoreMethod === 'button' && moreIds.length > 0) {
    return (
      <div className="load-more">
        <Button
          className={classnames('js-load-more-btn', className)}
          outline
          clipCorner
          ariaLabel={text}
        >
          {text}
        </Button>
      </div>
    )
  } else if (loadMoreMethod === 'scroll' && moreIds.length > 0) {
    return <div className="load-more js-scroll-detector" />
  }

  return null
}

LoadMore.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  moreIds: PropTypes.array,
  loadMoreMethod: PropTypes.string
}

export default LoadMore
