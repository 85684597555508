import React from 'react'
import { number, oneOfType, string } from 'prop-types'

import styles from './AlertDot.styl'

const AlertDot = ({ count }) => {
  return <div className={styles.AlertDot}>{count && <span>{count}</span>}</div>
}

AlertDot.propTypes = {
  count: oneOfType([number, string])
}

export default AlertDot
