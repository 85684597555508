import renderComponent from '../lib/component-renderer'
import FilterableCategory from './container/FilterableCategory'
const debug = require('debug')('filterable-category')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-filterable-category', FilterableCategory)
  })
}
