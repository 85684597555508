import renderComponent from '../lib/component-renderer'
import Footer from '../../../../components/site/layout/component/Footer/component'

const debug = require('debug')('footer')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init footer')
    renderComponent('.js-footer', Footer)
  })
}
