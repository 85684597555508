const debug = require('debug')('io-gallery-block')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const containers = document.querySelectorAll('.gallery-block')

    containers.forEach(container => {
      const content = container.querySelectorAll('.original-content')

      const item = document.createElement('div')
      item.className = 'gallery-block-item'

      setInner(item, content[0])

      content.forEach(el => {
        el.style.backgroundImage = `url(${el.getAttribute(
          'data-thumbnail-url'
        )})`

        el.addEventListener('click', () => {
          content.forEach(el => {
            el.classList.remove('active')
          })
          setInner(item, el)
        })
      })

      container.insertAdjacentElement('afterbegin', item)
    })
  })
}

const setInner = (item, content) => {
  content.classList.add('active')
  item.innerHTML = ''
  content.childNodes.forEach(child => {
    item.appendChild(child.cloneNode(true))
  })
}
