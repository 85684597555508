import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'
import RenameListModal from './RenameListModal'

const debug = require('debug')('rename-list')

const RenameListModalContainer = ({ list, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSave = async name => {
    debug('rename saved list', name)
    let response
    try {
      response = await fetch(`/api/saved-lists/${list._id}`, {
        method: 'POST',
        credentials: 'include',
        redirect: 'error',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      })
    } catch (error) {
      setLoading(false)
      debug('rename saved list error', error)
      return setError('There was a problem. Please try again later.')
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('rename saved list error', response.status)
      return setError('There was a problem. Please try again later.')
    }

    onSuccess(await response.json())
  }

  return (
    <RenameListModal
      list={list}
      error={error}
      loading={loading}
      onClose={onClose}
      onSave={handleSave}
    />
  )
}

RenameListModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  list: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default withColor(RenameListModalContainer)
