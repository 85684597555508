import React, { PureComponent } from 'react'
import { string, node } from 'prop-types'

import PlatformContext from './Context'

const Provider = ({ platform, children }) => {
  return (
    <PlatformContext.Provider value={{ platform }}>
      {children}
    </PlatformContext.Provider>
  )
}

Provider.propTypes = {
  platform: string,
  children: node
}

const withPlatform = ChildComponent => {
  class WithPlatform extends PureComponent {
    render() {
      return (
        <PlatformContext.Consumer>
          {context => (
            <ChildComponent
              {...this.props}
              platform={context && context.platform}
            />
          )}
        </PlatformContext.Consumer>
      )
    }
  }

  WithPlatform.displayName = `${ChildComponent.displayName ||
    ChildComponent.name}`

  return WithPlatform
}

export { Provider, withPlatform }
