import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CollectionList from '../../layout/component/CollectionList'
import CollectionPreview from '../../layout/component/CollectionPreview'
import CollectionHero from '../../layout/component/CollectionHero'
import SectionHeading from '../../layout/component/primitive/SectionHeading'
import Notice from '../../layout/component/primitive/Notice'
import CommentForm from '../../layout/component/CommentForm'
import HeadingForm from '../../layout/component/HeadingForm'
import LinkForm from '../../layout/component/LinkForm'
import { widthMin, desktop } from '../../layout/component/primitive/Media'
import { withPlatform } from '../../layout/component/PlatformContext'
import useSSR from '../../lib/ssr-hook'

import styles from './CollectionView.styl'

const CollectionView = ({
  error,
  success,
  user,
  collectionId,
  name,
  description,
  date,
  items,
  images,
  editingItem,
  canEdit,
  savedLists,
  onReorder,
  onEdit,
  onDeleteItem,
  onAddComment,
  onShowCommentForm,
  onHideCommentForm,
  showCommentForm,
  onAddHeading,
  onShowHeadingForm,
  onHideHeadingForm,
  showHeadingForm,
  onAddLink,
  onShowLinkForm,
  onHideLinkForm,
  showLinkForm,
  onShowEmbedModal
}) => {
  const { isBrowser } = useSSR()
  const breakpoint = isBrowser && window.matchMedia(widthMin(desktop))
  const [dragable, setDragable] = useState(isBrowser && breakpoint.matches)

  const onBreakpointChange = event => setDragable(event.matches)

  useEffect(() => {
    if (!isBrowser) return null
    breakpoint.addListener(onBreakpointChange)
    return () => {
      breakpoint.removeListener(onBreakpointChange)
    }
  }, [])

  return (
    <Fragment>
      {!!success && (
        <Notice type="success">Your collection has been saved.</Notice>
      )}
      {!!error && (
        <Notice minimal type="error">
          {error}
        </Notice>
      )}

      {canEdit ? (
        <>
          <SectionHeading title={name} />
          <div className={styles.CollectionPreview}>
            <CollectionPreview
              imageSource={images}
              dateSubtitle={date}
              description={description}
              collectionHero
              canEdit={canEdit}
              editUrl={`/iq/collection/${collectionId}/edit`}
              canAddItems={canEdit}
              onAddLink={onShowLinkForm}
              onAddComment={onShowCommentForm}
              onAddHeading={onShowHeadingForm}
              onShowEmbedModal={onShowEmbedModal}
              items={items}
              user={user}
            />
          </div>
        </>
      ) : (
        <div className={styles.CollectionHero}>
          <CollectionHero
            collectionId={collectionId}
            savedLists={savedLists}
            title={name}
            images={images}
            text={description}
            date={date}
          />
        </div>
      )}

      <CollectionList
        disableReorder={!canEdit || !dragable}
        disableDelete={!canEdit}
        disableEdit={!canEdit}
        onDelete={onDeleteItem}
        onChange={onReorder}
        onEdit={onEdit}
        collectionId={collectionId}
        items={items}
        user={user}
        savedLists={savedLists}
      />

      {showCommentForm && (
        <CommentForm
          onClose={onHideCommentForm}
          onSubmit={onAddComment}
          author={`${user.firstName} ${user.lastName}`}
          authorId={user._id}
          item={editingItem}
        />
      )}

      {showHeadingForm && (
        <HeadingForm
          onClose={onHideHeadingForm}
          onSubmit={onAddHeading}
          author={`${user.firstName} ${user.lastName}`}
          authorId={user._id}
          item={editingItem}
        />
      )}

      {showLinkForm && (
        <LinkForm
          onClose={onHideLinkForm}
          onSubmit={onAddLink}
          author={`${user.firstName} ${user.lastName}`}
          item={editingItem}
        />
      )}
    </Fragment>
  )
}
CollectionView.propTypes = {
  collectionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  date: PropTypes.string.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool,
  items: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  editingItem: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
  savedLists: PropTypes.array,
  onReorder: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
  onShowCommentForm: PropTypes.func.isRequired,
  onHideCommentForm: PropTypes.func.isRequired,
  showCommentForm: PropTypes.bool.isRequired,
  onAddHeading: PropTypes.func.isRequired,
  onShowHeadingForm: PropTypes.func.isRequired,
  onHideHeadingForm: PropTypes.func.isRequired,
  showHeadingForm: PropTypes.bool.isRequired,
  onAddLink: PropTypes.func.isRequired,
  onShowLinkForm: PropTypes.func.isRequired,
  onHideLinkForm: PropTypes.func.isRequired,
  showLinkForm: PropTypes.bool.isRequired,
  onShowEmbedModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired
}

export default withPlatform(CollectionView)
