import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TextControl from '../../layout/component/primitive/Control/TextControl'
import FieldTemplate from '../../layout/component/primitive/FieldTemplate'
import ButtonSimple from '../../layout/component/primitive/ButtonSimple'
import SectionHeading from '../../layout/component/primitive/SectionHeading'
import Notice from '../../layout/component/primitive/Notice'
import ShrinkWrap, {
  ShrinkWrapItem
} from '../../layout/component/primitive/ShrinkWrap'
import ToggleControl from '../../layout/component/ToggleControl'

import styles from './CollectionForm.styl'

const CollectionForm = ({
  error,
  errors = {},
  success,
  submitting,
  data = {},
  onDelete,
  onSubmit,
  onChange,
  isNew
}) => (
  <Fragment>
    <SectionHeading
      title={`${isNew ? 'New' : 'Edit'} Collection${
        !isNew ? `: ${data.name}` : ''
      }`}
      description="Collections are great for organising research and especially useful when collaborating with others. Save IQ stories, external links and comments from yourself and colleagues all in one place."
    />
    <form className={styles.Form} onSubmit={onSubmit}>
      {!!success && (
        <Notice type="success">Your collection has been saved.</Notice>
      )}
      {!!error && (
        <Notice minimal type="error">
          {error}
        </Notice>
      )}
      {!!errors && Object.keys(errors).length !== 0 && (
        <Notice minimal type="error">
          Whoops, something isn’t right!
        </Notice>
      )}
      <h3 className={styles.SubHeading}>Basic details</h3>
      <FieldTemplate label="Heading" controlName="name">
        <TextControl
          type="text"
          name="name"
          placeholder="Name"
          defaultValue={data.name}
          onChange={onChange}
          required
        />
      </FieldTemplate>

      <FieldTemplate label="Description" controlName="description">
        <TextControl
          type="textarea"
          name="description"
          placeholder="Description"
          defaultValue={data.description}
          onChange={onChange}
          multiline
          rows={6}
        />
      </FieldTemplate>

      <h3 className={styles.SubHeading}>Public</h3>

      <ShrinkWrap fullWidth>
        <ShrinkWrapItem>
          <label htmlFor="public">
            <p>Show this collection to all users on IQ.</p>
          </label>
        </ShrinkWrapItem>
        <ShrinkWrapItem shrink>
          <ToggleControl
            id="public"
            name="public"
            defaultValue="true"
            label=""
            checked={data.public}
            onChange={onChange}
          />
        </ShrinkWrapItem>
      </ShrinkWrap>

      <div className={styles.ButtonGroup}>
        <ButtonSimple ariaLabel="save" disabled={submitting}>
          Save
        </ButtonSimple>
        {!isNew && (
          <ButtonSimple ariaLabel="delete" onClick={onDelete} outline>
            Delete
          </ButtonSimple>
        )}
      </div>
    </form>
  </Fragment>
)

CollectionForm.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.object,
  success: PropTypes.bool,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  isNew: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    privacy: PropTypes.string
  })
}

export default CollectionForm
