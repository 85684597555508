import React, { PureComponent } from 'react'
import { array, string } from 'prop-types'
import classNames from 'classnames'

import styles from './OnboardingQuotesCarousel.styl'

class OnboardingQuotesCarousel extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: true,
      pageDots: true,
      adaptiveHeight: true,
      groupCells: 1
    })
  }

  render() {
    const { quotes, className } = this.props
    return (
      <div className={classNames(styles.OnboardingQuotesCarousel, className)}>
        <div
          className={styles.Carousel}
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {quotes &&
            quotes.map((quote, i) => (
              <blockquote key={`Quote-image-${i}`} className={styles.Quote}>
                <h5>{quote.quote}</h5>
                <cite>{quote.author}</cite>
              </blockquote>
            ))}
        </div>
      </div>
    )
  }
}

OnboardingQuotesCarousel.defaultProps = {}

OnboardingQuotesCarousel.propTypes = {
  quotes: array.isRequired,
  className: string
}

export default OnboardingQuotesCarousel
