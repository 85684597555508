import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import NativeSelectControl from './NativeSelect'
import styles from './Select.styl'
import Icon from '../../Icon'

const CustomSelectControl = props => {
  const { error, modifiers, disabled } = props
  const controlClassNames = classNames(
    styles.SelectGroup,
    modifiers &&
      modifiers.map(modifierClass => `custom-select--${modifierClass}`),
    { 'custom-select--error': error },
    disabled && styles.disabled
  )

  return (
    <span className={controlClassNames}>
      <NativeSelectControl {...props} />
      <span className={styles.SelectIcon}>
        <Icon type="Down" />
      </span>
    </span>
  )
}

CustomSelectControl.propTypes = {
  error: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool
}

export default CustomSelectControl
