import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Footer.styl'
import Icon from '../../primitive/Icon'

const SocialLinks = ({ socialLinks, className }) => (
  <div className={className}>
    <a
      aria-label="email"
      className={styles.SocialButton}
      href="mailto:contact@contagious.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon type="Email" a11yText="Email" />
    </a>
    {socialLinks.linkedin && (
      <a
        aria-label="linkedin"
        className={styles.SocialButton}
        href={socialLinks.linkedin}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type="Linkedin" a11yText="Linkedin" />
      </a>
    )}
    {socialLinks.instagram && (
      <a
        aria-label="instagram"
        className={styles.SocialButton}
        href={socialLinks.instagram}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type="Instagram" a11yText="Instagram" />
      </a>
    )}
    {socialLinks.twitter && (
      <a
        aria-label="twitter"
        className={styles.SocialButton}
        href={socialLinks.twitter}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type="Twitter" a11yText="Twitter" />
      </a>
    )}
    {socialLinks.facebook && (
      <a
        aria-label="facebook"
        className={styles.SocialButton}
        href={socialLinks.facebook}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type="Facebook" a11yText="Facebook" />
      </a>
    )}
  </div>
)

SocialLinks.propTypes = {
  socialLinks: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default SocialLinks
