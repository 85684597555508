import React from 'react'
import { bool, object, string } from 'prop-types'

import OnboardingFormLayout from '../OnboardingFormLayout'
import PasswordControl from '../primitive/Control/PasswordControl'
import TextControl from '../primitive/Control/TextControl'
import FieldTemplate from '../primitive/FieldTemplate'
import Notice from '../primitive/Notice'
import FormErrors from '../Onboarding/FormErrors'
import CheckControl from '../primitive/Control/CheckControl'

import styles from './OnboardingForm.styl'

const OnboardingAccountForm = ({
  formErrors = {},
  formData = {},
  onboardingConfig,
  error,
  submitting
}) => {
  return (
    <OnboardingFormLayout
      step={1}
      title="Set up your account"
      disabled={submitting}
      buttonText="Create account"
    >
      <div className={styles.Form}>
        {!!error && (
          <Notice minimal type="error">
            {error}
          </Notice>
        )}
        <FormErrors
          minimal
          errors={formErrors}
          useBulletPoints={formErrors && Object.keys(formErrors).length > 1}
        />
        <div className={styles.TwoColumns}>
          <FieldTemplate
            label="First name"
            controlName="firstName"
            status={formErrors.firstName && 'error'}
            feedback={formErrors.firstName}
          >
            <TextControl
              type="text"
              name="firstName"
              placeholder="First name"
              status={formErrors.firstName && 'error'}
              defaultValue={formData.firstName}
              required
              autoComplete="given-name"
            />
          </FieldTemplate>

          <FieldTemplate
            label="Last name"
            controlName="lastName"
            status={formErrors.lastName && 'error'}
            feedback={formErrors.lastName}
          >
            <TextControl
              type="text"
              name="lastName"
              placeholder="Last name"
              status={formErrors.lastName && 'error'}
              defaultValue={formData.lastName}
              required
              autoComplete="family-name"
            />
          </FieldTemplate>
        </div>
        <FieldTemplate
          label="Email address"
          controlName="emailAddress"
          status={formErrors.emailAddress && 'error'}
        >
          <TextControl
            type="text"
            name="emailAddress"
            placeholder={onboardingConfig.emailAddressFieldLabel}
            status={formErrors.emailAddress && 'error'}
            defaultValue={formData.emailAddress}
            required
            autoComplete="off"
          />
        </FieldTemplate>
        <FieldTemplate
          label="Password"
          controlName="password"
          status={formErrors.password && 'error'}
          feedback={formErrors.password}
        >
          <PasswordControl
            name="password"
            status={formErrors.password && 'error'}
            autoComplete="password"
            placeholder="Password"
          />
        </FieldTemplate>
        <div className={styles.Checkbox}>
          <CheckControl
            name="termsAgreed"
            value="1"
            type="checkbox"
            required
            status={formErrors.termsAgreed && 'error'}
            checked={formData.termsAgreed}
            small
          >
            <span>
              I agree to the Contagious{' '}
              <a
                aria-label="privacy policy"
                href="/privacy-policy"
                target="_blank"
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                aria-label="terms & conditions"
                href="/terms-and-conditions"
                target="_blank"
              >
                terms &amp; conditions
              </a>
            </span>
          </CheckControl>
        </div>
      </div>
    </OnboardingFormLayout>
  )
}

OnboardingAccountForm.propTypes = {
  formErrors: object,
  formData: object,
  onboardingConfig: object,
  error: string,
  submitting: bool
}

export default OnboardingAccountForm
