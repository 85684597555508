import React from 'react'
import renderComponent from '../lib/component-renderer'

import ExploreNavigation from '../../../../components/site/layout/component/ExploreNavigation/component'
import ExploreNavigationContainer from './container/ExploreNavigation'

const debug = require('debug')('explore-navigation')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-explore-navigation', props => (
      <ExploreNavigationContainer {...props} component={ExploreNavigation} />
    ))
  })
}
