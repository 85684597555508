import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ImageFader.styl'

const ImageFader = ({
  images,
  current,
  width,
  height,
  delay,
  delayInterval
}) => {
  const getBoundedCurrent = () => {
    if (current < 0) return 0
    if (current >= images.length) return images.length - 1
    return current
  }

  const boundedCurrent = getBoundedCurrent(current)

  return (
    <div
      className={styles.ImageFader}
      {...(delay && {
        style: { '--imageFaderDelay': `${delay * delayInterval}ms` }
      })}
    >
      <div
        className={styles.ImageFaderPlaceholder}
        style={{ paddingBottom: `${(height / width) * 100}%` }}
      >
        {images.map((image, i) => (
          <img
            key={image.src}
            className={classNames(
              styles.ImageFaderImage,
              boundedCurrent === i ? styles.active : styles.inactive
            )}
            src={image.src}
            alt={image.alt}
            width={width}
            height={height}
            loading="lazy"
          />
        ))}
      </div>
    </div>
  )
}

ImageFader.defaultProps = {
  images: [],
  delayInterval: 1000
}

ImageFader.propTypes = {
  images: arrayOf(
    shape({
      alt: string,
      src: string
    })
  ),
  current: number,
  height: number,
  width: number,
  delay: number,
  delayInterval: number
}

export default ImageFader
