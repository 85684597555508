import React from 'react'
import styles from './CaseStudies.styl'
import SubNavItem from '../SubNavItem'
import ArticleItem from '../ArticleItem'
import { array } from 'prop-types'

const CaseStudies = ({ subNavs, articles }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.SubNavList}>
        {subNavs.map(item => {
          return (
            <SubNavItem
              key={`SecondaryLink-CaseStudies-${item.url}`}
              url={item.url}
              label={item.title || item.text}
            />
          )
        })}
      </div>
      {articles && articles.length > 0 && (
        <div className={styles.Articles}>
          {articles.map(article => (
            <ArticleItem
              key={article._id}
              shortHeadline={article.shortHeadline}
              imageUrl={article.imageUrl}
              slug={article.slug}
              fullUrlPath={article.__fullUrlPath}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CaseStudies
CaseStudies.propTypes = {
  subNavs: array,
  articles: array
}
