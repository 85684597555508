import React, { Fragment } from 'react'
import { array, object, func, string, bool } from 'prop-types'
import { withColor } from '../ColorContext'
import isEmpty from 'lodash.isempty'

import HeadingSlash from '../primitive/HeadingSlash'
import ButtonSimple from '../primitive/ButtonSimple'
import CheckControlGroup from '../primitive/Control/CheckControlGroup'
import CheckControl from '../primitive/Control/CheckControl'
import List from './List'
import Icon from '../primitive/Icon'
import MobilePanel from './MobilePanel'

import styles from './FilterPanel.styl'

const sortOptions = [
  { text: 'Relevance', value: 'relevance' },
  { text: 'Newest', value: 'recent' }
]

class FilterPanel extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showingMore: []
    }
    this.toggleMobileOpen = this.toggleMobileOpen.bind(this)
    this.embellishFilters = this.embellishFilters.bind(this)
  }

  toggleMobileOpen() {
    this.setState({ mobileIsOpen: !this.state.mobileIsOpen })
  }

  embellishFilters() {
    const { labels } = this.props
    const filters = this.props.data || []

    if (!isEmpty(filters) || filters.length > 0) {
      const filtersArray = Object.keys(filters).map(filter => {
        const filterName = filters[filter] && filters[filter].name
        const groupKey = filterName.replace(/ /g, '-').toLowerCase()
        return { groupKey, ...filters[filter] }
      })

      if (!isEmpty(labels)) {
        const embellishedLabels = {
          groupKey: 'content-type',
          name: 'Content Type',
          items: labels
        }

        const targetIndex = filtersArray.findIndex(
          filter => filter.name.toLowerCase() === 'country'
        )

        if (targetIndex !== -1)
          filtersArray.splice(targetIndex + 1, 0, embellishedLabels)
      }

      return filtersArray && filtersArray.length > 0 ? filtersArray : filters
    } else {
      /*
        This is here to handle "collection" in search specifically.
        This needs to be handled separately as collections don't have taxonomies,
        thus the filers break.
      */
      const filtersArray = []

      if (!isEmpty(labels)) {
        filtersArray.push({
          groupKey: 'content-type',
          name: 'Content Type',
          items: labels
        })
      }

      return filtersArray && filtersArray.length > 0 ? filtersArray : filters
    }
  }

  render() {
    const {
      sort = 'relevance',
      showSort = true,
      color,
      showCount,
      onSort,
      onChange,
      onLabelChange
    } = this.props
    const { mobileIsOpen } = this.state

    const filters = this.embellishFilters()

    return (
      <Fragment>
        <div className={styles.FilterPanel}>
          {showSort && (
            <div className={styles.SortBy}>
              <h3 className={styles.Heading}>
                <HeadingSlash>Sort by</HeadingSlash>
              </h3>
              <CheckControlGroup className={styles.CheckGroup}>
                {sortOptions.map(option => (
                  <CheckControl
                    key={`sort-${option.value}`}
                    type="radio"
                    name="sort"
                    value={option.value}
                    onChange={() => onSort(option.value)}
                    checked={sort === option.value}
                    small
                  >
                    {option.text}
                  </CheckControl>
                ))}
              </CheckControlGroup>
            </div>
          )}
          <h3 className={styles.Heading}>
            <HeadingSlash>Filter</HeadingSlash>
          </h3>
          {filters &&
            filters.length > 0 &&
            filters.map(filter => (
              <List
                key={`${filter.groupKey}`}
                onChange={
                  filter.groupKey === 'content-type' ? onLabelChange : onChange
                }
                group={{ id: filter.groupKey, ...filter }}
                color={color}
                showCount={showCount}
              />
            ))}
        </div>
        {/* Mobile Only */}
        <ButtonSimple
          outline
          className={styles.MobileButton}
          onClick={this.toggleMobileOpen}
          ariaLabel="filters"
        >
          <Icon type="Filter" width={16} height={16} />
          Filters
        </ButtonSimple>
        {mobileIsOpen && (
          <MobilePanel
            filters={filters}
            onLabelChange={onLabelChange}
            color={color}
            onChange={onChange}
            onSort={onSort}
            showCount={showCount}
            showSort={showSort}
            sort={sort}
            sortOptions={sortOptions}
            onClose={this.toggleMobileOpen}
          />
        )}
        {/* Mobile Only */}
      </Fragment>
    )
  }
}

FilterPanel.defaultProps = {
  showCount: true
}

FilterPanel.propTypes = {
  labels: array,
  data: object.isRequired,
  sort: string,
  onChange: func.isRequired,
  onSort: func.isRequired,
  onLabelChange: func,
  showSort: bool,
  color: string,
  showCount: bool
}

export default withColor(FilterPanel)
