import renderComponent from '../lib/component-renderer'

import Vacancies from '../../../../components/site/widgets/vacancies/component/Vacancies'

const debug = require('debug')('vacancies')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init vacancies')
    renderComponent('.js-vacancies', Vacancies)
  })
}
