import renderComponent from '../lib/component-renderer'

import SubNavigation from '../../../site/widgets/sub-navigation/component/SubNavigation'

const debug = require('debug')('sub-navigation')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init sub-navigation')
    renderComponent('.js-sub-navigation', SubNavigation)
  })
}
