import React from 'react'

import Control from '../../primitive/ControlLegacy'

import styles from '../FooterNewsletterSignupForm.styl'
import { Grid, GridItem } from '../../primitive/Grid'
import HubspotForm from '../../HubspotForm'
import HeadingSlash from '../../primitive/HeadingSlash'

const FooterNewsletterSignupForm = () => (
  <div className={styles.Wrapper}>
    <Grid gap={8}>
      <GridItem sizes={['tablet-one-half']}>
        <h3 className={styles.Heading}>
          <HeadingSlash>Your weekly dose of Contagious thinking</HeadingSlash>
        </h3>
        <p className={styles.Copy}>
          Subscribe to the Contagious weekly newsletter and stay up to date with
          creative news, marketing trends and cutting-edge research.
        </p>
      </GridItem>
      <GridItem sizes={['tablet-one-half']} className={styles.FormContainer}>
        <HubspotForm
          formId="cbc86710-813a-4d8b-9cd0-1d8848a3ba7a"
          portalId="1725887"
          commsText="Weekly newsletter opt in"
          commsId={4848078}
          ctaLabel="Submit"
          notificationStyle={styles.Notification}
        >
          <Control
            name="checkGroup"
            theme="LightBg"
            className={styles.FormField}
            options={[
              {
                name: 'consentToProcess',
                dataText: 'I agree to the privacy policy',
                text: (
                  <span>
                    I agree to{' '}
                    <a
                      aria-label="privacy-policy"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      the privacy policy
                    </a>
                  </span>
                ),
                value: true,
                required: true
              }
            ]}
            type="checkbox"
          />
        </HubspotForm>
      </GridItem>
    </Grid>
  </div>
)

export default FooterNewsletterSignupForm
