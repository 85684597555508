import React from 'react'
import PropTypes from 'prop-types'
import ArticleHero from '../../../../layout/component/ArticleHero'

const ArticleImagesWidget = props => {
  const { images = [], fullWidth, provider, videoLink, headline } = props
  if (images.length === 0) return null

  return (
    <ArticleHero
      images={images}
      fullWidth={fullWidth}
      provider={provider}
      videoLink={videoLink}
      headline={headline}
    />
  )
}

ArticleImagesWidget.propTypes = {
  images: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  provider: PropTypes.string,
  videoLink: PropTypes.string,
  headline: PropTypes.string.isRequired
}

export default ArticleImagesWidget
