import React, { useState, useEffect } from 'react'
import CategoryPickerForm from '../../CategoryPickerForm'
import Loading from '../../primitive/Loading'
import Notice from '../../primitive/Notice'
import Surround from '../../primitive/Surround'
import updateFollowing from '../lib/following-updater'

const FollowingContainer = () => {
  const [following, setFollowing] = useState({})
  const [loading, setLoading] = useState(2)
  const [taxonomies, setTaxonomies] = useState([])
  const [categories, setCategories] = useState({})
  const [error, setError] = useState(null)

  const fetchFollowing = async () => {
    const res = await fetch('/api/user/following', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setFollowing(await res.json())
    setLoading(prevState => prevState - 1)
  }

  const fetchCategories = async () => {
    const res = await fetch('/api/categories', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const { taxonomies, categoryIndex } = await res.json()
    setTaxonomies(taxonomies)
    setCategories(categoryIndex)
    setLoading(prevState => prevState - 1)
  }

  useEffect(() => {
    fetchFollowing()
    fetchCategories()
  }, [])

  const handleAction = async (taxonomy, category, follow) => {
    const foundTaxonomy = taxonomies.find(({ _id }) => _id === taxonomy) || {}
    const foundCategory =
      categories[taxonomy].find(({ _id }) => _id === category) || {}
    const eventLabel = `${foundTaxonomy.name} / ${foundCategory.name}`
    try {
      const updatedFollowing = await updateFollowing({
        taxonomy,
        category,
        follow
      })
      setFollowing(updatedFollowing)
      window.ioEventTracker({
        category: 'Following',
        action: follow ? 'add' : 'remove',
        label: eventLabel
      })
    } catch (e) {
      window.sentry.captureException(e)
      setError(
        'There was an issue performing that action. Please try again later.'
      )
    }
  }

  const toggle = (taxonomyId, categoryId) => {
    const follow = following[taxonomyId]
      ? !following[taxonomyId].items[categoryId]
      : true
    handleAction(taxonomyId, categoryId, follow)
  }
  const setOption = handleAction

  const hasSelected = (taxonomyId, categoryId) =>
    following[taxonomyId] ? !!following[taxonomyId].items[categoryId] : false

  return (
    <>
      {error && (
        <Notice minimal type="error">
          {error}
        </Notice>
      )}
      {loading > 0 ? (
        <Loading />
      ) : (
        <Surround padded background="white" rounded>
          <CategoryPickerForm
            taxonomies={taxonomies}
            categories={categories}
            hasSelected={hasSelected}
            onToggle={toggle}
            onSetOption={setOption}
            noScroll
          />
        </Surround>
      )}
    </>
  )
}

export default FollowingContainer
