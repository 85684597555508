import React from 'react'
import { string, node } from 'prop-types'

const LinkWrapper = ({ link, children, ...other }) =>
  link ? (
    <a aria-label="" href={link} {...other}>
      {children}
    </a>
  ) : (
    children
  )

LinkWrapper.propTypes = {
  link: string,
  children: node.isRequired
}

export default LinkWrapper
