import React from 'react'
import classNames from 'classnames'
import { array, arrayOf, bool, number, object, string } from 'prop-types'
import { Grid, GridItem } from '../primitive/Grid'
import WidgetHeader from '../WidgetHeader'
import Card from '../ArticleCard'
import LoadMore from '../../../widgets/load-more/container/LoadMore'

import styles from './ArticleCardGrid.styl'

const gridSizeMap = {
  1: ['whole'],
  2: ['whole', 'mobile-landscape-one-half'],
  3: ['mobile-large-whole', 'mobile-landscape-one-third'],
  4: ['whole', 'mobile-large-one-half', 'desktop-one-quarter']
}
export { gridSizeMap }

const ArticleCardGrid = ({
  items,
  displayLimit = 4,
  tabletCarousel,
  gridItemProperties,
  inverseColor,
  gridSize,
  savedLists,
  hideSaveDropdown,
  title,
  link,
  platform,
  loadMore,
  loadMoreMethod,
  apiPath,
  moreIds,
  widgetJson,
  gutterless,
  className
}) => {
  const size = gridSize || gridSizeMap[displayLimit] || gridSizeMap[4]
  const isLargePreview = displayLimit === 1
  const imageConstrain = isLargePreview || items.length < 4 ? 1000 : 600

  const isGutterless = gutterless || platform === 'io'
  return (
    <div
      className={classNames(
        styles.ArticleCardGrid,
        className,
        {
          'js-load-more': loadMore
        },
        isGutterless && styles.gutterless,
        'article-card-grid'
      )}
      data-load-more-url={loadMore && apiPath}
      data-load-more-ids={loadMore && moreIds.join(',')}
      data-widget={loadMore && widgetJson}
    >
      {title && <WidgetHeader title={title} link={link} />}
      <Grid
        className={classNames(styles.Grid, {
          [styles.tabletCarousel]: tabletCarousel,
          'js-load-more-target': loadMore
        })}
        flex
        gap={4}
      >
        {items &&
          items.map((item, i) => (
            <GridItem
              className={styles.GridItem}
              sizes={size}
              key={`Card-${item._id}-${i}`}
            >
              <Card
                largePreview={isLargePreview}
                savedLists={savedLists}
                hideSaveDropdown={hideSaveDropdown}
                inverseColor={inverseColor}
                {...item}
                {...gridItemProperties}
                imageConstrain={imageConstrain}
              />
            </GridItem>
          ))}
      </Grid>

      {loadMore && (
        <LoadMore
          className={styles.LoadMoreButton}
          text="Load More"
          loadMoreMethod={loadMoreMethod}
          moreIds={moreIds}
        />
      )}
    </div>
  )
}

ArticleCardGrid.propTypes = {
  items: array.isRequired,
  displayLimit: number,
  inverseColor: bool,
  title: string,
  link: string,
  tabletCarousel: bool,
  gridItemProperties: object,
  gridSize: arrayOf(string),
  savedLists: array,
  hideSaveDropdown: bool,
  platform: string,
  loadMore: bool,
  loadMoreMethod: string,
  apiPath: string,
  moreIds: arrayOf(string),
  widgetJson: string,
  gutterless: bool,
  className: string
}

export default ArticleCardGrid
