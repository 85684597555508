import React from 'react'
import { string } from 'prop-types'
import Icon from '../primitive/Icon'

import styles from './Avatar.styl'

const Avatar = ({ name }) => {
  return (
    <div className={styles.Avatar}>
      <Icon
        className={styles.Icon}
        type="User"
        a11yText="User"
        width={40}
        height={40}
      />
      <span>{name}</span>
    </div>
  )
}

Avatar.propTypes = {
  name: string.isRequired
}

export default Avatar
