import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Divider.styl'

class Divider extends React.PureComponent {
  render() {
    const { className, children } = this.props
    return (
      <div className={classNames('divider', styles.Divider, className)}>
        {children && <div>{children}</div>}
      </div>
    )
  }
}

Divider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Divider
