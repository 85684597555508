import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import platforms from '../../../../common/video/platforms'
import VideoLoader from '../VideoLoader'
import Icon from '../primitive/Icon'

import styles from './Video.styl'

class Video extends React.Component {
  render() {
    const {
      provider,
      videos,
      header,
      previewImage,
      autoplay,
      fullSize,
      headline,
      className,
      overlayImage,
      variant = 'standard'
    } = this.props

    if (!provider) return null

    const platform = platforms[provider]
    const showLoader = header || previewImage || overlayImage
    var iframeSrc = `${platform.embedUrl(videos[0].videoId)}${
      showLoader || autoplay ? '&autoplay=1' : ''
    }`
    const Iframe = (
      <iframe
        className={styles.Video}
        src={iframeSrc}
        frameBorder="0"
        webkitallowfullscreen="webkitallowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        allowFullScreen="allowfullscreen"
        title={header || `Video for article ${headline}`}
      />
    )
    const videoLink = platform.link(videos[0].videoId)

    return (
      <>
        {variant === 'full-width' ? (
          <FullWidthVideo
            Iframe={Iframe}
            platform={platform}
            showLoader={showLoader}
            {...this.props}
          />
        ) : (
          <div
            className={classnames(!fullSize && styles.InlineVideo, className)}
          >
            <a
              aria-label={header || headline}
              href={videoLink}
              className={styles.VideoLink}
            >
              <Icon type="Play" width={6} /> {videoLink}
            </a>
            <figure className={styles.VideoContainer}>
              {showLoader ? (
                <VideoLoader title={header} previewImage={previewImage}>
                  {Iframe}
                </VideoLoader>
              ) : (
                Iframe
              )}
            </figure>
          </div>
        )}
      </>
    )
  }
}

Video.propTypes = {
  videos: PropTypes.array,
  provider: PropTypes.string,
  header: PropTypes.string,
  previewImage: PropTypes.string,
  overlayImage: PropTypes.string,
  autoplay: PropTypes.bool,
  fullSize: PropTypes.bool,
  headline: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string
}

export default Video

const FullWidthVideo = props => {
  return (
    <div className={styles.FullWidthVideoContainer}>
      <div className={styles.FullWidthCopyContainer}>
        <div className={styles.Copy}>
          {props.title && <h2 className={styles.Heading}>{props.title}</h2>}
          {props.description && (
            <div
              className={styles.Description}
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          )}
        </div>
      </div>
      <div className={styles.FullWidthVideo}>
        <div className={styles.SlantedVideoWrapper}>
          <figure className={styles.VideoContainer}>
            {props.showLoader ? (
              <VideoLoader previewImage={props.overlayImage}>
                {props.Iframe}
              </VideoLoader>
            ) : (
              props.Iframe
            )}
          </figure>
        </div>
      </div>
    </div>
  )
}

FullWidthVideo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showLoader: PropTypes.bool,
  Iframe: PropTypes.string,
  overlayImage: PropTypes.string
}
