import React from 'react'
import PropTypes from 'prop-types'
import Control from '../../../layout/component/primitive/ControlLegacy'
import Button from '../../../layout/component/primitive/Button'
import { Grid, GridItem } from '../../../layout/component/primitive/Grid'
import Notification from '../../../layout/component/primitive/Notification'
import countries from '../../../layout/lib/countries.json'
import businessTypes from '../../../layout/lib/hubspot-fields/business-types.json'
import brandIndustry from '../../../layout/lib/hubspot-fields/brand-industry.json'
import agencyType from '../../../layout/lib/hubspot-fields/agency-type.json'

import styles from './ResourcesForm.styl'

const countryList = ['United Kingdom'].concat(Object.values(countries))

const ResourcesFormWidget = props => (
  <div className={styles.Container}>
    <div className="widget widget--hubspot">
      <h2>{props.title || 'Download the article here'}</h2>
      <form
        className="js-hubspot-custom-form"
        data-form-id="4fad14d1-abf3-4a78-8775-243fa028de2d"
        data-portal-id="1725887"
        data-file-url={props.fileUrl}
      >
        <div className="js-hubspot-custom-form-success is-hidden">
          <Notification className={styles.Notification} state="success">
            Thank You!
          </Notification>
        </div>
        <div className="js-hubspot-custom-form-error is-hidden">
          <Notification className={styles.Notification} state="error">
            There was an error. Please try again.
          </Notification>
        </div>
        <div className="js-hubspot-custom-form-fields">
          <Grid>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="firstname"
                placeholder="First name"
                theme="DarkBg"
                className={styles.Control}
                required
              />
            </GridItem>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="lastname"
                placeholder="Last name"
                theme="DarkBg"
                className={styles.Control}
                required
              />
            </GridItem>
          </Grid>
          <Grid>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="company"
                placeholder="Company"
                theme="DarkBg"
                className={styles.Control}
                required
              />
            </GridItem>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="jobtitle"
                placeholder="Job title"
                theme="DarkBg"
                className={styles.Control}
                required
              />
            </GridItem>
          </Grid>
          <Control
            type="email"
            name="email"
            placeholder="Email"
            theme="DarkBg"
            className={styles.Control}
            required
          />
          <Grid>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="country"
                type="select"
                label="Country"
                theme="DarkBg"
                className={styles.Control}
                options={[
                  { value: '', text: 'Country' },
                  ...countryList.map(c => ({ text: c }))
                ]}
                required
              />
            </GridItem>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                type="tel"
                name="phone"
                placeholder="Phone Number"
                theme="DarkBg"
                className={styles.Control}
                required
              />
            </GridItem>
          </Grid>
          <Control
            name="brand_agency__c"
            type="select"
            label="Business Type"
            theme="DarkBg"
            className={styles.Control}
            options={[
              { value: '', text: 'Business type' },
              ...businessTypes.map(t => ({ text: t }))
            ]}
            required
          />
          <Grid>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="brand_industry__c"
                type="select"
                label="Brand Industry"
                theme="DarkBg"
                className={styles.Control}
                options={[
                  { value: '', text: 'Brand industry' },
                  ...brandIndustry.map(i => ({ text: i }))
                ]}
                required
              />
            </GridItem>
            <GridItem sizes={['tablet-one-half']}>
              <Control
                name="agency_type__c"
                type="select"
                label="Agency Type"
                theme="DarkBg"
                className={styles.Control}
                options={[
                  { value: '', text: 'Agency type' },
                  ...agencyType.map(t => ({ text: t }))
                ]}
                required
              />
            </GridItem>
          </Grid>
          <Control
            name="checkGroup"
            options={[
              {
                name: 'consentToProcess',
                text: (
                  <span>
                    I agree to the Contagious{' '}
                    <a
                      aria-label="privacy policy"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </span>
                ),
                value: true,
                required: true
              },
              {
                text:
                  'I confirm I would like to receive marketing offers, news and updates on Contagious membership, briefings and other products and services',
                value: 4848078
              }
            ]}
            type="checkbox"
            theme="DarkBg"
            className={styles.Control}
          />
          <Button
            ariaLabel="submit"
            className={styles.Button}
            secondary
            clipCorner
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  </div>
)

ResourcesFormWidget.propTypes = {
  title: PropTypes.string,
  fileUrl: PropTypes.string
}

export default ResourcesFormWidget
