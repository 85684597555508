import React from 'react'
import { object, func, string, bool } from 'prop-types'
import { withColor } from '../ColorContext'
import OnboardingFormLayout from '../OnboardingFormLayout'
import PasswordControl from '../primitive/Control/PasswordControl'
import IdentityControl from '../primitive/Control/IdentityControl'
import TextControl from '../primitive/Control/TextControl'
import FieldTemplate from '../primitive/FieldTemplate'
import Notice from '../primitive/Notice'
import FormErrors from '../Onboarding/FormErrors'
import CheckControl from '../primitive/Control/CheckControl'
import Loading from '../primitive/Loading'

import styles from './OnboardingForm.styl'

const OnboardingLoginForm = ({
  error,
  loading,
  formErrors = {},
  returnUrl,
  identity,
  password,
  allowPasswordInput,
  onIdentityChange,
  onPasswordChange,
  onEmailSubmit,
  onEditIdentity
}) => {
  return (
    <OnboardingFormLayout
      title={
        <h2>
          Welcome back!
          <br />
          Log in to your account
        </h2>
      }
      formAction={allowPasswordInput ? '/iq/login' : '/iq/login/type'}
      footer={<LoginFormFooter />}
      buttonText={allowPasswordInput ? 'Log in' : 'Next'}
      onSubmit={allowPasswordInput ? undefined : onEmailSubmit}
      disabled={loading}
      className={styles.Onboarding}
    >
      {!!error && (
        <Notice minimal type="error">
          {error}
        </Notice>
      )}
      <FormErrors minimal errors={formErrors} />
      <TextControl type="hidden" name="returnUrl" defaultValue={returnUrl} />
      <FieldTemplate
        label="Email address"
        controlName="identity"
        status={error && 'error'}
      >
        <IdentityControl
          name="identity"
          autoComplete="email"
          defaultValue={identity}
          status={formErrors.emailAddress && 'error'}
          onChange={onIdentityChange}
          showEdit={allowPasswordInput}
          readOnly={allowPasswordInput}
          placeholder="Email address"
          handleEditClick={onEditIdentity}
          required
        />
      </FieldTemplate>
      {loading && <Loading />}
      {!loading && allowPasswordInput && (
        <>
          <FieldTemplate
            label="Password"
            controlName="password"
            status={formErrors.password && 'error'}
          >
            <PasswordControl
              name="password"
              status={formErrors.password && 'error'}
              defaultValue={password}
              onChange={onPasswordChange}
              autoComplete="password"
              placeholder="Password"
              required
            />
            <a
              className={styles.ForgotPassword}
              href="/iq/password-set-request"
            >
              Forgotten your password?
            </a>
          </FieldTemplate>
          <div className={styles.Checkbox}>
            <CheckControl name="remember" value="true" type="checkbox" small>
              <span>Remember me</span>
            </CheckControl>
          </div>
        </>
      )}
    </OnboardingFormLayout>
  )
}

const LoginFormFooter = () => (
  <div className={styles.LoginFormFooter}>
    <p>
      <strong>Does your company have a Contagious membership?</strong> Email{' '}
      <a
        aria-label="mail to support@contagious.com"
        href="mailto:support@contagious.com"
      >
        support@contagious.com
      </a>{' '}
      for your unique registration link.
    </p>
    <p>
      <strong>Want access?</strong> <a href="/demo">Click here</a> to book a
      demo.
    </p>
  </div>
)

OnboardingLoginForm.propTypes = {
  returnUrl: string,
  identity: string,
  formErrors: object,
  error: string,
  loading: bool,
  password: string,
  allowPasswordInput: bool,
  onIdentityChange: func,
  onPasswordChange: func,
  onEmailSubmit: func,
  onEditIdentity: func
}

export default withColor(OnboardingLoginForm)
