import React from 'react'
import { node, string, bool } from 'prop-types'
import { withColor } from '../ColorContext'
import classNames from 'classnames'

import styles from './TagLabel.styl'

const TagLabel = withColor(
  ({ children, className, color, colorOverride, dark, hidden }) => {
    if (hidden) return null
    return (
      <div
        style={{ backgroundColor: colorOverride || color }}
        className={classNames(
          styles.TagLabel,
          dark && styles.DarkText,
          className
        )}
      >
        <span style={{ backgroundColor: colorOverride || color }} />
        {children}
      </div>
    )
  }
)

TagLabel.defaultProps = {}

TagLabel.propTypes = {
  children: node.isRequired,
  className: string,
  color: string,
  colorOverride: string,
  hidden: bool,
  dark: bool
}

export default TagLabel
