import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const WidgetWrapper = ({
  children,
  id,
  className,
  displayOptions = [],
  isPlaceholder = false,
  isSticky = false,
  data
}) => {
  const classes = [
    'widget-wrapper',
    'js-widget',
    { 'js-widget-placeholder': isPlaceholder },
    { 'js-sticky sticky': isSticky },
    className
  ].concat(displayOptions.map(mode => `js-widget--${mode} is-visible--${mode}`))
  return (
    <div className={classNames(classes)} data-widget-id={id} data-props={data}>
      {children}
    </div>
  )
}

WidgetWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  displayOptions: PropTypes.array,
  isPlaceholder: PropTypes.bool,
  isSticky: PropTypes.bool,
  children: PropTypes.node,
  data: PropTypes.node
}

export default WidgetWrapper
