import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HeadingSlash from '../primitive/HeadingSlash'
import { Grid, GridItem } from '../primitive/Grid'
import { withColor } from '../ColorContext'
import ButtonSimple from '../primitive/ButtonSimple'
import versionPath from '../../../../../site/lib/version-path'
import Icon from '../primitive/Icon'
import CollectionList from '../CollectionList'
import SaveArticle from '../SaveArticle'
import styles from './CollectionPreview.styl'

const Wrapper = ({ href, classes, children }) =>
  href ? (
    <a aria-label="" href={href} className={classes}>
      {children}
    </a>
  ) : (
    <div className={classes}>{children}</div>
  )

Wrapper.propTypes = {
  href: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired
}

class CollectionPreview extends PureComponent {
  constructor(...args) {
    super(...args)
    this.state = {
      isMoreShowing: false
    }
    this.previewRef = React.createRef()
    this.onViewMore = this.onViewMore.bind(this)
    this.onViewLess = this.onViewLess.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.scrollToPreviewRef = this.scrollToPreviewRef.bind(this)
  }

  onViewMore() {
    this.setState({ isMoreShowing: true })
  }

  onViewLess() {
    this.setState({ isMoreShowing: false }, () => {
      this.scrollToPreviewRef()
    })
  }

  onDelete(e) {
    e.preventDefault()
    this.props.onDelete()
  }

  scrollToPreviewRef() {
    this.previewRef.current.scrollIntoView()
  }

  render() {
    const { isMoreShowing } = this.state
    const {
      className,
      collectionId,
      imageSource,
      heading,
      description,
      nameSubtitle,
      dateSubtitle,
      href,
      collectionList,
      collectionHero,
      collectionPreview,
      savedLists,
      canEdit,
      canAddItems,
      onAddComment,
      onAddHeading,
      onAddLink,
      onShowEmbedModal,
      onDelete,
      editUrl,
      color,
      user,
      items,
      landscapeImages,
      hideSaveDropdown,
      onRemoveItem,
      gridClass
    } = this.props
    const classes = classNames(
      styles.CollectionPreview,
      collectionList && styles.collectionList,
      collectionHero && styles.collectionHero,
      landscapeImages && styles.landscapeImages,
      className
    )
    const itemCount = items && items.length
    return (
      <div ref={this.previewRef}>
        <Wrapper href={href} classes={classes}>
          {heading && collectionPreview && (
            <h3 className={styles.Subheading}>
              <HeadingSlash colorOverride={color}>{heading}</HeadingSlash>
            </h3>
          )}
          <Grid
            gap={4}
            flex
            className={classNames(styles.GridWrapper, gridClass)}
          >
            <GridItem
              sizes={[
                collectionHero && !collectionPreview
                  ? 'tablet-two-fifths'
                  : 'tablet-one-third'
              ]}
            >
              <div className={styles.ImageWrapper}>
                <div
                  className={classNames(
                    styles.Image,
                    Array.isArray(imageSource) &&
                      imageSource.length >= 4 &&
                      styles.grid
                  )}
                >
                  {/* if `imageSource` is an array of images and there are 4 or more, display a 2x2 grid */}
                  {/* if `imageSource is an array of images and there are less than 4, just show the first one */}
                  {/* if `imageSource` is not an array, it is the source of a single image, just show that */}
                  {Array.isArray(imageSource) ? (
                    imageSource.length >= 4 ? (
                      <div className={styles.ImageGrid}>
                        <div
                          className={styles.SingleImage}
                          style={{ backgroundImage: `url(${imageSource[0]})` }}
                        />
                        <div
                          className={styles.SingleImage}
                          style={{ backgroundImage: `url(${imageSource[1]})` }}
                        />
                        <div
                          className={styles.SingleImage}
                          style={{ backgroundImage: `url(${imageSource[2]})` }}
                        />
                        <div
                          className={styles.SingleImage}
                          style={{ backgroundImage: `url(${imageSource[3]})` }}
                        />
                      </div>
                    ) : (
                      <div
                        className={styles.SingleImage}
                        style={{
                          backgroundImage: `url(${imageSource[0] ||
                            versionPath(
                              '/asset/img/content/collection/default.png'
                            )})`
                        }}
                      />
                    )
                  ) : (
                    <div
                      className={styles.SingleImage}
                      style={{
                        backgroundImage: `url(${imageSource ||
                          versionPath(
                            '/asset/img/content/collection/default.png'
                          )})`
                      }}
                    />
                  )}
                </div>
              </div>
            </GridItem>
            <GridItem
              sizes={[
                collectionHero && !collectionPreview
                  ? 'tablet-three-fifths'
                  : 'tablet-two-thirds'
              ]}
            >
              <div
                className={classNames(
                  styles.ArticleText,
                  collectionPreview && styles.ArticleTextPreview
                )}
              >
                <div className={styles.CollectionMetaWrapper}>
                  {!!itemCount && (
                    <div
                      className={styles.ItemCount}
                    >{`${itemCount} items`}</div>
                  )}
                  {collectionHero && (
                    <div className={styles.CollectionMeta}>
                      {canEdit && (
                        <a aria-label="edit" href={editUrl}>
                          Edit
                        </a>
                      )}
                    </div>
                  )}
                </div>
                {dateSubtitle && !collectionHero && (
                  <p className={styles.Date}>{dateSubtitle}</p>
                )}
                {heading && !collectionPreview && (
                  <h3 className={styles.Heading}>
                    <HeadingSlash colorOverride={color}>{heading}</HeadingSlash>
                  </h3>
                )}
                {!collectionHero && (
                  <p className={styles.Author}>{nameSubtitle}</p>
                )}
                {description && (
                  <p className={styles.ArticleDescription}>{description}</p>
                )}
                {!collectionHero && (
                  <>
                    {!hideSaveDropdown && (
                      <SaveArticle
                        type="collection"
                        itemId={collectionId}
                        name={heading}
                        savedLists={savedLists}
                        onRemoveItem={onRemoveItem}
                      />
                    )}

                    {onDelete && (
                      <ButtonSimple
                        className={styles.DeleteButton}
                        outline
                        onClick={this.onDelete}
                        ariaLabel="delete"
                      >
                        <Icon
                          width={16}
                          height={16}
                          type="Delete"
                          a11yText="Delete"
                        />
                      </ButtonSimple>
                    )}
                  </>
                )}
                {collectionHero && canAddItems && (
                  <div className={styles.CollectionActionButtons}>
                    <ButtonSimple
                      ariaLabel="add comment"
                      onClick={onAddComment}
                    >
                      Add Comment
                    </ButtonSimple>
                    <ButtonSimple
                      ariaLabel="add heading"
                      onClick={onAddHeading}
                    >
                      Add Heading
                    </ButtonSimple>
                    <ButtonSimple ariaLabel="add link" onClick={onAddLink}>
                      Add Link
                    </ButtonSimple>
                    <ButtonSimple
                      ariaLabel="Embed Collection"
                      onClick={onShowEmbedModal}
                    >
                      Embed Collection
                    </ButtonSimple>
                  </div>
                )}
              </div>
            </GridItem>
          </Grid>
          {collectionPreview && (
            <>
              {items && items.length > 0 && !isMoreShowing && (
                <ButtonSimple
                  className={classNames(
                    styles.ControlButton,
                    styles.ShowItemsButton
                  )}
                  onClick={this.onViewMore}
                  ariaLabel="view more"
                >
                  View More
                </ButtonSimple>
              )}
              {isMoreShowing && (
                <>
                  <CollectionList
                    disableReorder
                    disableDelete
                    items={items || []}
                    collectionId={collectionId}
                    user={user}
                    savedLists={savedLists}
                  />
                  <ButtonSimple
                    ariaLabel="view less"
                    className={styles.ViewLessButton}
                    onClick={this.onViewLess}
                  >
                    View Less
                  </ButtonSimple>
                </>
              )}
            </>
          )}
        </Wrapper>
      </div>
    )
  }
}

CollectionPreview.propTypes = {
  className: PropTypes.string,
  imageSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  nameSubtitle: PropTypes.string,
  dateSubtitle: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  collectionId: PropTypes.string,
  collectionList: PropTypes.bool,
  collectionHero: PropTypes.bool,
  collectionPreview: PropTypes.bool,
  savedLists: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  canAddItems: PropTypes.bool,
  onAddComment: PropTypes.func,
  onAddHeading: PropTypes.func,
  onAddLink: PropTypes.func,
  onShowEmbedModal: PropTypes.func,
  onDelete: PropTypes.func,
  editUrl: PropTypes.string,
  color: PropTypes.string,
  itemCount: PropTypes.number,
  items: PropTypes.array,
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organisation: PropTypes.string
  }),
  landscapeImages: PropTypes.bool,
  hideSaveDropdown: PropTypes.bool,
  onRemoveItem: PropTypes.func,
  gridClass: PropTypes.string
}

export default withColor(CollectionPreview)
