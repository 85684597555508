const platforms = {
  youtube: {
    name: 'YouTube Video',
    link: id => `https://www.youtube.com/watch?v=${id}`,
    embedUrl: id => `https://www.youtube.com/embed/${id}?showinfo=0&rel=0`,
    isPlaylist: false
  },
  youtubePlaylist: {
    name: 'YouTube Playlist',
    link: id => `https://www.youtube.com/playlist?list=${id}`,
    embedUrl: id =>
      `https://www.youtube.com/embed/videoseries?list=${id}&showinfo=0&rel=0`,
    isPlaylist: true
  },
  vimeo: {
    name: 'Vimeo',
    link: id => `https://vimeo.com/${id}`,
    embedUrl: id =>
      `https://player.vimeo.com/video/${id}?color=ff0096&title=0&byline=0&portrait=0`,
    isPlaylist: false
  },
  viddler: {
    name: 'Viddler',
    link: id => `https://viddler.com/embed/${id}`,
    embedUrl: id => `https://viddler.com/embed/${id}`,
    isPlaylist: false
  }
}

module.exports = platforms
