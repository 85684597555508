import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Figure from '../primitive/Figure'
import Carousel from '../Carousel'
import Icon from '../primitive/Icon'
import Lightbox from '../Lightbox'
import Video from '../video'
import { withColor } from '../ColorContext'

import styles from './ArticleHero.styl'

class ArticleHero extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showingLightbox: false
    }

    this.toggleLightbox = this.toggleLightbox.bind(this)
  }

  toggleLightbox() {
    this.setState({
      showingLightbox: !this.state.showingLightbox
    })
  }

  render() {
    var { images } = this.props

    if (!images.length) return null

    const { fullWidth, provider, videoLink, color, headline } = this.props
    const imageLimit = videoLink ? 1 : images.length

    images = images.slice(0, imageLimit)

    return (
      <div className={classNames(styles.Hero, fullWidth && styles.FullWidth)}>
        {images.length > 1 ? (
          <Carousel autoplay className="ArticleHero">
            {images.map((image, i) => (
              <Figure
                key={`image${i}`}
                constrained={image.constrained}
                imgUrls={image.imgUrls}
                alt={image.caption || `Header image for article ${headline}`}
                caption={image.caption}
                credits={image.credits}
                additionalClassName="carousel-cell"
              />
            ))}
          </Carousel>
        ) : (
          <Figure
            constrained={images[0].constrained}
            imgUrls={images[0].imgUrls}
            alt={images[0].caption || `Header image for article ${headline}`}
            caption={images[0].caption}
            credits={images[0].credits}
          />
        )}
        {videoLink && (
          <span className={styles.VideoLink} onClick={this.toggleLightbox}>
            <div
              className={styles.VideoLinkIcon}
              style={color && { backgroundColor: color }}
            >
              <Icon type="Play" />
            </div>
          </span>
        )}
        {this.state.showingLightbox && (
          <Lightbox toggle={this.toggleLightbox}>
            <Video
              provider={provider}
              videos={[{ videoId: videoLink }]}
              autoplay
            />
          </Lightbox>
        )}
      </div>
    )
  }
}

ArticleHero.propTypes = {
  images: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  provider: PropTypes.string,
  videoLink: PropTypes.string,
  color: PropTypes.string,
  platform: PropTypes.string,
  headline: PropTypes.string.isRequired
}

export default withColor(ArticleHero)
