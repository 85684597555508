import renderComponent from '../lib/component-renderer'
import ArticleFooter from '../../../site/layout/component/ArticleFooter'

const debug = require('debug')('article footer')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article-footer')
    renderComponent('.js-article-footer', ArticleFooter)
  })
}
