import React, { useRef } from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import classNames from 'classnames'
import useEscapeKey from '../../lib/hook/useEscapeKey'
import useOutsideClick from '../../lib/hook/useOutsideClick'
import HeadingSlash from '../primitive/HeadingSlash'
import ScrollArea from '../primitive/ScrollArea'
import ButtonSimple from '../primitive/ButtonSimple'
import IconButton from '../primitive/IconButton'
import Loading from '../primitive/Loading'

import styles from './AlertsPopover.styl'

const AlertsPopover = ({ items, open, loading, onClose, onClear }) => {
  if (!open) return null

  const handleClose = () => {
    onClose && onClose()
  }

  const wrapper = useRef()
  useEscapeKey(open && handleClose)
  useOutsideClick(wrapper, open && handleClose)

  const hasItems = !!items && items.length > 0
  return (
    <div
      className={classNames(styles.AlertsPopover, open && styles.open)}
      ref={wrapper}
    >
      <div className={styles.Header}>
        <div className={styles.Title}>
          <HeadingSlash>Selected for you</HeadingSlash>
        </div>
        {onClose && (
          <IconButton
            type="button"
            icon="Close"
            transparent
            iconWidth={14}
            iconHeight={14}
            small
            onClick={onClose}
          />
        )}
      </div>
      <div className={styles.ContentWrapper}>
        <ScrollArea className={styles.Content}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {hasItems ? (
                items.map(item => (
                  <AlertsPopoverItem {...item} key={`AlertsItem-${item._id}`} />
                ))
              ) : (
                <p className={styles.Empty}>You’re all caught up</p>
              )}
            </>
          )}
        </ScrollArea>
      </div>
      <div className={styles.Actions}>
        <ButtonSimple
          ariaLabel="See all"
          className={styles.Action}
          href="/iq/feed"
        >
          See all
        </ButtonSimple>
        {hasItems && (
          <ButtonSimple
            ariaLabel="Clear notifications"
            className={styles.Action}
            onClick={onClear}
          >
            Clear notifications
          </ButtonSimple>
        )}
      </div>
    </div>
  )
}

const AlertsPopoverItem = ({
  category,
  shortHeadline,
  __fullUrlPath,
  brand,
  author,
  imageUrl
}) => {
  const getAuthorOrBrand = () => {
    if (brand) return brand
    if (author) return author.name
    return null
  }

  return (
    <a aria-label={shortHeadline} href={__fullUrlPath} className={styles.Item}>
      <div
        className={styles.Image}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={styles.Details}>
        <h3 className={styles.Heading}>
          <HeadingSlash>{shortHeadline}</HeadingSlash>
        </h3>
        <p className={styles.Author}>{getAuthorOrBrand()}</p>
        {category && <p className={styles.Because}>{category}</p>}
      </div>
    </a>
  )
}

const alertItemShape = {
  __fullUrlPath: string,
  shortHeadline: string,
  brand: string,
  author: string,
  imageUrl: string,
  category: string
}

AlertsPopoverItem.propTypes = alertItemShape

AlertsPopover.propTypes = {
  onClose: func,
  onClear: func,
  open: bool,
  loading: bool,
  items: arrayOf(shape(alertItemShape))
}

export { AlertsPopoverItem, AlertsPopover as default }
