import renderComponent from '../lib/component-renderer'

import StaticCarousel from '../../../site/widgets/static-carousel/component/StaticCarousel'

const debug = require('debug')('static-carousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init static-carousel')
    renderComponent('.js-static-carousel', StaticCarousel)
  })
}
