import React from 'react'
import { string } from 'prop-types'

import Icon from '../../../primitive/Icon'

import styles from './NavLogo.styl'

const NavLogo = ({ label, url }) => (
  <a
    aria-label="home"
    href={url}
    className={styles.NavLogo}
    data-track="HomeButton"
  >
    <Icon
      type="Logo"
      a11yText={label}
      className={styles.NavLogoIcon}
      width={65}
    />
  </a>
)

NavLogo.propTypes = {
  label: string,
  url: string
}

export default NavLogo
