import React from 'react'
import renderComponent from '../lib/component-renderer'

import SavedLists from '../../../../components/site/saved-list/component/SavedLists'
import SavedListsContainer from './container/SavedLists'

const debug = require('debug')('saved-lists')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Saved Lists')
    renderComponent('.js-saved-lists', props => (
      <SavedListsContainer {...props} component={SavedLists} />
    ))
  })
}
