import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../primitive/Button'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'
import styles from './ArticleCarousel.styl'
import { Grid, GridItem } from '../primitive/Grid'
import TagLabel from '../TagLabel'

const Slide = ({ article }) => (
  <a
    href={article.__fullUrlPath}
    key={`article${article._id}`}
    className={styles.Slide}
  >
    <Grid gap={8}>
      <GridItem sizes={['desktop-one-half']}>
        <img className={styles.Image} src={article.imageUrl} />
      </GridItem>
      <GridItem sizes={['desktop-one-half']}>
        {(article.eyebrow || article.__displayDate) && (
          <div className={styles.Date}>
            {article.eyebrow || article.__displayDate.date}
          </div>
        )}
        <h2 className={styles.Title}>
          <HeadingSlash>{article.shortHeadline}</HeadingSlash>
        </h2>
        {article.sell && <p className={styles.Description}>{article.sell}</p>}
      </GridItem>
    </Grid>
    {article.label && (
      <TagLabel className={styles.Tag} {...article.labelConfig}>
        {article.labelConfig ? article.labelConfig.displayName : article.label}
      </TagLabel>
    )}
  </a>
)

Slide.propTypes = {
  article: PropTypes.object
}

class ArticleCarousel extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: false,
      pageDots: true,
      imagesLoaded: true,
      adaptiveHeight: false
    })

    // https://github.com/metafizzy/flickity/issues/318
    this.timeout = setTimeout(() => {
      this.carousel.resize()
    }, 50)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { articles } = this.props
    const count = articles.length
    if (articles.length === 0) return
    return (
      <div
        className={classnames(
          styles.ArticleCarousel,
          count === 1 && styles.single
        )}
      >
        {count > 1 && (
          <>
            <div
              ref={carousel => {
                this.carousel = carousel
              }}
            >
              {articles.map((article, i) => (
                <Slide key={`slide${i}`} article={article} />
              ))}
            </div>
            <Button
              icon
              className={classnames(styles.NavButton, styles.NavPrev)}
              onClick={() => {
                this.carousel.previous()
              }}
              ariaLabel="previous"
            >
              <Icon type="ChevronLeft" />
            </Button>
            <Button
              icon
              className={classnames(styles.NavButton, styles.NavNext)}
              onClick={() => {
                this.carousel.next()
              }}
              ariaLabel="next"
            >
              <Icon type="ChevronRight" />
            </Button>
          </>
        )}
        {count === 1 && <Slide article={articles[0]} />}
      </div>
    )
  }
}

ArticleCarousel.propTypes = {
  articles: PropTypes.array
}

export default ArticleCarousel
