import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Figure.styl'

const baseClass = styles.Figure

const Figure = ({
  constrained,
  imgUrls,
  alt,
  caption,
  credits,
  additionalClassName,
  landscape,
  lazy
}) => {
  return (
    <figure
      className={classNames(
        baseClass,
        additionalClassName,
        landscape && styles.landscape
      )}
    >
      <div>
        <img
          loading={lazy ? 'lazy' : 'eager'}
          srcSet={imgUrls}
          sizes="(max-width: 410px) 250px, (max-width: 768px) 375px, 500px"
          src={constrained}
          alt={alt}
        />
        {caption && (
          <figcaption className={styles.Caption}>{caption}</figcaption>
        )}
      </div>
      {credits && <figcaption className={styles.Credits}>{credits}</figcaption>}
    </figure>
  )
}

Figure.propTypes = {
  constrained: PropTypes.string,
  imgUrls: PropTypes.array.isRequired,
  alt: PropTypes.string,
  additionalClassName: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  landscape: PropTypes.bool,
  lazy: PropTypes.bool
}

export default Figure
