import React, { useState } from 'react'
import { shape, func, bool, string } from 'prop-types'
import classNames from 'classnames'
import Modal from '../Modal'
import ToggleControl from '../ToggleControl'
import { withColor } from '../ColorContext'
import Notice from '../primitive/Notice'
import HeadingSlash from '../primitive/HeadingSlash'
import TextControl from '../primitive/Control/TextControl'
import ButtonSimple from '../primitive/ButtonSimple'
import ScrollArea from '../primitive/ScrollArea'
import Icon from '../primitive/Icon'
import Loading from '../primitive/Loading'
import Avatar from '../Avatar'

import styles from './ShareListModal.styl'

const ShareListModal = ({
  list,
  onClose,
  onChangeCollaborative,
  onRemoveUser,
  error,
  success,
  loading
}) => {
  const [copied, setCopied] = useState(false)
  const handleChangeCollaborative = ({ target: { value } }) => {
    onChangeCollaborative(!JSON.parse(value))
  }

  const copyToClipboard = content => {
    const el = document.createElement('textarea')
    el.value = content
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const users = [list.__user, ...list.__users]

  return (
    <Modal
      onClose={onClose}
      className={styles.ShareListModal}
      rounded
      fullScreenMobile
    >
      <h2>
        <HeadingSlash>Share</HeadingSlash>
      </h2>
      <ButtonSimple
        className={styles.CloseButton}
        transparent
        onClick={onClose}
        ariaLabel="close modal"
      >
        <Icon width={24} height={24} type="Close" a11yText="Close modal" />
      </ButtonSimple>

      <p className={styles.Subtitle}>
        You can share the list “{list.name}” with anyone who currently has an
        active Contagious subscription
      </p>

      {!!success && <Notice type="success">Your list has been saved.</Notice>}
      {error && (
        <Notice minimal type="error">
          {error}
        </Notice>
      )}
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h3>Share Link</h3>
          <div className={styles.CopyUrl}>
            <TextControl type="url" name="link" value={list.shareLink} />
            <div
              className={classNames(styles.CopyButton, copied && styles.copied)}
            >
              <span>Copied!</span>
              <ButtonSimple
                onClick={() => copyToClipboard(list.shareLink)}
                outline
                ariaLabel="copy link"
              >
                Copy Link
              </ButtonSimple>
            </div>
          </div>
          <div className={styles.SettingsLabel}>Settings</div>
          <div className={styles.Settings}>
            <div className={styles.Setting}>
              <div className={styles.SettingLabel}>
                <h4>Make this board collaborative</h4>
                <p>To allow others to add or remove items.</p>
              </div>
              <ToggleControl
                id="collaborative"
                name="collaborative"
                value={list.collaborative.toString()}
                label="collaborative"
                checked={list.collaborative}
                onChange={handleChangeCollaborative}
              />
            </div>

            <div className={styles.SettingLabel}>
              <h4>Users sharing this list</h4>
            </div>
            <ScrollArea className={styles.Users}>
              {users.map((user, i) => (
                <div className={styles.UserWrapper} key={user._id}>
                  <Avatar name={`${user.firstName} ${user.lastName}`} />
                  {i === 0 ? (
                    <span>Owner</span>
                  ) : (
                    <>
                      <span>Collaborator</span>
                      <ButtonSimple
                        ariaLabel="remove"
                        onClick={() => onRemoveUser(user._id)}
                      >
                        Remove
                      </ButtonSimple>
                    </>
                  )}
                </div>
              ))}
            </ScrollArea>
          </div>
        </div>
      )}
    </Modal>
  )
}

ShareListModal.propTypes = {
  list: shape({ name: string.isRequired }).isRequired,
  onClose: func.isRequired,
  onChangeCollaborative: func.isRequired,
  onRemoveUser: func.isRequired,
  loading: bool.isRequired,
  error: string,
  success: bool
}

export default withColor(ShareListModal)
