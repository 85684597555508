import React from 'react'
import PropTypes from 'prop-types'
import TrialForm from '../../../layout/component/TrialForm'
import Spacer from '../../../layout/component/primitive/Spacer'

const TrialFormWidget = props => {
  return (
    <div className="widget widget--trial-form" id="trial-form">
      <Spacer>
        <TrialForm
          returnUrl={props.returnUrl}
          reCaptchaKey={props.reCaptchaKey}
        />
      </Spacer>
    </div>
  )
}

TrialFormWidget.propTypes = {
  returnUrl: PropTypes.string,
  reCaptchaKey: PropTypes.string.isRequired
}

export default TrialFormWidget
