import React, { useEffect, useRef, useState } from 'react'
import { func, arrayOf, bool, object, number, string, array } from 'prop-types'
import classnames from 'classnames'
import useEscapeKey from '../../../lib/hook/useEscapeKey'
import debounce from 'lodash.debounce'

import Headroom from 'react-headroom'

import styles from './ExploreNavigation.styl'

import NavToggle from './component/NavToggle'
import NavLogo from './component/NavLogo'
import NavSearch from './component/NavSearch'
import NavLinks from './component/NavLinks'
import SlideNav from '../../SlideNav'
import ButtonSimple from '../../primitive/ButtonSimple'
import TickerBar from './component/TickerBar'
import DesktopNavItems from './component/DesktopNavItems'
import Icon from '../../primitive/Icon'

const ExploreNavigation = ({
  categorySuggestions,
  contentSuggestions,
  suggestionsLoading,
  onSearch,
  minimal,
  newFollowingCount,
  panelLinks,
  sticky,
  onboarding,
  inviteLink,
  tickerBar,
  caseStudiesArticles,
  learningArticles
}) => {
  const [expanded, setExpanded] = useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)

  const [isTransparent, setIsTransparent] = useState(sticky)
  const toggle = useRef()
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  useEffect(() => {
    if (sticky) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleScroll = debounce(() => {
    const currentScrollPos =
      typeof window !== 'undefined' ? window.pageYOffset : 0
    if (currentScrollPos > 50) {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }
  }, 10)

  const handleToggleExpanded = () => {
    setExpanded(!expanded)
  }

  const handleCloseExpanded = () => {
    setExpanded(false)
    toggle.current && toggle.current.focus()
  }

  useEscapeKey(expanded && handleCloseExpanded)

  return (
    <Headroom
      disable={sticky}
      className={classnames(styles.HeadroomContainer, sticky && styles.sticky)}
    >
      <div
        className={classnames(
          styles.ExploreNavigation,
          expanded && styles.expanded,
          isTransparent && styles.transparent
        )}
      >
        {!minimal && !onboarding && tickerBar && <TickerBar {...tickerBar} />}
        <div className={styles.ExploreNavigationInner}>
          {!minimal && <div className={styles.MobileNavSpacer} />}
          <NavLogo label="Contagious IQ" url="/iq" />
          {!minimal && (
            <NavToggle
              setRef={toggle}
              smallScreen
              expanded={expanded}
              onClick={handleToggleExpanded}
            />
          )}

          {!minimal && (
            <>
              <div className={styles.ExploreNavigationCenter}>
                <div className={styles.ExploreNavigationSearch}>
                  <NavSearch
                    onSearch={onSearch}
                    mobileSearchOpen={mobileSearchOpen}
                    setMobileSearchOpen={setMobileSearchOpen}
                    suggestionsLoading={suggestionsLoading}
                    suggestions={{
                      categories: categorySuggestions,
                      content: contentSuggestions
                    }}
                    isSearchFocus={isSearchFocus}
                    setIsSearchFocus={setIsSearchFocus}
                  />
                </div>
              </div>

              <div className={styles.ExploreNavigationLinks}>
                <NavLinks newFollowingCount={newFollowingCount} />
              </div>
            </>
          )}
          {onboarding && (
            <nav className={styles.OnboardingNav}>
              <ButtonSimple
                ariaLabel="get in touch"
                className={styles.TextButton}
                href="mailto:support@contagious.com"
              >
                Get in touch
              </ButtonSimple>
              {inviteLink && (
                <ButtonSimple ariaLabel="register" href={inviteLink}>
                  Register
                </ButtonSimple>
              )}
            </nav>
          )}
        </div>
        {!minimal && (
          <DesktopNavItems
            panelLinks={panelLinks}
            caseStudiesArticles={caseStudiesArticles}
            learningArticles={learningArticles}
            isSearchFocus={isSearchFocus}
          />
        )}
      </div>
      {!minimal && (
        <div className={styles.ExploreNavigationMobileSearch}>
          <div className={styles.NavSearchToggle}>
            <button
              aria-label="Reveal Search"
              className={styles.NavSearchToggleButton}
              onClick={() => setMobileSearchOpen(true)}
            >
              <Icon
                width={16}
                height={16}
                type="Search"
                a11yText="Reveal Search"
              />
              <span>Search</span>
            </button>
          </div>
        </div>
      )}
      <SlideNav
        onClose={handleCloseExpanded}
        links={panelLinks}
        expanded={expanded}
      />
    </Headroom>
  )
}

ExploreNavigation.defaultProps = {
  secondaryLinks: {}
}

ExploreNavigation.propTypes = {
  onSearch: func,
  categorySuggestions: arrayOf(object),
  contentSuggestions: object,
  suggestionsLoading: bool,
  minimal: bool,
  newFollowingCount: number,
  panelLinks: object,
  sticky: bool,
  onboarding: bool,
  inviteLink: string,
  tickerBar: object,
  caseStudiesArticles: array,
  learningArticles: array
}

export default ExploreNavigation
