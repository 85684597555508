import React, { Fragment } from 'react'
import { array, bool, string, number, func } from 'prop-types'
import ArticleCardGrid from '../ArticleCardGrid'
import Loading from '../primitive/Loading'
import HeadingSlash from '../primitive/HeadingSlash'
import Notice from '../primitive/Notice'
import ButtonSimple from '../primitive/ButtonSimple'
import Spacer from '../primitive/Spacer'
import Pagination from '../../component/Pagination'

import styles from './FollowedArticles.styl'

const FollowedArticles = ({
  articles,
  loading,
  error,
  page,
  pages,
  onPageChange,
  savedLists
}) => (
  <Fragment>
    {!!error && (
      <Notice minimal type="error">
        {error}
      </Notice>
    )}
    <div className={styles.Heading}>
      <div className={styles.HeadingInner}>
        <h2>
          <HeadingSlash className={styles.Slash}>Selected for you</HeadingSlash>
        </h2>
        <ButtonSimple href="/iq/profile#following" ariaLabel="Manage interests">
          Manage interests
        </ButtonSimple>
      </div>
      <p>Keep alert with all the content relevant to your interests</p>
    </div>

    {loading ? (
      <Loading />
    ) : (
      <>
        {!articles || articles.length === 0 ? (
          <p data-testid="empty-copy">You are not following any categories</p>
        ) : (
          <ArticleCardGrid
            items={articles}
            displayLimit={4}
            savedLists={savedLists}
            gutterless
          />
        )}
      </>
    )}
    {pages > 1 && (
      <Spacer>
        <Pagination
          data-testid="pagination"
          total={pages}
          currentPage={page}
          onChange={onPageChange}
        />
      </Spacer>
    )}
  </Fragment>
)

FollowedArticles.propTypes = {
  articles: array.isRequired,
  loading: bool,
  error: string,
  page: number,
  pages: number,
  onPageChange: func,
  savedLists: array.isRequired
}

export default FollowedArticles
