import React, { PureComponent } from 'react'
import { bool, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './IconButton.styl'

import Icon from '../Icon'

export class IconButton extends PureComponent {
  render() {
    const {
      a11yText,
      className,
      href,
      icon,
      iconHeight,
      iconWidth,
      increaseHitArea,
      rounded,
      small,
      text,
      transparent,
      ...other
    } = this.props

    const IconButtonEl = href ? 'a' : 'button'

    return (
      <IconButtonEl
        className={classNames(
          styles.IconButton,
          increaseHitArea && styles.increaseHitArea,
          rounded && styles.rounded,
          small && styles.small,
          transparent && styles.transparent,
          className
        )}
        href={href}
        {...other}
      >
        <div className={styles.IconButtonInner}>
          <Icon
            type={icon}
            height={iconHeight}
            width={iconWidth}
            a11yText={a11yText}
          />
          {text && <span className={styles.IconButtonText}>{text}</span>}
        </div>
      </IconButtonEl>
    )
  }
}

IconButton.displayName = 'IconButton'

IconButton.propTypes = {
  a11yText: string,
  className: string,
  href: string,
  icon: string.isRequired,
  iconHeight: number,
  iconWidth: number,
  increaseHitArea: bool,
  rounded: bool,
  small: bool,
  text: string,
  transparent: bool
}

export default IconButton
