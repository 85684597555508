import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Grid, GridItem } from '../primitive/Grid'
import HeadingSlash from '../primitive/HeadingSlash'
import BlockTextTrio from '../BlockTextTrio'

import styles from './TabbedPageIntro.styl'

const Tab = ({ children, link }) => {
  if (typeof window !== 'undefined' && window.location.pathname === link) {
    return (
      <div className={classNames(styles.Tab, styles.active)}>{children}</div>
    )
  }

  return (
    <a aria-label="tabbed page intro" href={link} className={styles.Tab}>
      {children}
    </a>
  )
}

Tab.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string
}

const TabbedPageIntro = props => {
  const {
    leftLink,
    leftLabel,
    rightLink,
    rightLabel,
    imageUrl,
    preheading,
    blockHeadline1,
    blockSell1,
    blockHeadline2,
    blockSell2,
    blockHeadline3,
    blockSell3
  } = props

  return (
    <div className={styles.TabbedPageIntro}>
      <div className={styles.Tabs}>
        <Tab link={leftLink}>{leftLabel}</Tab>
        <Tab link={rightLink}>{rightLabel}</Tab>
      </div>
      <div className={styles.Content}>
        {imageUrl && (
          <Grid gap={8}>
            <GridItem sizes={['desktop-three-eighths']}>
              {preheading && <p className={styles.Sell}>{preheading}</p>}

              <p className={styles.Sell}>
                <h3 className={styles.Headline}>
                  <HeadingSlash unstyled>{blockHeadline1}</HeadingSlash>
                </h3>{' '}
                {blockSell1}
              </p>
              <p className={styles.Sell}>
                <h3 className={styles.Headline}>
                  <HeadingSlash unstyled>{blockHeadline2}</HeadingSlash>
                </h3>{' '}
                {blockSell2}
              </p>
              <p className={styles.Sell}>
                <h3 className={styles.Headline}>
                  <HeadingSlash unstyled>{blockHeadline3}</HeadingSlash>
                </h3>{' '}
                {blockSell3}
              </p>
            </GridItem>
            <GridItem sizes={['desktop-five-eighths']}>
              <img src={imageUrl} />
            </GridItem>
          </Grid>
        )}
        {!imageUrl && (
          <BlockTextTrio
            blockStyle="transparent"
            blocks={[
              {
                category: blockHeadline1,
                content: blockSell1
              },
              {
                category: blockHeadline2,
                content: blockSell2
              },
              {
                category: blockHeadline3,
                content: blockSell3
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}

TabbedPageIntro.propTypes = {
  leftLink: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLink: PropTypes.string,
  rightLabel: PropTypes.string,
  imageUrl: PropTypes.string,
  preheading: PropTypes.string,
  blockHeadline1: PropTypes.string,
  blockSell1: PropTypes.string,
  blockHeadline2: PropTypes.string,
  blockSell2: PropTypes.string,
  blockHeadline3: PropTypes.string,
  blockSell3: PropTypes.string
}

export default TabbedPageIntro
