import React from 'react'
import { string, object, array, number, func, bool } from 'prop-types'
import SectionHeading from '../primitive/SectionHeading'
import SearchResults from '../SearchResults'

const Search = ({
  term,
  labels,
  filters,
  articles = [],
  title,
  showFilters,
  count,
  onFilterChange,
  onLabelChange,
  onRefresh,
  onSort,
  onPageChange,
  sort,
  loading,
  error,
  pages,
  currentPage,
  savedLists
}) => {
  return (
    <>
      <SectionHeading
        title={title || 'Search results'}
        description={
          loading ? (
            'Loading...'
          ) : (
            <p onClick={onRefresh}>
              <strong>
                {count ? count : articles.length} article{count > 1 ? 's' : ''}
              </strong>
              {term ? ` found for “${term}”` : ''}
            </p>
          )
        }
      />
      <SearchResults
        labels={labels}
        filters={filters}
        articles={articles}
        count={count}
        showFilters={showFilters}
        onFilterChange={onFilterChange}
        onLabelChange={onLabelChange}
        onSort={onSort}
        onPageChange={onPageChange}
        sort={sort}
        loading={loading}
        error={error}
        pages={pages}
        currentPage={currentPage}
        savedLists={savedLists}
      />
    </>
  )
}

Search.propTypes = {
  term: string,
  labels: array.isRequired,
  filters: object.isRequired,
  articles: array.isRequired,
  showFilters: bool,
  title: string,
  count: number,
  onFilterChange: func,
  onLabelChange: func,
  onRefresh: func,
  onSort: func,
  onPageChange: func,
  pages: number,
  currentPage: number,
  sort: string,
  loading: bool,
  error: string,
  savedLists: array
}
export default Search
