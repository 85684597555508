import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../ColorContext'
import LinkForm from './LinkForm'
import createItemSchema from '../../../../service/collection/item-schema'
import useDebounce from '../../lib/hook/useDebounce'

const schema = createItemSchema()

const LinkFormContainer = ({ onClose, onSubmit, author, item }) => {
  const blank = schema.makeDefault({ type: 'link', author })
  const initialState = item || blank
  const [data, setData] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const handleChange = ({ target: { name, value } }) => {
    setData(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSubmit = e => {
    e && e.preventDefault()
    setErrors({})

    schema.validate(data, (err, errors) => {
      if (errors && Object.keys(errors).length) {
        return setErrors(errors)
      }
      return onSubmit(data)
    })
  }

  const debouncedLink = useDebounce(data.link, 500)

  const fetchLinkDetails = async link => {
    setLoading(true)
    try {
      const res = await fetch(
        `/api/collections/link?link=${encodeURIComponent(link)}`
      )
      setLoading(false)
      if (res.status !== 200) return
      const { title, note } = await res.json()
      setData(prevState => ({ ...prevState, title, note }))
    } catch (error) {
      // Gracefully ignore bad requests
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!debouncedLink || debouncedLink === initialState.link) return
    fetchLinkDetails(debouncedLink)
  }, [debouncedLink])

  return (
    <LinkForm
      onSubmit={handleSubmit}
      onClose={onClose}
      data={data}
      errors={errors}
      loading={loading}
      onChange={handleChange}
      isNew={!item}
    />
  )
}

LinkFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  author: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
}

export default withColor(LinkFormContainer)
