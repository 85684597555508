const parseHubspotCookie = () => {
  const cookie = document.cookie
  const hubspotCookieId = 'hubspotutk='
  const cookieStart = cookie.indexOf(hubspotCookieId)
  if (cookieStart === -1) return undefined

  let cookieEnd = cookie.indexOf(';', cookieStart)
  // this is the final cookie in the string
  if (cookieEnd === -1) {
    cookieEnd = undefined
  }
  const hubspotTrackingToken = cookie.slice(
    cookieStart + hubspotCookieId.length,
    cookieEnd
  )

  return hubspotTrackingToken
}

export default parseHubspotCookie
