import AskContagiousForm from '../../../site/widgets/ask-contagious-form/component'
import renderComponent from '../lib/component-renderer'

const debug = require('debug')('askContagiousForm')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init askContagiousForm')
    renderComponent('.js-ask-contagious-form', AskContagiousForm)
  })
}
