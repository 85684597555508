import React, { PureComponent } from 'react'
import { oneOf } from 'prop-types'

import DecoratedTextControl from '../DecoratedTextControl'
import IconButton from '../../IconButton'

export class PasswordControl extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      type: props.type
    }

    this.handleTypeToggle = this.handleTypeToggle.bind(this)
  }

  handleTypeToggle() {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    })
    this.input.focus()
  }

  render() {
    return (
      <DecoratedTextControl
        {...this.props}
        type={this.state.type}
        controlRef={input => {
          this.input = input
        }}
        after={
          <IconButton
            type="button"
            icon={
              this.state.type === 'password' ? 'VisibilityOff' : 'Visibility'
            }
            transparent
            iconWidth={16}
            iconHeight={16}
            onClick={this.handleTypeToggle}
          />
        }
        afterInteractive
      />
    )
  }
}

PasswordControl.displayName = 'PasswordControl'

PasswordControl.defaultProps = {
  type: 'password'
}

PasswordControl.propTypes = {
  type: oneOf(['password', 'text'])
}

export default PasswordControl
