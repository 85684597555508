import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../primitive/Button'
import Icon from '../primitive/Icon'

import styles from './Challenge.styl'

const ArrowButton = ({ direction, onClick, className }) => (
  <Button
    icon
    className={classNames(styles.NavButton, className)}
    onClick={onClick}
    ariaLabel="previous"
  >
    <Icon type={direction === 'left' ? 'ChevronLeft' : 'ChevronRight'} />
  </Button>
)

ArrowButton.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  onClick: PropTypes.func
}

export const ChallengeItem = ({ title, link, showOverlay, imageUrl }) => (
  <a
    className={classNames(
      styles.ChallengeItem,
      showOverlay ? styles.showOverlay : ''
    )}
    href={link}
  >
    <img className={styles.ChallengeImage} src={imageUrl} />
    <h3 className={styles.ChallengeHeading}>{title}</h3>
  </a>
)

ChallengeItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  showOverlay: PropTypes.bool,
  imageUrl: PropTypes.string
}

class Challenge extends React.PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')

    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: false,
      prevNextButtons: false,
      autoPlay: false,
      pageDots: false,
      imagesLoaded: true,
      adaptiveHeight: false,
      cellAlign: 'left'
    })
  }

  render() {
    const { items, title } = this.props

    if (!items || items.length === 0) return null

    return (
      <div className={styles.ChallengeContainer}>
        <div className={styles.Challenge}>
          <div className={styles.TitleWrapper}>
            <h2 className={styles.Title}>{title}</h2>
            <div className={styles.MobileNav}>
              <ArrowButton
                direction="left"
                onClick={() => {
                  this.carousel.previous()
                }}
              />
              <ArrowButton
                direction="right"
                onClick={() => {
                  this.carousel.next()
                }}
              />
            </div>
          </div>
          <div className={styles.CarouselWrapper}>
            <div
              className={styles.Carousel}
              ref={carousel => {
                this.carousel = carousel
              }}
            >
              {items.map((item, i) => (
                <ChallengeItem
                  title={item.title}
                  link={item.link}
                  showOverlay={item.showOverlay}
                  imageUrl={item.__imageUrl}
                  key={`Challenge--Item--${i}`}
                />
              ))}
            </div>
          </div>

          <div className={styles.ChallengeGrid}>
            {items.map((item, i) => (
              <ChallengeItem
                title={item.title}
                link={item.link}
                showOverlay={item.showOverlay}
                imageUrl={item.__imageUrl}
                key={`Challenge--Item--${i}`}
              />
            ))}
            <div className={styles.AskContagious}>
              <h3 className={styles.AskContagiousHeadline}>
                Ask{' '}
                <img
                  src="/asset/img/content/challenge/contagious-wordmark-white.png"
                  alt="Contagious Wordmark White"
                />
              </h3>
              <p className={styles.AskContagiousCopy}>
                Can&apos;t find what you&apos;re after? Our team of editors and
                analysts can provide bespoke analysis and case studies to help
                with your challenge.
              </p>
              <a className={styles.AskContagiousCta} href="/iq/ask-contagious">
                Send us your request
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Challenge.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  link: PropTypes.string,
  showOverlay: PropTypes.bool
}

export default Challenge
