import React from 'react'
import { string, object, array, number, func, bool } from 'prop-types'
import SearchResults from '../../../../site/layout/component/SearchResults'

const FilterableLabel = ({
  filters,
  labels,
  articles = [],
  showFilters,
  count,
  onSort,
  onPageChange,
  pages,
  currentPage,
  sort,
  loading,
  error,
  onFilterChange,
  onLabelChange,
  savedLists
}) => {
  if (loading && articles.length === 0) return null
  return (
    <SearchResults
      filters={filters}
      articles={articles}
      labels={labels}
      count={count}
      onFilterChange={onFilterChange}
      onLabelChange={onLabelChange}
      onSort={onSort}
      onPageChange={onPageChange}
      showFilters={showFilters}
      pages={pages}
      currentPage={currentPage}
      sort={sort}
      loading={loading}
      error={error}
      showSort={false}
      savedLists={savedLists}
    />
  )
}

FilterableLabel.propTypes = {
  filters: object.isRequired,
  articles: array.isRequired,
  labels: array,
  count: number,
  onFilterChange: func,
  onLabelChange: func,
  onRefresh: func,
  onSort: func,
  onPageChange: func,
  showFilters: bool,
  pages: number,
  currentPage: number,
  sort: string,
  loading: bool,
  error: string,
  savedLists: array
}
export default FilterableLabel
