import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'
import TextControl from '../primitive/Control/TextControl'
import FieldTemplate from '../primitive/FieldTemplate'

import styles from './LinkForm.styl'

const LinkForm = ({
  onClose,
  onSubmit,
  onChange,
  errors,
  loading,
  data,
  isNew
}) => (
  <Modal onClose={onClose} className={styles.LinkForm} title="Add Link" rounded>
    <form onSubmit={onSubmit}>
      <div className={styles.CollectionSelect}>
        <FieldTemplate
          label="Link"
          controlName="link"
          status={errors.link && 'error'}
          feedback={errors.link}
        >
          <TextControl
            type="url"
            name="link"
            placeholder={loading ? 'Loading...' : 'Paste Link'}
            value={data.link}
            onChange={onChange}
            status={errors.link && 'error'}
            required
          />
        </FieldTemplate>
        <FieldTemplate
          label="Title"
          controlName="title"
          status={errors.title && 'error'}
          feedback={errors.title}
        >
          <TextControl
            type="text"
            name="title"
            placeholder="Title"
            value={data.title}
            onChange={onChange}
            status={errors.title && 'error'}
            required
          />
        </FieldTemplate>
        <FieldTemplate
          label="Note"
          controlName="note"
          status={errors.note && 'error'}
          feedback={errors.note}
        >
          <TextControl
            type="textarea"
            name="note"
            placeholder="Note"
            value={data.note}
            onChange={onChange}
            status={errors.note && 'error'}
            multiline
            required
          />
        </FieldTemplate>
      </div>
      <div className={styles.ButtonGroup}>
        <ButtonSimple
          ariaLabel="cancel"
          type="button"
          outline
          onClick={onClose}
        >
          Cancel
        </ButtonSimple>
        <ButtonSimple
          ariaLabel={isNew ? 'Add to collection' : 'Save'}
          type="submit"
        >
          {isNew ? 'Add to collection' : 'Save'}
        </ButtonSimple>
      </div>
    </form>
  </Modal>
)

LinkForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired
}

export default LinkForm
