import React, { Children, PureComponent } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import CheckControl from '../CheckControl'

import styles from './CheckGroup.styl'

class CheckGroup extends PureComponent {
  render() {
    const { children, className } = this.props
    if (Children.count(children) === 1) return children

    return (
      <ul className={classNames(styles.CheckGroup, className)}>
        {Children.map(children, child => (
          <li
            className={styles.CheckGroupItem}
            key={`checkGroupItem${child.props.name}${child.props.value}`}
          >
            {child}
          </li>
        ))}
      </ul>
    )
  }
}

CheckGroup.displayName = 'CheckGroup'

CheckGroup.propTypes = {
  className: string,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    return (
      Children.toArray(prop).find(child => child.type !== CheckControl) &&
      new Error(`${componentName} only accepts “<CheckControl />” elements.`)
    )
  }
}

export default CheckGroup
