import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const BaseControl = props => {
  const {
    children,
    className,
    error,
    modifiers,
    name,
    controlRef,
    // Use the element prop as a React component
    element: Element,
    // Attributes added by author, or embellished by type-spcific controls
    ...other
  } = props

  const fieldId = `field--${name}`

  const controlClassNames = classNames(
    className,
    modifiers && modifiers.map(modifierClass => `control--${modifierClass}`),
    { 'control--error': error }
  )

  return (
    <Element
      className={controlClassNames}
      name={name}
      ref={controlRef}
      {...other}
      id={fieldId}
    >
      {children}
    </Element>
  )
}

BaseControl.propTypes = {
  controlRef: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.string.isRequired,
  error: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string
}

export default BaseControl
