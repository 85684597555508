import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ModalPortal from '../ModalPortal'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'
import { withColor } from '../ColorContext'
import Timer from '../Timer'

import styles from './Modal.styl'

class Modal extends PureComponent {
  constructor(props) {
    super(props)
    this.handleEscape = this.handleEscape.bind(this)
  }

  componentDidMount() {
    if (this.props.onOpen) {
      this.props.onOpen()
    }
    document.addEventListener('keydown', this.handleEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscape, false)
  }

  handleEscape(e) {
    if (e.keyCode === 27) {
      this.props.onClose()
    }
  }

  render() {
    const {
      children,
      onClose,
      title,
      className,
      timer,
      color,
      rounded,
      largeHeader,
      fullScreenMobile
    } = this.props
    return (
      <ModalPortal>
        <div className={styles.ModalWrapper}>
          <div
            className={styles.ModalOverlay}
            onClick={onClose}
            tabIndex={-1}
          />
          <div
            className={classnames(
              styles.ModalWrapperInner,
              fullScreenMobile && styles.fullScreenMobile,
              largeHeader && styles.largeHeader
            )}
            role="document"
          >
            <div
              className={classnames(
                styles.Modal,
                rounded && styles.rounded,
                className
              )}
            >
              {timer && <Timer timer={timer} onClose={onClose} color={color} />}
              {title && (
                <div className={styles.ModalHeader}>
                  <h2 className={styles.Heading}>
                    <HeadingSlash>{title}</HeadingSlash>
                  </h2>
                  <div className={styles.ModalClose} onClick={onClose}>
                    <Icon type="Close" />
                  </div>
                </div>
              )}
              <div className={styles.ModalInner}>{children}</div>
            </div>
          </div>
        </div>
      </ModalPortal>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  timer: PropTypes.number,
  color: PropTypes.string,
  fullScreenMobile: PropTypes.bool,
  rounded: PropTypes.bool,
  largeHeader: PropTypes.bool
}

export default withColor(Modal)
