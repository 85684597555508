const dispatchFollowingUpdate = following => {
  if (typeof window === 'undefined') return
  const event = new window.CustomEvent('FOLLOWING_UPDATE', {
    detail: { following }
  })
  window.dispatchEvent(event)
}

const send = async update => {
  const res = await fetch('/api/user/follower', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(update)
  })
  if (res.status !== 200) {
    throw new Error(await res.text())
  }
  const updatedFollowing = await res.json()

  dispatchFollowingUpdate(updatedFollowing)

  return updatedFollowing
}

export default send
