import React, { PureComponent } from 'react'
import { array } from 'prop-types'
import styles from './LinkList.styl'

class LinkList extends PureComponent {
  render() {
    const { links } = this.props
    return (
      <div className={styles.LinkList}>
        {links.map((link, i) => (
          <div
            key={i}
            className={link.active ? styles.active : undefined}
            onClick={link.onClick}
          >
            <span>{link.label}</span>
          </div>
        ))}
      </div>
    )
  }
}

LinkList.propTypes = {
  links: array
}

export default LinkList
