import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../primitive/Button'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'
import styles from './StaticCarousel.styl'
import { Grid, GridItem } from '../primitive/Grid'

class StaticCarousel extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: false,
      pageDots: true,
      imagesLoaded: true,
      adaptiveHeight: false
    })

    // https://github.com/metafizzy/flickity/issues/318
    this.timeout = setTimeout(() => {
      this.carousel.resize()
    }, 50)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { items } = this.props
    const count = items.length
    return (
      <div className={styles.StaticCarousel}>
        <div
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {items.map((item, i) => (
            <div key={`StaticCarousel-${i}`} className={styles.Slide}>
              <Grid gap={8} flex>
                <GridItem sizes={['desktop-one-half']}>
                  <img className={styles.Image} src={item.imageUrl} />
                </GridItem>
                <GridItem sizes={['desktop-one-half']}>
                  <div className={styles.Content}>
                    <div>
                      {item.eyebrow && (
                        <div className={styles.Eyebrow}>{item.eyebrow}</div>
                      )}
                      <h2 className={styles.Title}>
                        <HeadingSlash>{item.title}</HeadingSlash>
                      </h2>
                      {item.body && (
                        <div
                          className={styles.Description}
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        />
                      )}
                    </div>
                    {item.ctaLink && item.ctaText && (
                      <div className={styles.ButtonWrapper}>
                        <Button
                          href={item.ctaLink}
                          outline
                          ariaLabel={item.ctaText}
                        >
                          {item.ctaText}
                        </Button>
                      </div>
                    )}
                  </div>
                </GridItem>
              </Grid>
            </div>
          ))}
        </div>
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavPrev)}
            onClick={() => {
              this.carousel.previous()
            }}
            ariaLabel="previous"
          >
            <Icon type="ChevronLeft" />
          </Button>
        )}
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavNext)}
            onClick={() => {
              this.carousel.next()
            }}
            ariaLabel="next"
          >
            <Icon type="ChevronRight" />
          </Button>
        )}
      </div>
    )
  }
}

StaticCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      eyebrow: PropTypes.string,
      imageUrl: PropTypes.string.isRequired,
      ctaText: PropTypes.string,
      ctaLink: PropTypes.string
    })
  ).isRequired
}

export default StaticCarousel
