import React from 'react'
import PropTypes from 'prop-types'

import styles from './EditButton.styl'

const EditButton = ({ articleId }) => (
  <a
    className={styles.Edit}
    href={`/edit/${articleId}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    EDIT
  </a>
)

EditButton.propTypes = {
  articleId: PropTypes.string.isRequired
}

export default EditButton
