import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import { withColor } from '../ColorContext'
import SelectControl from '../primitive/Control/SelectControl'
import Loading from '../primitive/Loading'
import ButtonSimple from '../primitive/ButtonSimple'
import FieldTemplate from '../primitive/FieldTemplate'

import styles from './AddToCollection.styl'

const AddToCollection = ({
  onClose,
  loading,
  error,
  success,
  articleId,
  selectedCollection,
  onSelect,
  collections,
  onAdd,
  color,
  itemType
}) => {
  return (
    <Modal
      onClose={onClose}
      className={styles.AddToCollection}
      title="Add to a collection"
      timer={success && 6000}
      rounded
    >
      {success ? (
        <h1>
          {`You successfully added this ${itemType} to the collection.`}{' '}
          <a
            aria-label="view collection"
            href={selectedCollection.link}
            style={color && { color }}
          >
            View Collection
          </a>
        </h1>
      ) : (
        <form>
          <h3 className={styles.ExistingCollection}>Existing collection</h3>
          {error && <p>{error}</p>}
          {loading ? (
            <Loading />
          ) : (
            <div className={styles.CollectionSelect}>
              <FieldTemplate
                hideLabel
                label="Select a collection"
                controlName="collection"
              >
                <SelectControl
                  type="select"
                  name="collection"
                  onChange={e => onSelect(e.target.value)}
                  required
                >
                  {collections.map((c, i) => (
                    <option value={c.value} key={`${c.text}-${i}`}>
                      {c.text}
                    </option>
                  ))}
                </SelectControl>
              </FieldTemplate>
            </div>
          )}
          {itemType === 'article' && (
            <a
              className={styles.NewCollection}
              href={`/iq/collections/new?articleId=${articleId}`}
              style={color && { color }}
            >
              + Create new collection
            </a>
          )}
          <div className={styles.ButtonGroup}>
            <ButtonSimple
              onClick={e => {
                e.preventDefault()
                onAdd()
              }}
              disabled={!selectedCollection}
              ariaLabel="Add to collection"
            >
              Add to collection
            </ButtonSimple>
          </div>
        </form>
      )}
    </Modal>
  )
}

AddToCollection.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  articleId: PropTypes.string,
  itemType: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.bool,
  selectedCollection: PropTypes.object,
  collections: PropTypes.array,
  color: PropTypes.string
}

export default withColor(AddToCollection)
