import React from 'react'
import Icon from '../primitive/Icon'

export default {
  agencies: {
    descriptions: [
      'Best for small agencies',
      'Best for small and medium- sized agencies',
      'Best for large agencies and groups',
      'Best for groups and networks'
    ],
    rows: [
      {
        title: 'Contagious IQ users',
        values: ['10', '25', '50', 'Uncapped']
      },
      {
        title: 'Access to The Creative Accelerator',
        values: ['10', '25', '50', 'Uncapped']
      },
      {
        title: 'Briefings',
        values: ['1', '2', '2', '4']
      },
      {
        title: 'Most Contagious tickets',
        values: ['2', '5', '10', '20']
      },
      {
        title: 'Contagious Quarterly magazine',
        values: ['1', '3', '5', '10']
      }
    ],
    prices: ['13,500', '25,000', '32,500', 'POA']
  },

  brands: {
    descriptions: [
      'For brands eager to keep creativity front of mind',
      'For brands looking to build foundational skills in creativity',
      'For brands on a mission to embed a culture of creative excellence'
    ],
    rows: [
      {
        title: 'Access to Contagious IQ',
        values: [
          <Icon key="icon9" type="Tick" />,
          <Icon key="icon10" type="Tick" />,
          <Icon key="icon11" type="Tick" />
        ]
      },
      {
        title: 'Access to The Creative Accelerator',
        values: [
          <Icon key="icon12" type="Tick" />,
          <Icon key="icon13" type="Tick" />,
          <Icon key="icon14" type="Tick" />
        ]
      },
      {
        title: 'Monthly newsletters',
        values: [
          <Icon key="icon15" type="Tick" />,
          'Plus voting mechanic & Contagious feedback',
          'Plus bespoke scale'
        ]
      },
      {
        title: 'Best of Creativity webinar',
        tooltip:
          "A live presentation where we take your team through the world's best creative ideas",
        values: [
          <Icon key="icon16" type="Tick" />,
          <Icon key="icon17" type="Tick" />,
          <Icon key="icon18" type="Tick" />
        ]
      },
      {
        title: 'Sector Review webinar',
        tooltip:
          'A live presentation where we champion the key trends and campaigns from a sector of your choice',
        values: [
          <Icon key="icon19" type="Tick" />,
          <Icon key="icon20" type="Tick" />,
          <Icon key="icon21" type="Tick" />
        ]
      },
      {
        title: 'Event tickets',
        tooltip:
          'Members receive a 25% discount to all Contagious events. Planning on attending regularly? Our Events Bolt-ons mean you could receive even bigger discounts. Just ask us for more information. ',
        values: [
          <Icon key="icon22" type="Tick" />,
          <Icon key="icon23" type="Tick" />,
          <Icon key="icon24" type="Tick" />
        ]
      },
      {
        title: 'Post-event on demand access',
        tooltip:
          "Couldn't make an event in person? Dont worry - all our members can watch available video content on demand later",
        values: [
          <Icon key="icon25" type="Tick" />,
          <Icon key="icon26" type="Tick" />,
          <Icon key="icon27" type="Tick" />
        ]
      },
      {
        title: 'Member rate discounts',
        tooltip:
          'As well as discounts to events, Contagious members also receive preferential rates on other services, such as briefings and research projects',
        values: [
          <Icon key="icon28" type="Tick" />,
          <Icon key="icon29" type="Tick" />,
          <Icon key="icon30" type="Tick" />
        ]
      },
      {
        title: 'Engagement sessions',
        values: [
          <Icon key="icon31" type="Tick" />,
          <Icon key="icon32" type="Tick" />,
          <Icon key="icon33" type="Tick" />
        ]
      },
      {
        title: 'Virtual Gatherings',
        values: [
          '-',
          <Icon key="icon34" type="Tick" />,
          <Icon key="icon35" type="Tick" />
        ]
      },
      {
        title: 'Bespoke calendar of events',
        values: ['-', '-', <Icon key="icon36" type="Tick" />]
      },
      {
        title: 'Dedicated microsite',
        tooltip:
          'Your own curated homepage within Contagious IQ, featuring content aligned to the specific needs of your team',
        values: ['-', '-', <Icon key="icon37" type="Tick" />]
      }
    ],
    prices: ['25,000', '45,000', '85,000']
  }
}
