import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'
import { withColor } from '../ColorContext'

import styles from './DeleteListModal.styl'

const DeleteListModal = ({ onClose, onDelete, error, loading }) => {
  return (
    <Modal
      onClose={onClose}
      className={styles.DeleteListModal}
      title="Are you sure you want to delete this list?"
      rounded
    >
      {error && <p>{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.ButtonGroup}>
          <ButtonSimple ariaLabel="cancel" outline onClick={() => onClose()}>
            Cancel
          </ButtonSimple>
          <ButtonSimple ariaLabel="delete" onClick={() => onDelete()}>
            Delete
          </ButtonSimple>
        </div>
      )}
    </Modal>
  )
}

DeleteListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
}

export default withColor(DeleteListModal)
