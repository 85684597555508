import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './VisuallyHidden.styl'

class VisuallyHidden extends PureComponent {
  render() {
    const { children } = this.props
    return <span className={styles.VisuallyHidden}>{children}</span>
  }
}

VisuallyHidden.displayName = 'VisuallyHidden'

VisuallyHidden.propTypes = {
  children: PropTypes.node.isRequired
}

export default VisuallyHidden
