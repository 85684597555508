import React from 'react'
import { createPortal } from 'react-dom'
import { bool, func, node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Overlay.styl'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'

const Overlay = ({ children, open, title, onClose, smallScreenOnly, href }) => {
  if (!open) return null
  const LinkEl = href ? 'a' : 'div'
  return createPortal(
    <div
      className={classNames(
        styles.Overlay,
        smallScreenOnly && styles.smallScreenOnly
      )}
    >
      <div className={styles.OverlayHeader}>
        {title && (
          <LinkEl className={styles.OverlayTitle} href={href}>
            <HeadingSlash>{title}</HeadingSlash>
          </LinkEl>
        )}
        {onClose && (
          <button
            aria-label="close"
            type="button"
            className={styles.OverlayClose}
            onClick={onClose}
          >
            <Icon type="Close" a11yText="Close" />
          </button>
        )}
      </div>

      <div>{children}</div>
    </div>,
    document.body
  )
}

Overlay.defaultProps = {}

Overlay.propTypes = {
  open: bool,
  onClose: func,
  children: node,
  title: string,
  href: string,
  smallScreenOnly: bool
}

export default Overlay
