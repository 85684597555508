import React, { useState, useEffect } from 'react'
import { object, func, bool } from 'prop-types'
import classNames from 'classnames'
import { parse } from 'querystring'
import useEscapeKey from '../../../../../lib/hook/useEscapeKey'
import useDebounce from '../../../../../lib/hook/useDebounce'
import Icon from '../../../../primitive/Icon'
import NavSearchPanel from '../../../../NavSearchPanel'

import styles from './NavSearch.styl'

// Composable select on focus HOC
const selected = Component => {
  const SelectOnFocus = args => {
    const onFocusFunc = e => {
      e.target.select()
      args.onFocus && args.onFocus()
    }
    SelectOnFocus.displayName = Component.displayName
    return <Component {...args} onFocus={onFocusFunc} />
  }
  return SelectOnFocus
}

const SelectedInput = selected(({ ...args }) => <input {...args} />)

const NavSearch = ({
  onSearch,
  suggestions,
  suggestionsLoading,
  isSearchFocus,
  setIsSearchFocus,
  mobileSearchOpen,
  setMobileSearchOpen
}) => {
  const [active, setActive] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleChange = e => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { query } = parse(location.search.substr(1))
      if (query) {
        setSearchTerm(query)
      }
    }
  }, [])

  useEffect(() => {
    if (!expanded) return
    if (!debouncedSearchTerm || debouncedSearchTerm.length <= 2) return
    onSearch && onSearch(debouncedSearchTerm)
  }, [debouncedSearchTerm, expanded])

  useEffect(() => {
    setExpanded(active && searchTerm.length > 2)
  }, [active, searchTerm])

  const handleCloseMobile = () => {
    setMobileSearchOpen(false)
    setIsSearchFocus(false)
  }

  const handleOpen = () => {
    setActive(true)
    setIsSearchFocus(true)
  }

  const handleClose = () => {
    setActive(false)
    setIsSearchFocus(false)
  }
  const handleCloseDesktop = () => {
    setSearchTerm('')
    setActive(false)
    setIsSearchFocus(false)
  }
  useEscapeKey((active || expanded) && handleClose)

  return (
    <>
      <div
        className={classNames(
          styles.NavSearch,
          active && styles.active,
          expanded && styles.expanded,
          mobileSearchOpen && styles.openMobile
        )}
      >
        <div className={styles.NavSearchBg} onClick={handleClose} />
        <div className={styles.NavSearchInner}>
          <div className={styles.NavSearchFormWrapper}>
            <form className={styles.NavSearchForm} action="/iq/search">
              <SelectedInput
                className={styles.NavSearchField}
                type="text"
                placeholder="Search"
                name="query"
                autoComplete="off"
                value={searchTerm}
                data-track="Search"
                onFocus={handleOpen}
                onChange={handleChange}
              />
              <button type="submit">
                <Icon type="Search" a11yText="Search" width={14} />
              </button>
            </form>
            <button
              aria-label="close search"
              className={classNames(styles.NavSearchToggleClose)}
              onClick={handleCloseMobile}
            >
              <Icon type="Close" a11yText="Close Search" />
            </button>
            {isSearchFocus && searchTerm && (
              <button
                className={styles.CloseSearchDesktop}
                onClick={handleCloseDesktop}
              >
                <Icon type="Close" a11yText="Close Search" />
              </button>
            )}
          </div>
          {expanded && (
            <div className={styles.NavSearchOverlay}>
              <NavSearchPanel
                suggestions={suggestions}
                loading={suggestionsLoading}
              />
            </div>
          )}
        </div>
      </div>
      {isSearchFocus && (
        <div className={styles.Overlay} onClick={handleCloseDesktop} />
      )}
    </>
  )
}

NavSearch.propTypes = {
  mobileSearchOpen: bool,
  setMobileSearchOpen: func,
  onSearch: func,
  suggestions: object,
  suggestionsLoading: bool,
  isSearchFocus: bool,
  setIsSearchFocus: func
}

export default NavSearch
