import React, { useState, useEffect } from 'react'
import { string, object, func, bool } from 'prop-types'
import styles from './FilterPanel.styl'
import classnames from 'classnames'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'
import CheckControlGroup from '../primitive/Control/CheckControlGroup'
import CheckControl from '../primitive/Control/CheckControl'

const List = ({ group, onChange, color, showCount = true }) => {
  const [expanded, setExpanded] = useState(false)
  const [shownItemCount, setShownItemCount] = useState(group.items.length)
  const [items, setItems] = useState(group.items.slice(0, 5))

  const checkVisibleKeys = () => {
    setExpanded(items.length === group.items.length)
  }
  useEffect(() => {
    setShownItemCount(group.items.length)
    setItems(group.items.slice(0, items.length < 5 ? 5 : items.length))
  }, [group.items])

  useEffect(() => {
    checkVisibleKeys()
  }, [items])

  const onShowMore = () => {
    setItems(prevState => [
      ...prevState,
      ...group.items.slice(prevState.length, prevState.length + 10)
    ])
  }

  const onShowLess = () => {
    const itemsToShow = items.slice(0, 5)
    setItems(itemsToShow)
    setShownItemCount(itemsToShow.length)
    setExpanded(false)
  }
  return (
    <div className={styles.Group}>
      <h4>
        <HeadingSlash>{group.name}</HeadingSlash>
      </h4>
      <CheckControlGroup>
        {items.map(item => {
          if (!item) return null
          return (
            <CheckControl
              type="checkbox"
              name="checkboxExample"
              value={`${group.id}/${item.slug}`}
              key={`item--${group.id}--${item.slug}`}
              disabled={item.disabled}
              checked={item.checked}
              small
              color="red"
              onChange={e =>
                onChange({
                  group: group.id,
                  item: item.slug,
                  value: e.target.checked
                })
              }
            >
              <span className={item.checked ? styles.filterActive : null}>
                {`${item.name}${
                  showCount && item.count ? ` (${item.count})` : ``
                }`}
              </span>
            </CheckControl>
          )
        })}
      </CheckControlGroup>
      {group.items.length > 5 && shownItemCount >= 5 && (
        <p
          className={classnames(styles.ShowMorePrompt, {
            [styles.notExpanded]: !expanded
          })}
          onClick={expanded ? onShowLess : onShowMore}
        >
          View {expanded ? 'less' : 'more'}
          &nbsp;
          <span className={styles.ExpandIcon} style={color && { color: color }}>
            <Icon type="Up" width={20} />
          </span>
        </p>
      )}
    </div>
  )
}

List.propTypes = {
  group: object,
  onChange: func,
  color: string,
  showCount: bool
}

export default List
