import React from 'react'
import Spacer from '../../../layout/component/primitive/Spacer'
import SubNavigation from '../../../layout/component/SubNavigation'

const SubNavigationWidget = props => {
  return (
    <div className="widget widget--sub-navigation">
      <Spacer>
        <SubNavigation {...props} />
      </Spacer>
    </div>
  )
}

export default SubNavigationWidget
