import renderComponent from '../lib/component-renderer'

import ArticleCarousel from '../../../../components/site/layout/component/ArticleCarousel'

const debug = require('debug')('article-carousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init article-carousel')
    renderComponent('.js-article-carousel', ArticleCarousel)
  })
}
