import React, { useState } from 'react'
import { number, array, func } from 'prop-types'
import { stringify } from 'qs'

const FollowingContainer = ({
  articles,
  page: defaultPage,
  pages,
  savedLists,
  component: Component
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(defaultPage)
  const [results, setResults] = useState(articles)

  const pageSize = 24

  const handlePageChange = async newPage => {
    setLoading(true)
    setError(null)
    setPage(newPage)
    window.scrollTo(0, 0)
    try {
      const res = await fetch(
        `/api/feed?${stringify({
          page: newPage,
          pageSize
        })}`,
        {
          credentials: 'include',
          redirect: 'error'
        }
      )
      setLoading(false)

      if (res.status === 200) {
        const { results } = await res.json()
        setResults(results)
        history.replaceState(null, '', `?${stringify({ page: newPage })}`)
      } else {
        throw new Error('An unexpected error occurred, please try again.')
      }
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }

  return (
    <Component
      savedLists={savedLists}
      articles={results}
      loading={loading}
      error={error}
      page={page}
      pages={pages}
      onPageChange={handlePageChange}
    />
  )
}

FollowingContainer.propTypes = {
  page: number.isRequired,
  pages: number.isRequired,
  savedLists: array.isRequired,
  articles: array.isRequired,
  component: func.isRequired
}

export default FollowingContainer
