import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../primitive/Icon'
import Button from '../primitive/Button'
import HeadingSlash from '../primitive/HeadingSlash'

import styles from './TeamCarouselItem.styl'

class TeamCarouselItem extends React.PureComponent {
  constructor() {
    super()
    this.showBio = this.showBio.bind(this)
    this.state = {
      bioIsOpen: false
    }
  }

  showBio() {
    const currentState = this.state.bioIsOpen
    this.setState({ bioIsOpen: !currentState })
  }

  render() {
    const { image, name, position, summary, socialLinks, bio } = this.props

    return (
      <div className={styles.TeamMember}>
        <div className={styles.ImageContainer}>
          <img src={image} alt={name} />
        </div>
        <div className={styles.InfoContainer}>
          <span className={styles.PersonMeta}>
            <h3>{name}</h3>
            <h3>
              <HeadingSlash>{position}</HeadingSlash>
            </h3>
          </span>
          <p className={styles.PersonSummary}>{summary}</p>
          {socialLinks && (
            <div className={styles.SocialBlock}>
              {socialLinks.linkedIn && (
                <Button
                  icon
                  className={styles.SocialButton}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={socialLinks.linkedIn}
                  ariaLabel="linkedIn"
                >
                  <Icon a11yText="LinkedIn" type="Linkedin" />
                </Button>
              )}
              {socialLinks.email && (
                <Button
                  icon
                  className={styles.SocialButton}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={socialLinks.email}
                  ariaLabel="email"
                >
                  <Icon a11yText="Email" type="Email" />
                </Button>
              )}
            </div>
          )}
          {bio && (
            <div
              className={classNames(
                styles.Bio,
                this.state.bioIsOpen ? styles.bioIsOpen : styles.bioIsClosed
              )}
            >
              <h4 className={styles.BioTitle} onClick={this.showBio}>
                Bio
              </h4>
              <div
                className={styles.BioCopy}
                dangerouslySetInnerHTML={{ __html: bio }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

TeamCarouselItem.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  summary: PropTypes.string,
  bio: PropTypes.string,
  socialLinks: PropTypes.object
}

export default TeamCarouselItem
