import React from 'react'
import { func } from 'prop-types'
import classNames from 'classnames'
import Video from '../video'
import OnboardingFormLayout from '../OnboardingFormLayout'

import styles from './OnboardingForm.styl'

const OnboardingCompleteForm = () => {
  const handleSubmit = e => {
    e.preventDefault()
    window.location = '/iq'
  }
  return (
    <OnboardingFormLayout
      step={6}
      title="You’re all set"
      subtitle={
        <p>
          We’ve tailored your homepage based on your choices. Excited? You will
          be after watching this:
        </p>
      }
      buttonText="Enter the Creative Promised Land"
      onSubmit={handleSubmit}
    >
      <div className={classNames(styles.Form)}>
        <Video
          className={styles.Video}
          provider="vimeo"
          videos={[{ videoId: '575399881' }]}
        />
      </div>
    </OnboardingFormLayout>
  )
}

OnboardingCompleteForm.propTypes = {
  onSubmit: func
}

export default OnboardingCompleteForm
