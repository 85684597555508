import React from 'react'
import PropTypes from 'prop-types'

import Notice from '../../primitive/Notice'
import OnboardingFormLayout from '../../OnboardingFormLayout'
import PasswordControl from '../../primitive/Control/PasswordControl'
import FieldTemplate from '../../primitive/FieldTemplate'

const copyMap = {
  Set: (
    <>
      <p>
        Before you get started on Contagious, we just need you to setup a
        password for your account.
      </p>
      <p>You can easily change this at any time in your profile settings.</p>
    </>
  ),
  Reset: (
    <>
      <p>Please enter and confirm your new password to reset.</p>
    </>
  ),
  Verify: (
    <>
      <p>Thanks for confirming your email address.</p>
      <p>Set up a password then you’re all done.</p>
    </>
  )
}

const titleCopyMap = {
  Set: 'Set Password',
  Reset: 'Reset Password',
  Verify: 'Set Password'
}

const PasswordResetForm = ({
  error,
  emailAddress,
  token,
  expiry,
  action = 'Set',
  returnUrl
}) => (
  <OnboardingFormLayout
    title={titleCopyMap[action]}
    subtitle={copyMap[action]}
    buttonText={titleCopyMap[action]}
  >
    {error && (
      <>
        {Array.isArray(error) && error.length > 1 ? (
          <Notice minimal type="error">
            <ul>
              {error.map((error, i) => (
                <li key={`Error-${i}`}>{error}</li>
              ))}
            </ul>
          </Notice>
        ) : (
          <Notice minimal type="error">
            {error}
          </Notice>
        )}
      </>
    )}
    <input name="emailAddress" type="hidden" value={emailAddress} />
    <input name="token" type="hidden" value={token} />
    <input name="expiry" type="hidden" value={expiry} />
    <input name="returnUrl" type="hidden" value={returnUrl} />
    <FieldTemplate label="Password" controlName="password">
      <PasswordControl
        name="password"
        autoComplete="password"
        placeholder="Password"
        required
      />
    </FieldTemplate>
    <FieldTemplate label="Confirm Password" controlName="confirm-password">
      <PasswordControl
        name="confirmPassword"
        autoComplete="confirm-password"
        placeholder="Confirm Password"
        required
      />
    </FieldTemplate>
  </OnboardingFormLayout>
)

PasswordResetForm.propTypes = {
  error: PropTypes.string,
  emailAddress: PropTypes.string,
  token: PropTypes.string,
  expiry: PropTypes.string,
  action: PropTypes.string,
  returnUrl: PropTypes.string
}

export default PasswordResetForm
