import React, { PureComponent } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import classnames from 'classnames'

import Button from '../primitive/Button'
import Icon from '../primitive/Icon'

import styles from './QuoteCarousel.styl'

class QuoteCarousel extends PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: false,
      pageDots: false,
      imagesLoaded: true,
      adaptiveHeight: true
    })

    // https://github.com/metafizzy/flickity/issues/318
    this.timeout = setTimeout(() => {
      this.carousel.resize()
    }, 50)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { quotes } = this.props
    const count = quotes.length
    return (
      <div
        className={classnames(styles.QuoteCarousel, 'js-quote-carousel')}
        data-props={JSON.stringify({ quotes })}
      >
        <div className={styles.QuoteMark}>“</div>
        <div
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {quotes.map((quote, i) => (
            <div key={`quote${i}`} className={styles.Slide}>
              <p className={styles.Quote}>{quote.quote}</p>
              <p className={styles.Attribution}>
                <span className={styles.Highlighted}>{quote.attribution}</span>
                {', '}
                {quote.organisation}
              </p>
            </div>
          ))}
        </div>
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavPrev)}
            onClick={() => {
              this.carousel.previous()
            }}
            ariaLabel="previous"
          >
            <Icon type="ChevronLeft" />
          </Button>
        )}
        {count > 1 && (
          <Button
            icon
            className={classnames(styles.NavButton, styles.NavNext)}
            onClick={() => {
              this.carousel.next()
            }}
            ariaLabel="next"
          >
            <Icon type="ChevronRight" />
          </Button>
        )}
        <div className={styles.QuoteMark}>”</div>
      </div>
    )
  }
}

QuoteCarousel.propTypes = {
  quotes: arrayOf(
    shape({
      quote: string.isRequired,
      attribution: string.isRequired,
      organisation: string.isRequired
    })
  ).isRequired
}

export default QuoteCarousel
