import DemoForm from '../../../../components/site/widgets/demo-form/component/DemoForm'
import renderComponent from '../lib/component-renderer'

const debug = require('debug')('demoForm')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init demoForm')
    renderComponent('.js-demo-form', DemoForm)
  })
}
