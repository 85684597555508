import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withColor } from '../../ColorContext'

import styles from './HeadingSlash.styl'

class HeadingSlash extends React.PureComponent {
  render() {
    const { children, unstyled, className, color, colorOverride } = this.props
    const classes = classNames(!unstyled && styles.Slash, className)

    return (
      <Fragment>
        {children}&nbsp;
        <span
          className={classes}
          style={{ color: colorOverride || (color && !unstyled && color) }}
          aria-hidden
        >
          /
        </span>
      </Fragment>
    )
  }
}

HeadingSlash.defaultProps = {
  unstyled: false
}

HeadingSlash.propTypes = {
  children: PropTypes.node,
  unstyled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  colorOverride: PropTypes.string
}

export default withColor(HeadingSlash)
