import React, { PureComponent } from 'react'
import { bool, node, oneOfType, string } from 'prop-types'

import CustomCheckControl from './CustomCheckControl'
import NativeCheckControl from './NativeCheckControl'
import ShrinkWrap, { ShrinkWrapItem } from '../../ShrinkWrap'

class CheckControl extends PureComponent {
  render() {
    const { children, native, small } = this.props

    const CheckControlType = native ? NativeCheckControl : CustomCheckControl

    return (
      <ShrinkWrap element="label" spacing={small && 'medium'}>
        <ShrinkWrapItem shrink vAlign="middle">
          <CheckControlType {...this.props} small={small} />
        </ShrinkWrapItem>
        <ShrinkWrapItem vAlign="middle">{children}</ShrinkWrapItem>
      </ShrinkWrap>
    )
  }
}

CheckControl.propTypes = 'CheckControl'

CheckControl.propTypes = {
  children: oneOfType([string, node]).isRequired,
  native: bool,
  small: bool
}

export default CheckControl
