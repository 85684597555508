import React from 'react'
import { string, node, bool, oneOf, func } from 'prop-types'
import classNames from 'classnames'
import { withColor } from '../../ColorContext'

import styles from './ButtonSimple.styl'

const ButtonSimple = ({
  children,
  disabled,
  href,
  target,
  type,
  outline,
  refNode,
  white,
  transparent,
  className,
  ariaLabel,
  ...other
}) => {
  const classes = classNames(
    styles.ButtonSimple,
    disabled && styles.disabled,
    outline && styles.outline,
    white && styles.white,
    transparent && styles.transparent,
    className
  )

  if (href) {
    return (
      <a
        aria-label={ariaLabel || ''}
        className={classes}
        href={href}
        {...(target && { target })}
        {...(target === '_blank' && { rel: 'noopener noreferrer' })}
        ref={refNode}
        {...other}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        aria-label={ariaLabel || ''}
        className={classes}
        type={type}
        disabled={disabled}
        ref={refNode}
        {...other}
      >
        {children}
      </button>
    )
  }
}

ButtonSimple.propTypes = {
  className: string,
  children: node.isRequired,
  disabled: bool,
  href: string,
  target: string,
  outline: bool,
  white: bool,
  transparent: bool,
  type: oneOf(['button', 'reset', 'submit']),
  refNode: func,
  ariaLabel: string
}

export default withColor(ButtonSimple)
