import React from 'react'
import PropTypes from 'prop-types'

import styles from './PreNavigation.styl'

const PreNavigation = ({ strapline, loginUrl, registerUrl }) => {
  return (
    <div className={styles.PreNavigation}>
      <div
        className={styles.Message}
        dangerouslySetInnerHTML={{ __html: strapline }}
      />
      <div className={styles.Actions}>
        {registerUrl && (
          <a href={registerUrl} className={styles.RegisterButton}>
            <span>Become a member</span>
          </a>
        )}
        {loginUrl && (
          <a aria-label="login" href={loginUrl} className={styles.LoginButton}>
            <span>Login</span>
          </a>
        )}
      </div>
    </div>
  )
}

PreNavigation.propTypes = {
  strapline: PropTypes.string,
  loginUrl: PropTypes.string,
  registerUrl: PropTypes.string
}

export default PreNavigation
