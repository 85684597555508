import renderComponent from '../lib/component-renderer'
import PrimaryNavigation from '../../../../components/site/layout/component/PrimaryNavigation/component'

const debug = require('debug')('primary-navigation')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-primary-navigation', PrimaryNavigation)
  })
}
