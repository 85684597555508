import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'
import { withColor } from '../ColorContext'

import styles from './VideoLoader.styl'

class VideoLoader extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      willExit: false,
      showVideo: false
    }

    this.loadVideo = this.loadVideo.bind(this)
  }

  loadVideo() {
    this.setState({
      willExit: true
    })

    setTimeout(() => {
      this.setState({
        showVideo: true
      })
    }, 150)
  }

  render() {
    const { title, previewImage, children, color } = this.props
    const backgrounds = []
    const alpha = 'rgba(0,0,0,0.5)'

    if (title) backgrounds.push(`linear-gradient(${alpha},${alpha})`)
    if (previewImage) backgrounds.push(`url(${previewImage})`)

    const previewStyle = {
      backgroundImage: backgrounds.join(',')
    }

    return (
      <div className={styles.VideoContainer}>
        {!this.state.showVideo && (
          <div
            className={classnames(
              styles.PreviewContainer,
              this.state.willExit ? styles.exit : undefined
            )}
            style={previewStyle}
            onClick={this.loadVideo}
          >
            <div className={styles.PlayIcon} style={{ backgroundColor: color }}>
              <Icon type="Play" />
            </div>
            {title && (
              <h2 className={styles.Heading}>
                <HeadingSlash>{title}</HeadingSlash>
              </h2>
            )}
          </div>
        )}
        {this.state.showVideo && (
          <div className={classnames(styles.Video, styles.enter)}>
            {children}
          </div>
        )}
      </div>
    )
  }
}

VideoLoader.propTypes = {
  title: PropTypes.string,
  previewImage: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string
}

export default withColor(VideoLoader)
