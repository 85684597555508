import React, { useState } from 'react'
import { func, bool, string } from 'prop-types'
import Modal from '../Modal'
import FieldTemplate from '../primitive/FieldTemplate'
import TextControl from '../primitive/Control/TextControl'
import ButtonSimple from '../primitive/ButtonSimple'
import Icon from '../primitive/Icon'
import { withColor } from '../ColorContext'

import styles from './NewListModal.styl'

const NewListModal = ({
  onClose,
  onCreate,
  error,
  success,
  loading,
  listLink,
  itemId,
  color
}) => {
  const [name, setName] = useState('')
  return (
    <Modal
      onClose={onClose}
      className={styles.NewListModal}
      title={`New List ${success ? ' Created' : ''}`}
      timer={success ? 6000 : undefined}
      rounded
    >
      {success ? (
        <h1>
          {!itemId && (
            <>
              Start saving items to it by clicking the{' '}
              <Icon type={'BookmarkBorder'} a11yText={'Save'} width={16} />{' '}
              icon.{' '}
            </>
          )}
          <a
            aria-label="View it here"
            href={listLink}
            style={color && { color }}
          >
            View it here
          </a>
        </h1>
      ) : (
        <form>
          {error && <p>{error}</p>}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className={styles.CollectionSelect}>
              <FieldTemplate label="New List Name" controlName="name">
                <TextControl
                  type="text"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
              </FieldTemplate>
            </div>
          )}
          <div className={styles.ButtonGroup}>
            <ButtonSimple
              onClick={e => {
                e.preventDefault()
                onCreate(name)
              }}
              disabled={!name}
              ariaLabel="Create List"
            >
              Create List
            </ButtonSimple>
          </div>
        </form>
      )}
    </Modal>
  )
}

NewListModal.propTypes = {
  onClose: func.isRequired,
  onCreate: func.isRequired,
  loading: bool.isRequired,
  success: bool.isRequired,
  error: string,
  listLink: string,
  itemId: string,
  color: string
}

export default withColor(NewListModal)
