import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { widthMin, tablet } from '../primitive/Media'
import Button from '../primitive/Button'
import Icon from '../primitive/Icon'
import HeadingSlash from '../primitive/HeadingSlash'

import styles from './TeamCarousel.styl'

class TeamCarousel extends PureComponent {
  constructor() {
    super()
    this.onBreakpointChange = this.onBreakpointChange.bind(this)
  }

  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.breakpoint = window.matchMedia(widthMin(tablet))
    this.breakpoint.addListener(this.onBreakpointChange)

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      setGallerySize: true,
      autoPlay: false,
      pageDots: false,
      imagesLoaded: true,
      groupCells: this.breakpoint.matches ? 2 : 1
    })
  }

  componentWillUnmount() {
    this.breakpoint.removeListener(this.onBreakpointChange)
  }

  onBreakpointChange(e) {
    this.carousel.options.groupCells = e.matches ? 2 : 1
  }

  render() {
    const { children, className, heading } = this.props
    const count = React.Children.count(this.props.children)
    return (
      <div className={styles.TeamCarouselContainer}>
        {heading && (
          <h2 className={styles.Heading}>
            <HeadingSlash>{heading}</HeadingSlash>
          </h2>
        )}
        <div className={classNames(styles.Carousel, styles[className])}>
          <div
            ref={carousel => {
              this.carousel = carousel
            }}
          >
            {children}
          </div>
          <div className={styles.Navigation}>
            {count > 1 && (
              <Button
                icon
                className={classNames(
                  styles.NavigationButton,
                  styles.NavigationPrev
                )}
                onClick={() => {
                  this.carousel.previous()
                }}
                ariaLabel="previous"
              >
                <Icon type="Left" />
              </Button>
            )}

            {count > 1 && (
              <Button
                icon
                className={classNames(
                  styles.NavigationButton,
                  styles.NavigationNext
                )}
                onClick={() => {
                  this.carousel.next()
                }}
                ariaLabel="next"
              >
                <Icon type="Right" />
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

TeamCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  heading: PropTypes.string
}

export default TeamCarousel
