import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Notification.styl'

class Notification extends PureComponent {
  render() {
    const { className, children, state, id, html } = this.props

    return (
      <div
        id={id}
        className={classNames(
          styles.Wrapper,
          state && styles[state],
          className
        )}
      >
        <div
          className={styles.Inner}
          {...(html && { dangerouslySetInnerHTML: { __html: html } })}
        >
          {children}
        </div>
      </div>
    )
  }
}

Notification.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  state: PropTypes.oneOf([
    'warning',
    'error',
    'success',
    'successBlack',
    'notice'
  ]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  html: PropTypes.string
}

export default Notification
