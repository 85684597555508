import React from 'react'
import { object, bool } from 'prop-types'
import Notice from '../primitive/Notice'

const FormErrors = ({ errors, minimal, useBulletPoints = true }) => {
  if (!errors || Object.keys(errors).length === 0) {
    return null
  }
  const ErrorEl = useBulletPoints ? 'li' : 'span'
  return (
    <Notice minimal type="error">
      {!minimal && <h1>Whoops, something isn’t right!</h1>}
      <ul>
        {Object.keys(errors).map(key => (
          <ErrorEl key={`form-error${key}`}>{errors[key]}</ErrorEl>
        ))}
      </ul>
    </Notice>
  )
}

FormErrors.propTypes = {
  errors: object,
  minimal: bool,
  useBulletPoints: bool
}

export default FormErrors
