export default categories => {
  if (!categories) {
    return {}
  }
  return (Array.isArray(categories) ? categories : [categories]).reduce(
    (filter, item) => {
      const [taxonomy, category] = item.split('/')
      if (!filter[taxonomy]) filter[taxonomy] = {}
      filter[taxonomy][category] = true
      return filter
    },
    {}
  )
}
