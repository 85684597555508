import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Figure from '../../primitive/Figure'
import VisuallyHidden from '../../primitive/VisuallyHidden'

import styles from './InlineImage.styl'

const InlineImage = props => {
  const {
    imgUrls,
    original,
    constrained,
    externalLink,
    link,
    showOriginalWhenClicked,
    title,
    caption,
    credits,
    className,
    lazy,
    landscape,
    headline
  } = props
  const Wrapper = link || showOriginalWhenClicked ? 'a' : 'div'
  const url = link || (showOriginalWhenClicked ? original : null)

  return (
    <Wrapper
      href={url}
      target={externalLink ? '_blank' : null}
      rel={externalLink ? 'noreferrer noopener' : null}
      className={classnames(lazy && 'carousel-cell', styles[className])}
    >
      <Figure
        imgUrls={imgUrls}
        constrained={constrained}
        alt={title || `Body image for ${headline}`}
        credits={credits}
        caption={caption}
        landscape={landscape}
        lazy
      />
      {(link || showOriginalWhenClicked) && (
        <VisuallyHidden>{title || `Body image for ${headline}`}</VisuallyHidden>
      )}
    </Wrapper>
  )
}

InlineImage.propTypes = {
  imgUrls: PropTypes.array.isRequired,
  original: PropTypes.string,
  constrained: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  externalLink: PropTypes.bool,
  link: PropTypes.string,
  showOriginalWhenClicked: PropTypes.bool,
  className: PropTypes.string,
  lazy: PropTypes.bool,
  landscape: PropTypes.bool,
  headline: PropTypes.string.isRequired
}

export default InlineImage
