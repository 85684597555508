import React, { useState } from 'react'
import { array } from 'prop-types'
import classNames from 'classnames'

import Tab from '../Tab'

import styles from './TabsWrapper.styl'

const TabsWrapper = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label)
  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  return (
    <div className={styles.TabsWrapper}>
      <ol className={styles.Tabs}>
        {children.map((child, i) => {
          const { label } = child.props
          return (
            <Tab
              isActive={activeTab === label}
              key={label}
              onClick={handleTabClick}
              label={label}
              id={`tab-${i}`}
            />
          )
        })}
      </ol>
      {children.map((child, i) => {
        const { label } = child.props
        return (
          <section
            role="tabpanel"
            key={`TabContent-${i}`}
            aria-labelledby={`tab-${i}`}
            className={classNames(
              styles.TabContentInner,
              label === activeTab && styles.active
            )}
          >
            {child.props.children}
          </section>
        )
      })}
    </div>
  )
}

TabsWrapper.propTypes = {
  children: array.isRequired
}

export default TabsWrapper
