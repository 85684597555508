import React from 'react'
import { bool, func } from 'prop-types'
import classNames from 'classnames'
import DecoratedTextControl from '../DecoratedTextControl'
import IconButton from '../../IconButton'

import styles from './IdentityControl.styl'

const IdentityControl = ({ showEdit, handleEditClick, readOnly, ...props }) => {
  return (
    <div
      className={classNames(
        styles.IdentityControl,
        readOnly && styles.readOnly
      )}
    >
      <DecoratedTextControl
        {...props}
        type="email"
        readOnly={readOnly}
        after={
          showEdit ? (
            <IconButton
              type="button"
              icon="Edit"
              transparent
              iconWidth={16}
              iconHeight={16}
              onClick={handleEditClick}
            />
          ) : null
        }
        afterInteractive
      />
    </div>
  )
}

IdentityControl.propTypes = {
  showEdit: bool,
  handleEditClick: func,
  readOnly: bool
}

export default IdentityControl
