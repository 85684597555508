import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import HeadingSlash from '../primitive/HeadingSlash'
import { Grid, GridItem } from '../primitive/Grid'
import TagLabel from '../TagLabel'
import SaveArticle from '../SaveArticle'
import Icon from '../primitive/Icon'
import ButtonSimple from '../primitive/ButtonSimple'
import versionPath from '../../../../../site/lib/version-path'

import styles from './ArticleCard.styl'

const ArticleCard = ({
  className,
  _id,
  shortHeadline,
  headline,
  sell,
  __fullUrlPath,
  score,
  largePreview,
  smallPreview,
  smallCard,
  inverseColor,
  color,
  brand,
  section,
  label,
  labelConfig = {},
  crop,
  imageConstrain,
  imageUrl,
  __image,
  __displayDate,
  hideSaveDropdown,
  savedLists,
  onRemoveItem,
  onDelete,
  name
}) => {
  const classes = classNames(
    styles.ArticleCard,
    largePreview && styles.largePreview,
    smallPreview && styles.smallPreview,
    inverseColor && styles.inverseColor,
    smallCard && styles.smallCard,
    className
  )

  const imgUrl = imageUrl
    ? imageUrl
    : __image && __image.crop
    ? __image
        .crop(crop)
        .constrain(imageConstrain)
        .url()
    : versionPath('/asset/img/content/collection/default.png')

  const formattedDisplayDate = __displayDate
    ? __displayDate.formattedDisplayDate
    : ''

  const handleDelete = e => {
    e.preventDefault()
    onDelete()
  }

  const displayColor = color || (section && section.color)

  return (
    <div className={classes}>
      <Grid className={styles.ArticleCardWrapper} gutterless>
        <GridItem
          className={styles.ImageContainer}
          sizes={[
            (largePreview && 'tablet-one-half') ||
              (smallPreview && 'tablet-one-third')
          ]}
        >
          <a
            aria-label={label || (labelConfig && labelConfig.displayName) || ''}
            className={styles.ImageWrapper}
            href={__fullUrlPath}
          >
            <div className={styles.Image}>
              <img loading="lazy" src={imgUrl} alt={shortHeadline || name} />
            </div>
            {label && (
              <TagLabel
                className={styles.Tag}
                colorOverride={displayColor}
                {...labelConfig}
              >
                {(labelConfig && labelConfig.displayName) || label}
              </TagLabel>
            )}
          </a>
        </GridItem>
        <GridItem
          className={styles.ArticleBody}
          sizes={[
            (largePreview && 'tablet-one-half') ||
              (smallPreview && 'tablet-two-thirds')
          ]}
        >
          <div className={styles.ArticleText}>
            <a
              aria-label={headline || shortHeadline || name}
              className={styles.Link}
              href={__fullUrlPath}
            >
              <div className={styles.HeadingWrapper}>
                <h3 className={styles.Heading}>
                  <HeadingSlash
                    colorOverride={inverseColor ? '#fff' : undefined}
                  >
                    {headline || shortHeadline || name}
                  </HeadingSlash>
                </h3>
                <div className={styles.Actions}>
                  {onDelete && (
                    <ButtonSimple
                      className={styles.DeleteButton}
                      outline
                      onClick={handleDelete}
                      ariaLabel="delete"
                    >
                      <Icon
                        width={16}
                        height={16}
                        type="Delete"
                        a11yText="Delete"
                      />
                    </ButtonSimple>
                  )}
                </div>
              </div>

              {brand && <p className={styles.Author}>{brand}</p>}
              {sell && <p className={styles.ArticleDescription}>{sell}</p>}
            </a>
            <div className={styles.ArticleCardFooter}>
              {formattedDisplayDate && (
                <p className={styles.Date}>
                  {formattedDisplayDate}
                  {process.env.SENTRY_ENV === 'development' &&
                    score &&
                    typeof score !== 'undefined' && <> ({score.toFixed(4)})</>}
                </p>
              )}
              {!hideSaveDropdown && (
                <SaveArticle
                  type="article"
                  itemId={_id}
                  name={shortHeadline || name}
                  savedLists={savedLists}
                  onRemoveItem={onRemoveItem}
                  inverse={inverseColor}
                />
              )}
            </div>
          </div>
        </GridItem>
      </Grid>
    </div>
  )
}

ArticleCard.propTypes = {
  className: PropTypes.string,
  _id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelConfig: PropTypes.object,
  __displayDate: PropTypes.object,
  section: PropTypes.object,
  shortHeadline: PropTypes.string,
  headline: PropTypes.string,
  name: PropTypes.string,
  sell: PropTypes.string,
  brand: PropTypes.string,
  score: PropTypes.number,
  __fullUrlPath: PropTypes.string,
  largePreview: PropTypes.bool,
  smallPreview: PropTypes.bool,
  smallCard: PropTypes.bool,
  color: PropTypes.string,
  onDelete: PropTypes.func,
  inverseColor: PropTypes.bool,
  crop: PropTypes.string,
  imageConstrain: PropTypes.number,
  imageUrl: PropTypes.string,
  __image: PropTypes.object,
  hideSaveDropdown: PropTypes.bool,
  savedLists: PropTypes.array,
  onRemoveItem: PropTypes.func
}

export default ArticleCard
