import React from 'react'
import PropTypes from 'prop-types'

import styles from './Timer.styl'

class Timer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      countdownPercentage: 100,
      elapsed: 0
    }
  }

  componentDidMount() {
    this.countdown = setInterval(() => {
      const elapsed = this.state.elapsed + 100

      if (elapsed >= this.props.timer + 100) {
        clearInterval(this.countdown)
        this.props.onClose()
      }

      this.setState({
        countdownPercentage:
          ((this.props.timer - elapsed) / this.props.timer) * 100,
        elapsed: elapsed
      })
    }, 100)
  }

  componentWillUnmount() {
    clearInterval(this.countdown)
  }

  render() {
    const { countdownPercentage } = this.state
    const { color } = this.props
    return (
      <span
        className={styles.Timer}
        style={{
          width: `${countdownPercentage}%`,
          backgroundColor: color
        }}
      />
    )
  }
}

Timer.propTypes = {
  timer: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  color: PropTypes.string
}

export default Timer
