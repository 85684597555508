import 'whatwg-fetch'
import { stringify } from 'qs'
import debug from 'debug'
const log = debug('load-more:log')
const error = debug('load-more:error')

const logError = err => {
  error('Request for widgets failed', err.message)
}

const initLoadMore = el => {
  if (el.className.indexOf('js-load-more--initialized') > -1) return

  const btn = el.getElementsByClassName('js-load-more-btn')[0]
  const target = el.getElementsByClassName('js-load-more-target')[0]
  const loadMoreContainer = el.getElementsByClassName('load-more')[0]
  const ids = el.getAttribute('data-load-more-ids').split(',')
  const url = el.getAttribute('data-load-more-url')
  const showArticleProperties = el.getAttribute('data-show-article-properties')
  const widgetJson = el.getAttribute('data-widget')

  const msgPrefix = 'Trying to init load more functionality but '
  const widget = JSON.parse(widgetJson)
  const pageSize = widget.loadMorePageSize
  let currentPage = 0

  el.classList.add('js-load-more--initialized')

  if (!ids.length) return log(msgPrefix + 'no ids to load')
  if (!url) return log(msgPrefix + 'no load more url found')
  if (!target) return log(msgPrefix + 'no load more target found', el)

  const next = () => {
    const start = currentPage * pageSize
    const end = start + pageSize
    const toLoad = ids.slice(start, end)
    const lastItem = target.lastChild

    if (!toLoad.length) log('No more articles to load')
    if (end >= ids.length && btn) loadMoreContainer.style.display = 'none'
    currentPage++

    log('Loading more articles', toLoad)

    const queryIds = toLoad.join(',')
    const fullUrl =
      url +
      '?' +
      stringify({ ids: queryIds, widget: widgetJson, showArticleProperties })

    const injectArticles = articlesHtml => {
      const el = document.createElement('div')
      el.innerHTML = articlesHtml
      Array.from(el.children).forEach(child => target.appendChild(child))

      // Focus the first link in the new content
      lastItem.nextSibling.querySelector('a:not([tabindex="-1"])').focus()
    }

    fetch(fullUrl)
      .then(response => response.json())
      .then(injectArticles)
      .catch(logError)
  }

  switch (widget.loadMoreMethod) {
    default:
      if (!btn) return log(msgPrefix + 'no load more button found', el)
      btn.addEventListener('click', e => {
        e.preventDefault()
        next()
      })
      break
  }
}

export default () => {
  log('Init Load More Component')
  Array.from(document.querySelectorAll('.js-load-more')).forEach(initLoadMore)
}
