import React from 'react'
import Headroom from 'react-headroom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../../primitive/Icon'
import PreNavigation from './PreNavigation'
import MainNavigation from './MainNavigation'

import styles from './Navigation.styl'

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuOpen: false
    }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  toggleMobileMenu() {
    document && document.body.classList.toggle('noScroll')
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    })
  }

  render() {
    const { mobileMenuOpen } = this.state
    const { links, strapline, loginUrl, registerUrl } = this.props

    return (
      <>
        <PreNavigation
          strapline={strapline}
          loginUrl={loginUrl}
          registerUrl={registerUrl}
        />
        <Headroom className={styles.HeadroomContainer}>
          <div
            className={classnames(
              styles.Navigation,
              mobileMenuOpen && styles.MenuOpen
            )}
          >
            <a
              aria-label="home"
              href={'/'}
              className={styles.Logo}
              data-track="HomeButton"
            >
              <Icon type="Logo" className={styles.NavLogoIcon} width={65} />
            </a>
            <MainNavigation isOpen={mobileMenuOpen} links={links} />
            <button
              aria-label={mobileMenuOpen ? 'Close' : 'Menu'}
              className={styles.MenuToggle}
              onClick={this.toggleMobileMenu}
            >
              <Icon
                type={mobileMenuOpen ? 'Close' : 'Menu'}
                a11yText="Menu"
                width={25}
              />
            </button>
          </div>
        </Headroom>
      </>
    )
  }
}

Navigation.defaultProps = {
  homepage: { link: '/', label: 'Contagious', hoverLabel: 'Take me home' }
}

Navigation.propTypes = {
  color: PropTypes.string,
  homepage: PropTypes.object,
  links: PropTypes.array,
  strapline: PropTypes.string,
  loginUrl: PropTypes.string,
  registerUrl: PropTypes.string,
  profileUrl: PropTypes.string
}

export default Navigation
