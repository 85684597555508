import React from 'react'
import { string } from 'prop-types'

import styles from './TextSummary.styl'
import HeadingSlash from '../primitive/HeadingSlash'

const TextSummary = ({ color, date, details, name, url }) => {
  return (
    <a aria-label={name} href={url} className={styles.TextSummary}>
      {date && <div className={styles.TextSummaryDate}>{date}</div>}
      <h3>
        <HeadingSlash colorOverride={color}>{name}</HeadingSlash>
      </h3>
      {details && <div className={styles.TextSummaryDetails}>{details}</div>}
    </a>
  )
}

TextSummary.propTypes = {
  color: string,
  name: string.isRequired,
  url: string.isRequired,
  date: string,
  details: string
}

export default TextSummary
