import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import VideoGrid from '../../../layout/component/VideoGrid'
import Spacer from '../../../layout/component/primitive/Spacer'

const VideoGridWidget = props => {
  const { title, items } = props
  return (
    <div className="widget widget--video-grid">
      <Spacer>
        <div
          className="js-video-grid"
          data-props={JSON.stringify({ items, title })}
        >
          <VideoGrid items={items} title={title} />
        </div>
      </Spacer>
    </div>
  )
}

VideoGridWidget.propTypes = {
  title: string.isRequired,
  items: arrayOf(
    shape({
      title: string.isRequired,
      body: string.isRequired,
      videoProvider: string,
      videoId: string
    })
  ).isRequired
}

export default VideoGridWidget
