import StepProgressWrapper from './component/StepProgressWrapper'
import StepProgressItem from './component/StepProgressItem'

/**
 * A navigation-style component which visually tracks progress through a
 * pre-defined journey. Completed steps can optionally act as links
 * allowing navigation back to completed steps.
 */

const StepProgress = StepProgressWrapper
StepProgress.displayName = 'StepProgress'

StepProgress.Item = StepProgressItem
StepProgress.Item.displayName = 'StepProgress.Item'

export default StepProgress
