import { useEffect } from 'react'
import createDebug from 'debug'

export const SAVED_CHANGE = 'SAVED/SAVED_CHANGE'
export const SAVED_CHANGE_ALL = 'SAVED/SAVED_CHANGE_ALL'
export const NEW_LIST = 'SAVED/NEW_LIST'

const debug = createDebug('saved-dispatcher')

const dispatch = (type, detail) => {
  if (typeof window === 'undefined') return
  const event = new window.CustomEvent(type, {
    detail
  })
  debug('dispatching', type, detail)
  window.dispatchEvent(event)
}

const dispatchChange = saved => dispatch(SAVED_CHANGE, saved)
const dispatchChangeAll = saved => dispatch(SAVED_CHANGE_ALL, saved)
const dispatchNewList = list => dispatch(NEW_LIST, list)

const dispatcher = {
  dispatchChange,
  dispatchChangeAll,
  dispatchNewList
}

export default dispatcher

const useDispatcher = (type, fn) => {
  const handler = ({ detail }) => fn(detail)
  useEffect(() => {
    window.addEventListener(type, handler)
    return () => {
      window.removeEventListener(type, handler)
    }
  }, [])
}

export const useChangeDispatcher = fn => useDispatcher(SAVED_CHANGE, fn)
export const useChangeAllDispatcher = fn => useDispatcher(SAVED_CHANGE_ALL, fn)
export const useNewListDispatcher = fn => useDispatcher(NEW_LIST, fn)
