import React from 'react'
import PropTypes, { oneOf } from 'prop-types'
import classnames from 'classnames'

import BaseControl from '../BaseControl'
import Option from './Option'
import styles from './Select.styl'

const NativeSelectControl = ({ className, options, theme, ...other }) => {
  const controlClassNames = classnames(
    theme && styles[theme],
    styles.SelectElement,
    className
  )

  return (
    <BaseControl {...other} className={controlClassNames} element="select">
      {options.map((option, i) => (
        <Option key={'option' + i} option={option} />
      ))}
    </BaseControl>
  )
}

NativeSelectControl.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  type: PropTypes.string,
  theme: oneOf(['LightBg', 'DarkBg'])
}

export default NativeSelectControl
