module.exports = {
  createMapped: staticMap => ({
    versionPath: assetPath => {
      const mapUrl = urlPath => staticMap[urlPath] || urlPath
      if (Array.isArray(assetPath)) {
        return assetPath.map(mapUrl)
      } else {
        return mapUrl(assetPath)
      }
    }
  })
}
