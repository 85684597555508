import React from 'react'
import { func, number, string } from 'prop-types'
import classNames from 'classnames'
import formatPagination from './lib/pagination-formatter'

import styles from './Pagination.styl'

const Pagination = ({ total, currentPage, onChange, className }) => {
  const pages = formatPagination(total, currentPage)
  return (
    <div className={classNames(styles.Pagination, className)}>
      <button
        aria-label="previous"
        className={styles.Previous}
        disabled={currentPage <= 1}
        onClick={() => onChange(currentPage - 1)}
      >
        Previous
      </button>
      {pages.map(page =>
        page === '…' ? (
          <span className={styles.Spacer} key={`Spacer-${page}`}>
            {page}
          </span>
        ) : (
          <button
            aria-label={`page ${page}`}
            className={classNames(
              styles.Page,
              currentPage == page && styles.active
            )}
            key={`Page-${page}`}
            onClick={() => onChange(page)}
          >
            {page}
          </button>
        )
      )}
      <button
        aria-label="next"
        disabled={currentPage >= total}
        onClick={() => onChange(currentPage + 1)}
        className={styles.Previous}
      >
        Next
      </button>
    </div>
  )
}

Pagination.propTypes = {
  total: number.isRequired,
  currentPage: number,
  onChange: func.isRequired,
  className: string
}

export default Pagination
