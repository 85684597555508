import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './ArticleActions.styl'

const ArticleActions = ({ children, text, borderTop }) => {
  return (
    <div
      className={classNames(
        styles.ArticleActions,
        borderTop && styles.borderTop
      )}
    >
      {text && <div className={styles.ArticleActionsContent}>{text}</div>}
      <div className={styles.ArticleActionsButtons}>{children}</div>
    </div>
  )
}

ArticleActions.propTypes = {
  children: node.isRequired,
  text: node,
  borderTop: bool
}

export default ArticleActions
