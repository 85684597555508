import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Control from '../../primitive/ControlLegacy'
import Button from '../../primitive/Button'
import Notification from '../../primitive/Notification'
import HeadingSlash from '../../primitive/HeadingSlash'

import styles from '../NewsletterSignupForm.styl'
import { Grid, GridItem } from '../../primitive/Grid'

class NewsletterSignupForm extends React.PureComponent {
  render() {
    const { layout, title, content, formId, className, padded } = this.props
    const formClassNames = classnames(
      styles.SignupForm,
      styles[layout],
      padded && styles.padded,
      className && styles[className]
    )
    const hubspotFormId = formId || '9e922d7b-bec9-4082-bf83-cb8e54a2aec8'

    return (
      <div className={formClassNames}>
        <h2 className={styles.Heading}>
          <HeadingSlash>{title}</HeadingSlash>
        </h2>
        <Grid>
          <GridItem
            sizes={
              layout === 'vertical'
                ? [
                    'mobile-large-one-half',
                    'tablet-one-half',
                    'desktop-medium-one-whole'
                  ]
                : ['tablet-three-sevenths']
            }
          >
            <p className={styles.copy}>{content}</p>
          </GridItem>
          <GridItem
            sizes={
              layout === 'vertical'
                ? [
                    'mobile-large-one-half',
                    'tablet-one-half',
                    'desktop-medium-one-whole'
                  ]
                : ['tablet-four-sevenths']
            }
          >
            <form
              className="js-hubspot-custom-form"
              data-form-id={hubspotFormId}
              data-portal-id="1725887"
              data-communications={JSON.stringify([
                { text: 'Weekly newsletter opt in', id: 4848078 }
              ])}
            >
              <div className="js-hubspot-custom-form-success is-hidden">
                <Notification className={styles.Notification} state="success">
                  Thanks for signing up.
                </Notification>
              </div>
              <div className="js-hubspot-custom-form-error is-hidden">
                <Notification className={styles.Notification} state="error">
                  There was an error. Please try again.
                </Notification>
              </div>
              <div className="js-hubspot-custom-form-fields">
                <Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  theme="DarkBg"
                  className={styles.TextInput}
                  required
                />
                <Grid flex className={styles.GridWrapper}>
                  <GridItem
                    className={styles.CheckWrapper}
                    sizes={
                      layout === 'vertical'
                        ? [
                            'mobile-large-one-half',
                            'tablet-one-half',
                            'desktop-medium-one-whole'
                          ]
                        : ['tablet-three-sevenths']
                    }
                  >
                    <Control
                      name="checkGroup"
                      options={[
                        {
                          name: 'consentToProcess',
                          dataText: 'I agree to the privacy policy',
                          text: (
                            <span>
                              I agree to the{' '}
                              <a
                                aria-label="privacy policy"
                                href="/privacy-policy"
                                target="_blank"
                              >
                                privacy policy
                              </a>
                            </span>
                          ),
                          value: true,
                          required: true
                        }
                      ]}
                      type="checkbox"
                      theme="DarkBg"
                      className={styles.CheckGroup}
                    />
                  </GridItem>
                  <GridItem
                    className={styles.ButtonWrapper}
                    sizes={
                      layout === 'vertical'
                        ? [
                            'mobile-large-one-half',
                            'tablet-one-half',
                            'desktop-medium-one-whole'
                          ]
                        : ['tablet-four-sevenths']
                    }
                  >
                    <Button
                      ariaLabel="get it now"
                      className={styles.Button}
                      secondary
                      clipCorner
                    >
                      GET IT NOW
                    </Button>
                  </GridItem>
                </Grid>
              </div>
            </form>
          </GridItem>
        </Grid>
      </div>
    )
  }
}

NewsletterSignupForm.propTypes = {
  layout: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  padded: PropTypes.bool,
  className: PropTypes.string
}

export default NewsletterSignupForm
