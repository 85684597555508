import renderComponent from '../lib/component-renderer'

import TrialForm from '../../../../components/site/widgets/trial-form/component/TrialForm'

const debug = require('debug')('trialForm')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init trialForm')
    renderComponent('.js-trial-form', TrialForm)
  })
}
