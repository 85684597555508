import React from 'react'
import { bool, func, node, number, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './StepProgressItem.styl'

import VisuallyHidden from '../../../primitive/VisuallyHidden'

const StepProgressItem = ({
  children,
  complete,
  current,
  href,
  onClick,
  status,
  to
}) => {
  const isClickable = (complete || current) && (href || to || onClick)
  const StepProgressItemEl = isClickable ? 'a' : 'div'
  return (
    <li
      className={classNames(
        styles.StepProgressItem,
        complete && styles.complete,
        current && styles.current,
        status && styles[status]
      )}
      {...(current && { 'aria-current': 'step' })}
    >
      <StepProgressItemEl
        className={styles.StepProgressItemInner}
        {...(href && { href })}
        {...(onClick && { onClick })}
        {...(to && { to })}
      >
        <VisuallyHidden>{children}</VisuallyHidden>
        {complete && <VisuallyHidden> – Complete</VisuallyHidden>}
      </StepProgressItemEl>
    </li>
  )
}

StepProgressItem.propTypes = {
  children: node.isRequired,
  complete: bool,
  current: bool,
  icon: string,
  progress: number,
  status: oneOf(['positive', 'negative']),
  href: string,
  to: string,
  onClick: func
}

export default StepProgressItem
