import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import NativeCheckControl from './NativeCheck'
import styles from './Check.styl'

const CustomCheckControl = props => {
  const { error, modifiers, type, color } = props
  const controlClassNames = classNames(
    styles.CustomCheckControl,
    modifiers &&
      modifiers.map(modifierClass => `custom-check--${modifierClass}`),
    { 'custom-check--error': error }
  )

  return (
    <span className={controlClassNames} style={color && { color: color }}>
      <NativeCheckControl {...props} className={styles.CustomCheckInput} />
      <span
        className={classNames(
          type === 'checkbox'
            ? styles.CustomCheckIndicator
            : styles.CustomRadioIndicator,
          'custom-check-indicator'
        )}
      />
    </span>
  )
}

CustomCheckControl.propTypes = {
  error: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default CustomCheckControl
