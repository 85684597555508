import React from 'react'
import PropTypes from 'prop-types'

import CheckControl from './check'
import TextControl from './text'
import SelectControl from './select'
import TextareaControl from './textarea'
import FileControl from './file'

const Control = props => {
  const { type } = props
  const CheckBox = () => <CheckControl {...props} />
  const Select = () => <SelectControl {...props} />
  const Textarea = () => <TextareaControl {...props} />
  const File = () => <FileControl {...props} />

  const componentMap = {
    checkbox: CheckBox,
    radio: CheckBox,
    select: Select,
    textarea: Textarea,
    file: File
  }

  return componentMap[type] ? componentMap[type]() : <TextControl {...props} />
}

Control.propTypes = {
  type: PropTypes.string
}

export default Control
