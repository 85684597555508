import React from 'react'

import FooterNewsletterSignupForm from '../component'

const FooterNewsletterSignupFormContainer = () => (
  <div className="js-footer-newsletter-signup-form">
    <FooterNewsletterSignupForm />
  </div>
)

export default FooterNewsletterSignupFormContainer
