import React from 'react'
import { number, string } from 'prop-types'
import classNames from 'classnames'

import Icon from '../../../../primitive/Icon'

import styles from './NavLogo.styl'

const NavLogo = ({ label, url, size }) => (
  <a href={url} className={classNames(styles.NavLogo)} data-track="HomeButton">
    <Icon
      type="Logo"
      a11yText={label}
      className={styles.NavLogoIcon}
      width={size ? size : 60}
    />
  </a>
)

NavLogo.propTypes = {
  label: string,
  url: string,
  size: number
}

export default NavLogo
