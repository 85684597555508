import renderComponent from '../lib/component-renderer'

import PersonnelCarousel from '../../../../components/site/widgets/personnel-carousel/component/PersonnelCarousel'

const debug = require('debug')('personnelCarousel')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init personnelCarousel')
    renderComponent('.js-personnel-carousel', PersonnelCarousel)
  })
}
