import renderComponent from '../lib/component-renderer'
import BestInClass from '../../../site/layout/component/BestInClass'

const debug = require('debug')('best in class')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init best in class')
    renderComponent('.js-best-in-class', BestInClass)
  })
}
