import clone from 'lodash.clonedeep'

/**
 * Sets the `.checked` properties of a labels group that are present in a filter from the possible options
 */
export default (options = [], selectedLabels = []) => {
  const newLabels = clone(options).map(label => ({
    ...label,
    checked: selectedLabels.includes(label.name)
  }))
  return newLabels
}
