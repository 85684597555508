const hat = require('hat')
const required = require('@clocklimited/validity-required')
const schemata = require('schemata')
const oneOf = require('validity-required-options')
const validateIfPropertyEquals = require('@clocklimited/validity-validate-if-property-equals')
const validateIfPropertyNotSet = require('validity-validate-if-property-not-set')
const url = require('@clocklimited/validity-url')
const compose = require('validity-compose')
const itemTypes = require('./item-types.json')

export default () =>
  schemata({
    name: 'Collection Item',
    properties: {
      id: {
        type: String,
        defaultValue: () => hat()
      },
      type: {
        type: String,
        validators: {
          all: [oneOf(itemTypes)]
        }
      },
      articleId: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'article', required)]
      },
      title: {
        type: String,
        validators: [
          validateIfPropertyEquals(
            'type',
            'comment',
            validateIfPropertyNotSet('comment', required)
          ),
          validateIfPropertyEquals('type', 'heading', required)
        ]
      },
      link: {
        type: String,
        validators: [
          validateIfPropertyEquals('type', 'link', compose([required, url]))
        ]
      },
      imageId: {
        type: String
      },
      note: {
        type: String
      },
      author: {
        type: String
      },
      authorId: {
        type: String
      },
      comment: {
        type: String,
        validators: [
          validateIfPropertyEquals(
            'type',
            'comment',
            validateIfPropertyNotSet('title', required)
          )
        ]
      },
      legacyId: {
        type: Number
      },
      collectionId: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'collection', required)]
      }
    }
  })
