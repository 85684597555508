import React from 'react'
import renderComponent from '../lib/component-renderer'

import OnboardingLoginContainer from './container/OnboardingLogin'
import Login from '../../../site/identity/component/Login'
import PasswordResetRequest from '../../../../components/site/identity/component/PasswordResetRequest'
import PasswordReset from '../../../../components/site/identity/component/PasswordReset'

const debug = require('debug')('identity')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Login')
    renderComponent('.js-login', props => (
      <OnboardingLoginContainer {...props} component={Login} />
    ))
    debug('init PasswordResetRequest')
    renderComponent('.js-password-reset-request', PasswordResetRequest)
    debug('init PasswordRequest')
    renderComponent('.js-password-reset', PasswordReset)
  })
}
