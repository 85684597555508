import React from 'react'
import { string, number, array, oneOfType, node, func, bool } from 'prop-types'

import Surround from '../primitive/Surround'
import ButtonSimple from '../primitive/ButtonSimple'
import StepProgress from '../StepProgress'

import styles from './OnboardingFormLayout.styl'

const OnboardingFormLayout = ({
  title,
  subtitle,
  step,
  children,
  onSubmit,
  onSkip,
  onBack,
  buttonText,
  footer,
  disabled,
  hideButton = false,
  formAction
}) => {
  const subtitleEl = typeof subtitle === 'string' ? <p>{subtitle}</p> : subtitle
  const titleEl = typeof title === 'string' ? <h2>{title}</h2> : title
  return (
    <Surround
      background="white"
      padded
      rounded
      shadow
      className={styles.OnboardingFormLayout}
    >
      {onBack && (
        <button
          aria-label="back"
          className={styles.BackButton}
          onClick={onBack}
        >
          Back
        </button>
      )}
      {onSkip && (
        <button
          aria-label="skip"
          className={styles.SkipButton}
          onClick={onSkip}
        >
          Skip
        </button>
      )}
      {step && (
        <div className={styles.Steps}>
          <StepProgress label="Onboarding progress" title={`Step ${step}`}>
            {Array(6)
              .fill()
              .map((_, i) => (
                <StepProgress.Item
                  key={`Step ${i}`}
                  icon="_placeholder"
                  complete={step > i}
                >
                  {`Step ${step}`}
                </StepProgress.Item>
              ))}
          </StepProgress>
        </div>
      )}
      <form method="post" action={formAction ? formAction : null}>
        <div className={styles.Information}>
          {titleEl}
          {subtitleEl}
        </div>
        <div className={styles.Content}>{children}</div>
        {!hideButton && (
          <div className={styles.Save}>
            <ButtonSimple
              ariaLabel={buttonText || 'Save and continue'}
              type="submit"
              disabled={disabled}
              onClick={onSubmit}
            >
              {buttonText || 'Save and continue'}
            </ButtonSimple>
          </div>
        )}
      </form>
      {footer && <div className={styles.Footer}>{footer}</div>}
    </Surround>
  )
}

OnboardingFormLayout.propTypes = {
  title: oneOfType([string, node]),
  subtitle: oneOfType([string, node]),
  step: number,
  children: oneOfType([array, node]),
  onSubmit: func,
  onSkip: func,
  onBack: func,
  buttonText: string,
  disabled: bool,
  footer: node,
  hideButton: bool,
  formAction: string
}

export default OnboardingFormLayout
