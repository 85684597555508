import React from 'react'
import OnboardingHero from '../../../layout/component/OnboardingPage/OnboardingHero'
import OnboardingCompleteForm from '../../../layout/component/OnboardingForm/OnboardingCompleteForm'
import { onboardingImages } from '../../../layout/component/OnboardingPage/data/onboarding-page-data'

const Complete = props => (
  <OnboardingHero backgroundMobile images={onboardingImages}>
    <OnboardingCompleteForm {...props} />
  </OnboardingHero>
)

export default Complete
