import renderComponent from '../lib/component-renderer'
import MembershipTable from '../../../../components/site/layout/component/MembershipTable'

const debug = require('debug')('MembershipTable')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-membership-table', MembershipTable)
  })
}
