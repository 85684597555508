import React from 'react'
import { shape, func, string, arrayOf } from 'prop-types'
import Modal from '../Modal'
import { withColor } from '../ColorContext'
import HeadingSlash from '../primitive/HeadingSlash'
import ButtonSimple from '../primitive/ButtonSimple'
import Icon from '../primitive/Icon'

import styles from './SavedListsModal.styl'

const SavedListsModal = ({
  savedLists,
  activeList,
  onClose,
  onChangeList,
  onCreateList
}) => {
  const handleChangeList = savedList => {
    onClose()
    onChangeList(savedList)
  }

  return (
    <Modal
      onClose={onClose}
      className={styles.SavedListsModal}
      rounded
      fullScreenMobile
    >
      <h2 className={styles.SavedListHeading}>
        <HeadingSlash>Your saved lists</HeadingSlash>
      </h2>
      <ButtonSimple
        className={styles.CloseButton}
        transparent
        onClick={onClose}
        ariaLabel="close modal"
      >
        <Icon width={24} height={24} type="Close" a11yText="Close modal" />
      </ButtonSimple>
      <ul className={styles.SavedLists}>
        {savedLists.map(savedList => (
          <li
            key={`SavedLists-${savedList._id}`}
            className={
              activeList && savedList._id === activeList._id && styles.active
            }
          >
            <a
              href={`/iq/saved/${savedList._id}`}
              onClick={e => {
                e.preventDefault()
                handleChangeList(savedList)
              }}
            >
              {savedList.name}
            </a>
          </li>
        ))}
      </ul>

      <ButtonSimple outline onClick={onCreateList} ariaLabel="new list">
        <Icon width={12} height={12} type="Plus" a11yText="Create new list" />{' '}
        New List
      </ButtonSimple>
    </Modal>
  )
}

const savedListShape = shape({
  _id: string.isRequired,
  name: string.isRequired
})

SavedListsModal.propTypes = {
  savedLists: arrayOf(savedListShape).isRequired,
  activeList: savedListShape.isRequired,
  onClose: func.isRequired,
  onChangeList: func.isRequired,
  onCreateList: func.isRequired
}

export default withColor(SavedListsModal)
