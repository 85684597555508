import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import styles from './ShrinkWrap.styl'

export class ShrinkWrapItem extends PureComponent {
  render() {
    const { children, shrink, vAlign } = this.props
    return (
      <span
        className={classNames(
          styles.ShrinkWrapItem,
          shrink && styles.shrink,
          vAlign && styles[vAlign]
        )}
      >
        {children}
      </span>
    )
  }
}

ShrinkWrapItem.displayName = 'ShrinkWrapItem'

ShrinkWrapItem.propTypes = {
  children: node.isRequired,
  shrink: bool,
  vAlign: oneOf(['top', 'middle', 'bottom'])
}

class ShrinkWrap extends PureComponent {
  render() {
    const { children, element, fixed, fullWidth, spacing } = this.props
    const ShrinkWrapEl = element || 'div'

    return (
      <ShrinkWrapEl
        className={classNames(
          styles.ShrinkWrap,
          fixed && styles.fixed,
          fullWidth && styles.fullWidth,
          spacing && styles[spacing]
        )}
      >
        {children}
      </ShrinkWrapEl>
    )
  }
}

ShrinkWrap.displayName = 'ShrinkWrap'

ShrinkWrap.propTypes = {
  children: node.isRequired,
  element: string,
  fixed: bool,
  fullWidth: bool,
  spacing: oneOf(['none', 'narrow', 'medium'])
}

export default ShrinkWrap
