import React from 'react'
import { arrayOf, bool, shape, string, oneOfType, node } from 'prop-types'

import styles from './WidgetHeader.styl'
import HeadingSlash from '../primitive/HeadingSlash'
import LinkWrapper from '../primitive/LinkWrapper'
import classNames from 'classnames'

const WidgetHeader = ({
  title,
  description,
  link,
  links,
  inverseColor,
  smallTitle
}) => {
  return (
    <div
      className={classNames(
        styles.WidgetHeader,
        inverseColor && styles.inverseColor,
        smallTitle && styles.smallTitle
      )}
    >
      <div className={styles.WidgetHeaderTitleWrapper}>
        <LinkWrapper link={link} className={styles.WidgetHeaderTitleLink}>
          <h2 className={styles.WidgetHeaderTitle}>
            {title}
            <HeadingSlash colorOverride={inverseColor ? '#fff' : undefined} />
          </h2>
        </LinkWrapper>
        <div className={styles.WidgetHeaderText}>
          <p>{description}</p>
        </div>
      </div>
      {links && links.length > 0 && (
        <ul className={styles.WidgetHeaderLinkList}>
          {links.map(link => (
            <li key={link.url} className={styles.WidgetHeaderLinkItem}>
              <a
                aria-label={link.text}
                href={link.url}
                className={styles.WidgetHeaderLink}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

WidgetHeader.defaultProps = {
  links: []
}

WidgetHeader.propTypes = {
  title: oneOfType([string, node]),
  description: string,
  link: string,
  links: arrayOf(shape({ text: string, url: string })),
  inverseColor: bool,
  smallTitle: bool
}

export default WidgetHeader
