import React, { PureComponent } from 'react'
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types'

import styles from './RadioBlocks.styl'

import Icon from '../Icon'

export class RadioBlock extends PureComponent {
  render() {
    const {
      children,
      name,
      value,
      checked,
      onChange,
      type = 'radio'
    } = this.props
    return (
      <label className={styles.RadioBlock}>
        <input
          type={type}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span className={styles.RadioBlockContent}>
          <span className={styles.RadioBlockIcon}>
            <Icon type="Check" a11yText="" width={20} height={20} />
          </span>
          {children}
        </span>
      </label>
    )
  }
}

RadioBlock.displayName = 'RadioBlock'

RadioBlock.propTypes = {
  children: node.isRequired,
  checked: bool,
  name: string,
  value: oneOfType([bool, string]),
  onChange: func,
  type: oneOf(['radio', 'checkbox'])
}

class RadioBlocks extends PureComponent {
  render() {
    const { children, element } = this.props
    const RadioBlocksEl = element || 'div'

    return (
      <RadioBlocksEl className={styles.RadioBlocks}>{children}</RadioBlocksEl>
    )
  }
}

RadioBlocks.displayName = 'RadioBlocks'

RadioBlocks.propTypes = {
  children: node.isRequired,
  element: string
}

export default RadioBlocks
