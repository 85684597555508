import React from 'react'
import { array, object, number, func, bool } from 'prop-types'
import CheckboxGrid from '../CheckboxGrid'
import Tabs from '../Tabs'
import ScrollArea from '../primitive/ScrollArea'
import SelectInput from '../primitive/SelectInput'
import styles from './CategoryPickerForm.styl'

const CategoryPickerForm = ({
  taxonomies,
  categories,
  hasSelected,
  checkboxColumns = 3,
  onToggle,
  onSetOption,
  noScroll
}) => {
  return (
    <div className={noScroll && styles.noScroll}>
      {taxonomies.length === 1 && (
        <div className={styles.Categories}>
          <ScrollArea className={styles.ScrollArea}>
            <CheckboxGrid
              columnCount={checkboxColumns}
              items={categories[taxonomies[0]._id].map(category => ({
                _id: category._id,
                name: category.name,
                value: true,
                checked: hasSelected(taxonomies[0]._id, category._id)
              }))}
              onChange={category => {
                onToggle(taxonomies[0]._id, category._id)
              }}
            />
          </ScrollArea>
        </div>
      )}

      {taxonomies.length > 1 && (
        <Tabs>
          {taxonomies.map(taxonomy => (
            <Tabs.Item
              label={taxonomy.name}
              key={`Categories-Taxonomy-${taxonomy._id}`}
            >
              <div className={styles.Categories}>
                {categories[taxonomy._id].length > 300 ? (
                  <>
                    <p>Type the name of the brand you’re interested in.</p>
                    <SelectInput
                      defaultValue={
                        categories[taxonomy._id]
                          ? categories[taxonomy._id]
                              .filter(({ _id }) =>
                                hasSelected(taxonomy._id, _id)
                              )
                              .map(category => ({
                                label: category.name,
                                value: category._id
                              }))
                          : []
                      }
                      onChange={(_, { action, option, removedValue }) => {
                        if (action === 'select-option') {
                          onSetOption(taxonomy._id, option.value, true)
                        } else if (action === 'remove-value') {
                          onSetOption(taxonomy._id, removedValue.value, false)
                        }
                      }}
                      options={categories[taxonomy._id].map(category => ({
                        label: category.name,
                        value: category._id
                      }))}
                    />
                  </>
                ) : (
                  <ScrollArea className={styles.ScrollArea}>
                    <CheckboxGrid
                      columnCount={checkboxColumns}
                      items={categories[taxonomy._id].map(category => ({
                        _id: category._id,
                        name: category.name,
                        value: true,
                        checked: hasSelected(taxonomy._id, category._id)
                      }))}
                      onChange={category => {
                        onToggle(taxonomy._id, category._id)
                      }}
                    />
                  </ScrollArea>
                )}
              </div>
            </Tabs.Item>
          ))}
        </Tabs>
      )}
    </div>
  )
}

CategoryPickerForm.propTypes = {
  taxonomies: array.isRequired,
  categories: object.isRequired,
  checkboxColumns: number,
  hasSelected: func.isRequired,
  onToggle: func.isRequired,
  onSetOption: func.isRequired,
  noScroll: bool
}

export default CategoryPickerForm
