import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'

import Field, {
  FieldQuestion,
  FieldAnswer,
  // FieldRequired,
  FieldAssistance,
  FieldFeedback
} from '../../Field'
import VisuallyHidden from '../../VisuallyHidden'

const CheckFieldTemplate = props => {
  const {
    assistance,
    children,
    controlName,
    feedback,
    hideLabel,
    inverse,
    label,
    // required,
    status
  } = props

  const CheckFieldTemplateQuestion = () => (
    <FieldQuestion
      htmlFor={controlName} /* matches Control controlName */
      element="div"
      id={`field-question--${controlName}`}
    >
      {label}
      {/* {required && <FieldRequired />} */}
    </FieldQuestion>
  )

  return (
    <Field
      id={`field--${controlName}`}
      status={status}
      aria-labelledby={label && `field-question--${controlName}`}
      role="group"
      template="check"
    >
      {label &&
        (hideLabel ? (
          <VisuallyHidden>
            <CheckFieldTemplateQuestion />
          </VisuallyHidden>
        ) : (
          <CheckFieldTemplateQuestion />
        ))}

      <FieldAnswer>{children}</FieldAnswer>
      {assistance && <FieldAssistance>{assistance}</FieldAssistance>}
      {feedback && <FieldFeedback inverse={inverse}>{feedback}</FieldFeedback>}
    </Field>
  )
}

CheckFieldTemplate.propTypes = {
  assistance: node,
  controlName: string.isRequired,
  children: node.isRequired,
  feedback: node,
  hideLabel: bool,
  inverse: bool,
  label: node,
  // required: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default CheckFieldTemplate
