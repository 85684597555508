import React, { memo } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import classNames from 'classnames'

import OnboardingHero from './OnboardingHero'
import ImageFaderGrid from '../ImageFaderGrid'
import ButtonSimple from '../primitive/ButtonSimple'
import { Grid, GridItem } from '../primitive/Grid'
import HeadingSlash from '../primitive/HeadingSlash'
import OnboardingQuotesCarousel from './OnboardingQuotesCarousel'

import { brands, cards, quotes } from './data/onboarding-page-data'
import styles from './OnboardingPage.styl'

import { shuffleArray } from '../../../../../lib/array-shuffler'

const OnboardingPage = ({ name, images, inviteLink }) => {
  return (
    <>
      <OnboardingHero images={images} withScrollIndicator>
        <HeroContent name={name} inviteLink={inviteLink} />
      </OnboardingHero>
      <Brands />
      <Cards />
      <Quotes inviteLink={inviteLink} />
    </>
  )
}

OnboardingPage.propTypes = {
  name: string.isRequired,
  inviteLink: string.isRequired,
  images: arrayOf(shape({ src: string, alt: string }))
}

const HeroContent = ({ name, inviteLink }) => (
  <div className={styles.Content}>
    <h2>Supercharge your creativity</h2>
    <p>{name} now has access to Contagious IQ.</p>
    <p>
      Contagious IQ is a tool for briefing more effectively, developing
      compelling strategies and inspiring teams with world-class creativity. Its
      goal is simple: to make you smarter at marketing.
    </p>
    <p>Register now and learn from the best, so you can make the best</p>
    <ButtonSimple ariaLabel="register" href={inviteLink}>
      Register
    </ButtonSimple>
  </div>
)

HeroContent.propTypes = {
  name: string.isRequired,
  inviteLink: string.isRequired
}

const BrandsComponent = () => (
  <section className={styles.Brands}>
    <div className={classNames(styles.BrandsWrapper, styles.Container)}>
      <h3>Used by the world’s leading brands and agencies</h3>
      <ImageFaderGrid images={shuffleArray(brands)} width={165} height={118} />
    </div>
  </section>
)

export const Brands = memo(BrandsComponent)

const CardsComponent = () => (
  <section className={classNames(styles.Container, styles.Cards)}>
    <Grid flex gap={6}>
      {cards.map((card, i) => (
        <GridItem
          className={styles.GridItem}
          sizes={['whole', 'tablet-one-half']}
          key={`Card-${i}`}
          spacingBottom={4}
        >
          <div className={styles.Card}>
            <h4>
              <HeadingSlash colorOverride="#FF0096">{card.title}</HeadingSlash>
            </h4>
            {card.body}
          </div>
        </GridItem>
      ))}
    </Grid>
  </section>
)

export const Cards = memo(CardsComponent)

const QuotesComponent = ({ inviteLink }) => (
  <section className={styles.Quotes}>
    <div className={styles.Container}>
      <div className={styles.QuotesWrapper}>
        <h3>Don’t just take our word for it.</h3>
        <OnboardingQuotesCarousel quotes={quotes} />
      </div>
      {inviteLink && (
        <ButtonSimple ariaLabel="register" white href={inviteLink}>
          Register
        </ButtonSimple>
      )}
    </div>
  </section>
)

QuotesComponent.propTypes = {
  inviteLink: string
}

export const Quotes = memo(QuotesComponent)

export default OnboardingPage
