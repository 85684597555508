import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { withColor } from '../ColorContext'
import NewListModal from './NewListModal'

const debug = require('debug')('new-list')

const NewListModalContainer = ({ type, itemId, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [listLink, setListLink] = useState(null)

  const handleCreate = async name => {
    debug('creating saved list', name)
    let response
    try {
      response = await fetch(
        `/api/saved-lists?${itemId ? `type=${type}&itemId=${itemId}` : ''}`,
        {
          method: 'POST',
          credentials: 'include',
          redirect: 'error',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name })
        }
      )
    } catch (error) {
      setLoading(false)
      debug('create saved list error', error)
      return setError('There was a problem. Please try again later.')
    }

    setLoading(false)
    if (response.status !== 200) {
      debug('create saved list error', response.status)
      return setError('There was a problem. Please try again later.')
    }

    const createdList = await response.json()
    setListLink(createdList.link)
    setSuccess(true)
    onSuccess(createdList)
  }

  return (
    <NewListModal
      itemId={itemId}
      success={success}
      error={error}
      listLink={listLink}
      loading={loading}
      onClose={onClose}
      onCreate={handleCreate}
    />
  )
}

NewListModalContainer.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  type: string,
  itemId: string
}

export default withColor(NewListModalContainer)
