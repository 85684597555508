import React, { useState } from 'react'
import { array } from 'prop-types'
import ReadingList from '../../../../site/layout/component/ReadingList'
import formatItem from '../../../../site/widgets/reading-list/lib/item-formatter'
import {
  useChangeAllDispatcher,
  useChangeDispatcher,
  useNewListDispatcher
} from '../../../../site/layout/component/SaveArticle/lib/saved-update-dispatcher'

const ReadingListContainer = ({ items: initialItems }) => {
  const [items, setItems] = useState(initialItems)

  const onSavedChange = async () => {
    const res = await fetch('/api/saved-list/recent?limit=4', {
      credentials: 'include',
      redirect: 'error'
    })
    const data = await res.json()
    setItems(data.map(formatItem))
  }

  useChangeDispatcher(onSavedChange)
  useChangeAllDispatcher(onSavedChange)
  useNewListDispatcher(onSavedChange)
  return <ReadingList items={items} />
}

ReadingListContainer.propTypes = {
  items: array.isRequired
}

export default ReadingListContainer
