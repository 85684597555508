import renderComponent from '../lib/component-renderer'

import ProfileForm from '../../../site/layout/component/ProfileForm'

const debug = require('debug')('profile')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init Profile')
    renderComponent('.js-profile', ProfileForm)
  })
}
