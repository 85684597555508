import renderComponent from '../lib/component-renderer'
import Video from '../../../../components/site/layout/component/video'

const debug = require('debug')('video')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    renderComponent('.js-video', Video)
  })
}
