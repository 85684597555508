import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import ButtonSimple from '../primitive/ButtonSimple'
import TextControl from '../primitive/Control/TextControl'
import FieldTemplate from '../primitive/FieldTemplate'

import styles from './CommentForm.styl'

const CommentForm = ({ onClose, onChange, onSubmit, errors, data, isNew }) => (
  <Modal
    onClose={onClose}
    className={styles.CommentForm}
    title="Add Comment"
    rounded
  >
    <form onSubmit={onSubmit}>
      <div className={styles.CollectionSelect}>
        <FieldTemplate
          label="Title"
          controlName="title"
          status={errors.title && 'error'}
          feedback={errors.title}
        >
          <TextControl
            type="text"
            name="title"
            placeholder="Title"
            value={data.title}
            onChange={onChange}
            status={errors.title && 'error'}
            required
          />
        </FieldTemplate>
        <FieldTemplate
          label="Comment"
          controlName="comment"
          status={errors.comment && 'error'}
          feedback={errors.comment}
        >
          <TextControl
            type="textarea"
            name="comment"
            placeholder="Comment"
            rows={5}
            value={data.comment}
            onChange={onChange}
            status={errors.comment && 'error'}
            multiline
            required
          />
        </FieldTemplate>
      </div>
      <div className={styles.ButtonGroup}>
        <ButtonSimple
          ariaLabel="cancel"
          type="button"
          outline
          onClick={onClose}
        >
          Cancel
        </ButtonSimple>
        <ButtonSimple
          ariaLabel={isNew ? 'Add to collection' : 'Save'}
          type="submit"
        >
          {isNew ? 'Add to collection' : 'Save'}
        </ButtonSimple>
      </div>
    </form>
  </Modal>
)

CommentForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  data: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired
}

export default CommentForm
