import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icon from '../primitive/Icon'
import Prose from '../Prose'

import styles from './Expander.styl'

export default class Expander extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isExpanded: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  render() {
    const { question, answer } = this.props
    const FaqHeading = () => {
      return <div>{question}</div>
    }

    return (
      <li
        className={classnames(
          styles.Expander,
          this.state.isExpanded && styles.isActive
        )}
      >
        <button
          aria-label={this.state.isExpanded ? 'view less' : 'view more'}
          className={styles.Question}
          onClick={this.handleClick}
        >
          <FaqHeading />
          <Icon
            width={12}
            height={10}
            className={styles.Icon}
            type={this.state.isExpanded ? 'ChevronUp' : 'ChevronDown'}
          />
        </button>
        <Prose className={styles.Answer} dangerousHtml={answer} />
      </li>
    )
  }
}

Expander.defaultProps = {}

Expander.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
}
