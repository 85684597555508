import React, { useEffect, useRef, useState } from 'react'
import { array, bool, func, object } from 'prop-types'
import classNames from 'classnames'
import { withColor } from '../ColorContext'

import useScrollBlock from '../../lib/hook/useScrollBlock'
import useEscapeKey from '../../lib/hook/useEscapeKey'
import useOutsideClick from '../../lib/hook/useOutsideClick'

import styles from './SlideNav.styl'

import Icon from '../primitive/Icon'
import NavLogo from './component/NavLogo'

const SlideNav = ({ expanded, links, onClose }) => {
  const [activePanelKey, updateActivePanelKey] = useState()
  const [visibleLevel, updateVisibleLevel] = useState(0)

  const focusCloseTarget = useRef()

  const handleLinkButtonClick = key => {
    updateActivePanelKey(key)
    updateVisibleLevel(1)
  }

  const handleClose = () => {
    updateVisibleLevel(0)
    updateActivePanelKey(null)
    onClose && onClose()
  }

  const handleBackClick = () => {
    updateVisibleLevel(0)
  }

  // Switch focus to appropriate target when switching nav levels
  useEffect(() => {
    focusCloseTarget.current.focus()
  }, [visibleLevel])

  // Move focus within nav on open
  useEffect(() => {
    if (!expanded) return
    focusCloseTarget.current.focus()
  }, [expanded])

  // Stop site scrolling when nav is open
  const [blockScroll, allowScroll] = useScrollBlock()
  useEffect(() => {
    if (expanded) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [expanded])

  const wrapper = useRef()
  useEscapeKey(expanded && handleClose)
  useOutsideClick(wrapper, expanded && handleClose)

  const secondaryLinks = [
    {
      url: '/iq/feed',
      title: 'Alerts'
    },
    {
      url: '/iq/profile',
      title: 'Account Settings'
    }
  ]

  return (
    <div className={classNames(styles.SlideNav, expanded && styles.expanded)}>
      <div className={styles.SlideNavBg} />
      <div
        className={classNames(
          styles.SlideNavPanel,
          visibleLevel > 0 && styles.secondaryActive
        )}
        ref={wrapper}
      >
        <div className={styles.SlideNavPanelInner}>
          {/* Header */}
          <div className={styles.SlideNavHeader}>
            <NavLogo url="/iq" label="Return to Homepage" />

            {/* Close Button */}
            <button
              aria-label="close navigation"
              onClick={handleClose}
              className={styles.SlideNavCloseButton}
              ref={focusCloseTarget}
            >
              <Icon type="Close" a11yText="Close Navigation" />
            </button>
          </div>

          {/* Lists Wrapper */}
          <div className={styles.SlideNavContent}>
            {/* Primary */}
            <div
              className={classNames(
                styles.SlideNavContentInner,
                styles.SlideNavContentInnerLeft
              )}
            >
              <ul>
                {Object.entries(links).map(([key, value]) => {
                  return (
                    <li key={`PrimaryLink${key}`}>
                      {(value.links || value.items) && (
                        <button
                          aria-label={value.title}
                          onClick={() => handleLinkButtonClick(key)}
                          className={classNames(
                            styles.SlideNavListLink,
                            activePanelKey &&
                              activePanelKey === key &&
                              styles.active
                          )}
                        >
                          <span>{value.title}</span>
                          <Icon type="ChevronRight" width={8} />
                        </button>
                      )}
                      {!value.links && !value.items && (
                        <a
                          href={value.url}
                          className={classNames(
                            styles.SlideNavListLink,
                            activePanelKey &&
                              activePanelKey === key &&
                              styles.active,
                            value.colourOverride && styles.isPink
                          )}
                        >
                          <span>{value.title}</span>
                        </a>
                      )}
                    </li>
                  )
                })}
                {secondaryLinks.map(link => (
                  <li
                    className={styles.SlideNavListSecondaryItem}
                    key={`PrimaryLink${link.url}`}
                  >
                    <a
                      aria-label={link.title}
                      href={link.url}
                      className={styles.SlideNavListLink}
                    >
                      <span>{link.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div
              className={classNames(
                styles.SlideNavContentInner,
                styles.SlideNavContentInnerRight
              )}
            >
              <div>
                {/* Back Button */}
                <button
                  aria-label="back"
                  onClick={handleBackClick}
                  className={styles.SlideNavBack}
                >
                  <Icon type="ChevronLeft" width={8} />{' '}
                  <span className={styles.SlideNavBackLabel}>Back</span>
                </button>

                {activePanelKey && links[activePanelKey].links && (
                  <ul>
                    {links[activePanelKey].links.map(item => {
                      return (
                        <li key={`SecondaryLink${item.url}`}>
                          <a
                            href={item.url}
                            className={styles.SlideNavListLink}
                          >
                            {item.title}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                )}

                {activePanelKey && links[activePanelKey].items && (
                  <ul>
                    {links[activePanelKey].items.map(item => {
                      return (
                        <li key={`SecondaryLink${item.url}`}>
                          <a
                            data-track="NavLink"
                            data-track-label={item.text}
                            href={item.url}
                            className={styles.SlideNavListLink}
                          >
                            {item.text}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SlideNav.defaultProps = {
  links: {}
}

SlideNav.propTypes = {
  expanded: bool,
  links: object,
  secondaryLinks: array,
  onClose: func
}

export default withColor(SlideNav)
