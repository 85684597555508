import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withColor } from '../../ColorContext'

import styles from './Button.styl'

class Button extends PureComponent {
  render() {
    const {
      className,
      children,
      disabled,
      href,
      target,
      type,
      primary,
      secondary,
      tertiary,
      danger,
      outline,
      muted,
      fullWidth,
      clipCorner,
      clipBottom,
      navButton,
      icon,
      small,
      refNode,
      ariaLabel,
      ...other
    } = this.props
    const classes = classNames(
      styles.Button,
      primary && (icon ? styles.iconPrimary : styles.primary),
      secondary && styles.secondary,
      outline && styles.outline,
      tertiary && styles.tertiary,
      danger && styles.danger,
      muted && styles.muted,
      fullWidth && styles.fullWidth,
      clipCorner && styles.clipCorner,
      clipBottom && styles.clipBottom,
      navButton && styles.navButton,
      icon && styles.icon,
      small && styles.small,
      className && className
    )

    if (href) {
      return (
        <a
          className={classes}
          aria-label={ariaLabel || ''}
          href={href}
          {...(target && { target })}
          {...(target === '_blank' && { rel: 'noopener noreferrer' })}
          ref={refNode}
          {...other}
        >
          {children}
        </a>
      )
    } else {
      return (
        <button
          aria-label={ariaLabel || ''}
          className={classes}
          type={type}
          disabled={disabled}
          ref={refNode}
          {...other}
        >
          {children}
        </button>
      )
    }
  }
}

Button.propTypes = {
  name: PropTypes.string.isRequired
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  danger: PropTypes.bool,
  outline: PropTypes.bool,
  muted: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clipCorner: PropTypes.bool,
  clipBottom: PropTypes.bool,
  navButton: PropTypes.bool,
  icon: PropTypes.bool,
  small: PropTypes.bool,
  refNode: PropTypes.func,
  ariaLabel: PropTypes.string
}

export default withColor(Button)
